import DeleteIcon from "@mui/icons-material/Delete";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'; import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import TablePagination from "@mui/material/TablePagination";
import ApprovalIcon from "@mui/icons-material/Approval";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { FontAwesomeIconCompo } from "../IconButton";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { ChipCompo, MyComponentWithIconProps } from "../IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import DownloadIcon from "@mui/icons-material/Download";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { LabelCompo } from "../Label";
import ViewListIcon from "@mui/icons-material/ViewList";
import ReportIcon from "@mui/icons-material/Report";
import ContactsIcon from "@mui/icons-material/Contacts";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Link from "@mui/material/Link";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, tableHead, hiddenColumnNames, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell, index) => {
          const hiddenColumn = hiddenColumnNames.filter(
            (hiddenCol) => hiddenCol === headCell.name
          );

          return (
            <TableCell
              key={headCell.name}
              align={"center"}
              sx={{ display: hiddenColumn.length !== 0 && "none" }}
              style={{
                minWidth:
                  headCell.name === "index"
                    ? 20
                    : headCell.width
                      ? headCell.width
                      : 100,
              }}
            // width={headCell.name === "index" ? "10%" : headCell.width?headCell.width:''}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            // sortDirection={orderBy === headCell.name ? order : false}
            >
              {headCell.name === "index" ? "#" : headCell.title}
              {orderBy === headCell.name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  tableData,
  tableHead,
  onChangeStatus,
  rowDelete,
  rowEdit,
  rowCopy,
  showSendRequestIcon,
  rowSendRequest,
  sendRequestIconName = "",
  sendRequestIconValue = "",
  showEditIcon = true,
  showApprovalIcon,
  onChangeApprovalStatus,
  showDeleteIcon = true,
  showCopyIcon,
  copyConditionName = "",
  copyConditionValue = "",
  showApply = false,
  showApplyTitle = "",
  rowAdd,
  rowTransfer,
  rowView,
  showAddButtonInTable,
  iconTitle,
  iconTitleForCopy,
  showTransferButtonInTable,
  showViewButtonInTable,
  isActionColActive,
  hiddenColumnNames,
  showDownload,
  rowOutlet,
  showOutlet,
  rowDownload,
  setData,
  showSend,
  rowSend,
  rowApply,
  rowPDF,
  rowLink,
  showPdf,
  deleteConditionName = "",
  deleteConditionValue = "",
  editConditionName = "",
  editConditionValue = "",
  onChangeStatusStatic,
  credentialConditionName = "",
  credentialConditionValue = "",
  chipCompoColor,
  showReportIcon,
  rowReport,
  showContactInfo,
  showBankInfo,
  rowBank,
  rowContact,
  showCostMappingInfo,
}) {
  const [role, setRole] = React.useState("");

  const changeStatus = (e, row) => {
    e.stopPropagation();
    onChangeStatus(row);
  };

  const changeApprovalStatus = (e, row) => {
    e.stopPropagation();
    onChangeApprovalStatus(row);
  };

  const changeStatusUnique = (e, row) => {
    e.stopPropagation();
    onChangeStatusStatic(row);
  };

  const onClickEdit = (e, row) => {
    e.stopPropagation();
    rowEdit(row);
  };

  const onClickCopy = (e, row) => {
    e.stopPropagation();
    rowCopy(row);
  };

  const onClickSend = (e, row) => {
    e.stopPropagation();
    rowSend(row);
  };
  const onClickSendRequest = (e, row) => {
    e.stopPropagation();
    rowSendRequest(row);
  };
  const generatePDF = (e, row) => {
    e.stopPropagation();
    rowPDF(row);
  };

  const onClickDelete = (e, row) => {
    e.stopPropagation();
    rowDelete(row);
  };
  const onClickAdd = (e, row) => {
    e.stopPropagation();
    rowAdd(row);
  };

  const onClickTransfer = (e, row) => {
    e.stopPropagation();
    rowTransfer(row);
  };
  const onClickView = (e, row) => {
    e.stopPropagation();
    rowView(row);
  };

  const onClickDownload = (e, row) => {
    e.stopPropagation();
    rowDownload(row);
  };
  const onClickOutlet = (e, row) => {
    e.stopPropagation();
    rowOutlet(row);
  };
  const onClickApply = (e, row) => {
    e.stopPropagation();
    rowApply(row);
  };

  const onClickBank = (e, row) => {
    e.stopPropagation();
    rowBank(row);
  };

  const onClickContact = (e, row) => {
    e.stopPropagation();
    rowContact(row);
  };

  React.useEffect(() => {
    let role = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }
  }, []);

  const onClickReport = (e, row) => {
    e.stopPropagation();
    rowReport(row);
  };

  const onClickRead = (id) => {
    const rows = tableData.map((rowData) => {
      if (rowData.id === id) {
        rowData = {
          ...rowData,
          open: !rowData.open,
        };
        return rowData;
      }
      return rowData;
    });
    setData(rows);
  };
  const onClickLink = (e, params, key) => {
    e.stopPropagation();
    rowLink(params, key);
  };

  return (
    <TableRow key={row.name}>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter((row) => row.name === key);
        const hiddenColumn = hiddenColumnNames.filter(
          (hiddenCol) => hiddenCol === key
        );
        if (filteredList.length !== 0) {
          if (filteredList[0].positionCenter && key !== "isActive")
            return (
              <TableCell style={{ width: "10%" }}>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                style={{ width: "15%" }}
              >
                {row[key] == 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (filteredList[0].chipCompo) {
            return (
              <TableCell
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                style={{ width: "15%" }}
              >
                {filteredList[0].formDataKey && row[filteredList[0].formDataKey] ==
                  filteredList[0].chipClick ? (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color={chipCompoColor ? row["color"] : "error"}
                      onClick={(e) => changeStatusUnique(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color={chipCompoColor ? row["color"] : "success"}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (filteredList[0].readMore) {
            return (
              <TableCell
                width={filteredList[0].name === "index" ? "10%" : ""}
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                {!row.open &&
                  row[filteredList[0].readMoreData].length >
                  row[filteredList[0].readMoreData].slice(0, 70).length
                  ? row[filteredList[0].readMoreData].slice(0, 70) + " ... "
                  : row[filteredList[0].readMoreData].slice(0, 70)}{" "}
                {!row.open &&
                  row[filteredList[0].readMoreData] !==
                  row[filteredList[0].readMoreData].slice(0, 70) && (
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      label={" Read more"}
                      onClick={() => onClickRead(row.id)}
                    />
                  )}
                {row.open && row[filteredList[0].readMoreData]}
                {row.open &&
                  row[filteredList[0].readMoreData].length >
                  row[filteredList[0].readMoreData].slice(0, 70).length && (
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      label={"Read less"}
                      onClick={() => onClickRead(row.id)}
                    />
                  )}
              </TableCell>
            );
          } else
            return (
              <>
                {filteredList[0].linkFlag && (row[filteredList[0].linkName] == undefined || row[filteredList[0].linkName]) ?

                  <TableCell
                    onClick={(e) => onClickLink(e, row, key)}
                    width={filteredList[0].name === "index" ? "10%" : ""}
                    align={filteredList[0].align}
                    sx={{ display: hiddenColumn.length !== 0 && "none", cursor: "pointer", }}
                  >


                    {row[key]}
                  </TableCell> :
                  <TableCell
                    width={filteredList[0].name === "index" ? "5%" : ""}
                    align={filteredList[0].align}
                    sx={{ display: hiddenColumn.length !== 0 && "none" }}
                  >
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontWeight: filteredList[0].fontWeight
                      }}
                      label={row[key]}
                    />
                  </TableCell>}
              </>

            );
        }
        return null;
      })}
      {isActionColActive && (
        <>
          <TableCell align={"center"} style={{ width: "10%" }}>
            {showEditIcon && editConditionName == "" && (
              <>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="small"
                  title="Edit"
                  onClick={(e) => onClickEdit(e, row)}
                />
              </>
            )}
            {showApprovalIcon && (
              <>
                <MyComponentWithIconProps
                  statusImage={ApprovalIcon}
                  color="primary"
                  fontSize="small"
                  title="Change Status"
                  onClick={(e) => changeApprovalStatus(e, row)}
                />
              </>
            )}
            {showEditIcon &&
              editConditionName != "" &&
              row[editConditionName] == editConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={EditIcon}
                    color="primary"
                    fontSize="small"
                    title="Edit"
                    onClick={(e) => onClickEdit(e, row)}
                  />
                </>
              )}
            {showEditIcon &&
              editConditionName != "" &&
              row[editConditionName] != editConditionValue && <>&emsp;&emsp;</>}

            {showDeleteIcon && deleteConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="small"
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            )}
            {showDeleteIcon &&
              deleteConditionName != "" &&
              row[deleteConditionName] == deleteConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={DeleteIcon}
                    color="error"
                    fontSize="small"
                    title="Delete"
                    onClick={(e) => onClickDelete(e, row)}
                  />
                </>
              )}
            {/* {showCopyIcon && copyConditionName == "" && (
              <>
                <MyComponentWithIconProps
                  statusImage={CopyAllIcon}
                  color="primary"
                  fontSize="small"
                  title="Copy To Save"
                  onClick={(e) => onClickCopy(e, row)}
                />
              </>
            )} */}
            {showCopyIcon &&
              copyConditionName != "" &&
              row[copyConditionName] == copyConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={PriceChangeOutlinedIcon}
                    color="primary"
                    fontSize="small"
                    title={iconTitleForCopy ? iconTitleForCopy : "Copy To Save"}
                    onClick={(e) => onClickCopy(e, row)}
                  />
                </>
              )}
            {showCopyIcon && (
              <>
                <MyComponentWithIconProps
                  statusImage={PriceChangeOutlinedIcon}
                  color="primary"
                  fontSize="small"
                  title={iconTitleForCopy ? iconTitleForCopy : "Copy To Save"}
                  onClick={(e) => onClickCopy(e, row)}
                />
              </>
            )}
            {showSendRequestIcon &&
              sendRequestIconName != "" &&
              row[sendRequestIconName] == sendRequestIconValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={SendIcon}
                    color="primary"
                    fontSize="small"
                    title="Send Request"
                    onClick={(e) => onClickSendRequest(e, row)}
                  />
                </>
              )}
            {showDeleteIcon &&
              deleteConditionName != "" &&
              row[deleteConditionName] != deleteConditionValue && (
                <>&emsp;&emsp;</>
              )}
            {showReportIcon && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ReportIcon}
                  color="error"
                  fontSize="small"
                  title="Sub Report"
                  onClick={(e) => onClickReport(e, row)}
                />
              </>
            )}
            {showContactInfo && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ContactsIcon}
                  color="primary"
                  fontSize="small"
                  title="Add Contact Info"
                  onClick={(e) => onClickContact(e, row)}
                />
              </>
            )}
            {showBankInfo && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={AccountBalanceIcon}
                  color="primary"
                  fontSize="small"
                  title="Add Bank Info"
                  onClick={(e) => onClickBank(e, row)}
                />
              </>
            )}
            {showCostMappingInfo && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={MonetizationOnIcon}
                  color="primary"
                  fontSize="small"
                  title="Cost Center Mapping"
                  onClick={(e) => onClickContact(e, row)}
                />
              </>
            )}
            {showSend && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={RateReviewIcon}
                  color="primary"
                  fontSize="small"
                  title="Rate Configuration"
                  onClick={(e) => onClickSend(e, row)}
                />
              </>
            )}

            {/* {showApply && credentialConditionName != "" &&
              row[credentialConditionName] == credentialConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={TouchAppRoundedIcon}
                    color="primary"
                    fontSize="small"
                    title={"Send Credential"}
                    onClick={(e) => onClickApply(e, row)}
                  />
                </>
              )} */}

            {showApply && credentialConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={TouchAppRoundedIcon}
                  color="primary"
                  fontSize="small"
                  title={showApplyTitle ? showApplyTitle : "Send Credential"}
                  onClick={(e) => onClickApply(e, row)}
                />
              </>
            )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] == credentialConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={TouchAppRoundedIcon}
                    color="primary"
                    fontSize="small"
                    title={showApplyTitle ? showApplyTitle : "Send Credential"}
                    onClick={(e) => onClickApply(e, row)}
                  />
                </>
              )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] != credentialConditionValue && (
                <>&emsp;&emsp;</>
              )}

            {showPdf && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  color="error"
                  fontSize="small"
                  title="PDF"
                  baseClassName="fas"
                  className="fa-file-pdf"
                  onClick={(e) => generatePDF(e, row)}
                />
              </>
            )}

            {showAddButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ReceiptLongOutlinedIcon}
                  color="primary"
                  fontSize="small"
                  title={iconTitle ? iconTitle : "Add Structure"}
                  onClick={(e) => onClickAdd(e, row)}
                />
              </>
            )}
            {showTransferButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={MoveUpIcon}
                  color="primary"
                  fontSize="small"
                  title="Transfer"
                  onClick={(e) => onClickTransfer(e, row)}
                />
              </>
            )}

            {showViewButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="small"
                  title="View"
                  onClick={(e) => onClickView(e, row)}
                />
              </>
            )}
            {showDownload && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DownloadIcon}
                  color="primary"
                  fontSize="small"
                  title="View"
                  onClick={(e) => onClickDownload(e, row)}
                />
              </>
            )}
            {showOutlet && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ViewListIcon}
                  color="primary"
                  fontSize="small"
                  title="Show Details"
                  onClick={(e) => onClickOutlet(e, row)}
                />
              </>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  columns,
  onChangeStatus,
  rowDelete,
  rowEdit,
  showApprovalIcon,
  onChangeApprovalStatus,
  rowCopy,
  showEditIcon,
  showSendRequestIcon,
  rowSendRequest,
  sendRequestIconName = "",
  rowLink,
  sendRequestIconValue = "",
  showDeleteIcon,
  showCopyIcon,
  copyConditionName = "",
  copyConditionValue = "",
  tableData = [],
  showAddButtonInTable,
  iconTitle,
  iconTitleForCopy,
  showTransferButtonInTable,
  showViewButtonInTable,
  hiddenColumnNames = [],
  showPegination = true,
  rowAdd,
  rowTransfer,
  rowView,
  isActionColActive = false,
  showDownload,
  showOutlet,
  rowOutlet,
  rowDownload,
  setData,
  showSend,
  rowSend,
  showApply = false,
  showApplyTitle = "",
  rowApply,
  showPdf = false,
  onChangeStatusStatic,
  rowPDF,
  deleteConditionName = "",
  deleteConditionValue = "",
  editConditionName = "",
  editConditionValue = "",
  credentialConditionName = "",
  credentialConditionValue = "",
  chipCompoColor = false,
  totalList = [],
  showReportIcon,
  rowReport,
  showContactInfo,
  showBankInfo,
  rowContact,
  rowBank,
  showCostMappingInfo,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            tableHead={columns}
            hiddenColumnNames={hiddenColumnNames}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
          />
          <TableBody>
            {showPegination
              ? stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      tableHead={columns}
                      rowEdit={rowEdit}
                      showApprovalIcon={showApprovalIcon}
                      onChangeApprovalStatus={onChangeApprovalStatus}
                      rowCopy={rowCopy}
                      showEditIcon={showEditIcon}
                      showContactInfo={showContactInfo}
                      showBankInfo={showBankInfo}
                      showCostMappingInfo={showCostMappingInfo}
                      rowContact={rowContact}
                      rowBank={rowBank}
                      showDeleteIcon={showDeleteIcon}
                      showCopyIcon={showCopyIcon}
                      copyConditionName={copyConditionName}
                      copyConditionValue={copyConditionValue}
                      onChangeStatus={onChangeStatus}
                      rowDelete={rowDelete}
                      rowAdd={rowAdd}
                      rowTransfer={rowTransfer}
                      rowView={rowView}
                      showViewButtonInTable={showViewButtonInTable}
                      showAddButtonInTable={showAddButtonInTable}
                      iconTitle={iconTitle}
                      iconTitleForCopy={iconTitleForCopy}
                      showTransferButtonInTable={showTransferButtonInTable}
                      isActionColActive={isActionColActive}
                      hiddenColumnNames={hiddenColumnNames}
                      showDownload={showDownload}
                      rowOutlet={rowOutlet}
                      showOutlet={showOutlet}
                      showApplyTitle={showApplyTitle}
                      showApply={showApply}
                      rowDownload={rowDownload}
                      rowLink={rowLink}
                      rowApply={rowApply}
                      tableData={tableData}
                      setData={setData}
                      showSend={showSend}
                      rowSend={rowSend}
                      rowPDF={rowPDF}
                      showPdf={showPdf}
                      deleteConditionName={deleteConditionName}
                      deleteConditionValue={deleteConditionValue}
                      editConditionName={editConditionName}
                      editConditionValue={editConditionValue}
                      credentialConditionName={credentialConditionName}
                      credentialConditionValue={credentialConditionValue}
                      onChangeStatusStatic={onChangeStatusStatic}
                      showSendRequestIcon={showSendRequestIcon}
                      rowSendRequest={rowSendRequest}
                      sendRequestIconName={sendRequestIconName}
                      sendRequestIconValue={sendRequestIconValue}
                      chipCompoColor={chipCompoColor}
                    />
                  );
                })
              : stableSort(tableData, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <Row
                      key={row.id}
                      rowLink={rowLink}
                      row={row}
                      tableHead={columns}
                      rowEdit={rowEdit}
                      showApprovalIcon={showApprovalIcon}
                      onChangeApprovalStatus={onChangeApprovalStatus}
                      rowCopy={rowCopy}
                      showEditIcon={showEditIcon}
                      showDeleteIcon={showDeleteIcon}
                      showCopyIcon={showCopyIcon}
                      copyConditionName={copyConditionName}
                      copyConditionValue={copyConditionValue}
                      onChangeStatus={onChangeStatus}
                      rowDelete={rowDelete}
                      rowAdd={rowAdd}
                      rowTransfer={rowTransfer}
                      showContactInfo={showContactInfo}
                      showBankInfo={showBankInfo}
                      showCostMappingInfo={showCostMappingInfo}
                      rowContact={rowContact}
                      rowBank={rowBank}
                      rowView={rowView}
                      showViewButtonInTable={showViewButtonInTable}
                      showAddButtonInTable={showAddButtonInTable}
                      iconTitle={iconTitle}
                      iconTitleForCopy={iconTitleForCopy}
                      showTransferButtonInTable={showTransferButtonInTable}
                      isActionColActive={isActionColActive}
                      hiddenColumnNames={hiddenColumnNames}
                      showDownload={showDownload}
                      rowOutlet={rowOutlet}
                      showOutlet={showOutlet}
                      showApplyTitle={showApplyTitle}
                      showApply={showApply}
                      tableData={tableData}
                      setData={setData}
                      showSend={showSend}
                      rowSend={rowSend}
                      rowDownload={rowDownload}
                      rowApply={rowApply}
                      rowPDF={rowPDF}
                      showPdf={showPdf}
                      deleteConditionName={deleteConditionName}
                      deleteConditionValue={deleteConditionValue}
                      editConditionName={editConditionName}
                      editConditionValue={editConditionValue}
                      credentialConditionName={credentialConditionName}
                      credentialConditionValue={credentialConditionValue}
                      onChangeStatusStatic={onChangeStatusStatic}
                      showSendRequestIcon={showSendRequestIcon}
                      rowSendRequest={rowSendRequest}
                      sendRequestIconName={sendRequestIconName}
                      sendRequestIconValue={sendRequestIconValue}
                      chipCompoColor={chipCompoColor}
                    />
                  );
                }
              )}
            {totalList.length != 0 && (
              <TableRow
              >
                <TableCell></TableCell>
                {totalList.map((total) => {
                  return <TableCell align="right">{total}</TableCell>;
                })}
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
            {tableData.length === 0 && (
              <TableRow
                style={{
                  height: 53 * 1,
                }}
              >
                <TableCell align="center" colSpan={columns.length}>
                  No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPegination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
