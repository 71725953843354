import { Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  deleteConfirmMsg,
  deletemsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  serverMsg,
  updateMsg
} from "../../config/messageconstant";
import { stockEvaluationJSON } from "../../DynamicFormsJson/Transaction/stockEvaluation";
import { getCompmay, getLocationByIds } from "../../Slice/accountLedger.slice";
import {
  getClosingList,
  getStockCategoryList,
  getYear,
  setClosingList,
} from "../../Slice/stockValue.slice";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
class StockEvolutionFormNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationNameList: [],
      monthData:[],
      formData:{},
      dtofreezeMonth:{},
      yearId: "",
    
      dynamicMasterData: {
        CompanyDetails: this.props.locationList?.company,
        yearDetails: this.props.stockValue.year,
      },
    };
  }

  async componentDidMount() {
    const {
      getCompmay,
      getYear,
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      await getCompmay().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getYear().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.locationList !== nextProps.locationList) {
      if (
        this.props.locationList?.company !== nextProps.locationList?.company
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            CompanyDetails: nextProps.locationList?.company,
          },
        });
      }
    }
    if (this.props.stockValue !== nextProps.stockValue) {
      if (this.props.stockValue?.year !== nextProps.stockValue?.year) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetails: nextProps.stockValue?.year,
          },
        });
      }
    }
  }

 

  onSearch = async (dataToSearch) => {
    const {
      
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
          url: endpoint.freezeMonth + "/check-company-freeze-month?compId=" + dataToSearch.companyId + "&yearId=" + dataToSearch.yearId
      }).then(({ data, success }) => {
          if (success) {
              
              let rowData = data.data
this.setState({
  formData:dataToSearch,
  monthData:rowData.freezeMonthListBetweenList,
  dtofreezeMonth:rowData.dtofreezeMonth!=null?rowData.dtofreezeMonth:{}
})
              
            
              showLoador({ loador: false });
           
          } else {
             
              showLoador({ loador: false });
              showNotification({
                  msg: serverMsg,
                  severity: "error",
              });
          }

      });
    
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
 
  onClickFreeze = () => {
    const {  showLoador, showNotification } = this.props;
    const { dtofreezeMonth } = this.state;
    let dataToSave=dtofreezeMonth
    delete dataToSave.id
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiPost({
            url: endpoint.freezeMonth,
            postBody: dataToSave,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              this.onSearch(this.state.formData)
              showNotification({ msg: updateMsg });
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };
  onClickDelete = (row) => {
    const {  showLoador, showNotification } = this.props;
    
    swal({
      title: "Are you sure?",
      text: deleteConfirmMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiDelete({
            url: endpoint.freezeMonth+"/"+row.id
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              this.onSearch(this.state.formData)
              showNotification({ msg: deletemsg });
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };
  render() {
    const {
      dynamicMasterData,
      dtofreezeMonth,
      monthData,
    } = this.state;
    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <DynamicFormWithoutSave
            showBackToList={false}
            dynamicMasterData={dynamicMasterData}
            showTitle={'Monthwise Freeze'}
            screenTitle={stockEvaluationJSON.screenTitle}
            fieldMeta={stockEvaluationJSON.fieldMetaNew}
            showCancel={stockEvaluationJSON.showCancel}
            apiBaseURL={stockEvaluationJSON.apiBaseURL}
            showSaveNextBtn={stockEvaluationJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            // showSaveBtnMain={false}
            getListById={this.getListById}
            onSave={this.onSearch}
          />
          <br />

        
               
                <TableContainer>
                  <Table aria-label="simple table" size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ minWidth: "30px" }}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          align="center"
                         
                          style={{ minWidth: "100px" }}
                        >
                          Month
                        </TableCell>
                        <TableCell
                          align="center"
                          // colSpan={3}
                          
                        >
                          Action
                        </TableCell>
                      </TableRow>

                      
                    </TableHead>
                    <TableBody>
                      {monthData.map((row, index) => (
                        <TableRow key={row.name}>
                          <TableCell
                           
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell  component="th" scope="row">
                            {row.monthName} - {row.year}
                          </TableCell>
                          <TableCell
                           
                           align="center"
                           component="th"
                           scope="row"
                         >
                          {
                            monthData.length==index+1&&
                            <MyComponentWithIconProps
                            statusImage={DeleteIcon}
                            color="error"
                            fontSize="medium"
                            title="Delete"
                            onClick={(e) => this.onClickDelete(row)}
                          /> }
                       
                         </TableCell>
                        </TableRow>
                      ))}

{Object.keys(dtofreezeMonth).length!=0&&
   <TableRow key={'id'}>
   <TableCell
    
     align="center"
     component="th"
     scope="row"
   >
     {monthData.length + 1}
   </TableCell>
   <TableCell  component="th" scope="row">
     {dtofreezeMonth.suggestedMonthName} - {dtofreezeMonth.year}
   </TableCell>
   <TableCell
    
    align="center"
    component="th"
    scope="row"
  >
 <MyComponentWithIconProps
statusImage={CheckCircleIcon}
color="success"
fontSize="medium"
title="Freeze"
 onClick={this.onClickFreeze}
/>
  </TableCell>
 </TableRow>
}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="Submit"
                    variant="contained"
                    name="Submit"
                    onClick={this.onSubmitData}
                  />
                </Grid>
             
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locationList: state.accountLedger,
  stockValue: state.stockValueList,
});
const mapDispatchToProps = {
  showNotification,
  getClosingList,
  showLoador,
  getStockCategoryList,
  getCompmay,
  getLocationByIds,
  setClosingList,
  getYear,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockEvolutionFormNew);
