import InfoIcon from "@mui/icons-material/Info";
import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import AutoComplete from "../Comman/AutoComplete";
import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import MultipleSelect from "../Comman/MultipleSelect";
import { TextFieldCompo } from "../Comman/TextFieldCompo";
import TimePickerCompo from "../Comman/TimePicker";
import { withRouter } from "../withRouter";
import {
  blackColor,
  labelColor,
  redColor,
  titleColor,
} from "../../config/ColorObj";
import { onlyNumber, validText } from "../Comman/Util/Validations";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
class DynamicTransactionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveData: true,
    
    };
  }

  onAutocompleteChange = (dataKey, isRootLevelKey) => (name, newValue) => {
    let value = "";
    if (isRootLevelKey) {
      value = newValue;
    } else {
      value = {
        id: newValue,
      };
    }
    if (newValue != null) {
      this.props.onDataChange(dataKey, value);
    }
    else {
      this.props.onDataChange(dataKey, value);
    }
  };
// componentDidMount(){
//   const {fieldMeta} =this.props
//  let dateApiValid= fieldMeta.some((currentField)=>currentField.dateApiValid)

// }
  onMultiSelectChange = (dataKey, masterName) => (value, name) => {
    const { dynamicMasterData } = this.props;

    let dataValue = typeof value === "string" ? value.split(",") : value;
    
    this.props.onDataChange(dataKey, dataValue);
  };

  onTextFieldDataChange = (dataKey, inputType, spValid) => (event) => {
    if (inputType == "number") {
      if (spValid == "float") {
        if (
          event.target.value === "" ||
          (event.target.value !== "e" && +event.target.value >= 0)
        ) {
          console.log(event.target.value);
          this.props.onDataChange(dataKey, event.target.value);
        }
        // else {
        //   this.props.onDataChange(dataKey, 0);
        // }
      } else {
        if (event.target.value === "" || onlyNumber.test(event.target.value)) {
          this.props.onDataChange(dataKey, event.target.value);
        }
        // else {
        //   this.props.onDataChange(dataKey, 0);
        // }
      }
    } else {
      if (event.target.value === "" || validText.test(event.target.value)) {
        this.props.onDataChange(dataKey, event.target.value);
      }
    }
    // this.props.onDataChange(dataKey, event.target.value);
  };

  onDateChange = (dataKey,dateApiValid) => (date) => {
    console.log(date)
    if(dateApiValid){
      const {
      
        showLoador,
        showNotification,
      } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
            url: endpoint.freezeMonth + "/check-freeze-month-by-date?compId=f8fa0540-5397-11ee-8c99-0242ac120002&date="+dayjs(
              date).format("DD-MM-YYYY")
            
        }).then(({ data, success }) => {
            if (success) {
                
             
              if(data.data==null){
                this.props.onDataChange(dataKey, date);
              }else{
                showNotification({
                  msg: 'Please select proper date',
                  severity: "error",
              });
              }
              
                showLoador({ loador: false });
             
            } else {
               
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }
      
        });
      
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
     
    }else{
      this.props.onDataChange(dataKey, date);
    }
   
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.props.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    if (this.props.backToList) {
      this.props.goBackToList();
    } else {
      this.props.navigate(-1);
    }
  };

  render() {
    const {
      showTitle = true,
      fieldMeta = [],
      dynamicMasterData = {},
      showAddButton = false,
      screenTitle = "",
      paddingTopGrid=false,
      DataToSet = [],
      showBackToList = false,
      showTable = false,
      paddingTop = false,
      padding = false,
      formErrors,
      fieldData,
      subTitle = "",
      showEdit = false,
      showDelete = false,
      rowEdit,
      rowDelete,
      rowReset,
      showReset = false,
      amount,
    } = this.props;

    return (
      <>
        <Paper
          sx={{
            p: padding ? 2 : 0,
            pt: paddingTop ? 2 : 0,
            borderRadius: 2,
          }}
          elevation={0}
        >
          {showTitle && (
            <LandingScreenHeader
              screenTitle={screenTitle}
              showTable={showTable}
              showBackButton={showBackToList}
              showAddButton={showAddButton}
              onCancel={this.onCancel}
              subTitle={subTitle}
              amount={amount}
              showEdit={showEdit}
              showDelete={showDelete}
              rowEdit={rowEdit}
              rowDelete={rowDelete}
              showReset={showReset}
              rowReset={rowReset}
            />
          )}


          {DataToSet.length !== 0 && (
            <>
              <Grid container sx={{ margin: 1 }} spacing={1}>
                {DataToSet.map((dropDownData) => (
                  <Grid
                    container
                    xs={dropDownData.xs}
                    sm={dropDownData.sm}
                    md={dropDownData.md}
                    lg={dropDownData.lg}
                  >
                    <Grid
                      item
                      xs={dropDownData.labelXS ? dropDownData.labelXS : 5}
                      sm={dropDownData.labelSM ? dropDownData.labelSM : 5}
                      md={dropDownData.labelMD ? dropDownData.labelMD : 5}
                      lg={dropDownData.labelLG ? dropDownData.labelLG : 5}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: titleColor }}
                        label={dropDownData.label + ":"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={dropDownData.valueXS ? dropDownData.valueXS : 7}
                      sm={dropDownData.valueSM ? dropDownData.valueSM : 7}
                      md={dropDownData.valueMD ? dropDownData.valueMD : 7}
                      lg={dropDownData.valueLG ? dropDownData.valueLG : 7}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12 }}
                        label={dropDownData.value}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {/* <br /> */}
            </>
          )}

          <Grid sx={{paddingTop:paddingTopGrid?1:0}}container spacing={1}>
            {fieldMeta.map((currentField) => {
              if (currentField.hideGrid == undefined ||
                (currentField.hideGrid &&
                  fieldData[currentField.tableDataKey] ===
                  currentField.open)
              ) {
                return (
                  <Grid
                    container
                    xs={currentField.xs}
                    sm={currentField.sm}
                    md={currentField.md}
                    sx={{
                      marginTop: currentField.marginTop
                        ? currentField.marginTop
                        : 1,
                    }}
                  //  rowSpacing={12}
                  >
                    {currentField.toolTip && currentField.toolTip !== "" && (
                      <>
                        <Tooltip placement="top" title={currentField.toolTip}>
                          <InfoIcon color="disabled" />
                        </Tooltip>
                      </>
                    )}
                    <Grid
                      item
                      xs={currentField.labelXS ? currentField.labelXS : 5}
                      sm={currentField.labelSM ? currentField.labelSM : 5}
                      md={currentField.labelMD ? currentField.labelMD : 5}
                      lg={currentField.labelLG ? currentField.labelLG : 5}
                    >
                      {currentField.controlType !== "hideDatepicker" &&
                        currentField.controlType !== "hideTextfield" &&
                        currentField.controlType !== "hideMultiSelect" &&
                        currentField.controlType !== "hideAutocomplete" && (
                          <>

                            <LabelCompo
                              isMandatory={currentField.isMandatory}
                              className="text-black"
                              style={{
                                fontSize: 12,
                                color: currentField.isMandatory
                                  ? titleColor
                                  : blackColor,
                              }}
                              label={currentField.label != "" ? currentField.label + ":" : ""}
                            />
                          </>
                        )}
                      {(currentField.controlType === "hideMultiSelect" ||
                        currentField.controlType === "hideAutocomplete" ||
                        currentField.controlType === "hideTextfield" ||
                        currentField.controlType === "hideAutocomplete" ||
                        currentField.controlType === "hideDatepicker") &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <>

                            <LabelCompo
                              isMandatory={currentField.isMandatory}
                              className="text-black"
                              style={{
                                fontSize: 12,
                                color: currentField.isMandatory
                                  ? titleColor
                                  : blackColor,
                              }}
                              label={currentField.label != "" ? currentField.label + ":" : ""}
                            />
                          </>
                        )}
                    </Grid>
                    <Grid
                      item
                      xs={currentField.valueXS ? currentField.valueXS : 7}
                      sm={currentField.valueSM ? currentField.valueSM : 7}
                      md={currentField.valueMD ? currentField.valueMD : 7}
                      lg={currentField.valueLG ? currentField.valueLG : 7}
                      sx={{ paddingRight: 2 }}
                    >
                      {currentField.controlType === "textfield" && (
                        <>
                          <TextFieldCompo
                            placeHolder={currentField.placeHolder}
                            size="small"
                            color="primary"
                            name={currentField.label}
                            id={currentField.label}
                            value={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : ""
                            }
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : currentField.disableTrue
                                  ? true
                                  : false
                            }
                            inputProps={{
                              maxLength:
                                currentField.maxLength && currentField.maxLength,
                              min: currentField.minNum && currentField.minNum,
                            }}
                            type={
                              currentField.inputType
                                ? currentField.inputType
                                : "text"
                            }
                            onChange={this.onTextFieldDataChange(
                              currentField.dataKey,
                              currentField.inputType,
                              currentField.spValid
                            )}
                            fullWidth
                            errorText={
                              formErrors[currentField.dataKey]?.isError
                                ? formErrors[currentField.dataKey].errorText
                                : ""
                            }
                          />
                          {currentField.note &&
                            currentField.space &&
                            currentField.note.split("@").map((note) => {
                              return (
                                <>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 12,
                                      color: labelColor,
                                      marginLeft: 2,
                                    }}
                                    label={note}
                                  />
                                  {currentField.space === "br" ? (
                                    <br></br>
                                  ) : currentField.space === "&nbsp;" ? (
                                    " "
                                  ) : (
                                    ""
                                  )}
                                  {/* <br></br> */}
                                </>
                              );
                            })}
                        </>
                      )}
                      {currentField.controlType === "textarea" && (
                        <TextFieldCompo
                          placeHolder={currentField.placeHolder}
                          size="small"
                          color="primary"
                          name={currentField.label}
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          disabled={
                            fieldData["id"] && currentField.disable ? true : false
                          }
                          inputProps={{
                            maxLength:
                              currentField.maxLength && currentField.maxLength,
                          }}
                          type={
                            currentField.inputType ? currentField.inputType : "text"
                          }
                          multiline
                          onChange={this.onTextFieldDataChange(
                            currentField.dataKey,
                            currentField.inputType,
                            currentField.spValid
                          )}
                          fullWidth
                          errorText={
                            formErrors[currentField.dataKey]?.isError
                              ? formErrors[currentField.dataKey].errorText
                              : ""
                          }
                        />
                      )}
                      {currentField.controlType === "hideTextfield" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <TextFieldCompo
                            placeHolder={currentField.placeHolder}
                            size="small"
                            color="primary"
                            name={currentField.label}
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : currentField.disableTrue
                                  ? true
                                  : false
                            }
                            id={currentField.label}
                            value={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : ""
                            }
                            inputProps={{
                              maxLength:
                                currentField.maxLength && currentField.maxLength,
                            }}
                            type={
                              currentField.inputType
                                ? currentField.inputType
                                : "text"
                            }
                            onChange={this.onTextFieldDataChange(
                              currentField.dataKey,
                              currentField.inputType,
                              currentField.spValid
                            )}
                            fullWidth
                            errorText={
                              formErrors[currentField.dataKey]?.isError
                                ? formErrors[currentField.dataKey].errorText
                                : ""
                            }
                          />
                        )}
                      {currentField.controlType === "datepicker" && (
                        <BasicDatePicker
                          color="primary"
                          name="dob"
                          size="small"
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : null
                          }
                          {...(currentField.isMAxDate && {
                            maxDate: dayjs(),
                          })}
                          {...(currentField.freezCurrentDate && localStorage.getItem("freezCurrentDate") == 1 && {
                            maxDate: dayjs(),
                          })}

                          {...(currentField.isMinDate && {
                            minDate: currentField.addOne
                              ? dayjs().add(1, "day")
                              : dayjs(),
                          })}
                          disabled={
                            fieldData["id"] && currentField.disable ? true : false
                          }
                          fullWidth
                          onChange={this.onDateChange(currentField.dataKey,currentField.dateApiValid)}
                          isError={formErrors[currentField.dataKey]?.isError}
                          errorText={formErrors[currentField.dataKey]?.errorText}
                        />
                      )}
                      {currentField.controlType === "hideDatepicker" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <BasicDatePicker
                            color="primary"
                            name="dob"
                            size="small"
                            value={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : null
                            }
                            {...(currentField.isMAxDate && {
                              maxDate: dayjs(),
                            })}
                            {...(currentField.isMinDate && {
                              minDate: dayjs(),
                            })}
                            disabled={
                              fieldData["id"] && currentField.disable ? true : false
                            }
                            fullWidth
                            onChange={this.onDateChange(currentField.dataKey,currentField.dateApiValid)}
                            isError={formErrors[currentField.dataKey]?.isError}
                            errorText={formErrors[currentField.dataKey]?.errorText}
                          />
                        )}
                      {currentField.controlType === "hideAutocomplete" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <AutoComplete
                            label={currentField.label}
                            placeHolder={currentField.placeHolder}
                            keyColName={"id"}
                            value={
                              currentField.isRootLevelKey &&
                                fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : !currentField.isRootLevelKey &&
                                  fieldData[currentField.dataKey]
                                  ? fieldData[currentField.dataKey].id
                                  : ""
                            }
                            options={
                              dynamicMasterData[currentField["masterName"]]
                                ? dynamicMasterData[currentField["masterName"]]
                                : []
                            }
                            disabled={
                              fieldData["id"] && currentField.disable ? true : false
                            }
                            onChange={this.onAutocompleteChange(
                              currentField.dataKey,
                              currentField.isRootLevelKey
                            )}
                            isError={formErrors[currentField.dataKey]?.isError}
                            errorText={formErrors[currentField.dataKey]?.errorText}
                          />
                        )}

                      {currentField.controlType === "label" && (
                        <>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 12, color: labelColor }}
                            label={fieldData[currentField.dataKey] ? fieldData[currentField.dataKey] : "0"}
                          />
                        </>
                      )}
                      {currentField.controlType === "autocomplete" && (
                        <>
                          <AutoComplete
                            label={currentField.label}
                            placeHolder={currentField.placeHolder}
                            keyColName={"id"}
                            value={
                              currentField.isRootLevelKey &&
                                fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : !currentField.isRootLevelKey &&
                                  fieldData[currentField.dataKey]
                                  ? fieldData[currentField.dataKey].id
                                  : ""
                            }
                            options={
                              dynamicMasterData[currentField["masterName"]]
                                ? dynamicMasterData[currentField["masterName"]]
                                : []
                            }
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : currentField.disableTrue
                                  ? true
                                  : false
                            }
                            onChange={this.onAutocompleteChange(
                              currentField.dataKey,
                              currentField.isRootLevelKey
                            )}
                            isError={formErrors[currentField.dataKey]?.isError}
                            errorText={formErrors[currentField.dataKey]?.errorText}
                          />
                          {currentField.note &&
                            currentField.space &&
                            currentField.note.split("@").map((note) => {
                              return (
                                <>
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 12, color: labelColor }}
                                    label={note}
                                  />
                                  {currentField.space === "br" ? (
                                    <br></br>
                                  ) : currentField.space === "&nbsp;" ? (
                                    " "
                                  ) : (
                                    ""
                                  )}
                                  {/* <br></br> */}
                                </>
                              );
                            })}
                        </>
                      )}
                      {currentField.controlType === "autocomplete/textfield" && (
                        <>
                          {fieldData["id"] ? (
                            <TextFieldCompo
                              placeHolder={currentField.placeHolder}
                              size="small"
                              color="primary"
                              name={currentField.label}
                              id={currentField.label}
                              value={
                                fieldData[currentField.dataKeyName] &&
                                  fieldData[currentField.dataKeyName]
                                  ? fieldData[currentField.dataKeyName]
                                  : ""
                              }
                              disabled={true}
                              inputProps={{
                                maxLength:
                                  currentField.maxLength && currentField.maxLength,
                                min: currentField.minNum && currentField.minNum,
                              }}
                              type={
                                currentField.inputType
                                  ? currentField.inputType
                                  : "text"
                              }
                              onChange={this.onTextFieldDataChange(
                                currentField.dataKey,
                                currentField.inputType,
                                currentField.spValid
                              )}
                              fullWidth
                              errorText={
                                formErrors[currentField.dataKey]?.isError
                                  ? formErrors[currentField.dataKey].errorText
                                  : ""
                              }
                            />
                          ) : (
                            <>
                              <AutoComplete
                                label={currentField.label}
                                placeHolder={currentField.placeHolder}
                                keyColName={"id"}
                                value={
                                  currentField.isRootLevelKey &&
                                    fieldData[currentField.dataKey]
                                    ? fieldData[currentField.dataKey]
                                    : !currentField.isRootLevelKey &&
                                      fieldData[currentField.dataKey]
                                      ? fieldData[currentField.dataKey].id
                                      : ""
                                }
                                options={
                                  dynamicMasterData[currentField["masterName"]]
                                    ? dynamicMasterData[currentField["masterName"]]
                                    : []
                                }
                                disabled={
                                  fieldData["id"] && currentField.disable
                                    ? true
                                    : false
                                }
                                onChange={this.onAutocompleteChange(
                                  currentField.dataKey,
                                  currentField.isRootLevelKey
                                )}
                                isError={formErrors[currentField.dataKey]?.isError}
                                errorText={
                                  formErrors[currentField.dataKey]?.errorText
                                }
                              />
                              {currentField.note &&
                                currentField.space &&
                                currentField.note.split("@").map((note) => {
                                  return (
                                    <>
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 12, color: labelColor }}
                                        label={note}
                                      />
                                      {currentField.space === "br" ? (
                                        <br></br>
                                      ) : currentField.space === "&nbsp;" ? (
                                        " "
                                      ) : (
                                        ""
                                      )}
                                      {/* <br></br> */}
                                    </>
                                  );
                                })}
                            </>
                          )}
                        </>
                      )}
                      {currentField.controlType === "multiSelect" && (
                        <MultipleSelect
                          label={currentField.placeHolder}
                          er={formErrors[currentField.dataKey]?.errorText}
                          name1={currentField.dataKey}
                          value1={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : []
                          }
                          names={
                            dynamicMasterData[currentField["masterName"]] &&
                              dynamicMasterData[currentField["masterName"]].length !=
                              0
                              ? [
                                { id: 0, name: "All" },
                                ...dynamicMasterData[currentField["masterName"]],
                              ]
                              : []
                          }
                          onChange={this.onMultiSelectChange(
                            currentField.dataKey,
                            currentField["masterName"]
                          )}
                        />
                      )}
                      {currentField.controlType === "hideMultiSelect" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <MultipleSelect
                            label={currentField.placeHolder}
                            placeHolder={currentField.placeHolder}
                            name1={currentField.dataKey}
                            value1={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : []
                            }
                            names={
                              dynamicMasterData[currentField["masterName"]]
                                ? dynamicMasterData[currentField["masterName"]]
                                : []
                            }
                            onChange={this.onMultiSelectChange(
                              currentField.dataKey
                            )}
                            er={formErrors[currentField.dataKey]?.errorText}
                          />
                        )}
                      {currentField.controlType === "timePicker" && (
                        <TimePickerCompo
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          onChange={this.timeChangeHandler(currentField.dataKey)}
                        />
                      )}
                      {currentField.controlType === "radiogroup" && (
                        <>
                          <RadioGroup
                            row
                            aria-label={currentField.label}
                            name={currentField.label}
                            id={currentField.label}
                            disabled={
                              fieldData["id"] && currentField.disable ? true : false
                            }
                            value={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : ""
                            }
                            onChange={this.onRadioChange(currentField.dataKey)}
                            size="small"
                          >
                            {currentField.radioGroupItems.map((radioItem) => (
                              <FormControlLabel
                                disabled={
                                  fieldData["id"] && currentField.disable
                                    ? true
                                    : false
                                }
                                sx={{
                                  "& svg": {
                                    width: "0.8em",
                                    height: "0.8em"
                                  }
                                }}
                                value={radioItem.value}
                                control={<Radio />}
                                label={radioItem.label}
                              />
                            ))}
                          </RadioGroup>
                          {currentField.note &&
                            currentField.note.split("@").map((note) => {
                              return (
                                <>
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 12, color: labelColor }}
                                    label={note}
                                  />
                                  <br></br>
                                </>
                              );
                            })}
                        </>
                      )}
                    </Grid>
                  </Grid>
                )
              }


            })}
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicTransactionForm);
