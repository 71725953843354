import endpoint from "../../config/endpoints";

export const DebitNoteJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.recieptPaymentHeader,
  screenTitle: "Debit Note",
  formPath: "/debit-note-form",
  formPathView: "/debit-note-view",
  showBackToList: false,
  showTitle: false,
  searchList: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucher",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
       md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
       md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
  fieldMeta: [
    // {
    //   label: "Voucher No.",
    //   controlType: "textfield",
    //   placeHolder: "Enter Voucher No.",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "documentNo",
    //   inputType: "number",
    //   isMandatory:false,
    //   disableTrue:true
    // },
    {
      label: "Sub-voucher",
      controlType: "textfield",
      placeHolder: "Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "subVoucherName",
      isRootLevelKey: false,
      isMandatory: true,
      disable:true
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      freezCurrentDate:true,
      isMandatory: true,
      dateApiValid:true
    },
   
    {
      label: "Debit A/C",
      controlType: "autocomplete",
      placeHolder: "Select Debit Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      getListId: "cashBankId",
      isRootLevelKey: true,
      isMandatory: true,
    }, 
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Profit Center",
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
  fieldMetaAdd: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucherMaster",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      freezCurrentDate:true,
      isMandatory: true,
      dateApiValid:true
    },
    {
      label: "Debit A/C",
      controlType: "autocomplete",
      placeHolder: "Select Debit Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      getListId: "cashBankId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Profit Center",
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Credit Account",
      controlType: "autocomplete",
      placeHolder: "Select Credit Account ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "creditAccountDetails",
      dataKey: "mainAccountId",
      getListId: "mainAccountId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "debitBalance",
      disableTrue:true
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "amt",
      inputType: "number",
      isMandatory: true,
    },

    
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeDetails",
      dataKey: "type",
      isRootLevelKey: true,
      isMandatory: true,
      defaultValue: "C",
    },
   
    
    
    {
      label: "Effect on GSTR1*",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isEffectonGstr",
      tableDataKey: "isEffectonGstr",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
   
    {
      label: "Percentage",
      controlType: "hideTextfield",
      inputType: "number",
      placeHolder: "Percentage",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "percentage",
      hideGrid:true,
      openDataKey: "isEffectonGstr",
      open: "1",
      error: "Percentage",
      tableDataKey: "isEffectonGstr",
    },
    {
      label: "HSN Code",
      controlType: "hideTextfield",
      inputType: "number",
      placeHolder: "HSN Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "HsnCode",
      openDataKey: "isEffectonGstr",
      open: "1",
      // error: "isEffectonGstr",
      hideGrid:true,
      tableDataKey: "isEffectonGstr",
    },
    {
      label: "Taxable Amount",
      controlType: "hideTextfield",
      inputType: "number",
      placeHolder: "Taxable Amount",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "taxableAmount",
      openDataKey: "isEffectonGstr",
      open: "1",
      // error: "isEffectonGstr",
      hideGrid:true,
      tableDataKey: "isEffectonGstr",
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      isMandatory: false,
    },

  ],
  fieldMetaForOption: [
    {
      label: "Cost Center",
      controlType: "autocomplete",
      placeHolder: "Select Cost Center ",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "costCenterDetail",
      dataKey: "costCenterMaster",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      inputType: "number",
      isMandatory: true,
    },
  ],
  fieldMeta1: [
    // {
    //   label: "Voucher Amount",
    //   controlType: "textfield",
    //   placeHolder: "Enter Voucher Amount",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "voucherAmount",
    //   isMandatory: true,
    //   disableTrue: true,
    // },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
};
