import { useState, useEffect } from "react";
import RTTable from "../../components/Comman/RT/RTTable";
import { apiGet } from "../../utils/api_service";
import { serverMsg, urlMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { AccountSummaryJson } from "../../DynamicFormsJson/Report/AccountSummary";
import { Paper ,Grid} from "@mui/material";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import moment from "moment";
import GroupSummarySubreportByGroup from "./GroupSummarySubReportByGroup";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor } from "../../config/ColorObj";
import MonthlyBarChart from "../../components/MonthlyBarChart";
import BarChart from '../../components/Charts/BarChart';
const GroupSummary = ({ showLoador, showNotification }) => {
  const [rowList, setRowList] = useState([]);
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [openTable, setOpenTable] = useState(false);
  const [popUpData, setPopupdata] = useState({})
  const [formData, setFormData] = useState({})
  const [searchValue, setSearchValue] = useState("");
  const [creditTotalAmt, setCreditTotalAmt] = useState("")
  const [debitTotalAmt, setDebitTotalAmt] = useState("");
  const [opBalance, setOpBalance] = useState("")
  const [fieldData, setFieldData] = useState({});
  useEffect(() => {
    showLoador({ loador: true });
    apiGet({
      url: endpoint.company,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        console.log(data);
        let rowData = data.data;
        const location=localStorage.getItem("location");
        const locationList =localStorage.getItem("locationIdList");
       const locallist=JSON.parse(locationList)
       const locationlist=locallist.filter((data)=>data.id==location)
      
           if(locationlist.length!=0 && locationlist[0]!=null && locationlist[0].company!=null){
             
            setFieldData({ companyId: locationlist[0].company.id })
           }
        setDynamicMasterData({
          CompanyDetails: rowData
        });
      } else {
        showLoador({ loador: false })
        showNotification({
          msg: serverMsg,
          severity: "error",
        });

      }

    });
  }, []);
  const onSave = (searchData) => {
    let filterDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === searchData.companyId)
    setFormData({
      ...searchData,
      name: filterDta.length != 0 ? filterDta[0].name : ""
    })

    if (searchData != null) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.accountGroupSummary +
          "?companyIds=" +
          searchData.companyId +
          "&fromDate=" +
          searchData.fromDate +
          "&toDate=" +
          searchData.toDate,
      }).then(({ data, success }) => {

        showLoador({ loador: true });
        if (success) {
          console.log(data);
          let rowData = data.data;
          let index = 0;
          let cerditTotal = 0;
          let debitTotal = 0;
          let opBalance = 0;
          let rowList = rowData.map((row) => {

            cerditTotal = cerditTotal + row.creditAmt;
            debitTotal = debitTotal + row.debitAmt;

            return {
              nameLink: true,
              groupId: row.groupId,
              groupName: row.groupName,

              debitAmt: Math.abs((+row.debitAmt)).toFixed(2),
              creditAmt: Math.abs((+row.creditAmt)).toFixed(2),
              flag:0,
            };
          });
          opBalance = cerditTotal + debitTotal;
          let cA = opBalance >= 0 ? Math.abs(opBalance).toFixed(2) : ""
          let dA = opBalance < 0 ? Math.abs(opBalance).toFixed(2) : ""
          rowList.push({ nameLink: false, groupName: "Total", debitAmt: Math.abs((+debitTotal)).toFixed(2), creditAmt: Math.abs((+cerditTotal)).toFixed(2),flag:1, })
          rowList.push({ nameLink: false, groupName: "Diff. in Op Balance", debitAmt: dA, creditAmt: cA ,flag:1})


          setRowList(rowList);
          showLoador({ loador: false })
        } else {
          showLoador({ loador: false })
          showNotification({
            msg: serverMsg,
            severity: "error",
          });

        }
      });


    };
  }


  const rowLink = (rowData) => {
    console.log("rowData")
    console.log(rowData)

    setPopupdata(rowData)
    setOpenTable(!openTable);
  }
  const onSearch = (searchV) => {
    setSearchValue(searchV)
   
  };
  const generatePDFMethod = () => {
    var columns = [["Account Head", "Debit", "Credit"]];
    let tableList = []

    rowList.map(((rowData, index) => {

      let tableData = [];

      tableData.push(rowData.groupName)
      tableData.push(rowData.debitAmt)

      tableData.push(rowData.creditAmt)





      tableList.push(tableData)
    }))

    // tableList.push("Total");
    // tableList.push("");
    // tableList.push({creditAmt:creditTotalAmt});
    // tableList.push({debitAmt:debitTotalAmt});


    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    // if(creditTotalAmt>= debitTotalAmt){
    //   tableList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})

    // }else{
    //   tableList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
    // }
    const columnStyles = {

      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate
        },
        {
          label: "Company Name",
          value: formData.name
        },
      ],
      pdfTitle: "Group Summary",
    });

  };

  const getFilteredTableData = (tableData) => {

    let listData = tableData.filter((row) => {

      let isValid = false;

      if (row.groupName.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (row.debitAmt.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (row.creditAmt.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      // if (creditTotalAmt.toString()
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase())
      // ) {
      //   isValid = true;
      // }
      // if (debitTotalAmt.toString()
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase())
      // ) {
      //   isValid = true;
      // }
      return isValid;

    });
    return listData
  };
  const generateExcelMethod = () => {
    var columns = ["Sr No.", "Account Head", "Debit", "Credit"];
    let alignList = [];

    let rows = []

    rowList.map((reportData, index) => {
      let reportObj = [index + 1];
      reportObj.push(reportData.groupName)
      reportObj.push(Math.abs(reportData.debitAmt))
      reportObj.push(Math.abs(reportData.creditAmt))



      rows.push(reportObj)



      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("")
      // reportObj.push(debitTotal==0?"": Math.abs(debitTotal) )
      // reportObj.push(creditTotal==0?"":Math.abs(creditTotal) )
      // rows.push(reportObj)

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("Day Closing")
      // reportObj.push("")
      // reportObj.push(Math.abs(reportData.closingAmt) )
      // rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate, "Company Name", formData.name

      ],
      title: "Group Summary",
    });

  };

  const toggle = () => {
    
    setOpenTable(!openTable);
    if(Object.keys(formData).length!=0){
      console.log("formData")
      onSave(formData)
    }
    

  };
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          fieldData={fieldData}
          DataToSet={[]}
          showSaveBtn={AccountSummaryJson.showSaveBtn}
          screenTitle={AccountSummaryJson.screenTitle}
          fieldMeta={AccountSummaryJson.fieldMeta}
          showCancel={AccountSummaryJson.showCancel}
          apiBaseURL={AccountSummaryJson.apiBaseURL}
          showSaveNextBtn={AccountSummaryJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
        />
        <br />
        <RTTable
          showPegination={false}
          columns={[


            { name: "groupName", title: "Account Head", linkFlag: true, linkName: "nameLink" },

            { name: "debitAmt", title: "Debit", align: "right" },
            { name: "creditAmt", title: "Credit", align: "right" },
          ]}
          hiddenColumnNames={[]}
          tableData={getFilteredTableData(rowList)}
          rowLink={rowLink}
          isActionColActive={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
        />
      </Paper>
      {
                rowList.length != 0 && <Grid sx={{ paddingTop: 2 }} container spacing={1} justifyContent="center">
                    <Grid container xs={12} sm={8} md={8} lg={8}>
                    <Grid sx={{ padding: 1 }} item xs={12} sm={10} md={10} lg={10}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>
                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Group Summary'}
                                    />
                                </div>

                                <BarChart
                                height={200}
                                    series={[{
                                      name:"debit amount",
                                        data:rowList.filter((row)=>row.flag==0 && row.debitAmt!="" ).map((rowData) => {
                                            return rowData.debitAmt
                                        }),
                                    }, {
                                      name:"credit amount",
                                        data: rowList.filter((row)=>row.flag==0 && row.creditAmt!="" ).map((rowData) => {
                                            return rowData.creditAmt
                                        }),
                                    }]}
                                    options={
                                        {
                                            chart: {
                                                type: 'bar',
                                                height: 350,
                                                toolbar: {
                                                    show: false,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },

                                            xaxis: {
                                                categories: rowList.filter((row)=>row.flag==0 && row.groupName!="" &&row.groupName!="Total").map((rowData) => {
                                                    return rowData.groupName
                                                }),
                                            },
                                            grid: {
                                                show: false,
                                            },


                                        }
                                    }

                                />
                                
                            </Paper>
                        </Grid>
                       
                        
                    </Grid>
                </Grid>
            }
      {openTable && (
        <GroupSummarySubreportByGroup


          open={openTable}
          handleCloses={toggle}
          popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
          dynamicMasterData={formData}

        />
      )}

    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };
export default connect(mapStateToProps, mapDispatchToProps)(GroupSummary);

