import { Paper, Grid } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { cashFlowSummaryJson } from "../../DynamicFormsJson/Report/cashFlowSummary";
import RTTable from "../../components/Comman/RT/RTTable";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor } from "../../config/ColorObj";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CashFlowSubReportByGroup from "./CashFlowSubReportByGroup";
import MFGAndPL from "./MFGAndPL";
import MFMPALTable from "./MFM&PALTable";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import MonthlyBarChart from "../../components/MonthlyBarChart";
import CashFlowDetailTable from "./CashFlowSummaryDetailTable";

const CashFlowSummary = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [formData, setFormData] = React.useState({});
  const [cashList, setCashbookList] = React.useState([]);
  const [popUpData, setPopupdata] = useState({});
  const [openTable, setOpenTable] = useState(false);
  const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
  const [fieldData, setFieldData] = React.useState({});
  const [MFGList, setMFGList] = React.useState({});
  const [detailList, setDetailList] = React.useState([]);
  const [searchFlag, setSearchFlag] = useState(false);
  useEffect(() => {
    getDropDownList();
  }, []);

  const getDropDownList = async () => {
    showLoador({ loador: true });

    let CompanyDetails = await apiGet({
      url: endpoints.company,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        let rowData = data.data;
        if (rowData.length == 1) {
          setFieldData({ companyId: rowData[0].id });
        }
        return rowData;
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    setDynamicMasterData({
      CompanyDetails: CompanyDetails,
    });
  };

  const getList = (fromDate, toDate, companyId) => {
    setSearchFlag(false);
    showLoador({ loador: true });
    apiGet({
      url:
        endpoints.cashFlowSummary +
        "/cash-flow-summary?companyIds=" +
        companyId +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate,
    }).then(({ data, success }) => {
      if (success) {
        let rowList = [];
        let leftAmount = 0;
        let rightAmount = 0;

        let rowData = data.data;

        let LeftAlign = rowData.inflow !== null ? rowData.inflow : [];
        let RightAlign = rowData.outflow !== null ? rowData.outflow : [];

        let rIndex = 0;
        let isDynamic = true;
        if (RightAlign.length >= LeftAlign.length) {
          RightAlign.map((right, index) => {
            let lAmt = right.debitAmt;
            rightAmount = rightAmount + lAmt;
            let dataToPush = {
              groupNameR:
                right.groupDetail != null ? right.groupDetail.group_name : "",
              groupIdR: right.groupDetail != null ? right.groupDetail.id : "",
              amountL: Math.abs(lAmt).toFixed(2),
            };
            if (isDynamic) {
              let dynamicInfo = LeftAlign[index] ? LeftAlign[index] : {};

              if (Object.keys(dynamicInfo).length != 0) {
                let lAmt = dynamicInfo.creditAmt;
                leftAmount = leftAmount + lAmt;
                dataToPush = {
                  groupId:
                    dynamicInfo.groupDetail != null
                      ? dynamicInfo.groupDetail.id
                      : "",
                  groupName:
                    dynamicInfo.groupDetail != null
                      ? dynamicInfo.groupDetail.group_name
                      : "",
                  amount: Math.abs(lAmt).toFixed(2),
                  ...dataToPush,
                };
              }
            }

            rowList.push({ ...dataToPush, flag: 0 });
          });
        } else {
          LeftAlign.map((right, index) => {
            let lAmt = right.creditAmt;
            leftAmount = leftAmount + lAmt;
            let dataToPush = {
              groupName:
                right.groupDetail != null ? right.groupDetail.group_name : "",
              groupId: right.groupDetail != null ? right.groupDetail.id : "",
              amount: Math.abs(lAmt).toFixed(2),
            };
            if (isDynamic) {
              let dynamicInfo = RightAlign[index] ? RightAlign[index] : {};
              if (Object.keys(dynamicInfo).length != 0) {
                let lAmt = dynamicInfo.debitAmt;
                rightAmount = rightAmount + lAmt;
                dataToPush = {
                  ...dataToPush,
                  groupIdR:
                    dynamicInfo.groupDetail != null
                      ? dynamicInfo.groupDetail.id
                      : "",
                  groupNameR:
                    dynamicInfo.groupDetail != null
                      ? dynamicInfo.groupDetail.group_name
                      : "",
                  amountL: Math.abs(lAmt).toFixed(2),
                };
              } else {
                isDynamic = false;

                rIndex = rIndex + 1;

                dataToPush = {
                  ...dataToPush,
                  groupNameR: "",
                  amountL: "",
                };
              }
            } else {
              {
                isDynamic = false;

                rIndex = rIndex + 1;

                dataToPush = {
                  ...dataToPush,
                  groupNameR: "",
                  amountL: "",
                };
              }
            }

            rowList.push({ ...dataToPush, flag: 0 });
          });
        }

        let dataToPush = {
          groupName: "Total",
          amount: Math.abs(leftAmount).toFixed(2),
          groupNameR: "",
          amountL: Math.abs(rightAmount).toFixed(2),
          groupNameRLink: false,
          groupNameLink: false,
        };
        rowList.push({ ...dataToPush, flag: 1 });
        setCashbookList(rowList);
        showLoador({ loador: false });
      } else {
        setCashbookList([]);
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  };

  const onSave = (dataToSearch) => {
    let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter(
      (row) => row.id === dataToSearch.companyId
    );

    setFormData({
      ...dataToSearch,
      name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
    });
    getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId);
  };
  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };

  const generatePDFMethod = () => {
    generatePDFExcelData();
    var columns = [["InFlow", "Amount", "OutFlow", "Amount"]];

    let tableList = [];
    cashList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      tableList.push(tableData);
    });

    const columnStylesPlain = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStylesPlain,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "Company Name",
          value: formData.name,
        },
        {
          label: "Year Name",
          value: formData.yearName,
        },
      ],
      pdfTitle: cashFlowSummaryJson.screenTitle,
    });
  };
  const generatePDFExcelData = () => {
    let tableList = [];
    MFGList.DynamicData &&
      MFGList.DynamicData.filter(
        (rowData) =>
          rowData.groupDetail != null && rowData.groupDetail.allignment != "R"
      ).map((rowData) => {
        let leftTotal = 0;
        leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt;
        let tableData = [];
        tableData.push(rowData.groupDetail.group_name);
        tableData.push(
          Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2)
        );
      });
    MFGList.staticData &&
      MFGList.staticData
        .filter((rowData) => rowData.alligment != "R")
        .map((rowData) => {
          let leftTotal = 0;
          leftTotal = leftTotal + rowData.amt;
          let tableData = [];
          tableData.push(rowData.name);
          tableData.push(Math.abs(rowData.amt).toFixed(2));
        });
    MFGList.DynamicData &&
      MFGList.DynamicData.filter(
        (rowData) =>
          rowData.groupDetail != null && rowData.groupDetail.allignment == "R"
      ).map((rowData) => {
        let leftTotal = 0;
        leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt;
        let tableData = [];
        tableData.push(rowData.groupDetail.group_name);
        tableData.push(
          Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2)
        );
      });
    MFGList.staticData &&
      MFGList.staticData
        .filter((rowData) => rowData.alligment == "R")
        .map((rowData) => {
          let leftTotal = 0;
          leftTotal = leftTotal + rowData.amt;
          let tableData = [];
          tableData.push(rowData.name);
          tableData.push(Math.abs(rowData.amt).toFixed(2));
        });
  };

  const generateExcelMethod = () => {
    var columns = ["InFlow", "Amount", "OutFlow", "Amount"];
    let alignList = [];

    let rows = [];
    cashList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      rows.push(tableData);
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Company Name",
        formData.name,
        "Year Name",
        formData.yearName,
      ],
      title: cashFlowSummaryJson.screenTitle,
    });
  };
  const getFilteredTableData = (tableData) => {
    let listData = tableData.filter((row) => {
      let isValid = false;

      if (
        row.groupName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      if (
        row.groupNameR
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.amount.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      if (
        row.amountL.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      return isValid;
    });
    return listData;
  };
  const rowLink = (rowData, key) => {
    if (key == "groupNameR") {
      if (rowData.groupIdR) {
        setPopupdata({
          groupId: rowData.groupIdR,
          groupName: rowData.groupNameR,
          flag: 0,
        });
        setOpenTable(!openTable);
      } else {
        toggleMFGAndProfitAndLoss();
      }
    } else {
      if (rowData.groupId) {
        setPopupdata({
          groupId: rowData.groupId,
          groupName: rowData.groupName,
          flag: 1,
        });
        setOpenTable(!openTable);
      } else {
        toggleMFGAndProfitAndLoss();
      }
    }
  };
  const toggle = () => {
    setOpenTable(!openTable);
    if (formData != null && Object.keys(formData).length != 0) {
      getList(formData.fromDate, formData.toDate, formData.companyId);
    }
  };
  const toggleMFGAndProfitAndLoss = () => {
    setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
  };

  const showDetail = (dataToSearch) => {
    setSearchFlag(true);
    let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter(
      (row) => row.id === dataToSearch.companyId
    );

    setFormData({
      ...dataToSearch,
      name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
    });
    getDeatilList(
      dataToSearch.fromDate,
      dataToSearch.toDate,
      dataToSearch.companyId
    );
    // getDeatilList(dataToSearch.companyId, dataToSearch.yearId)
  };

  const getDeatilList = (fromDate, toDate, companyId) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoints.cashFlowSummary +
        "/cash-flow-detail?companyIds=" +
        companyId +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate,
    }).then(({ data, success }) => {
      if (success) {
        let rowList = [];
        let leftAmount = 0;
        let rightAmount = 0;
        let rowData = data.data;
        let RightAlign = rowData.inflow !== null ? rowData.inflow : [];
        let LeftAlign = rowData.outflow !== null ? rowData.outflow : [];
        let rIndex = 0;
        let isDynamic = true;
        if (RightAlign.length >= LeftAlign.length) {
          RightAlign.map((right, index) => {
            let lAmt = right.debitAmt;
            rightAmount = rightAmount + lAmt;
            let dataToPush = {
              groupNameR:
                right.groupDetail != null ? right.groupDetail.group_name : "",
              groupIdR: right.groupDetail != null ? right.groupDetail.id : "",
              amountL: Math.abs(lAmt).toFixed(2),
              resultR:
                right.groupDetail != null && right.groupDetail.result
                  ? right.groupDetail.result
                  : [],
            };
            if (isDynamic) {
              let dynamicInfo = LeftAlign[index] ? LeftAlign[index] : {};

              if (Object.keys(dynamicInfo).length != 0) {
                let lAmt = dynamicInfo.creditAmt;
                leftAmount = leftAmount + lAmt;
                dataToPush = {
                  groupId:
                    dynamicInfo.groupDetail != null
                      ? dynamicInfo.groupDetail.id
                      : "",
                  groupName:
                    dynamicInfo.groupDetail != null
                      ? dynamicInfo.groupDetail.group_name
                      : "",
                  amount: Math.abs(lAmt).toFixed(2),
                  resultL:
                    dynamicInfo.groupDetail != null &&
                    dynamicInfo.groupDetail.result
                      ? dynamicInfo.groupDetail.result
                      : [],
                  ...dataToPush,
                };
              } else {
                isDynamic = false;

                rIndex = rIndex + 1;

                dataToPush = {
                  groupName: "",
                  amount: "",
                  ...dataToPush,
                };
              }
            } else {
              isDynamic = false;
              dataToPush = {
                groupName: "",
                amount: "",
                ...dataToPush,
              };
            }

            rowList.push({ ...dataToPush, flag: 0 });
          });
        } else {
          LeftAlign.map((right, index) => {
            let lAmt = right.creditAmt;
            leftAmount = leftAmount + lAmt;
            let dataToPush = {
              groupName:
                right.groupDetail != null ? right.groupDetail.group_name : "",
              groupId: right.groupDetail != null ? right.groupDetail.id : "",
              amount: Math.abs(lAmt).toFixed(2),
              resultL:
                right.groupDetail != null && right.groupDetail.result
                  ? right.groupDetail.result
                  : [],
            };
            if (isDynamic) {
              let dynamicInfo = RightAlign[index] ? RightAlign[index] : {};
              if (Object.keys(dynamicInfo).length != 0) {
                let lAmt = dynamicInfo.debitAmt;
                rightAmount = rightAmount + lAmt;
                dataToPush = {
                  ...dataToPush,
                  groupIdR:
                    dynamicInfo.groupDetail != null
                      ? dynamicInfo.groupDetail.id
                      : "",
                  groupNameR:
                    dynamicInfo.groupDetail != null
                      ? dynamicInfo.groupDetail.group_name
                      : "",
                  amountL: Math.abs(lAmt).toFixed(2),
                  resultR:
                    dynamicInfo.groupDetail != null &&
                    dynamicInfo.groupDetail.result
                      ? dynamicInfo.groupDetail.result
                      : [],
                };
              } else {
                isDynamic = false;

                rIndex = rIndex + 1;

                dataToPush = {
                  ...dataToPush,
                  groupNameR: "",
                  amountL: "",
                };
              }
            } else {
              isDynamic = false;

              rIndex = rIndex + 1;

              dataToPush = {
                ...dataToPush,
                groupNameR: "",
                amountL: "",
              };
            }

            rowList.push({ ...dataToPush, flag: 0 });
          });
        }

        let dataToPush = {
          groupName: "Total",
          amount: Math.abs(leftAmount).toFixed(2),
          groupNameR: "Total",
          amountL: Math.abs(rightAmount).toFixed(2),
          groupNameRLink: false,
          groupNameLink: false,
        };
        rowList.push({ ...dataToPush, flag: 1 });
        setDetailList(rowList);
        showLoador({ loador: false });
        let DynamicData =
          Object.keys(data.data).length != 0 &&
          data.data.dynamicData.length != 0
            ? data.data.dynamicData
            : [];
        let staticData =
          Object.keys(data.data).length != 0 && data.data.staticData.length != 0
            ? data.data.staticData
            : [];
        setMFGList({ DynamicData: DynamicData, staticData: staticData });
      } else {
        setDetailList([]);
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  };

  const generateDetailPDFMethod = () => {
    // generatePDFExcelData()
    var columns = [["InFlow", "Amount", "OutFlow", "Amount"]];
    console.log("detail pdf");
    let tableList = [];

    detailList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      tableList.push(tableData);
      if (rowData.resultR && rowData.resultL) {
        if (rowData.resultR.length >= rowData.resultL.length) {
          rowData.resultR.map((data, index) => {
            let leftInfo = rowData.resultL[index] ? rowData.resultL[index] : {};
            let creditAmt = 0;
            let debitAmt = 0;
            creditAmt = data.creditAmt == null ? creditAmt : data.creditAmt;
            debitAmt = data.debitAmt == null ? debitAmt : data.debitAmt;
            let creditAmtL = 0;
            let debitAmtL = 0;
            creditAmtL =
              leftInfo.creditAmt != undefined && leftInfo.creditAmt != null
                ? leftInfo.creditAmt
                : "";
            debitAmtL =
              leftInfo.debitAmt != undefined && leftInfo.debitAmt != null
                ? leftInfo.debitAmt
                : "";
            let tableData = [];
            tableData.push(leftInfo.name ? leftInfo.name : "");
            tableData.push(
              creditAmtL != "" && debitAmtL != ""
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );

            tableData.push(data.name);
            tableData.push(Math.abs(creditAmt + debitAmt).toFixed(2));
            tableList.push(tableData);
          });
        } else {
          rowData.resultL.map((data, indexData) => {
            let leftInfo = rowData.resultR[indexData]
              ? rowData.resultR[indexData]
              : {};
            let creditAmt = 0;
            let debitAmt = 0;
            creditAmt = data.creditAmt == null ? creditAmt : data.creditAmt;
            debitAmt = data.debitAmt == null ? debitAmt : data.debitAmt;
            let creditAmtL = 0;
            let debitAmtL = 0;
            creditAmtL =
              leftInfo.creditAmt != undefined && leftInfo.creditAmt != null
                ? leftInfo.creditAmt
                : "";
            debitAmtL =
              leftInfo.debitAmt != undefined && leftInfo.debitAmt != null
                ? leftInfo.debitAmt
                : "";
            let tableData = [];
            tableData.push(data.name);
            tableData.push(Math.abs(creditAmt + debitAmt).toFixed(2));
            tableData.push(leftInfo.name ? leftInfo.name : "");
            console.log(Math.abs(creditAmtL + debitAmtL).toFixed(2));
            console.log(
              (creditAmtL != "" && debitAmtL != "") ||
                ((creditAmtL == 0 || debitAmtL == 0) &&
                  (creditAmtL != "" || debitAmtL != ""))
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );
            tableData.push(
              (creditAmtL != "" && debitAmtL != "") ||
                ((creditAmtL == 0 || debitAmtL == 0) &&
                  (creditAmtL != "" || debitAmtL != ""))
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );
            tableList.push(tableData);
          });
        }
      }
    });

    const columnStyles = {
      0: { cellWidth: 150 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    const columnStylesPlain = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStylesPlain,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "Company Name",
          value: formData.name,
        },
        {
          label: "Year Name",
          value: formData.yearName,
        },
      ],
      pdfTitle: cashFlowSummaryJson.screenTitle,
    });
  };
  const generateDetailExcelMethod = () => {
    var columns = ["InFlow", "Amount", "OutFlow", "Amount"];
    let alignList = [];

    let rows = [];

    detailList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      rows.push(tableData);
      if (rowData.resultR && rowData.resultL) {
        if (rowData.resultR.length >= rowData.resultL.length) {
          rowData.resultR.map((data, index) => {
            let leftInfo = rowData.resultL[index] ? rowData.resultL[index] : {};
            let creditAmt = 0;
            let debitAmt = 0;
            creditAmt = data.creditAmt == null ? creditAmt : data.creditAmt;
            debitAmt = data.debitAmt == null ? debitAmt : data.debitAmt;
            let creditAmtL = 0;
            let debitAmtL = 0;
            creditAmtL =
              leftInfo.creditAmt != undefined && leftInfo.creditAmt != null
                ? leftInfo.creditAmt
                : "";
            debitAmtL =
              leftInfo.debitAmt != undefined && leftInfo.debitAmt != null
                ? leftInfo.debitAmt
                : "";
            let tableData = [];
            tableData.push(leftInfo.name ? leftInfo.name : "");
            tableData.push(
              creditAmtL != "" && debitAmtL != ""
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );

            tableData.push(data.name);
            tableData.push(Math.abs(creditAmt + debitAmt).toFixed(2));
            rows.push(tableData);
          });
        } else {
          rowData.resultL.map((data, indexData) => {
            let leftInfo = rowData.resultR[indexData]
              ? rowData.resultR[indexData]
              : {};
            let creditAmt = 0;
            let debitAmt = 0;
            creditAmt = data.creditAmt == null ? creditAmt : data.creditAmt;
            debitAmt = data.debitAmt == null ? debitAmt : data.debitAmt;
            let creditAmtL = 0;
            let debitAmtL = 0;
            creditAmtL =
              leftInfo.creditAmt != undefined && leftInfo.creditAmt != null
                ? leftInfo.creditAmt
                : "";
            debitAmtL =
              leftInfo.debitAmt != undefined && leftInfo.debitAmt != null
                ? leftInfo.debitAmt
                : "";
            let tableData = [];
            tableData.push(data.name);
            tableData.push(Math.abs(creditAmt + debitAmt).toFixed(2));
            tableData.push(leftInfo.name ? leftInfo.name : "");
            console.log(Math.abs(creditAmtL + debitAmtL).toFixed(2));
            console.log(
              (creditAmtL != "" && debitAmtL != "") ||
                ((creditAmtL == 0 || debitAmtL == 0) &&
                  (creditAmtL != "" || debitAmtL != ""))
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );
            tableData.push(
              (creditAmtL != "" && debitAmtL != "") ||
                ((creditAmtL == 0 || debitAmtL == 0) &&
                  (creditAmtL != "" || debitAmtL != ""))
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );
            rows.push(tableData);
          });
        }
      }
    });

    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Company Name",
        formData.name,
        "Year Name",
        formData.yearName,
      ],
      title: cashFlowSummaryJson.screenTitle,
    });
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          fieldData={fieldData}
          showSaveBtn={cashFlowSummaryJson.showSaveBtn}
          screenTitle={cashFlowSummaryJson.screenTitle}
          fieldMeta={cashFlowSummaryJson.fieldMeta}
          showCancel={cashFlowSummaryJson.showCancel}
          apiBaseURL={cashFlowSummaryJson.apiBaseURL}
          showSaveNextBtn={cashFlowSummaryJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          generatePDF={searchFlag ? generateDetailPDFMethod : generatePDFMethod}
          generateExcel={
            searchFlag ? generateDetailExcelMethod : generateExcelMethod
          }
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
          showPDFDownload={true}
          detailButton={false}
          onSaveDetail={showDetail}
        />
        <br />

        {searchFlag ? (
          <>
            <CashFlowDetailTable
              tableHead={[
                {
                  title: "InFlow",

                  align: "left",
                },
                {
                  title: "OutFlow",
                  align: "left",
                },
              ]}
              rowList={detailList}
              formData={formData}
              getDeatilList={getDeatilList}
            />
          </>
        ) : (
          <RTTable
            showPegination={false}
            columns={[
              {
                name: "groupName",
                title: "Inflow",
                linkFlag: true,
                linkName: "groupNameLink",
              },
              {
                name: "amount",
                title: "Amount",
                fontWeight: "bold",
                align: "right",
              },
              {
                name: "groupNameR",
                title: "Outflow",
                linkFlag: true,
                linkName: "groupNameRLink",
              },
              {
                name: "amountL",
                title: "Amount",
                fontWeight: "bold",
                align: "right",
              },
            ]}
            hiddenColumnNames={[]}
            tableData={getFilteredTableData(cashList)}
            rowLink={rowLink}
            isActionColActive={false}
            // rowCopy={this.rowCopy}
          />
        )}
        {openTable && (
          <CashFlowSubReportByGroup
            open={openTable}
            handleCloses={toggle}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            dynamicMasterData={formData}
          />
        )}
        {openMFGAndProfitAndLoss && (
          <MFGAndPL
            rowLink={rowLink}
            open={openMFGAndProfitAndLoss}
            handleCloses={toggleMFGAndProfitAndLoss}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            formData={formData}
          />
        )}
      </Paper>
      <Grid
        sx={{ paddingTop: 2 }}
        container
        spacing={1}
        justifyContent="center"
      >
        <Grid container xs={12} sm={10} md={10} lg={10}>
          <>
            {cashList.length != 0 && (
              <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6}>
                <Paper sx={{ borderRadius: 2 }} elevation={0}>
                  <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 14, color: blackColor }}
                      label={"Inflow of Cash"}
                    />
                  </div>

                  <MonthlyBarChart
                    // radioValue={onChangeRadioValue}
                    height={200}
                    series={[
                      {
                        name: "Amount",
                        data: cashList
                          .filter(
                            (row) =>
                              row.groupName != "Total" && row.amount != ""
                          )
                          .map((rowData) => {
                            return rowData.amount;
                          }),
                      },
                    ]}
                    optionsObj={{
                      chart: {
                        type: "bar",
                        height: 365,
                        events: {
                          dataPointSelection: (event, chartContext, config) => {
                            //   onClickLineChart(config.dataPointIndex);
                          },
                        },
                      },

                      plotOptions: {
                        bar: {
                          columnWidth: "40%",
                          distributed: true,
                        },
                      },
                      stroke: {
                        show: true,
                        width: 2,
                        // colors: ["transparent"],
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      xaxis: {
                        categories: cashList
                          .filter(
                            (row) =>
                              row.groupName != "Total" && row.groupName != ""
                          )
                          .map((rowData, index) => {
                            index = index + 1;

                            return rowData.groupName;
                          }),
                      },
                      yaxis: {
                        title: {
                          text: "$ (thousands)",
                        },
                      },
                      fill: {
                        opacity: 1,
                      },
                      colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                      tooltip: {
                        theme: "light",
                      },
                    }}
                  />
                </Paper>
              </Grid>
            )}
          </>
          {cashList.length != 0 && (
            <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6}>
              <Paper sx={{ borderRadius: 2 }} elevation={0}>
                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 14, color: blackColor }}
                    label={"Outflow of Cash"}
                  />
                </div>

                <MonthlyBarChart
                  height={200}
                  series={[
                    {
                      name: "Amount",
                      data: cashList
                        .filter((row) => row.flag != 1 && row.amountL != "")
                        .map((rowData) => {
                          return rowData.amountL;
                        }),
                    },
                  ]}
                  optionsObj={{
                    chart: {
                      type: "bar",
                      height: 365,
                      events: {
                        dataPointSelection: (event, chartContext, config) => {
                          //   onClickLineChart(config.dataPointIndex);
                        },
                      },
                    },

                    plotOptions: {
                      bar: {
                        columnWidth: "40%",
                        distributed: true,
                      },
                    },
                    stroke: {
                      show: true,
                      width: 2,
                      // colors: ["transparent"],
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    xaxis: {
                      categories: cashList
                        .filter(
                          (row) =>
                            row.groupNameR != "Total" && row.groupNameR != ""
                        )
                        .map((rowData, index) => {
                          index = index + 1;

                          return rowData.groupNameR;
                        }),
                    },
                    yaxis: {
                      title: {
                        text: "$ (thousands)",
                      },
                    },
                    fill: {
                      opacity: 1,
                    },
                    colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                    tooltip: {
                      theme: "light",
                    },
                  }}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CashFlowSummary);
