import endpoint from "../../config/endpoints";

export const salePurchaseHistotyJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.asset,
  screenTitle: "Sale Purchase History",
  formPath: "/purchase-sale-history",
  formPathView: "/sale-purchase-view",
  showBackToList: false,
  showTitle: true,
 
searchList: [
   
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md:3.5,
      lg:3.5,
      sm:3.5,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
    //   onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md:3.5,
      lg:3.5,
      sm:3.5,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      
    },
    {
        label: "Entry type",
        controlType: "autocomplete",
        placeHolder: "Select Entry type ",
        md:3.5,
        lg:3.5,
        sm:3.5,
        xs: 12,
        masterName: "entryTypeDetails",
        dataKey: "entryType",
        isRootLevelKey: true,
        isMandatory: true,
      },
  ],
  fieldMeta: [
//     {
//         label: "Ledger Code",
//         controlType: "autocomplete",
//         placeHolder: "Select Ledger Code ",
//         md: 6,
//         lg: 6,
//         sm: 6,
//         xs: 12,
//         masterName:"ledgeCodeDetail",
//         dataKey: "ledgerCode",
//         isRootLevelKey: true,
//         isMandatory: true,
//       },
   
   
//     {
//       label: "Depreciation Percentage.",
//       controlType: "textfield",
//       placeHolder: "Enter Depreciation Percentage.",
//       md: 6,
//       lg: 6,
//       sm: 6,
//       xs: 12,
//       dataKey: "depreciationPercentage",
//       inputType: "number",
//       isMandatory: true,
//       calculateMinus:true
//     },
//     {
//         label: "Opening Value.",
//         controlType: "textfield",
//         placeHolder: "Enter Opening Value.",
//         md: 6,
//         lg: 6,
//         sm: 6,
//         xs: 12,
//         dataKey: "openingValue",
//         inputType: "number",
//         isMandatory: true,
//         calculateMinus:true
//       },
   
    
   
//     {
//       label: "Purchase In First Half",
//       controlType: "textfield",
//       placeHolder: "Enter Purchase In First Half",
//       md: 6,
//       lg: 6,
//       sm: 6,
//       xs: 12,
//       dataKey: "purchaseInFirstHalf",
//       inputType: "number",
//       isMandatory: true,
//       calculateMinus:true
//     },
//     {
//         label: "Purchase In Second Half",
//         controlType: "textfield",
//         placeHolder: "Enter Purchase In Second Half",
//         md: 6,
//         lg: 6,
//         sm: 6,
//         xs: 12,
//         dataKey: "purchaseInSecondHalf",
//         inputType: "number",
//         isMandatory: true,
//         calculateMinus:true
//       },
  
//     {
//       label: "Sale Value",
//       controlType: "textfield",
//       placeHolder: "Enter Sale Value",
//       md: 6,
//       lg: 6,
//       sm: 6,
//       xs: 12,
//       dataKey: "sellValue",
//       inputType: "number",
//       isMandatory: true,
//       calculateMinus:true
//     },
//     {
//         label: "Total",
//         controlType: "textfield",
//         placeHolder: "Enter Total",
//         md: 6,
//         lg: 6,
//         sm: 6,
//         xs: 12,
//         dataKey: "total",
//         inputType: "number",
//         isMandatory: false,
//         disableTrue: true,
//         calculateMinus:true
//       },
//       {
//         label: "Depreciation Value",
//         controlType: "textfield",
//         placeHolder: "Enter Depreciation Value",
//         md: 6,
//         lg: 6,
//         sm: 6,
//         xs: 12,
//         dataKey: "1dvalue",
//         inputType: "number",
//         isMandatory: false,
//         disableTrue: true,
//         calculateMinus:true
//       },
//       {
//         label: "Closing Value",
//         controlType: "textfield",
//         placeHolder: "Enter Closing Value",
//         md: 6,
//         lg: 6,
//         sm: 6,
//         xs: 12,
//         dataKey: "closingValue",
//         inputType: "number",
//         isMandatory: false,
//         disableTrue: true,
//         calculateMinus:true
//       },
//     {
//       label: "Remark",
//       controlType: "textfield",
//       placeHolder: "Enter Remark",
//       md: 12,
//       lg: 12,
//       sm: 12,
//       xs: 12,
//       labelSM: 2,
//       labelMD: 2,
//       labelLG: 2,
//       valueSM: 10,
//       valueMD: 10,
//       valueLG: 10,
//       dataKey: "remark",
//       isMandatory: true,
//     },
  ],
 
 
  
};
