import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { openingBalanceJson } from "../../DynamicFormsJson/Report/OpeningBalance";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import OpeningBalanceTable from "./OpeningBalanceTable";

const OpeningBalance = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [formData, setFormData] = React.useState({});
  const [cashList, setCashbookList] = React.useState([]);
  const [fieldData, setFieldData] = React.useState({});
  useEffect(() => {
    getDropDownList()
  }, []);
  
  const getDropDownList = async () => {
    showLoador({ loador: true });
    const accountLedgerGroupMasterDetail = await apiGet({
      url: endpoints.accountLedgerGroupMaster,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        let rowData = data.data;

        showLoador({ loador: false });
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.groupName };
        })
      }else {
      
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return []
    });
    showLoador({ loador: true });
    let CompanyDetails = await apiGet({
      url: endpoints.company,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data.data);
        let rowData = data.data;
        const location=localStorage.getItem("location");
        const locationList =localStorage.getItem("locationIdList");
       const locallist=JSON.parse(locationList)
       const locationlist=locallist.filter((data)=>data.id==location)
      
           if(locationlist.length!=0 && locationlist[0]!=null && locationlist[0].company!=null){
             
             setFieldData({ companyId: locationlist[0].company.id })
           }
        showLoador({ loador: false });
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.name };
        })
      }else {
      
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return []
    });
    setDynamicMasterData({
      accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetail,
      CompanyDetails: CompanyDetails
    });
  }
 
  const getList = (fromDate, toDate, companyId, accId) => {
    showLoador({ loador: true });
    apiGet({
      url: endpoints.trailBalance + "?companyId=" + companyId + "&fromDate=" + fromDate + "&toDate=" + toDate + "&groupIds=" + accId+"&flag=1",

    }).then(({ data, success }) => {
      if (success) {
        
        showLoador({ loador: false });
        setCashbookList(data.data)
      } else {
        setCashbookList([])
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

    });
  }

  const onSave = (dataToSearch) => {
    let account = dataToSearch.accountLedgerGroupsId.split(",")
    let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
    let filteraccountLedgerGroupMaster = []
     dynamicMasterData["accountLedgerGroupMasterDetail"].map((row) => {
     
      account.map((accData) => {
        if (row.id === accData) {
          filteraccountLedgerGroupMaster.push(row.name)
        }
      })     
    })
  setFormData({
    ...dataToSearch,
    name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
    accName: filteraccountLedgerGroupMaster.join(),
  })
  getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId,dataToSearch.accountLedgerGroupsId )
}
const onSearch = (searchV) => {
  setSearchValue(searchV)
};

const generatePDFMethod = () => {
 
  var columns = [["Accd", "Account Head", "Debit", "Credit"]];
  let tableList = []
  cashList.map(((rowData, index) => {
    if(rowData.showGroup==1){
    let creditTotal = 0
    let debitTotal = 0
    let tableData = []
    let accountLedgerCode = ""
    let accountLedgerName = ""
    let debit = ""
    let Credit = ""
    rowData.getTrialBalanceReport.map((data) => {
     
        debitTotal = debitTotal + data.openingDebitAmount
     
        creditTotal = creditTotal + data.openingCreditAmount
      

        accountLedgerCode = data.accountLedgerCode!=null?accountLedgerCode + data.accountLedgerCode+ "\n":accountLedgerCode + "\n"
        accountLedgerName = data.accountLedgerName!=null?accountLedgerName + data.accountLedgerName+ "\n":accountLedgerName + "\n"
      debit = debit + Math.abs(data.openingDebitAmount)+ "\n" 
      Credit = Credit + Math.abs(data.openingCreditAmount)+ "\n" 
    })

    tableData = []
    
    tableData.push(rowData.groupName + "\n" + accountLedgerCode )
   
    tableData.push("\n" + accountLedgerName )
    tableData.push("\n" + debit+"\n"+debitTotal )
    tableData.push("\n" + Credit+"\n"+creditTotal )
    tableList.push(tableData)
  }
  }))
 
  const columnStyles = {
    0: { cellWidth: 150 },
    1: { cellWidth: 150 },
    2: { cellWidth: 90 },
  };
  const columnStylesPlain = {
    0: { cellWidth: 80 },
    1: { cellWidth: 150 },
    2: { cellWidth: 90 },
  };
  generatePDF({
    columnStylesPlain: columnStylesPlain,
    rows: tableList,
    columns,
    columnStyles: columnStyles,
    headerList: [
      {
        label: "Date Range ",
        value: formData.fromDate + " To " + formData.toDate
      },
      {
        label: "Company Name",
        value: formData.name
      },
      {
        label: "Group Name",
        value: formData.accName
      },
    ],
    pdfTitle: openingBalanceJson.screenTitle,
  });

};



const generateExcelMethod = () => {
 var columns = ["Accd", "Account Head", "Debit", "Credit"];
  let alignList = [];

  let rows = []
  cashList.map((reportData, index) => {
    if(reportData.showGroup==1){
      let reportObj = [];
      reportObj.push(reportData.groupName)
      reportObj.push("")
      reportObj.push("")
      reportObj.push("")
      rows.push(reportObj)
      let creditTotal = 0
      let debitTotal = 0
    
      reportData.getTrialBalanceReport.map((data) => {
        debitTotal = debitTotal + data.openingDebitAmount
       
        creditTotal = creditTotal + data.openingCreditAmount
  
        let type = data.type == "D" ? "Amt Paid To " + data.refAccName : "Amt Received "
  
      
        reportObj = [];
        
        reportObj.push(data.accountLedgerCode)
        reportObj.push(data.accountLedgerName)
        reportObj.push(data.openingDebitAmount)
        reportObj.push(data.openingCreditAmount)
        rows.push(reportObj)
       
      })
      reportObj = [];
      reportObj.push("")
      reportObj.push("")
      reportObj.push( Math.abs(debitTotal))
      reportObj.push( Math.abs(creditTotal))
      rows.push(reportObj)
    }
   

  });
  generateExcel({
    columns,
    rows,
    alignList,
    headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate, "Company Name", formData.name,"Group Name", formData.accName

    ],
    title: openingBalanceJson.screenTitle,
  });

};
const getFilteredTableData = (tableData) => {

  let listData = tableData.filter((row) => {

    let isValid = false;

    if (row.groupName.toString()
      .toLowerCase()
      .includes(searchValue.toLowerCase())
    ) {
      isValid = true;
    }


    return isValid;

  });
  return listData
};
const getDataForDisabled=(data)=>{
  console.log(data)
  return {}
}
return (
  <>
    <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

      <DynamicFormWithReport
        dynamicMasterData={dynamicMasterData}
        showBackToList={false}
        DataToSet={[]}
        showSaveBtn={openingBalanceJson.showSaveBtn}
        screenTitle={openingBalanceJson.screenTitle}
        fieldMeta={openingBalanceJson.fieldMeta}
        showCancel={openingBalanceJson.showCancel}
        apiBaseURL={openingBalanceJson.apiBaseURL}
        showSaveNextBtn={openingBalanceJson.showSaveNextBtn}
        showSaveBtnMain={true}
        padding={false}
        generatePDF={generatePDFMethod}
        fieldData={fieldData}
        generateExcel={generateExcelMethod}
        searchName="Group Name"
        onSearch={onSearch}
        saveBtnText={"Search"}
        paddingTop={false}
        onSave={onSave}
        showExcelDownload={true}
        
      />
      <br />

      <OpeningBalanceTable
        tableHead={[
          {
            title: "Accd",
            align: "left",
          },
          {
            title: "Account Head",
            align: "left",
          },
          {
            title: "Debit",
            align: "left",
          },
          {
            title: "Credit",
            align: "left",
          },
        ]}
        rowList={getFilteredTableData(cashList)}
        formData={formData}
        getList={getList}
        dynamicMasterData={formData}
      />
    </Paper>
  </>
);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(OpeningBalance);
