import { Paper,Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { profitAndLossPlantWiseJson } from "../../DynamicFormsJson/Report/ProfitAndLossPlantWise";
import RTTable from "../../components/Comman/RT/RTTable";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import LeftRightAdjustTable from "./LeftRightAdjustTable";
import MonthlyBarChart from "../../components/MonthlyBarChart";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor } from "../../config/ColorObj";
import GroupSummaryByPlantSubreportByGroup from "./GroupSummaryByPlantSubReportByGroup";
import { getLocation } from "../../Slice/accountLedger.slice";
const ProfitAndLossPlantWise = ({
  showLoador,
  showNotification,
  getLocation,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [formData, setFormData] = React.useState({});
  const [cashList, setCashbookList] = React.useState([]);
  const [popUpData, setPopupdata] = useState({});
  const [openTable, setOpenTable] = useState(false);
  const [fieldData, setFieldData] = React.useState({});
  const [valuesArray, setValueArray] = useState([]);
  const [MFGList, setMFGList] = React.useState({});
  useEffect(() => {
    getDropDownList();
  }, []);
  const toggle = () => {
    setOpenTable(!openTable);
    if(formData!=null && Object.keys(formData).length!=0){
      let filterLocationDta = dynamicMasterData["locationDetails"].filter(
        (row) => row.id === formData.locationId
      );
      getList(filterLocationDta, formData.yearId, "B");
  }
  };
  const getDropDownList = async () => {
    showLoador({ loador: true });
    const yearMasterDetail = await apiGet({
      url: endpoints.year,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        let rowData = data.data;

        showLoador({ loador: false });
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.yearName };
        });
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

      return [];
    });
    // showLoador({ loador: true });
    // let locationDetails = await getLocation().then(({ response, success }) => {
    //     if (!success) {
    //         showLoador({ loador: false });
    //       showNotification({ msg: serverMsg, severity: "error" });
    //       return [];
    //     }else{
    //         showLoador({ loador: false });
    //         // let rowData = data.data;

    //         if (response.length !== 0) {
    //             setFieldData({ locationId: response[0].id });
    //           }
    //         return response;
    //     }
    //   });
    let locationDetails = [];
    let valuesArray = [];
    const locationIdList = await localStorage.getItem("locationIdList");
    if (locationIdList) {
      valuesArray = JSON.parse(locationIdList);
    }

    valuesArray.length != 0 &&
      valuesArray.map((location) => {
        locationDetails.push({ ...location,
          companyId:
            location.company != null && location.company.id
              ? location.company.id
              : "",
          name:
            location.company != null && location.company.name
              ? location.company.name
              : "",})
         
        })
    
    if (locationDetails.length != 0) {
      setFieldData({ locationId: locationDetails[0].id });
    }
    setDynamicMasterData({
      yearMasterDetail: yearMasterDetail,
      locationDetails: locationDetails,
    });
  };
  const getList = (filterLocationDta, yearId, type) => {
    let companyId =
      filterLocationDta.length != 0 ? filterLocationDta[0].companyId : "";
    let locationId =
      filterLocationDta.length != 0 ? filterLocationDta[0].id : "";
    showLoador({ loador: true });
    apiGet({
      url:
        endpoints.MFGAndPfPlant +
        "?companyIds=" +
        companyId +
        "&yearId=" +
        yearId +
        "&type=" +
        type +
        "&locationId=" +
        locationId,
      //    "?companyId=" + companyId + "&fromDate=" + "fromDate" + "&toDate=" + toDate + "&groupIds=" + accId
    }).then(({ data, success }) => {
     

      if (success) {
        let rowList = []
        let leftAmount = 0
        let rightAmount = 0
       
        let rowData =  Object.keys(data.data).length != 0 &&
            data.data.profitAndLoss != null? data.data.profitAndLoss:{}
if(Object.keys(rowData).length!=0){
  let RightAlign = rowData.dynamicData !== null ? rowData.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R") : []
  let LeftAlign = rowData.dynamicData !== null ? rowData.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R") : []
  let RightAlignProfitStatic = Object.keys(rowData).length != 0 && rowData.staticData.length != 0 ? rowData.staticData.filter((rowData) => rowData.alligment == "R") : [];
  let LeftAlignProfitStatic = Object.keys(rowData).length != 0 && rowData.staticData.length != 0 ? rowData.staticData.filter((rowData) => rowData.alligment != "R") : [];

  let rIndex = 0
  let isDynamic = true
  if (RightAlign.length >= LeftAlign.length) {
      RightAlign.map((right, index) => {
          let lAmt = right.creditAmt + right.debitAmt
          rightAmount = rightAmount + lAmt
          let dataToPush = {
              groupNameR: right.groupDetail != null ? right.groupDetail.group_name : "",
              groupIdR: right.groupDetail != null ? right.groupDetail.id : "",
              amountL: Math.abs(lAmt).toFixed(2),
          }
          if (isDynamic) {
              let dynamicInfo = LeftAlign[index] ? LeftAlign[index] : {}

              if (Object.keys(dynamicInfo).length != 0) {
                  let lAmt = dynamicInfo.creditAmt + dynamicInfo.debitAmt
                  leftAmount = leftAmount + lAmt
                  dataToPush = {
                      groupId: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.id : "",
                      groupName: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.group_name : "",
                      amount: Math.abs(lAmt).toFixed(2),
                      ...dataToPush
                  }
              } else {
                  isDynamic = false

                  let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
                  rIndex = rIndex + 1
                  if (Object.keys(dynamicInfo).length != 0) {
                      let lAmt = dynamicInfo.amt
                      leftAmount = leftAmount + lAmt
                      dataToPush = {
                          groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                          amount: Math.abs(lAmt).toFixed(2),
                          groupNameLink: false,
                          ...dataToPush
                      }
                  } else {
                      dataToPush = {
                          groupName: "",
                          amount: '',
                          groupNameLink: false,
                          ...dataToPush
                      }
                  }
              }
          } else {
              {

                  isDynamic = false

                  let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
                  rIndex = rIndex + 1
                  if (Object.keys(dynamicInfo).length != 0) {
                      let lAmt = dynamicInfo.amt
                      leftAmount = leftAmount + lAmt
                      dataToPush = {
                          groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                          amount: Math.abs(lAmt).toFixed(2),
                          groupNameLink: false,
                          ...dataToPush
                      }
                  } else {
                      dataToPush = {
                          groupName: "",
                          amount: '',
                          groupNameLink: false,
                          ...dataToPush
                      }
                  }

              }
          }

          rowList.push({...dataToPush, flag:0,})
      });
      RightAlignProfitStatic.map((right, index) => {
          let lAmt = right.amt
          rightAmount = rightAmount + lAmt
          let dataToPush = {
              groupNameR: right.name != null ? right.name : "",
              amountL: Math.abs(lAmt).toFixed(2),
              groupNameRLink: false
          }
          let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
          rIndex = rIndex + 1
          if (Object.keys(dynamicInfo).length != 0) {
              let lAmt = dynamicInfo.amt
              leftAmount = leftAmount + lAmt
              dataToPush = {
                  groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                  amount: Math.abs(lAmt).toFixed(2),
                  groupNameLink: false,
                  ...dataToPush
              }
          } else {
              dataToPush = {
                  groupName: "",
                  amount: '',
                  groupNameLink: false,
                  ...dataToPush
              }
          }
          rowList.push({...dataToPush, flag:0,})
          
      });
  } else {
      LeftAlign.map((right, index) => {
          let lAmt = right.creditAmt + right.debitAmt
          leftAmount = leftAmount + lAmt
          let dataToPush = {
              groupName: right.groupDetail != null ? right.groupDetail.group_name : "",
              groupId: right.groupDetail != null ? right.groupDetail.id : "",
              amount: Math.abs(lAmt).toFixed(2),
              groupNameLink:true,
          }
          if (isDynamic) {
              let dynamicInfo = RightAlign[index] ? RightAlign[index] : {}
              if (Object.keys(dynamicInfo).length != 0) {
                  let lAmt = dynamicInfo.creditAmt + dynamicInfo.debitAmt
                  rightAmount = rightAmount + lAmt
                  dataToPush = {
                      ...dataToPush,
                      groupIdR: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.id : "",
                      groupNameR: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.group_name : "",
                      amountL: Math.abs(lAmt).toFixed(2),
                      groupNameRLink:true,

                  }
              } else {
                  isDynamic = false

                  let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
                  rIndex = rIndex + 1
                  if (Object.keys(dynamicInfo).length != 0) {
                      let lAmt = dynamicInfo.amt
                      rightAmount = rightAmount + lAmt
                      dataToPush = {
                          ...dataToPush,
                          groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                          amountL: Math.abs(lAmt).toFixed(2),
                          groupNameRLink: false

                      }
                  } else {
                      dataToPush = {
                          ...dataToPush,
                          groupNameR: "",
                          amountL: '',
                          groupNameRLink: false

                      }
                  }
              }
          } else {
              {

                  isDynamic = false

                  let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
                  rIndex = rIndex + 1
                  if (Object.keys(dynamicInfo).length != 0) {
                      let lAmt = dynamicInfo.amt
                      rightAmount = rightAmount + lAmt
                      dataToPush = {
                          ...dataToPush,
                          groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                          amountL: Math.abs(dynamicInfo.amt).toFixed(2),
                          groupNameRLink: false
                      }
                  } else {
                      dataToPush = {
                          ...dataToPush,
                          groupNameR: "",
                          amountL: '',
                          groupNameRLink: false
                      }
                  }

              }
          }

          rowList.push({...dataToPush, flag:0,})
      });
      LeftAlignProfitStatic.map((right, index) => {
          let lAmt = right.amt
              leftAmount = leftAmount + lAmt
          let dataToPush = {
              groupName: right.name != null ? right.name : "",
              amount: Math.abs(lAmt).toFixed(2),
              groupNameLink: false
          }
          let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
          rIndex = rIndex + 1
          if (Object.keys(dynamicInfo).length != 0) {
              let lAmt = dynamicInfo.amt
              rightAmount = rightAmount + lAmt
              dataToPush = {
                  ...dataToPush,
                  groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                  amountL: Math.abs(lAmt).toFixed(2),
                  groupNameRLink: false
                  

              }
          } else {
              dataToPush = {
                  ...dataToPush,
                  groupNameR: "",
                  amountL: '',
                  groupNameRLink: false,
            // groupNameLink: false
              }
          }
          rowList.push({...dataToPush, flag:0,})
      });
  }
}
       
       let dataToPush = {
            groupName: "Total",
            amount: Math.abs(leftAmount).toFixed(2),
            groupNameR: "",
            amountL:Math.abs(rightAmount).toFixed(2),
            groupNameRLink: false,
            groupNameLink: false
        }
        rowList.push({...dataToPush, flag:1,})
        setCashbookList(rowList)
        showLoador({ loador: false });
        let DynamicData = Object.keys(rowData).length != 0 && rowData.dynamicData.length != 0 ? rowData.dynamicData : [];
        let staticData = Object.keys(rowData).length != 0 && rowData.staticData.length != 0 ? rowData.staticData : [];
        setMFGList({ DynamicData: DynamicData, staticData: staticData })

    }else {
        setCashbookList([])
        showLoador({ loador: false });
        showNotification({
            msg: serverMsg,
            severity: "error",
        });
    }

    });
  };

  const onSave = (dataToSearch) => {
    let filterLocationDta = dynamicMasterData["locationDetails"].filter(
      (row) => row.id === dataToSearch.locationId
    );

    let yearList = dynamicMasterData["yearMasterDetail"].filter(
      (row) => row.id === dataToSearch.yearId
    );

    console.log("filterLocationDta");
    console.log(filterLocationDta);

    setFormData({
      ...dataToSearch,
      name:
        filterLocationDta.length != 0 ? filterLocationDta[0].name : "",
      yearName: yearList[0].yearName,
      fromDate: yearList[0].fromDate,
      toDate: yearList[0].toDate,
      companyId:
        filterLocationDta.length != 0 ? filterLocationDta[0].companyId : "",
      locationId: filterLocationDta.length != 0 ? filterLocationDta[0].id : "",
    });
    getList(filterLocationDta, dataToSearch.yearId, "B");
    //   getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId,dataToSearch.accountLedgerGroupsId )
  };
  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };
  const generatePDFMethod = () => {
   
    var columns = [["Group Name", "Amount", "Group Name", "Amount"]];
   
    let tableList = [];
 cashList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      tableList.push(tableData);
    });

    const columnStyles = {
      0: { cellWidth: 150 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    const columnStylesPlain = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    console.log(formData.accName);
    generatePDF({
      columnStylesPlain: columnStylesPlain,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "Company Name",
          value: formData.name,
        },
        {
          label: "Year Name",
          value: formData.yearName,
        },
      ],
      pdfTitle: profitAndLossPlantWiseJson.screenTitle,
    });
  };
  // const generatePDFMethod = () => {
  //   var columns = [["Group Name", "Amount", "Group Name", "Amount"]];

  //   let tableList = [];

  //   cashList.map((rowData, index) => {
  //     let tableData = [];

  //     tableData.push(rowData.groupName);

  //     tableData.push(rowData.amount);
  //     tableData.push(rowData.groupNameR);

  //     tableData.push(rowData.amountL);
  //     tableList.push(tableData);
  //   });

  //   const columnStyles = {
  //     0: { cellWidth: 150 },
  //     1: { cellWidth: 150 },
  //     2: { cellWidth: 90 },
  //   };
  //   const columnStylesPlain = {
  //     0: { cellWidth: 80 },
  //     1: { cellWidth: 150 },
  //     2: { cellWidth: 90 },
  //   };
  //   console.log(formData.accName);
  //   generatePDF({
  //     columnStylesPlain: columnStylesPlain,
  //     rows: tableList,
  //     columns,
  //     headerList: [
  //       {
  //         label: "Company Name",
  //         value: formData.name,
  //       },
  //       {
  //         label: "Year Name",
  //         value: formData.yearName,
  //       },
  //     ],
  //     pdfTitle: profitAndLossPlantWiseJson.screenTitle,
  //   });
  // };

  const generateExcelMethod = () => {
    var columns = ["Group Name", "Amount", "Group Name", "Amount"];
    let alignList = [];

    let rows = [];
    cashList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      rows.push(tableData);
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Company Name",
        formData.name,
        "Year Name",
        formData.yearName,
      ],
      title: profitAndLossPlantWiseJson.screenTitle,
    });
  };
  const getFilteredTableData = (tableData) => {

    let listData = tableData.filter((row) => {

        let isValid = false;

        if (row.groupName.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
            isValid = true;
        }

        if (row.groupNameR.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
            isValid = true;
        }
        if (row.amount.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
            isValid = true;
        }

        if (row.amountL.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
            isValid = true;
        }
        return isValid;

    });
    return listData
};
  // const rowLink = (rowData) => {
  //   console.log("rowData");
  //   console.log(rowData);

  //   setPopupdata(rowData);
  //   setOpenTable(!openTable);
  // };

//   const rowLink = (rowData, key) => {
//     if (rowData.groupDetail && key == 'groupName') {
//         setPopupdata({ groupId: rowData.groupDetail.id, groupName: rowData.groupDetail.group_name })
//         setOpenTable(!openTable);
//     }
//     else {
//         toggleMFGAndProfitAndLoss()
//     }
// }
const rowLink = (rowData, key) => {
  if (key == "groupNameR") {
      if (rowData.groupIdR) {
          setPopupdata({ groupId: rowData.groupIdR, groupName: rowData.groupNameR })
          setOpenTable(!openTable);
      }
      else {
          toggle()
      }


  } else {
      if (rowData.groupId) {
          setPopupdata({ groupId: rowData.groupId, groupName: rowData.groupName })
          setOpenTable(!openTable);
      }
      else {
          toggle()
      }

  }

}
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={profitAndLossPlantWiseJson.showSaveBtn}
          screenTitle={profitAndLossPlantWiseJson.screenTitle}
          fieldMeta={profitAndLossPlantWiseJson.fieldMeta}
          showCancel={profitAndLossPlantWiseJson.showCancel}
          apiBaseURL={profitAndLossPlantWiseJson.apiBaseURL}
          showSaveNextBtn={profitAndLossPlantWiseJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          fieldData={fieldData}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br />

        {/* <LeftRightAdjustTable
                    showPegination={false}
                    columns={[
                        { name: "groupName", title: "Liabilities", linkFlag: true, linkName: "groupNameLink" },
                        { name: "amount", title: "Amount", fontWeight: "bold", align: "right" },
                        { name: "groupNameR", title: "Assets", linkFlag: true, linkName: "groupNameRLink" },
                        { name: "amountL", title: "Amount", fontWeight: "bold", align: "right" }
                    ]}
                    tableData={MFGList}
                    hiddenColumnNames={[]}
                    // tableData={getFilteredTableData(cashList)}
                    rowLink={rowLink}
                    isActionColActive={false}
                // rowCopy={this.rowCopy}
                /> */}

<RTTable
                    showPegination={false}
                    columns={[

                        { name: "groupName", title: "Group Name", linkFlag: true, linkName: "groupNameLink" },
                        { name: "amount", title: "Amount", align: "right", fontWeight: "bold" },
                        { name: "groupNameR", title: "Group Name", linkFlag: true, linkName: "groupNameRLink" },
                        { name: "amountL", title: "Amount", align: "right", fontWeight: "bold" },
                    ]}
                    hiddenColumnNames={[]}
                    tableData={getFilteredTableData(cashList)}
                    rowLink={rowLink}
                    isActionColActive={false}
                // rowCopy={this.rowCopy}
                />
      </Paper>
      {
                cashList.length != 0 && <Grid sx={{ paddingTop: 2 }} container spacing={1} justifyContent="center">
                    <Grid container xs={12} sm={10} md={10} lg={10}>
                    <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>
                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Profit And Loss'}
                                    />
                                </div>

                                <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={200}
                      series={[
                        {
                            name:"Amount",
                            data: cashList.filter((row)=>row.flag==0 && row.amount!="" ).map((rowData) => {
                                return rowData.amount
                            }),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                              // onClickLineChart(config.dataPointIndex);
                            },
                          },
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "40%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                            categories: cashList.filter((row)=>row.flag==0 && row.groupName!="" && row.groupName!="Total").map((rowData,index) => {
                               
                               
                                return rowData.groupName
                               
                            }),
                        },
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />
                                
                            </Paper>
                        </Grid>
                        <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>

                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Profit And Loss'}
                                    />
                                </div>

                               
                    {/* Sales & Manpower Table 2 starts */}
                    <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={200}
                      series={[
                        {
                            name:"Amount",
                            data: cashList.filter((row)=>row.flag==0 && row.amountL!="" ).map((rowData) => {
                                return rowData.amountL
                            }),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                              // onClickLineChart(config.dataPointIndex);
                            },
                          },
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "40%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                            categories: cashList.filter((row)=>row.flag==0 && row.groupNameR!="" && row.groupNameR!="Total").map((rowData,index) => {
                                index=index+1;
                              
                                return rowData.groupNameR
                               
                                   
                                
                                
                            }),
                        },
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />

                   
                            </Paper>
                        </Grid>
                        
                    </Grid>
                </Grid>
            }
      {openTable && (
        <GroupSummaryByPlantSubreportByGroup
          open={openTable}
          handleCloses={toggle}
          popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
          dynamicMasterData={formData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  locationList: state.accountLedger,
});

const mapDispatchToProps = { showLoador, showNotification, getLocation };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitAndLossPlantWise);
