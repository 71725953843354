import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  salesPurchaseList: [],
};
let URL = endpoints.salesPurchase;
const salesPurchaseSlice = createSlice({
  name: "salesPurchase",
  initialState,
  reducers: {
    salesPurchaseSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesPurchaseList: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  salesPurchaseSuccess,
  cashRecieptPendingListSuccess,
  resetState,
} = salesPurchaseSlice.actions;

export default salesPurchaseSlice.reducer;

export const getSalesPurchase =
  ({ entryType, accountLedgerCode }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        //   url: URL + '?sort={"sortOrder": "ASC"}',
        url:
          URL +
          "/get-sales-purchase-by-account-code?accountLedgerCode=" +
          accountLedgerCode +
          "&entryType=" +
          entryType,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const content = data.data;
          const row = content.map((rowData, index) => {
            let areaForBeatData = {
              index: index + 1,
              ...rowData,
              id: rowData.id != null ? rowData.id : "",
              name: rowData.documentNo +" - "+ rowData.remainingAmt , 
              checked: false,
              disabled: false,
            };
            return areaForBeatData;
          });
          dispatch(salesPurchaseSuccess({ row }));
          return { response: data.data, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setSalesPurchase =
  ({ row }) =>
  async (dispatch) => {
    dispatch(salesPurchaseSuccess({ row }));
  };
