import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import {
  getLocation,
  getAccountLedgerGroupMaster,
  saveAccountLedger,
  setSaveAccountLedger,
} from "../../../Slice/accountLedger.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  saveWarningMsg,
  saveFailedMsg,
  savemsg,
} from "../../../config/messageconstant";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";
import { apiGet } from "../../../utils/api_service";

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      codeError:"",
      dynamicMasterData: {
        locationDetail: this.props.accountLedger?.location,
        accountLedgerGroupMasterDetail:
          this.props.accountLedger?.accountLedgerGroupMaster,
        accTypeMasterDetail: [
          { id: "0", name: "Other" },
          { id: "1", name: "Cash" },
          { id: "2", name: "Bank" },
          { id: "3", name: "Shadow Account" },
        ],
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.accountLedger !== nextProps.accountLedger) {
      if (
        this.props.accountLedger?.location !== nextProps.accountLedger?.location
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            locationDetail: nextProps.accountLedger?.location,
          },
        });
      }
    }
    if (this.props.accountLedger !== nextProps.accountLedger) {
      if (
        this.props.accountLedger?.accountLedgerGroupMaster !==
        nextProps.accountLedger?.accountLedgerGroupMaster
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            accountLedgerGroupMasterDetail:
              nextProps.accountLedger?.accountLedgerGroupMaster,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const {
      getLocation,
      getAccountLedgerGroupMaster,
      setSaveAccountLedger,
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      let var1 = localStorage.getItem("location");
      showLoador({ loador: true });
      console.log(var1);
      await getLocation().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getAccountLedgerGroupMaster().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmitData = async (dataToSave) => {
    const { codeError } = this.state;
    const { accountLedger,showNotification } = this.props;
    if(codeError==""){
      dataToSave = {
        ...dataToSave,
      };
      if (this.props.params.id) {
        dataToSave = {
          ...accountLedger.accountLedgerData,
          ...dataToSave,
        };
      }
      return this.onSave(dataToSave);
    }
    else{
      showNotification({
        msg:codeError,
        severity: "error",
      });
    }
    return false
  };
  onSubmitDataOld = async (dataToSave) => {
    const { showLoador, showNotification, accountLedger } = this.props;
    dataToSave = {
      ...dataToSave,
    };
    if (this.props.params.id) {
      dataToSave = {
        ...accountLedger.accountLedgerData,
        ...dataToSave,
      };
    }
    showLoador({ loador: true });
    await apiGet({
      url:
        endpoint.accountLedger +
        "/check-valid-account-ledger-code?accountLedgerCode=" +
        dataToSave.accountLedgerCode,
    }).then(({ data, success }) => {
      console.log(data.data.error);
      if (success) {
        if (data.data.error) {
          showNotification({
            msg: data.data.message,
            severity: "error",
          });
          return false;
        } else {
          swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(dataToSave);
              this.onSave(dataToSave);
            }
          });
        }
      } else {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
        showLoador({ loador: false });
      }
      return success;
    });
    showLoador({ loador: false });
  };

  onSave = async (dataToSave) => {
    const { showLoador, showNotification, saveAccountLedger } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = await saveAccountLedger({ dataToSave }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({
              msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
            });
            this.props.changeTab("1");
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
          return success;
        }
      );
      return response;
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getDataForDisabled = (printName) => {
    return { printName: printName };
  };
  onBlur = async (value) => {
    const { code } = this.state;
    if (code != value) {
      const { showLoador, showNotification } = this.props;
      showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.accountLedger +
          "/check-valid-account-ledger-code?accountLedgerCode=" +
          value,
      }).then(({ data, success }) => {
       
        if (success) {
          if (data.data.error) {
            showNotification({
              msg: data.data.message,
              severity: "error",
            });
           
          } 
this.setState({
  codeError:data.data.error?data.data.message:""
})
        } else {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
         
        }
        return success;
      });
      showLoador({ loador: false });
    }
    this.setState({
      code: value,
    });
  };
  render() {
    const { dynamicMasterData } = this.state;
    const { accountLedger } = this.props;
    return (
      <>
        <DynamicFormWithoutSave
          showSwal={true}
          waitTillSave={true}
          getByIdDataList={Object.keys(accountLedger.accountLedgerData).length!=0?{
            ...accountLedger.accountLedgerData,
            accType: accountLedger.accountLedgerData.accType
              ? accountLedger.accountLedgerData.accType.toString()
              : "",
          }:{}}
          showBackToList={false}
          getDataForDisabled={this.getDataForDisabled}
          formPath={AccountLedgerJson.formPath}
          fieldMeta={AccountLedgerJson.fieldMeta}
          tableColumnsToFilter={AccountLedgerJson.tableColumnsToFilter}
          apiBaseURL={AccountLedgerJson.apiBaseURL}
          baseIdColumn={AccountLedgerJson.baseIdColumn}
          dynamicMasterData={dynamicMasterData}
          showSaveBtnMain={false}
          showSaveBtn={true}
          saveBtnText={"SAVE & PROCEED"}
          onSave={this.onSubmitData}
          callApi={true}
          getByIdApi={true}
          onBlur={this.onBlur}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accountLedger: state.accountLedger,
});
const mapDispatchToProps = {
  getLocation,
  getAccountLedgerGroupMaster,
  showLoador,
  showNotification,
  saveAccountLedger,
  setSaveAccountLedger,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BasicInformation);
