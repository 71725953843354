import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { styles } from "../../../components/reportStyle";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import TreeView from './TreeView';
const BalanceSheet = ({ onClick,classes,cashList,headerList }) => {

 
  let tableHead=[
    {
      title: "Liabilities",

      align: "left"
    },
    {
      title: "Assets",
      align: "left"
    },
    
  ]


  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
      
       <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr className={classes.MuiTableHeadRoot}>
            {tableHead.map((head) => {
              return (
                <td
                  style={{ textAlign: "center" }}
                  className={classes.MuiTableHeadCell}
                >
                  {head.title}
                </td>
              );
            })}{" "}
          </tr>
          
          <tr>
                  <td

                    className={classes.MuiTableBodyCell}
                    style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                  >
                  <TreeView onClick={onClick}allignment={"L"}headerList={headerList}staticList={cashList.LeftAlignStatic}cashList={cashList.LeftAlign}/>
                 
                  </td>
                  <td

                    className={classes.MuiTableBodyCell}
                    style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                  >
                  
                  <TreeView onClick={onClick}staticList={cashList.RightAlignStatic}allignment={"R"}headerList={headerList} cashList={cashList.RightAlign}/>

                  </td>

                </tr>

        
        </table>
      </div>
       
      </Paper>
      
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };


export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  )(BalanceSheet);
