import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { OutstandingJson } from "../../DynamicFormsJson/Report/outstanding";
import RTTable from "../../components/Comman/RT/RTTable";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import OutstandingSubReportByType from "../AccountReport/OutStandingSubreportByType";
import { showLoador, showNotification } from "../Landing/Landing.slice";
const Outstanding = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [formData, setFormData] = React.useState({ entryType: '1' });
  const [cashList, setCashbookList] = React.useState([]);
  const [popUpData, setPopupdata] = useState({})
  const [openTable, setOpenTable] = useState(false);
  useEffect(() => {
    getList(1);
  }, []);



  const getList = (entryType) => {
    let locationId = localStorage.getItem("location")
    showLoador({ loador: true });
    apiGet({
      url: endpoints.salePurchaseApproval + "/bill-outstanding?entryType=" + entryType + "&locationId=" + locationId,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        const rowlist = data.data.length != 0 ? data.data.map((row, index) => {
          index = index + 1;
          return {
            index: index,
            accLedgerCode: row.accLedgerCode,
            accLedgerName: row.accLedgerName,
            remainingAmt: Math.abs((+row.remainingAmt)).toFixed(2),
            accId: row.accId,
          }
        }) : [];
        setCashbookList(rowlist)
      } else {
        setCashbookList([])
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

    });
  }

  const onSave = (dataToSearch) => {

    setFormData({
      ...dataToSearch,

    })
    getList(dataToSearch.entryType);
  }
  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };

  const generatePDFMethod = () => {

    var columns = [["Sr No", "Account Ledger Code", "Account Ledger Name", "Amount"]];
    let tableList = []
    cashList.map(((rowData, index) => {

      let tableData = []





      tableData = []
      tableData.push(rowData.index)
      tableData.push(rowData.accLedgerCode)

      tableData.push(rowData.accLedgerName)
      tableData.push(rowData.remainingAmt)

      tableList.push(tableData)
    }))



    generatePDF({

      rows: tableList,
      columns,

      headerList: [
        {
          label: "Entry Type ",
          value: formData.entryType == 0 ? "Bill Outstanding" : "Purchase Outstanding",
        },

      ],
      pdfTitle: OutstandingJson.screenTitle,
    });

  };



//   const generateExcelMethod = () => {
//     var columns = ["Sr No", "Account Ledger Code", "Account Ledger Name", "Amount"];
//     let alignList = [];
// console.log("cashList")
// console.log(cashList)
//     let rows = []
//     cashList.map((reportData, index) => {
//       let reportObj = [];








//       reportObj.push(reportData.index)
//       reportObj.push(reportData.accLedgerCode)
//       reportObj.push(reportData.accLedgerName)
//       reportObj.push(reportData.remainingAmt)
//       rows.push(reportObj)

//     })




//     generateExcel({
//       columns,
//       rows,
//       alignList,
//       headerList: ["Entry Type ", formData.entryType == 0 ? "Bill Outstanding" : "Purchase Outstanding",

//       ],
//       title: OutstandingJson.screenTitle,
//     });

//   };

  const generateExcelMethod = () => {
    var columns = ["Sr No", "Account Ledger Code", "Account Ledger Name", "Amount"];

    let alignList = [];

    let rows = [];

    cashList.map((reportData, index) => {
      let reportObj = [];
      reportObj.push(reportData.index)
      reportObj.push(reportData.accLedgerCode)
      reportObj.push(reportData.accLedgerName)
      reportObj.push(reportData.remainingAmt)

      rows.push(reportObj);

     

     
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Entry Type ", formData.entryType == 0 ? "Bill Outstanding" : "Purchase Outstanding"],
      title: OutstandingJson.screenTitle,
    });
  };

  const getFilteredTableData = (tableData) => {

    let listData = tableData.filter((row) => {

      let isValid = false;

      if (row.accLedgerCode.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (row.accLedgerName.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (row.remainingAmt.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      return isValid;

    });
    return listData
  };
  const getDataForDisabled = (data) => {
    return {}
  }

  const rowLink = (rowData) => {
  

    setPopupdata(rowData)
    setOpenTable(!openTable);
  }
  const toggle = () => {
    setOpenTable(!openTable);

  };
  return (
    <>

      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={OutstandingJson.showSaveBtn}
          screenTitle={OutstandingJson.screenTitle}
          fieldMeta={OutstandingJson.fieldMeta}
          showCancel={OutstandingJson.showCancel}
          apiBaseURL={OutstandingJson.apiBaseURL}
          showSaveNextBtn={OutstandingJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}

          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
          getDataForDisabled={getDataForDisabled}
        />

        <RTTable
          showPegination={false}
          columns={[
            { name: "index", title: "Sr. No.", align: "center" },

            { name: "accLedgerCode", title: "Account Ledger Code", align: "center" },
            { name: "accLedgerName", title: "Account Ledger Name", align: "left", linkFlag: true },
            { name: "remainingAmt", title: "Amount", align: "right" },
          ]}
          hiddenColumnNames={[]}
          tableData={getFilteredTableData(cashList)}
          rowLink={rowLink}
          isActionColActive={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
        />
      </Paper>
      {openTable && (
        <OutstandingSubReportByType



          open={openTable}
          handleCloses={toggle}
          popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
          formData={formData}

        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Outstanding);
