import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LedgerJson } from "../../DynamicFormsJson/Report/Ledger";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import { apiGet, apiPost } from "../../utils/api_service";
import LedgerTable from "../AccountReport/LedgerTable";
import { serverMsg, urlMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const Leadger = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [cashbook, setCashbookList] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [fieldData, setFieldData] = React.useState({});
  useEffect(() => {

    getDropDownList();
  }, [])
  const getDropDownList = async () => {
    showLoador({ loador: true });
    const accountLedgerGroupMasterDetail = await apiGet({
      url: endpoint.accountLedger,
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data;
        showLoador({ loador: false });
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.accountLedgerName + " - " + rowData.accountLedgerCode };
        })

      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return []
    });
    showLoador({ loador: true });
    let CompanyDetails = await apiGet({
      url: endpoint.company,
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data;
        if (rowData.length == 1) {
          setFieldData({ companyId: rowData[0].id })
        }
        showLoador({ loador: false });
        return rowData
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return []
    });

    setDynamicMasterData({
      accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetail,
      CompanyDetails: CompanyDetails
    });
  }

  const getList = (fromDate, toDate, accId, filteraccountLedgerGroupMaster) => {
    let valuesArray = [];
    const locationIdList = localStorage.getItem("locationIdList");

    if (locationIdList != null) {
      const location = JSON.parse(locationIdList);
      valuesArray = location.filter(
        (row) => row.id === localStorage.getItem("location")
      );
    }
    setFormData({


    })
    setFormData({
      fromDate: fromDate,
      toDate: toDate,
      accName: filteraccountLedgerGroupMaster,
      name: valuesArray.length != 0 && valuesArray[0].company != null ? valuesArray[0].company.name : "-",
    })
    let companyId = valuesArray.length != 0 && valuesArray[0].company != null && valuesArray[0].company.id ? valuesArray[0].company.id : ""
    let locationId = valuesArray.length != 0 && valuesArray[0] != null && valuesArray[0].id ? valuesArray[0].id : ""
    showLoador({ loador: true });

    apiPost({
      url: endpoint.accountLedgerReport + "?companyIds=" + companyId + "&fromDate=" + fromDate + "&toDate=" + toDate + "&accIds=" + accId + "&locationId=" + locationId
    }).then(({ data, success }) => {
      if (success) {
        // let rowData = data.data.map((rowData) => {
        //     return {
        //         ...rowData, transactionDetail: rowData.transactionDetail.map((trans) => {
        //             return { ...trans, transactionDetailes: trans.transactionDetailes != null ? JSON.parse(trans.transactionDetailes) : [] }
        //         })
        //     }
        // })
        showLoador({ loador: false });
        setCashbookList(data.data)
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

    });
  }
  const onSearch = (searchV) => {

    setSearchValue(searchV)
  };
  const generatePDFMethod = () => {
    var columns = [["Date", "Vou No.", "Naration", "Bill No.", "Debit", "Credit", "Balance"]];


    let tableList = []
    cashbook.map(((rowData, index) => {

      let tableData = []

      let documentDate = ""
      let subVoucherCode = ""
      let refAccName = ""
      let documentNo = ""
      let debit = ""
      let credit = ""
      let balanceData = ""
      let openingAmt = (rowData.openingDdebitAmt + rowData.openingCreditAmt)
      let balance = 0
      rowData.accLedgerList.map((data, indexData) => {


        if (indexData == 0) {
          balance = openingAmt + data.amount

        }
        else {
          balance = balance + data.amount
        }
        documentDate = documentDate == "" ? documentDate + data.documentDate : documentDate + "\n" + data.documentDate
        subVoucherCode = subVoucherCode == "" ? subVoucherCode + data.documentNo : subVoucherCode + "\n" + data.documentNo
        refAccName = refAccName == "" ? refAccName + data.remark == null ? data.refAccName : data.refAccName + "(" + data.remark + ")" :
          refAccName + "\n" + data.remark == null ? data.refAccName : data.refAccName + "(" + data.remark + ")"

        documentNo = documentNo == "" ? documentNo + "-" : documentNo + "\n" + "-"
        let debita = data.type == "D" ? Math.abs(data.amount) : ""
        let credita = data.type == "D" ? "" : Math.abs(data.amount)
        if (credita != "") {
          credit = credit.trim() == "" ? credit + credita : credit + "\n" + credita
          debit = debit == "" ? "\n" : debit + "\n"
        } else {
          debit = debit.trim() == "" ? debit + debita : debit + "\n" + debita
          credit = credit == "" ? "\n" : credit + "\n"
        }

        let balanceDataInfo = balance >= 0 ? Math.abs(balance).toFixed(2) + " Cr" : Math.abs(balance).toFixed(2) + " Dr"
        balanceData = balanceData == "" ? balanceData + balanceDataInfo : balanceData + "\n" + balanceDataInfo
      })
      let accDetailName = rowData.accDetail != null ? rowData.accDetail.account_ledger_name : ""
      let account_ledger_code = rowData.accDetail != null ? rowData.accDetail.account_ledger_code : ""

      console.log(debit)
      console.log(credit)
      tableData = []
      tableData.push(accDetailName + " - " + account_ledger_code + "\n\n" + documentDate)
      tableData.push("\n\n" + subVoucherCode)
      tableData.push("\n" + "Opening Balance" + "\n" + refAccName)
      tableData.push("\n\n" + documentNo)

      tableData.push("\n" + Math.abs(rowData.openingDdebitAmt) + "\n" + debit)
      tableData.push("\n" + Math.abs(rowData.openingCreditAmt) + "\n" + credit)
      tableData.push("\n\n" + balanceData)
      tableList.push(tableData)

    }))
    if (columns.length > 6) {
      showNotification({
        msg: "Plase Select only 6 columns",
        severity: "error",
      });
    } else {
      generatePDF({
        rows: tableList,
        columns,
        columnStyles: {},
        headerList: [
          {
            label: "Date Range ",
            value: formData.fromDate + " To " + formData.toDate
          },
          {
            label: "Company Name",
            value: formData.name
          },
          {
            label: "Group Name",
            value: formData.accName
          },
        ],
        pdfTitle: LedgerJson.screenTitle,
      });
    }
  };
  const getFilteredTableData = (tableData) => {

    let listData = tableData.filter((row) => {
      let accDetailName = row.accDetail != null ? row.accDetail.account_ledger_name : ""

      let isValid = false;

      if (accDetailName.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      return isValid;

    });
    return listData
  };
  const generateExcelMethod = () => {
    var columns = ["Date", "Vou No.", "Naration", "Bill No.", "Debit", "Credit", "Balance"];

    let alignList = [];

    let rows = []
    cashbook.map((reportData, index) => {
      let openingAmt = reportData.openingDdebitAmt + reportData.openingCreditAmt
      let balance = 0
      let accDetailName = reportData.accDetail != null ? reportData.accDetail.account_ledger_name : ""
      let account_ledger_code = reportData.accDetail != null ? reportData.accDetail.account_ledger_code : ""

      let reportObj = [];
      reportObj.push(accDetailName + " - " + account_ledger_code)
      reportObj.push("")
      reportObj.push("")
      reportObj.push("")
      reportObj.push("")
      reportObj.push("")
      reportObj.push("")
      rows.push(reportObj)
      reportObj = [];
      reportObj.push("")
      reportObj.push("")
      reportObj.push("Opening Balance")
      reportObj.push("")
      reportObj.push(reportData.openingDdebitAmt)
      reportObj.push(reportData.openingCreditAmt)
      reportObj.push((reportData.openingCreditAmt + reportData.openingDdebitAmt) >= 0 ? Math.abs(reportData.openingCreditAmt + reportData.openingDdebitAmt).toFixed(2) + " Cr" : Math.abs(reportData.openingCreditAmt + reportData.openingDdebitAmt).toFixed(2) + " Dr")
      rows.push(reportObj)

      reportData.accLedgerList.map((data, indexData) => {
        let debita = data.type == "D" ? Math.abs(data.amount) : ""
        let credita = data.type == "D" ? "" : Math.abs(data.amount)

        if (indexData == 0) {
          balance = openingAmt + data.amount

        }
        else {
          balance = balance + data.amount
        }

        reportObj = [];
        reportObj.push(data.documentDate)
        reportObj.push(data.documentNo == null ? "" : data.documentNo)
        reportObj.push(data.refAccName != null ? data.refAccName : "" + "/n" + data.remark != null ? data.remark : "")
        reportObj.push("-")
        reportObj.push(debita)
        reportObj.push(credita)
        reportObj.push(balance >= 0 ? Math.abs(balance).toFixed(2) + " Cr" : Math.abs(balance).toFixed(2) + " Dr")
        rows.push(reportObj)
      })
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate, "Company Name", formData.name, "Account Name", formData.accName

      ],
      title: LedgerJson.screenTitle,
    });

  };
  const onSave = (dataToSearch) => {
    let account = dataToSearch.account.split(",")
    let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
    let filteraccountLedgerGroupMaster = []
    dynamicMasterData["accountLedgerGroupMasterDetail"].map((row) => {

      account.map((accData) => {
        if (row.id === accData) {
          filteraccountLedgerGroupMaster.push(row.name + "")
        }
      })
    })

    getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.account, filteraccountLedgerGroupMaster)
  }
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          searchName="Account Ledger Name"
          DataToSet={[]}
          fieldData={fieldData}
          checkData={true}
          showSaveBtn={LedgerJson.showSaveBtn}
          screenTitle={LedgerJson.screenTitle}
          fieldMeta={LedgerJson.fieldMeta}
          showCancel={LedgerJson.showCancel}
          apiBaseURL={LedgerJson.apiBaseURL}
          showSaveNextBtn={LedgerJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          onSearch={onSearch}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br />
        <LedgerTable tableHead={[
          {
            title: "Date",

            align: "left"
          },
          {
            title: "Vou No.",
            align: "right"
          },
          {
            title: "Naration",
            align: "right"
          },
          {
            title: "Bill no.",
            align: "right"
          },
          {
            title: "Debit",
            align: "right"
          },
          {
            title: "Credit",
            align: "right"
          },
          {
            title: "Balance",
            align: "right"
          }
        ]} rowList={getFilteredTableData(cashbook)} />

      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Leadger);
