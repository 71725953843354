import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import GSTPOP from './GSTPOP'
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";
import { saveAccountLedger, setSaveAccountLedger, getGstType } from "../../../Slice/accountLedger.slice";
import { noInternetMsg, saveFailedMsg, saveWarningMsg } from "../../../config/messageconstant";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import * as msgConstant from "../../../config/messageconstant";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { gstNo } from "../../../components/Comman/Util/Validations";



class StatutoryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopup:false,
            dynamicMasterData: {
                // locationDetail: this.props.locationList?.location,
                gstTypeDetail: this.props.dropDownList?.gstType,

            },
            GSTData:{},
            formErrors:{},
        };
    }
    componentWillReceiveProps(nextProps) {
        // if (this.props.locationList !== nextProps.locationList) {
        //     if (this.props.locationList?.location !== nextProps.locationList?.location) {
        //         this.setState({
        //             dynamicMasterData: {
        //                 ...this.state.dynamicMasterData,
        //                 locationDetail: nextProps.locationList?.location,
        //             },
        //         });
        //     }
        // }
        if (this.props.dropDownList !== nextProps.dropDownList) {
            if (
                this.props.dropDownList?.gstType !== nextProps.dropDownList?.gstType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        gstTypeDetail: nextProps.dropDownList?.gstType,
                    },
                });
            }
        }
    }
    async componentDidMount() {
      
        const { getLocation, getGstType, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            // showLoador({ loador: true });
            // await getLocation().then(({ success }) => {
            //     showLoador({ loador: false });
            //     if (!success) {
            //         showNotification({ msg: serverMsg, severity: "error" });
            //     }
            // });
            showLoador({ loador: true });
            await getGstType().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: msgConstant.serverMsg, severity: "error" });
                }
            })
        }
        else {
            this.props.showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
        }
    }

    onSubmitData = (dataToSave) => {
        const { accountLedger } = this.props;
      

       
        dataToSave = {
            ...accountLedger.accountLedgerData,
            ...dataToSave
        };
        swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onSave(dataToSave);
            }
        });
   
    };

    onSave = (dataToSave) => {
        const { showLoador, showNotification, saveAccountLedger } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            saveAccountLedger({ dataToSave }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (success) {
                    this.props.changeTab(3)
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    getDataById = (id) => {
        const { showLoador, setSaveAccountLedger, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: endpoint.accountLedger + "/" + id,
            }).then(({ data, success }) => {
                console.log(data.data);
                showLoador({ loador: false });
                if (success) {

                    if (!data.error) {

                        let rowData = data.data
                        console.log(rowData)
                        setSaveAccountLedger({ rowObject: rowData });

                        return { data: rowData, success }
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { data: {}, success: false }
                }
                return { response: {}, success: false }
            });
            return response
        }
        else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    getGSTDetails= async(gstNo) => {
        const { showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = await apiGet({
                url: endpoint.accountLedger + "/gst-gov-validation?gstNo=" + gstNo,
            }).then(({ data, success }) => {
                console.log(data.data);
                 if (success) {
                    showLoador({ loador: false });
                   this.setState({
                    openPopup:true,
                    GSTData:JSON.parse(data.data)
                   })
                    return { response: data.data, success: success }
                }else{
                    console.log( 'data.response')
                    console.log( data.response.data.errorMessage)
                    showLoador({ loador: false });
                 
                    let msg=data.response.data.errorMessage
                    
                    let msg1=msg.split("400 Bad Request:")
                    let mainMSG=''
                    if(msg1.length==2){
                    try {
                        let maindata=JSON.parse(msg1[1])
                       if(maindata.length!=0){
                         mainMSG=maindata[0].error.message
                      }else{
                        mainMSG=msgConstant.serverMsg
                      }
                    } catch (error) {
                        mainMSG=msgConstant.serverMsg
                    }
                   
                   }else{
                    mainMSG=msgConstant.serverMsg
                   }
                   showNotification({ msg: mainMSG, severity: "error" });

                }
                return { response: {}, success: false }
            });
            return response
        }
        else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    handleCloses=()=>{
        this.setState({
            openPopup:false
        })
    }
    handleUpdate=()=>{
        const { accountLedger } = this.props;
        const { GSTData } = this.state;
        

       
        let dataToSave = {
            ...accountLedger.accountLedgerData,
            printName:GSTData.tradeNam,
            accountLedgerName:GSTData.tradeNam,
            pinCode:GSTData.pradr.addr.pncd,
            address:GSTData.pradr.addr.bno+" , "+GSTData.pradr.addr.bnm+" , "+GSTData.pradr.addr.st+" , "+GSTData.pradr.addr.dst+" , "+GSTData.pradr.addr.stcd+" - "+GSTData.pradr.addr.pncd
        };
        swal({
            title: "Are you sure?",
            text: 'Do you want to update record?',
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({
                    openPopup:false
                })
                this.onSave(dataToSave);
            }
        });
       
    }
    onClickEdit=(gstNoData)=>{
        if (
           !gstNo.test(gstNoData)
          ) {
            this.props.showNotification({ msg: 'GST is Invalid', severity: "error" });

           
          }else{
            this.getGSTDetails(gstNoData);
          }
        
    }
    render() {
        const { dynamicMasterData,GSTData,openPopup } = this.state;
        const {accountLedger} =this.props
        return (
            <>
                <DynamicFormWithoutSave
                   getByIdDataList={accountLedger.accountLedgerData}
                    formPath={AccountLedgerJson.formPath}
                    fieldMeta={AccountLedgerJson.fieldMetaForStatutory}
                    tableColumnsToFilter={AccountLedgerJson.tableColumnsToFilter}
                    apiBaseURL={AccountLedgerJson.apiBaseURL}
                    baseIdColumn={AccountLedgerJson.baseIdColumn}
                    dynamicMasterData={dynamicMasterData}
                    showSaveBtnMain={false}
                    showSaveBtn={true}
                    saveBtnText={"SAVE & PROCEED"}
                    onSave={this.onSubmitData}
                    callApi={true}
                    getByIdApi={true}
                    getDataById={this.getDataById}
                    onClickEdit={this.onClickEdit}
                />
                 {openPopup &&Object.keys(GSTData).length!=0&& (
        <GSTPOP
          openPopup={openPopup}
          handleCloses={this.handleCloses}
          GSTData={GSTData }
          handleUpdate={this.handleUpdate}
        />
      )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    accountLedger: state.accountLedger,
    dropDownList: state.accountLedger,
});
const mapDispatchToProps = {
    showLoador,
    saveAccountLedger,
    showNotification,
    setSaveAccountLedger,
    getGstType,
};
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StatutoryForm);
