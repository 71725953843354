import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import { titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
// import { apiPostForDownload, apiPostForImage } from "../../utils/api_service";
import { generateExcelImport } from "../../components/GeneratePDFExcel";
import { apiPostForImage } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import { taxJson } from "../../DynamicFormsJson/Report/tacReport";
import { downloadF } from "../PDF/PDFDownload";
import { serverMsg } from "../../config/messageconstant";

class ImportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "0",
      salesTeamId: "",
      stakeholderId: "",
      areaIds: "",
      beatIds: "",

      optionList: [
        {
          id: "0",
          name: "Tax Summery Report",
        },
        {
          id: "1",
          name: "gstr1 sales registered",
        },
        {
          id: "2",
          name: "gstr2-purchase-registered",
        },
      ],
      dynamicMasterData:{},
      fileName: "",
    };
  }

  onAutocompleteChange = () => (name, newValue) => {
    this.props;
    if (newValue != null) {
      this.setState({
        [name]: newValue,
        fileName: "",
      });
    }
  };

  componentDidMount() {
    
    let valuesArray = [];
    let locationDetails = [];
    const locationIdList =  localStorage.getItem("locationIdList");
    if (locationIdList) {
      valuesArray = JSON.parse(locationIdList);
    }
    valuesArray.length != 0 &&
      valuesArray.map((location) => {
        locationDetails.push({ ...location,
          companyId:
            location.company != null && location.company.id
              ? location.company.id
              : "",
          name:
            location.company != null && location.company.name
              ? location.company.name
              : "",})
         
        })
 
   
    this.setState({
      ...this.state,
      dynamicMasterData:{locationDetails}
    })
  }

  backToList = () => {
    this.props.navigate(-1);
  };

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  onClickViewPreview = (e, row) => {
    e.stopPropagation();
    const url = URL.createObjectURL(row);
    window.open(url);
  };

  onClearHandler = (event, value) => {
    this.setState({
      ...this.state,
      fileName: "",
    });
  };

  onSubmit = (dataToSet) => {
    console.log(dataToSet)
    const { fileName, option } = this.state;
    const { showNotification, showLoador } = this.props;

    let url = "";
    if (option == "0") {
      url =
        endpoint.excel +
        "?fromDate="+dataToSet.fromDate+"&toDate="+dataToSet.toDate+"&locationId="+dataToSet.locationId;
    } else if (option == "1") {
      url =
        endpoint.excel +
        "/get-excel-gstr1-sales-registered?fromDate="+dataToSet.fromDate+"&toDate="+dataToSet.toDate+"&locationId="+dataToSet.locationId;
    } else {
      url =
        endpoint.excel +
        "/get-excel-gstr2-purchase-registered?fromDate="+dataToSet.fromDate+"&toDate="+dataToSet.toDate+"&locationId="+dataToSet.locationId;
    }
    showLoador({ loador: true });
    downloadF({
      url: url,
      ext: "xls",
      openNewTab: true,
    }).then((response) => {
      if (!response) {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
        showLoador({ loador: false });
      } else {
        showLoador({ loador: false });
      }
    });
  };

  render() {
    const { option, optionList, dynamicMasterData } = this.state;
    return (
      <>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Export Data"}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={false}
            onCancel={this.backToList}
          />
          <br />

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Select"}
              />
              <AutoComplete
                keyColName={"id"}
                value={option}
                name="option"
                options={optionList}
                onChange={this.onAutocompleteChange()}
              />
            </Grid>
          </Grid>
          <DynamicFormWithReport
          searchIconTrue={false}
            showTable={false}
            showTitle={false}
            DataToSet={[]}
            screenTitle={taxJson.screenTitle}
            fieldMeta={taxJson.fieldMeta}
            showCancel={taxJson.showCancel}
            apiBaseURL={taxJson.apiBaseURL}
            showSaveNextBtn={taxJson.showSaveNextBtn}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={"Export"}
            onSave={this.onSubmit}
            baseIdColumn={taxJson.baseIdColumn}
            callApi={true}
            getByIdApi={true}
            dynamicMasterData={dynamicMasterData}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ImportData);
