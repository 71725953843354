import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { balanceSheetJson } from "../../../DynamicFormsJson/Report/balanceSheet";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import {
  generateExcel,
  generatePDF,
} from "../../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../../components/withRouter";
import { blackColor, titleColor } from "../../../config/ColorObj";
import endpoints from "../../../config/endpoints";
import { serverMsg } from "../../../config/messageconstant";
import { apiGet } from "../../../utils/api_service";
import AccGroupSumarySubReportByAccount from "../../AccountReport/AccGroupSumarySubReportByAccount";
import MFGAndPL from "../../AccountReport/MFGAndPL";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import AccountLedgerTable from "./AccountLedgerTable";
import { ButtonCompo } from "../../../components/Comman/Button";
const BalanceSheet = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");

  const [dynamicMasterData, setDynamicMasterData] = useState({
    searchByList: [
      { id: 1, name: "Year-wise" },
      { id: 2, name: "Month-wise" },
      { id: 3, name: "Date-range" },
    ],
  });
  const [formData, setFormData] = React.useState({});
  const [cashList, setCashbookList] = React.useState({
    
  });
  const [MFGList, setMFGList] = React.useState({});
  const [detailList, setDetailList] = React.useState([]);
  const [popUpData, setPopupdata] = useState({});
  const [openTable, setOpenTable] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
  const [fieldData, setFieldData] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const [mainRowData, setMainRowData] = React.useState([]);

  useEffect(() => {
    getDropDownList();
  }, []);

  const getDropDownList = async () => {
    showLoador({ loador: true });
    let CompanyDetails = await apiGet({
      url: endpoints.company,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        let rowData = data.data;
        const location = localStorage.getItem("location");
        const locationList = localStorage.getItem("locationIdList");
        const locallist = JSON.parse(locationList);
        const locationlist = locallist.filter((data) => data.id == location);

        if (
          locationlist.length != 0 &&
          locationlist[0] != null &&
          locationlist[0].company != null
        ) {
         
          setFieldData({ searchBy: 1, companyId: locationlist[0].company.id });
        }
        return rowData
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    showLoador({ loador: true });
    const yearMasterDetail = await apiGet({
      url: endpoints.year,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        let rowData = data.data;

        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.yearName };
        });
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    setDynamicMasterData({
      ...dynamicMasterData,
      CompanyDetails: CompanyDetails,  
      yearMasterDetail: yearMasterDetail,
    });
  };
  const getList = (companyId, fromDate, toDate) => {
    setSearchFlag(false);
console.log(mainRowData)
let monthYearDate=mainRowData.map((row)=>{
  return row.monthYearDate
})
.join(",")
    showLoador({ loador: true });
    apiGet({
      url:
        endpoints.balanceSheet +
         "-between-date-comparison?companyIds="+companyId +'&fromDate=1-4-2023&toDate=5-11-2024'+"&flag="+fieldData.searchBy+'&monthYearDate='+monthYearDate
        
    }).then(({ data, success }) => {
      if (success) {
      
        let rowData = data.data;
let dynamicData=rowData.dynamicData 
let staticDataMain=rowData.staticData 
        let RightAlign =
        dynamicData !== null
            ? dynamicData.filter(
                (rowData) =>
                  rowData.groupDetail != null &&
                  rowData.groupDetail.allignment == "R"
              )
            : [];
        let LeftAlign =
        dynamicData !== null
            ? dynamicData.filter(
                (rowData) =>
                  rowData.groupDetail != null &&
                  rowData.groupDetail.allignment != "R"
              )
            : [];
      
            let RightAlignStatic =
            staticDataMain !== null
                ? staticDataMain.filter(
                    (rowData) =>{
                      return rowData.alligment == "R"}
                  )
                : [];
            let LeftAlignStatic =
            staticDataMain !== null
                ? staticDataMain.filter(
                    (rowData) =>
                      rowData.alligment == "L"
                  )
                : [];

     
   
        setCashbookList({
          RightAlign:RightAlign,
          LeftAlign:LeftAlign,
          RightAlignStatic:RightAlignStatic,
          LeftAlignStatic:LeftAlignStatic
        });
        showLoador({ loador: false });
        let DynamicData =
        dynamicData
            ? dynamicData
            : [];
        let staticData =
        staticDataMain? staticDataMain
            : [];
        setMFGList({ DynamicData: DynamicData, staticData: staticData });
      } else {
        setCashbookList([]);
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  };

  const onSave = () => {
   
    let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter(
      (row) => row.id === fieldData.companyId
    );

    setFormData({
      ...fieldData,
      name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
    });
    getList(fieldData.companyId, fieldData.fromDate, fieldData.toDate);
  };

 
  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };

  const generatePDFMethod = () => {
    generatePDFExcelData();
    var columns = [["Group Name", "Amount", "Group Name", "Amount"]];

    let tableList = [];
    cashList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      tableList.push(tableData);
    });

    const columnStyles = {
      0: { cellWidth: 150 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    const columnStylesPlain = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStylesPlain,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "From Date",
          value: formData.fromDate,
        },
        {
          label: "To Date",
          value: formData.toDate,
        },
        {
          label: "Company Name",
          value: formData.name,
        },
      ],
      pdfTitle: balanceSheetJson.screenTitle,
    });
  };
  const generateDetailPDFMethod = () => {
    // generatePDFExcelData()
    var columns = [["Liabilities", "Amount", "Assets", "Amount"]];
    console.log("detail pdf");
    let tableList = [];

    // detailList.map(((rowData, index) => {

    //     let tableData = []

    //     tableData.push(rowData.groupName+"\n"+rowData.amount)

    //     // tableData.push(rowData.amount)
    //     tableData.push(rowData.groupNameR+"\n"+rowData.amountL)

    //     // tableData.push(rowData.amountL)
    //     tableList.push(tableData)
    // }))

    detailList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      tableList.push(tableData);
      if (rowData.resultR && rowData.resultL) {
        if (rowData.resultR.length >= rowData.resultL.length) {
          rowData.resultR.map((data, index) => {
            let leftInfo = rowData.resultL[index] ? rowData.resultL[index] : {};
            let creditAmt = 0;
            let debitAmt = 0;
            creditAmt = data.creditAmt == null ? creditAmt : data.creditAmt;
            debitAmt = data.debitAmt == null ? debitAmt : data.debitAmt;
            let creditAmtL = 0;
            let debitAmtL = 0;
            creditAmtL =
              leftInfo.creditAmt != undefined && leftInfo.creditAmt != null
                ? leftInfo.creditAmt
                : "";
            debitAmtL =
              leftInfo.debitAmt != undefined && leftInfo.debitAmt != null
                ? leftInfo.debitAmt
                : "";
            let tableData = [];
            tableData.push(leftInfo.name ? leftInfo.name : "");
            tableData.push(
              creditAmtL != "" && debitAmtL != ""
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );

            tableData.push(data.name);
            tableData.push(Math.abs(creditAmt + debitAmt).toFixed(2));
            tableList.push(tableData);
          });
        } else {
          rowData.resultL.map((data, indexData) => {
            let leftInfo = rowData.resultR[indexData]
              ? rowData.resultR[indexData]
              : {};
            let creditAmt = 0;
            let debitAmt = 0;
            creditAmt = data.creditAmt == null ? creditAmt : data.creditAmt;
            debitAmt = data.debitAmt == null ? debitAmt : data.debitAmt;
            let creditAmtL = 0;
            let debitAmtL = 0;
            creditAmtL =
              leftInfo.creditAmt != undefined && leftInfo.creditAmt != null
                ? leftInfo.creditAmt
                : "";
            debitAmtL =
              leftInfo.debitAmt != undefined && leftInfo.debitAmt != null
                ? leftInfo.debitAmt
                : "";
            let tableData = [];
            tableData.push(data.name);
            tableData.push(Math.abs(creditAmt + debitAmt).toFixed(2));
            tableData.push(leftInfo.name ? leftInfo.name : "");
            console.log(Math.abs(creditAmtL + debitAmtL).toFixed(2));
            console.log(
              (creditAmtL != "" && debitAmtL != "") ||
                ((creditAmtL == 0 || debitAmtL == 0) &&
                  (creditAmtL != "" || debitAmtL != ""))
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );
            tableData.push(
              (creditAmtL != "" && debitAmtL != "") ||
                ((creditAmtL == 0 || debitAmtL == 0) &&
                  (creditAmtL != "" || debitAmtL != ""))
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );
            tableList.push(tableData);
          });
        }
      }
    });

    const columnStyles = {
      0: { cellWidth: 150 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    const columnStylesPlain = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStylesPlain,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "From Date",
          value: formData.fromDate,
        },
        {
          label: "To Date",
          value: formData.toDate,
        },
        {
          label: "Company Name",
          value: formData.name,
        },
      ],
      pdfTitle: balanceSheetJson.screenTitle,
    });
  };
  const generatePDFExcelData = () => {
    let tableList = [];
    MFGList.DynamicData &&
      MFGList.DynamicData.filter(
        (rowData) =>
          rowData.groupDetail != null && rowData.groupDetail.allignment != "R"
      ).map((rowData) => {
        let leftTotal = 0;
        leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt;
        let tableData = [];
        tableData.push(rowData.groupDetail.group_name);
        tableData.push(
          Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2)
        );
      });
    MFGList.staticData &&
      MFGList.staticData
        .filter((rowData) => rowData.alligment != "R")
        .map((rowData) => {
          let leftTotal = 0;
          leftTotal = leftTotal + rowData.amt;
          let tableData = [];
          tableData.push(rowData.name);
          tableData.push(Math.abs(rowData.amt).toFixed(2));
        });
    MFGList.DynamicData &&
      MFGList.DynamicData.filter(
        (rowData) =>
          rowData.groupDetail != null && rowData.groupDetail.allignment == "R"
      ).map((rowData) => {
        let leftTotal = 0;
        leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt;
        let tableData = [];
        tableData.push(rowData.groupDetail.group_name);
        tableData.push(
          Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2)
        );
      });
    MFGList.staticData &&
      MFGList.staticData
        .filter((rowData) => rowData.alligment == "R")
        .map((rowData) => {
          let leftTotal = 0;
          leftTotal = leftTotal + rowData.amt;
          let tableData = [];
          tableData.push(rowData.name);
          tableData.push(Math.abs(rowData.amt).toFixed(2));
        });
  };

  const generateExcelMethod = () => {
    var columns = ["Group Name", "Amount", "Group Name", "Amount"];
    let alignList = [];

    let rows = [];
    cashList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      rows.push(tableData);
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "From Date",
        formData.fromDate,
        "To Date",
        formData.toDate,
        "Company Name",
        formData.name,
      ],
      title: balanceSheetJson.screenTitle,
    });
  };
  const generateDetailExcelMethod = () => {
    var columns = ["Liabilities", "Amount", "Assets", "Amount"];
    let alignList = [];
   
    let rows = [];
    detailList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      rows.push(tableData);
      if (rowData.resultR && rowData.resultL) {
        if (rowData.resultR.length >= rowData.resultL.length) {
          rowData.resultR.map((data, index) => {
            let leftInfo = rowData.resultL[index] ? rowData.resultL[index] : {};
            let creditAmt = 0;
            let debitAmt = 0;
            creditAmt = data.creditAmt == null ? creditAmt : data.creditAmt;
            debitAmt = data.debitAmt == null ? debitAmt : data.debitAmt;
            let creditAmtL = 0;
            let debitAmtL = 0;
            creditAmtL =
              leftInfo.creditAmt != undefined && leftInfo.creditAmt != null
                ? leftInfo.creditAmt
                : "";
            debitAmtL =
              leftInfo.debitAmt != undefined && leftInfo.debitAmt != null
                ? leftInfo.debitAmt
                : "";
            let tableData = [];
            tableData.push(leftInfo.name ? leftInfo.name : "");
            tableData.push(
              creditAmtL != "" && debitAmtL != ""
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );

            tableData.push(data.name);
            tableData.push(Math.abs(creditAmt + debitAmt).toFixed(2));
            rows.push(tableData);
          });
        } else {
          rowData.resultL.map((data, indexData) => {
            let leftInfo = rowData.resultR[indexData]
              ? rowData.resultR[indexData]
              : {};
            let creditAmt = 0;
            let debitAmt = 0;
            creditAmt = data.creditAmt == null ? creditAmt : data.creditAmt;
            debitAmt = data.debitAmt == null ? debitAmt : data.debitAmt;
            let creditAmtL = 0;
            let debitAmtL = 0;
            creditAmtL =
              leftInfo.creditAmt != undefined && leftInfo.creditAmt != null
                ? leftInfo.creditAmt
                : "";
            debitAmtL =
              leftInfo.debitAmt != undefined && leftInfo.debitAmt != null
                ? leftInfo.debitAmt
                : "";
            let tableData = [];
            tableData.push(data.name);
            tableData.push(Math.abs(creditAmt + debitAmt).toFixed(2));
            tableData.push(leftInfo.name ? leftInfo.name : "");
            console.log(Math.abs(creditAmtL + debitAmtL).toFixed(2));
            console.log(
              (creditAmtL != "" && debitAmtL != "") ||
                ((creditAmtL == 0 || debitAmtL == 0) &&
                  (creditAmtL != "" || debitAmtL != ""))
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );
            tableData.push(
              (creditAmtL != "" && debitAmtL != "") ||
                ((creditAmtL == 0 || debitAmtL == 0) &&
                  (creditAmtL != "" || debitAmtL != ""))
                ? Math.abs(creditAmtL + debitAmtL).toFixed(2)
                : ""
            );
            rows.push(tableData);
          });
        }
      }
    });
    // tableData.push(groupNameL+"       "+amountL)

    // // tableData.push(rowData.amount)
    // tableData.push(groupNameR+"       "+amountR)
    // rows.push(tableData)

    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "From Date",
        formData.fromDate,
        "To Date",
        formData.toDate,
        "Company Name",
        formData.name,
      ],
      title: balanceSheetJson.screenTitle,
    });
  };

 
  const rowLink = (rowData, key) => {
    if (key ) {
      
        setPopupdata({
         ...rowData
        });
        setOpenTable(!openTable);
      
    } else {
      toggleMFGAndProfitAndLoss();
    }
  };
  const toggle = () => {
    setOpenTable(!openTable);
    if (formData != null && Object.keys(formData).length != 0) {
      getList(formData.companyId, formData.fromDate, formData.toDate);
    }
  };
  const toggleMFGAndProfitAndLoss = () => {
    setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
    if (formData != null && Object.keys(formData).length != 0) {
      getList(formData.companyId, formData.fromDate, formData.toDate);
    }
  };
 
  const onClickAdd = () => {
    console.log(JSON.stringify(fieldData))
    let dataToSet=fieldData
   
if(fieldData.searchBy!=3&&dataToSet.monthYearDate){
  if(fieldData.searchBy==1){
    let mainInfo = mainRowData.find(
      (row) => row.monthYearDate === dataToSet.monthYearDate
    );
    if(mainInfo){
      showNotification({
        msg: 'Year already added',
        severity: "error",
      });
      return 
    }
    let yearInfo = dynamicMasterData["yearMasterDetail"].find(
      (row) => row.id === dataToSet.monthYearDate
    );
    dataToSet={
      ...dataToSet,
      showName:yearInfo.name
    }
  }else if(fieldData.searchBy==2){
   let showName=dataToSet.monthYearDate.format("MM")+"-"+dataToSet.monthYearDate.format("YYYY")
   let mainInfo = mainRowData.find(
    (row) => row.showName === showName
  ); 
  if(mainInfo){
    showNotification({
      msg: 'Month already added',
      severity: "error",
    });
    return 
  }
   dataToSet={
      ...dataToSet,
      showName:showName,
      monthYearDate:showName
    }
   
  }else{
    dataToSet={
      ...dataToSet,
      showName:'yearInfo.name'
    }
  }
     setFieldData({...fieldData,monthYearDate:null})
      setMainRowData([...mainRowData,dataToSet]);
}else if(fieldData.searchBy==3&&dataToSet.fromDate&&dataToSet.toDate){
  
  
 }else{
 let label= fieldData.searchBy==1?'Year ' :fieldData.searchBy==2?'Month':'From date and to date '
  showNotification({
    msg:label+' is mandotory',
    severity: "error",
  });
}

  };
  const onDataChange = (fieldName, newValue) => {
  
    const dataToReset = { ...fieldData };

    dataToReset[fieldName] = newValue;
    console.log(dataToReset);
    setFieldData(dataToReset);
  };
  const onClickDelete=(mainData,index)=>{
   let filterData= mainRowData.filter((rowInfo,infoIndex)=>infoIndex!==index)
  setMainRowData(filterData)
  }
  
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          formErrors={formErrors}
          PeriodData={
            formData.fromDate
              ? formData.fromDate + " To " + formData.toDate
              : ""
          }
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          fieldData={fieldData}
          onDataChange={onDataChange}
          showSaveBtn={balanceSheetJson.showSaveBtn}
          screenTitle={balanceSheetJson.screenTitle}
          fieldMeta={  mainRowData.length==0?balanceSheetJson.fieldMetaComparisionType:balanceSheetJson.fieldMetaComparisionTypeDisabled}
          showCancel={balanceSheetJson.showCancel}
          apiBaseURL={balanceSheetJson.apiBaseURL}
          showSaveNextBtn={balanceSheetJson.showSaveNextBtn}
          showSaveBtnMain={false}
          padding={false}
          generatePDF={searchFlag ? generateDetailPDFMethod : generatePDFMethod}
          generateExcel={
            searchFlag ? generateDetailExcelMethod : generateExcelMethod
          }
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
          showPDFDownload={true}
          detailButton={false}
          
        />
        {fieldData.searchBy&&fieldData.companyId
        &&
 <DynamicFormWithReport
 formErrors={formErrors}
 PeriodData={
   formData.fromDate
     ? formData.fromDate + " To " + formData.toDate
     : ""
 }
 dynamicMasterData={dynamicMasterData}
 showBackToList={false}
 DataToSet={[]}
 fieldData={fieldData}
 onDataChange={onDataChange}
 showSaveBtn={balanceSheetJson.showSaveBtn}
 screenTitle={""}
 fieldMeta={
   fieldData.searchBy == 1
     ? balanceSheetJson.fieldMetaYearwise
     :fieldData.searchBy == 2
     ? balanceSheetJson.fieldMetaMonthWise
     : balanceSheetJson.fieldMetaDateWise
 }
 showCancel={balanceSheetJson.showCancel}
 apiBaseURL={balanceSheetJson.apiBaseURL}
 showSaveNextBtn={balanceSheetJson.showSaveNextBtn}
 showSaveBtnMain={false}
 GridSave={1}
 onClickAdd={onClickAdd}
 showAddBtnMain={true}
 padding={false}
 generatePDF={
   searchFlag ? generateDetailPDFMethod : generatePDFMethod
 }
 generateExcel={
   searchFlag ? generateDetailExcelMethod : generateExcelMethod
 }
 onSearch={onSearch}
 saveBtnText={"Search"}
 paddingTop={false}
 onFormSave={onSave}
 showExcelDownload={true}
 showPDFDownload={true}
 detailButton={false}
/>
        }
        
        <div style={{display:"flex",flexWrap:"wrap"}}>
        {
          mainRowData.length!=0&& <LabelCompo
          className="text-black"
          style={{ fontSize: 14, color: titleColor,paddingRight:5 }}
          label={ fieldData.searchBy == 1
            ? "Year : "
            :fieldData.searchBy == 2
            ? "Month : "
            : "Date : "
    }
        />
        }
        {mainRowData.map((mainRow, index) => {
        let labelName= fieldData.searchBy != 3
         ? mainRow.showName
        : "01-06-2023 to 27-06-2023 "

         return (
          <>
          
          <LabelCompo
            className="text-black"
            style={{ fontSize: 14, color: blackColor }}
            label={labelName}
          />
            <MyComponentWithIconProps
                    statusImage={CloseIcon}
                    fontSize="medium"
                    title="delete"

                    sx={{marginLeft:1,marginRight:2,color: "#ff0000" }}
                     onClick={(e) => onClickDelete( mainRow,index)}
                  />
         
        
          </>
            
          );
        })}
       {
        mainRowData.length!=0?
      
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={ "Search"}
                    // fullWidth={true}
                    onClick={onSave}
                  />
                  :null
                }
         </div>

        <br />

       
          
         
        {openTable && (
          <AccGroupSumarySubReportByAccount
            open={openTable}
            handleCloses={toggle}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
           
            accPopupata={Object.keys(popUpData).length != 0 ? popUpData : {}}
            dynamicMasterData={formData}
          />
        )}
        {openMFGAndProfitAndLoss && (
          <MFGAndPL
            rowLink={rowLink}
            open={openMFGAndProfitAndLoss}
            handleCloses={toggleMFGAndProfitAndLoss}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            formData={formData}
          />
        )}
      </Paper>
      <AccountLedgerTable onClick={rowLink}headerList={mainRowData}cashList={cashList}/>
   
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BalanceSheet);
