import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { userJSON } from "../../../DynamicFormsJson/MastersJSON/user";
import { getAccessRoleList } from "../../../Slice/accessRole.slice";
import { getLocation } from "../../../Slice/accountLedger.slice";
import DynamicFormWithoutValidation from "../../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../../components/withRouter";
import { default as endpoint, default as endpoints } from "../../../config/endpoints";
import { noInternetMsg, saveWarningMsg, serverMsg, updateMsg } from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class UserFormEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        locationDetails: this.props.locationList?.location,
        accLocationDetails: this.props.locationList?.location,
        roleDetails: this.props.accessRole?.accessRole,
      },
      fieldData:{},
      formErrors:{},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.editData.id != nextProps.editData.id) {
        this.getByIdData(nextProps.editData.id)
      }
    if (this.props.locationList !== nextProps.locationList) {
      if (
        this.props.locationList?.location !== nextProps.locationList?.location
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            locationDetails: nextProps.locationList?.location,
            accLocationDetails: nextProps.locationList?.location,
          },
        });
      }
    }
  
    if (this.props.accessRole !== nextProps.accessRole) {
      if (
        this.props.accessRole?.accessRole !== nextProps.accessRole?.accessRole
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            roleDetails: nextProps.accessRole?.accessRole,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const { getLocation, getAccessRoleList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      // showLoador({ loador: true });
      // await getLocation().then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });
      // showLoador({ loador: true });
      // await getAccessRoleList().then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });
      if (this.props.editData.id) {
        this.getByIdData(this.props.editData.id)
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getByIdData = async (id) => {
    const {
      showLoador,
      showNotification,
      
    } = this.props;
    showLoador({ loador: true });
    const response =apiGet({
        url:
        endpoints.user1 + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
            if (!data.error) {
              let rowData = data.data;
              rowData = {
                ...rowData,
                userName:rowData.userName,
                locationId:rowData.locationId,
                accLocationId:rowData.accLocationId?rowData.accLocationId.split(','):"",
                email:rowData.email,
                contact:rowData.contact,
                roleId:rowData.roleId,
              };
              console.log(rowData)
              this.setState({fieldData:rowData})
              showLoador({ loador: false });
              return {
                data: rowData,
                success,
              };
            } else {
                showLoador({ loador: false });
              showNotification({ msg: serverMsg, severity: "error" });
            }
            return { data: {}, success: false };
          }
          return { response: {}, success: false };
      });
  }

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    userJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck1 = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    userJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined || fieldData[currentField.dataKey] === ""
            || fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    userJSON.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck() && this.mandatoryCheck1()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };
  onSubmitData = () => {
    const { showNotification } = this.props;
   
    if (this.checkValidationOnSubmit()) {
      const {
       
        fieldData,
       
        dynamicMasterData,
      } = this.state;
    
         
        
        const dataToSave = {
          ...fieldData,

          locationId
           : fieldData.locationId
          ,
          id: this.props.editData.id ? this.props.editData.id : "",
        
          accLocationId:fieldData.accLocationId?fieldData.accLocationId.toString():"",
          userName: fieldData.userName,
         
          contact: fieldData.contact,
          email: fieldData.email,
      
          // usrPwd: "ABCFQ",
          isActive:1,
        };

        console.log(dataToSave);
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {

              this.onSave(dataToSave);
            
          }
        });
     
      
    }
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    userJSON.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = this.getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  goBackToList = () => {
    if (this.props.approvalFlag == 0) {
      this.props.navigate("/user");
    } 
  };
  onCancel=()=>{
    this.setState({
      fieldData:{}
     })
    this.props.navigate("/user");
  }

  onSave = (dataToSave) => {
    const { showLoador, showNotification,} = this.props;
    if (navigator.onLine) {
      console.log(dataToSave)
      showLoador({ loador: true });
      apiPost({
        url: endpoint.user1,
        postBody: dataToSave,
    }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (success) {
        console.log(response)
        this.setState({
          fieldData:{}
         })
       
          showNotification({
            msg: updateMsg ,
          });
          this.props.callApi();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData,fieldData ,formErrors} = this.state;
    const {rowReset,approvalFlag} =this.props
    console.log(fieldData)
    return (
      <>
       

       
      


<DynamicFormWithoutValidation
            padding={true}
            paddingTopGrid={true}
            paddingTop={true} 
            // getListById={this.getListById}
            // formData={formData}
            dynamicMasterData={dynamicMasterData}
            screenTitle={userJSON.screenTitle}
            fieldMeta={userJSON.fieldMeta}
            apiBaseURL={userJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={true}
            showSaveBtnMain={false}
            
            
            onFormSave={this.onSubmitData}
            showReset={approvalFlag == 0 ? true : false}
            rowReset={rowReset}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            backToList={true}
            goBackToList={this.goBackToList}
          />
         {/* <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
            padding={2}
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name={"Save"}
              onClick={this.onSubmitData}
            />
             
          </Grid> */}

      </>
    );
  }
}
const mapStateToProps = (state) => ({
  locationList: state.accountLedger,
  accessRole: state.accessRole,
});
const mapDispatchToProps = {
  getLocation,
  getAccessRoleList,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserFormEdit);
