
const endpoint = {
  auth: "/api/auth/login",
  room: "/acdmc/v1/rooms",
  floor: "/acdmc/v1/floor",
  zone: "/v1/zone",
  unitOfMesurement: "/v1/uom",
  document: "/v1/document-table",
  category: "/v1/category",
  subCategory: "/v1/sub-category",
  salesExecutive: "/v1/sales-team",
  stockStakeHolderType: "/v1/stock-stake-holder-type",
  tax: "/v1/tax-percentage",
  area: "/v1/area",
  product: "/v1/product",
  region: "/v1/region",
  stackholder: "/v1/stock-stake-holder",
  stackholderDocument: "/v1/stock-stake-holder-document-table",
  uploadImage: "/api/distribution/v1/file-upload",
  orderConfiguration: "/v1/order-configuration",
  mrpConfiguration: "/v1/product-mrp-configuration",
  manager: "/v1/sales-team",
  forgotPassword: "/auth/user",
  userAccess: "/api/account/v1/access-role",
  module: "/api/account/v1/module",
  productRateConfiguration: "/v1/product-rate-configuration",
  checkPublicationDate:
    "/v1/product-rate-configuration-applicable-configuration",
  outletRateStructure: "/v1/product-rate-configuration-outlet",
  stackHolderTypeDocument: "/v1/stock-stake-holder-document-table",
  productRateConfOutlet: "/v1/product-rate-applicable-configuration-outlet",
  assignRateConfigurationToStakeholder:
    "/v1/stock-stake-holder-product-rate-configuration",
  productRateConfigurationDetails: "/v1/product-rate-configuration-details",
  productRateConfigurationOutletDetails:
    "/v1/product-rate-configuration-details-outlet",
  range: "/v1/range",
  grievanceCategory: "/v1/grivience-category",
  outletType: "/v1/outlet-type",
  beat: "/v1/beat",
  // year: "/v1/year",
  outlet: "/v1/outlet",
  productRateConfigurationDetailsOutlet:
    "/v1/product-rate-configuration-details-outlet",
  audit: "/v1/audit-head",
  auditQuestion: "/v1/audit-question",
  dayWiseBitAssign: "/v1/beat-assign-daywise-header",
  target: "/v1/target",
  regionAndTarget: "/v1/region-target-percentage",
  assignBeat: "/v1/beat-assign",
  auditOption: "/v1/audit-question-option",
  projection: "/v1/sales-excutive-beat-projection-header",
  grievance: "/v1/t-grivience",
  companyTarget: "/v1/company-target",
  adminDashboard: "/v1/order",
  outletOrderDetail: "/v1/outlet-order-detail",
  outletOrderHeader: "/v1/outlet-order-header",
  outletBillHeader: "/v1/outlet-bill-header",
  outletBillDetail: "/v1/outlet-bill-detail",
  manualOrder: "/v1/manual-order",
  paymentReceiptOutlet: "/v1/payment-reciept-outlet",
  paymentReceipt: "/v1/payment-reciept",
  creditNote: "/v1/credit-note-header",
  creaditNoteHeader: "/v1/holder-credit-note-header",
  grnGVNHeader: "/v1/grn-gvn-header",
  grnGVNDetail: "/v1/grn-gvn-detail",
  stockStakeholderOrderHeader: "/v1/stock-stake-holder-order-header",
  stockStakeholderBillHeader: "/v1/stock-stake-holder-bill-header",
  creditNoteHeader: "/v1/holder-credit-note-header",
  paymentReceipt: "/v1/payment-reciept",
  manageStockHeader: "/v1/stock-consumption-header",
  manageStockDetail: "/v1/stock-consumption-detail",
  noticeMsg: "/v1/notice-msg",
  multilingual: "/v1/language",
  state: "/v1/state",
  paymentReceiptDetail: "/v1/payment-reciept-detail",
  distributorDashbourd: "/v1/dashboard-for-stock-holder",
  monthWiseTotalAmount: "/v1/month-wise-total-amount",
  checkin: "/v1/check-in-out",
  location: "/v1/location-detail",
  yearEndHeader: "/api/account/v1/year-end-header",
  salesTeamOrderConfig: "/v1/sales-team-order-configuration",
  company: "/api/account/v1/company",
  offer: "/v1/offer-header",
  setting: "/api/account/v1/setting",
  report: "/v1/report-master",
  matrixReport: "/v1/matrix-report-master",
  type: "/v1/report-type-master",
  companyCategoryConfiguration: "/v1/company-category-configuration",
  formBuilder: "/api/account/v1/form-builder",
  formTypeBuilder: "/api/account/v1/form-type-builder",
  subVoucher: "/api/account/v1/sub-voucher",
  voucher: "/api/account/v1/voucher",
  subVoucherDetail: "/api/account/v1/sub-voucher-detail",
  accountLedger: "/api/account/v1/account-ledger",
  recieptPaymentHeader: "/api/account/v1/reciept-payment-header",
  journalEntryHeader: "/api/account/v1/journal-entry-header",
  getRecieptPaymentHeaderList: "/api/account/v1/get-payment-receipt-List",
  locationForAccountLedger: "/api/account/v1/location",
  accountLedgerGroupMaster: "/api/account/v1/account-ledger-groups-master",
  tdsDedctyType: "/api/account/v1/deducty-type",
  tdsAccountNature: "/api/account/v1/tds-account-nature",
  gstType: "/api/account/v1/gst-type",
  taxType: "/api/account/v1/tax-type",
  subTaxType: "/api/account/v1/sub-tax-type",
  accContact: "/api/account/v1/acc-contact",
  accbankInfo: "/api/account/v1/acc-bank-information",
  costCenter: "/api/account/v1/cost-center",
  cashbook: "/api/account/v1/report/cashbook",
  bankbook: "/api/account/v1/report/bankbook",
  accountGroupSummary: "/api/account/v1/report/account-group-summery",
  accountGroupSummaryByGroup:
    "/api/account/v1/report/account-group-summery-by-groupid",
  accountLedgerReport: "/api/account/v1/report/account-detail-by-accids",
  accountLedgerTransaction: "/api/account/v1/report/account-detail-by-accid-closing",
  trailBalance: "/api/account/v1/trail-balance",
  cashbalance: "/api/account/v1/accounts-report",
  cashbalanceDateWise: "/api/account/v1/accounts-report/datewise-cashbalance",
  cashbalanceByDate: "/api/account/v1/accounts-report/cashbalance-by-date",
  accountGroupSummaryByAcc: "/api/account/v1/report/account-detail-by-accid",
  bankbook: "/api/account/v1/report/bankbook",
  user: "/api/account/v1/user/info",
  user1: "/api/account/v1/user",
  stockValue: "/api/account/v1/stock-value",
  stockClosing: "/api/account/v1/stock-closing",
  year: "/api/account/v1/year-table",
  stockCategory: "/api/account/v1/stock-category",
  salePurchaseApproval: "/api/account/v1/purchase-sale-report",
  salesPurchase: "/api/account/v1/sales-purchase-api",
  MFGAndPf: "/api/account/v1/report/manufacture-profit-and-loss",
  MFGAndPfPlant: "/api/account/v1/report/manufacture-profit-and-loss-plant-wise",
  balanceSheet: "/api/account/v1/report/balance-sheet",
  balanceSheetPlant: "/api/account/v1/report/balance-sheet-plant",
  MFG: "/api/account/v1/report/manufacture-profit-and-loss-by-type",
  receiptBillInfo: "/api/account/v1/receipt-bill-info",
  accountReport: "/api/account/v1/accounts-report",
  purchaseSummaryReport: "/api/account/v1/purchase-sale-summary-report",
  asset: "/api/account/v1/assets-calculation",
  yearEnd: "/api/account/v1/year-end",
  payMode: "/api/account/v1/payment-mode",
  purchaseSaleHeader: "/api/account/v1/purchase-sale-header",
  profitCenter: "/api/account/v1/profit-center",
  shadowAccountMapping: "/api/account/v1/shadow-account-mapping",
  AccGroupSummaryByPlant: "/api/account/v1/report/account-group-summery-by-plant",
  AccGroupSummaryByPlantSubReportGroup: "/api/account/v1/report/account-group-summery-by-groupid-by-plant",
  AccGroupSummaryByPlantSubReportAcc: "/api/account/v1/report/account-detail-by-accid-by-plant",
  timeline: "/api/account/v1/transaction-logs",
  importData: '/api/account/v1/import',
  dash:"/api/account/v1/accounts-dashboard",
  b2b: "/api/account/v1/accounts-report/get-b2b-sale-report",
  b2cs: "/api/account/v1/accounts-report/get-b2cs-sale-report",
  b2cl: "/api/account/v1/accounts-report/get-b2cl-sale-report",
  cashFlowSummary:"/api/account/v1/report",
  mrnHead:"/api/erp/v1/qc-mrn-header",
   vendor:"/api/erp/v1/vendor",
   rateConfig:"/api/erp/v1/rate-configuration-header",
  grnHead:'/api/erp/v1/grn-header',
  importAccountLedger:'/api/account/v1/import-account-ledger-group',
  excel:'/api/account/v1/excel-report',
  freezeMonth:'/api/account/v1/freeze-month',
};
export default endpoint;
