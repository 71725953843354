import { useState, useEffect } from "react";
import RTTable from "../../components/Comman/RT/RTTable";
import { apiGet } from "../../utils/api_service";
import { serverMsg, urlMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { costCenterJson } from "../../DynamicFormsJson/Report/costCenter";
import { Paper } from "@mui/material";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import moment from "moment";
import CostCenterSubReport from "./CostCenterSubReport";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import { connect } from "react-redux";
const CostCenter = ({ showLoador, showNotification }) => {
  const [rowList, setRowList] = useState([]);
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [openTable, setOpenTable] = useState(false);
  const [popUpData, setPopupdata] = useState({})
  const [formData, setFormData] = useState({})
  const [searchValue, setSearchValue] = useState("");
  const [costCenterWise, setCostCenterWise] = useState([
    { name: "index", title: "Sr. No.", align: "center" },

    { name: "ccName", title: "CC Name", linkFlag: true },
    { name: "ccCode", title: "Code ", align: "center" },
    // { name: "date", title: "Date", align: "center" },
    { name: "amount", title: "Amount", align: "right" },
  ])
  const [accountWise, setAccountWise] = useState([
    { name: "index", title: "Sr. No.", align: "center" },

    { name: "accountLedgerName", title: "Account Name", linkFlag: true },
    // { name: "date", title: "Date", align: "center" },
    { name: "amount", title: "Amount", align: "right" },
  ]);

  useEffect(() => {

  }, []);
  const onSave = (searchData) => {

    let locationId = localStorage.getItem("location")
    if (searchData != null) {
      showLoador({ loador: true });
      apiGet({
        url:
          searchData.type == 0 ? endpoint.accountReport +
            "/get-total-amount-cc-wise?locationId=" + locationId + "&fromDate=" + searchData.fromDate + "&toDate=" + searchData.toDate :
            endpoint.accountReport +
            "/get-total-amount-account-wise?locationId=" + locationId + "&fromDate=" + searchData.fromDate + "&toDate=" + searchData.toDate,
      }).then(({ data, success }) => {

        showLoador({ loador: true });
        if (success) {
          setFormData({ ...searchData })
          let rowData = data.data;

          let rowListData = rowData.map((row, index) => {
            index = index + 1;

            return {
              index: index,
              ...row,
              amount: Math.abs((row.amount)).toFixed(2),
            };
          });



          setRowList(rowListData);
          showLoador({ loador: false })
        } else {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
          showLoador({ loador: false })
        }
      });


    };
  }


  const rowLink = (rowData) => {
    console.log("rowData")
    console.log(rowData)

    setPopupdata(rowData)
    setOpenTable(!openTable);
  }
  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };
  const generatePDFMethod = () => {
    var columns = [["Sr.No", "Account Head", "Credit", "Debit"]];
    let tableList = []

    rowList.map(((rowData, index) => {

      let tableData = [];
      tableData.push(rowData.index)
      tableData.push(rowData.groupName)


      tableData.push(rowData.creditAmt)
      tableData.push(rowData.debitAmt)




      tableList.push(tableData)
    }))

    // tableList.push("Total");
    // tableList.push("");
    // tableList.push({creditAmt:creditTotalAmt});
    // tableList.push({debitAmt:debitTotalAmt});


    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    // if(creditTotalAmt>= debitTotalAmt){
    //   tableList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})

    // }else{
    //   tableList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
    // }
    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate
        },
        {
          label: "Company Name",
          value: formData.name
        },
      ],
      pdfTitle: "Group Summary",
    });

  };

  const getFilteredTableData = (tableData) => {

    let listData = tableData.filter((row) => {

      let isValid = false;

      if (row.ccName.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (row.date.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (row.amount.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      return isValid;

    });
    return listData
  };
  const generateExcelMethod = () => {
    var columns = ["Sr.No", "Account Head", "Credit", "Debit"];
    let alignList = [];

    let rows = []

    rowList.map((reportData, index) => {
      let reportObj = [index + 1];
      reportObj.push(reportData.groupName)
      reportObj.push(Math.abs(reportData.creditAmt))
      reportObj.push(Math.abs(reportData.debitAmt))


      rows.push(reportObj)



      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("")
      // reportObj.push(debitTotal==0?"": Math.abs(debitTotal) )
      // reportObj.push(creditTotal==0?"":Math.abs(creditTotal) )
      // rows.push(reportObj)

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("Day Closing")
      // reportObj.push("")
      // reportObj.push(Math.abs(reportData.closingAmt) )
      // rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate, "Company Name", formData.name

      ],
      title: "Group Summary",
    });

  };

  const toggle = () => {
    setOpenTable(!openTable);

  };
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={costCenterJson.showSaveBtn}
          screenTitle={costCenterJson.screenTitle}
          fieldMeta={costCenterJson.fieldMeta}
          showCancel={costCenterJson.showCancel}
          apiBaseURL={costCenterJson.apiBaseURL}
          showSaveNextBtn={costCenterJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
        />
        <br />
        <RTTable
          showPegination={false}
          columns={
            formData.type != 0 ? accountWise : costCenterWise}
          hiddenColumnNames={[]}
          tableData={getFilteredTableData(rowList)}
          rowLink={rowLink}
          isActionColActive={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
        />
      </Paper>
      {openTable && (
        <CostCenterSubReport


          open={openTable}
          handleCloses={toggle}
          popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
          formData={formData}

        />
      )}

    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };
export default connect(mapStateToProps, mapDispatchToProps)(CostCenter);

