import dayjs from "dayjs";
import endpoint from "../../config/endpoints";

export const CashReceiptJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.recieptPaymentHeader,
  screenTitle: "Cash Receipt",
  formPath: "/cash-receipt-form",
  formPathView: "/cash-receipt-view",
  showBackToList: false,
  showTitle: false,
  searchList: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucher",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
  fieldMeta: [
    {
      label: "Sub-voucher",
      controlType: "textfield",
      placeHolder: "Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "subVoucherName",
      isRootLevelKey: false,
      isMandatory: true,
      disable: true,
    },
    
    {
      label: "Cash A/C",
      controlType: "autocomplete",
      placeHolder: "Select Cash Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      getListId: "cashBankId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Profit Center",
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
   
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Advance Payment",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isAdvance",
      // tableDataKey: "isAdvance",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value:"1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
      freezCurrentDate:true,
      dateApiValid:true
    },
  ],
  fieldMetaAdd: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucherMaster",
      isRootLevelKey: false,
      isMandatory: true,
      disable: true,
    },
    {
      label: "Profit Center",
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
    
    {
      label: "Cash A/C",
      controlType: "autocomplete",
      placeHolder: "Select Cash Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "cashBankId",
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Advance Payment",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isAdvance",
      // tableDataKey: "isAdvance",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value:"1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
      freezCurrentDate:true,
      dateApiValid:true
    },
  ],
  fieldMetaDetails: [
    {
      label: "Credit Account",
      controlType: "autocomplete",
      placeHolder: "Select Credit Account ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "creditAccountDetails",
      dataKey: "mainAccountId",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "mainAccountId",
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "amt",
      inputType: "number",
      isMandatory: true,
    },
    // {
    //   label: "Sort Order",
    //   controlType: "textfield",
    //   placeHolder: "Enter sort order",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   inputType: "number",
    //   dataKey: "sortNo",
    //   isMandatory: true,
    // },
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeDetails",
      dataKey: "type",
      isRootLevelKey: true,
      isMandatory: true,
      defaultValue: "C",
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
  fieldMetaForOption: [
    {
      label: "Cost Center",
      controlType: "autocomplete",
      placeHolder: "Select Cost Center ",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "costCenterDetail",
      dataKey: "costCenterMaster",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      inputType: "number",
      isMandatory: true,
    },
  ],
  fieldMetaForOption1: [
    {
      label: "Bill",
      controlType: "autocomplete",
      placeHolder: "Select Bill ",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "billDetail",
      dataKey: "billId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      inputType: "number",
      isMandatory: true,
    },
  ],
  fieldMeta1: [
    {
      label: "Received From",
      controlType: "textfield",
      placeHolder: "Enter Received From",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2.5,
      labelMD: 2.5,
      labelLG: 2.5,
      valueSM: 9.5,
      valueMD: 9.5,
      valueLG: 9.5,
      dataKey: "receivedFrom",
      isMandatory: true,
      disableTrue: false,
    },
   
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2.5,
      labelMD: 2.5,
      labelLG: 2.5,
      valueSM: 9.5,
      valueMD: 9.5,
      valueLG: 9.5,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
};
