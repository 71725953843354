import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BankbookJson } from "../../DynamicFormsJson/Report/BankBook";
import { getAccountLedgerListByType } from '../../Slice/subVoucherDetail.slice';
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import CashbookTable from "./BankbookTable";

import { showLoador, showNotification } from "../Landing/Landing.slice";

const Bankbook = ({ getAccountLedgerListByType, showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [cashbook, setCashbookList] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [fieldData, setFieldData] = React.useState({});
  useEffect(() => {
    getDropDownList()
  }, [])
  const getDropDownList = async () => {

    showLoador({ loador: true });
    const accountLedgerGroupMasterDetailForType = await getAccountLedgerListByType({
      accType: 2,
    }).then(({ response, success }) => {
      if (success) {
        showLoador({ loador: false });
        return response.map((rowData) => {
          return { ...rowData, id: rowData.id1 }
        })
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return []
    });
    showLoador({ loador: true });
    let CompanyDetails = await apiGet({
      url: endpoint.company,
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data;
        showLoador({ loador: false });
        const location=localStorage.getItem("location");
       const locationList =localStorage.getItem("locationIdList");
      const locallist=JSON.parse(locationList)
      const locationlist=locallist.filter((data)=>data.id==location)
       console.log("location")
        console.log(locationlist) 
         
        
          if(locationlist.length!=0 && locationlist[0]!=null && locationlist[0].company!=null){
            
            setFieldData({ companyId: locationlist[0].company.id })
          }
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.name };
        })
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return []
    });
    setDynamicMasterData({
      accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetailForType,
      CompanyDetails: CompanyDetails
    });
  }
  const getList = (fromDate, toDate, companyIds, accountLedgerGroupsId) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.bankbook + "?companyIds=" + companyIds + "&fromDate=" + fromDate + "&toDate=" + toDate + "&accId=" + accountLedgerGroupsId,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        let rowData = data.data.map((rowData) => {
          return {
            ...rowData, transactionDetail: rowData.transactionDetail.map((trans) => {
              return { ...trans, transactionDetailes: trans.transactionDetailes != null ? JSON.parse(trans.transactionDetailes) : [] }
            })
          }
        })
        setCashbookList(rowData)
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  }

  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };
  const generatePDFMethod = () => {
    var columns = [["Perticuler", "Receipt", "Payment"]];
    let tableList = []
    cashbook.map(((rowData, index) => {
      let creditTotal = 0
      let debitTotal = 0
      let tableData = []

      let Perticuler = ""
      let Receipt = ""
      let Payment = ""
      rowData.transactionDetail.map((data) => {
        if (data.type == "D") {
          debitTotal = debitTotal + data.amount
        } else {
          creditTotal = creditTotal + data.amount
        }

        let refAccName = data.type != "D" ? "To " + data.refAccName : "By " + data.refAccName
        let type = data.type != "D" ? "Amt Paid To " + data.refAccName : "Amt Received "
        Perticuler = Perticuler + refAccName + "\n" + type
        Receipt = Receipt + data.type == "D" ? Math.abs(data.amount).toFixed(2) : ""
        Payment = Payment + data.type == "D" ? "" : Math.abs(data.amount).toFixed(2)

      })

      tableData = []
      let ct = creditTotal == 0 ? "\n" : Math.abs(creditTotal).toFixed(2)
      
      tableData.push(rowData.date +"\nOpening Amt"+ "\n" + Perticuler + "\n\n\nDay Closing")
      let rec=rowData.openingAmt<=0 ?Math.abs(rowData.openingAmt).toFixed(2):""
     let det=rowData.openingAmt>0 ?Math.abs(rowData.openingAmt).toFixed(2):"" 
      let dt = debitTotal == 0 ? "" : Math.abs(debitTotal).toFixed(2)
      tableData.push("\n"+rec+"\n" + Receipt + "\n\n" + dt)
      let closingAmt = rowData.closingAmt > 0 ? Math.abs(rowData.closingAmt).toFixed(2) + " Cr" : Math.abs(rowData.closingAmt).toFixed(2) + " Dr"
      tableData.push("\n"+det+"\n" + Payment + "\n\n" + ct + "\n" + closingAmt)
      tableList.push(tableData)
    }))
    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    const columnStyles = {
     
      1: { cellWidth: 90 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      // columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate
        },
        {
          label: "Company Name",
          value: formData.name
        },
        {
          label: "Account Name",
          value: formData.accName
        },
      ],
      pdfTitle: BankbookJson.screenTitle,
    });

  };
  const getFilteredTableData = (tableData) => {

    let listData = []
    tableData.map((row) => {
      const subList = row["transactionDetail"].filter((currentRow) => {
        let isValid = false;

        if (
          currentRow["refAccName"] &&
          currentRow["refAccName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        return isValid;
      });
      if (subList.length != 0) {
        row = {
          ...row,
          ["transactionDetail"]: subList,
        };
        listData.push(row);
      }
    });
    return listData
  };
  const generateExcelMethod = () => {
    var columns = ["Perticuler", "Receipt", "Payment"];
    let alignList = [];

    let rows = []
    cashbook.map((reportData, index) => {
      let reportObj = [];
      reportObj.push(reportData.date)
      reportObj.push("")
      reportObj.push("")
      rows.push(reportObj)
      reportObj = ["Opening Amt"];
      reportObj.push(reportData.openingAmt<=0 ?Math.abs(reportData.openingAmt).toFixed(2):"")
      reportObj.push(reportData.openingAmt>0 ?Math.abs(reportData.openingAmt).toFixed(2):"")
      rows.push(reportObj)
      let creditTotal = 0
      let debitTotal = 0

      reportData.transactionDetail.map((data) => {
        if (data.type == "D") {
          debitTotal = debitTotal + data.amount
        } else {
          creditTotal = creditTotal + data.amount
        }

        let refAccName = data.type != "D" ? "To " + data.refAccName : "By " + data.refAccName
        let type = data.type != "D" ? "Amt Paid To " + data.refAccName : "Amt Received "

        let Receipt = data.type == "D" ? Math.abs(data.amount).toFixed(2) : ""
        let Payment = data.type == "D" ? "" : Math.abs(data.amount).toFixed(2)
        reportObj = [];
        reportObj.push(refAccName)
        reportObj.push(Receipt)
        reportObj.push(Payment)
        rows.push(reportObj)
        reportObj = [];
        reportObj.push(type)
        reportObj.push("")
        reportObj.push("")
        rows.push(reportObj)
      })
      reportObj = [];
      reportObj.push("")
      reportObj.push(debitTotal == 0 ? "" : Math.abs(debitTotal).toFixed(2))
      reportObj.push(creditTotal == 0 ? "" : Math.abs(creditTotal).toFixed(2))
      rows.push(reportObj)

      reportObj = [];
      reportObj.push("Day Closing")
      reportObj.push("")
      let closingAmt = reportData.closingAmt > 0 ? Math.abs(reportData.closingAmt).toFixed(2) + " Cr" : Math.abs(reportData.closingAmt).toFixed(2) + " Dr"
      reportObj.push(closingAmt)
      rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate, "Company Name", formData.name, "Account Name", formData.accName],
      title: BankbookJson.screenTitle,
    });

  };
  const onSave = (dataToSearch) => {
    let account = dataToSearch.accountLedgerGroupsId.split(",")
    let filteraccountLedgerGroupMaster = []
    dynamicMasterData["accountLedgerGroupMasterDetail"].map((row) => {

      account.map((accData) => {
        if (row.id === accData) {
          filteraccountLedgerGroupMaster.push(row.name)
        }
      })
    })
    let filterDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
    setFormData({
      ...dataToSearch,
      name: filterDta.length != 0 ? filterDta[0].name : "",
      accName: filteraccountLedgerGroupMaster.join(),
    })

    getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId, dataToSearch.accountLedgerGroupsId)
  }
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          fieldData={fieldData}
          showSaveBtn={BankbookJson.showSaveBtn}
          screenTitle={BankbookJson.screenTitle}
          fieldMeta={BankbookJson.fieldMeta}
          showCancel={BankbookJson.showCancel}
          apiBaseURL={BankbookJson.apiBaseURL}
          searchName="Reference Account Name"
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          showSaveNextBtn={BankbookJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
         <br />
        <CashbookTable tableHead={[
          {
            title: "Perticuler",

            align: "left"
          },
          {
            title: "Receipt",
            align: "right"
          },
          {
            title: "Payment",
            align: "right"
          }
        ]} rowList={getFilteredTableData(cashbook, [
          {
            name: "date",
          },
          {
            name: "amount",
          },
          {
            name: "refAccName",
          }
        ])}  getList={getList}
        formData={formData}/>

      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getAccountLedgerListByType, showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Bankbook);
