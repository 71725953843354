import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import endpoint from "../../config/endpoints";
import { deleteFailedMsg } from "../../config/messageconstant";
import { apiDelete } from "../../utils/api_service";

import swal from "sweetalert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BillMappingViewDialouge = ({
  open,
  getAuditQestionList,
  handleCloses,
  onSave,
  dataToSave,
  showLoador,
  showNotification,
  cashRecieptList,
  dynamicMasterData,
}) => {
  const [fieldData, setFieldData] = React.useState({});

  const [costMappingList, setCostMappingList] = React.useState([]);

  useEffect(() => {
    //
    async () => {};
    console.log(dataToSave);
    if (
      dataToSave &&
      dataToSave.recieptPaymentDetailBillInfoList.length !== 0 &&
      costMappingList.length == 0
    ) {
      console.log(dataToSave.recieptPaymentDetailBillInfoList);
      let rowList = dataToSave.recieptPaymentDetailBillInfoList.map(
        (rowData, index) => {
          // let catList = dynamicMasterData["billDetail"]
          //   ? dynamicMasterData["billDetail"].filter(
          //       (row) => row.id == rowData.ccId
          //     )
          //   : [];
          // console.log(catList);
          return {
            index: index + 1,
            billName: rowData.billNo,
            billId: rowData.id,
            amount: +rowData.amount,
            type: rowData.type,
          };
        }
      );
      setCostMappingList(rowList);
    }
  }, []);
  const handleClose = () => {
    handleCloses();
  };

  let columns = [
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "billName",
      title: "Bill Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "amount",
      title: "Amount",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  const rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (rowData.id) {
          onDelete(rowData);
        }
        const filteredList = costMappingList.filter(
          (row) => row.index !== rowData.index
        );
        setCostMappingList(
          filteredList.map((costMappingData, index) => {
            return { ...costMappingData, index: index + 1 };
          })
        );
      }
    });
  };

  const onDelete = (row) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.auditOption + "/" + row.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          getAuditQestionList();
        } else {
          showNotification({ msg: deleteFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const saveHandler = () => {
    onSave(costMappingList, dataToSave.index);
    setFieldData({});
  };
  const rowEdit = (rowData) => {
    console.log(rowData);
    const dataToSend = {
      ...rowData,
      amt: rowData.amt,
      costCenterMaster: rowData.ccId,
    };

    setFieldData(dataToSend);
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xl"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Bill Mapping
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid item xs={12} sm={12} md={12}>
            <DynamicTable
              data={costMappingList}
              tableHead={columns}
              showHeadDelete={true}
              showHeadEdit={false}
              rowEdit={false}
              showPegination={false}
              rowDelete={rowDelete}
              isActionColActive={false}
            />
          </Grid>
          <br />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  feedBack: state.feedBack,
  cashRecieptList: state.cashReciept,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  // getCostCenter
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillMappingViewDialouge);
