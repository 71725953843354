import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { profitAndLossJson } from "../../DynamicFormsJson/Report/ProfitAndLoass";
import RTTable from "../../components/Comman/RT/RTTable";
import MFMPALTable from "./MFM&PALTable";

import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GroupSummarySubReportByGroup from "./GroupSummarySubReportByGroup";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { LabelCompo } from "../../components/Comman/Label";
import { cyanBlue } from "../../config/ColorObj";

const profitAndLoss = ({ showLoador, showNotification }) => {
    const [searchValue, setSearchValue] = useState("");
    const [dynamicMasterData, setDynamicMasterData] = useState({});
    const [formData, setFormData] = React.useState({});
    const [cashList, setCashbookList] = React.useState([]);
    const [cashList1, setCashbookList1] = React.useState([]);
    const [popUpData, setPopupdata] = useState({})
    const [openTable, setOpenTable] = useState(false);
    const [fieldData, setFieldData] = React.useState({});
    useEffect(() => {

        getDropDownList();
    }, []);
    const toggle = () => {
        setOpenTable(!openTable);

    };
    const getDropDownList = async () => {
        showLoador({ loador: true });
        const yearMasterDetail = await apiGet({
            url: endpoints.year,
        }).then(({ data, success }) => {
            if (success) {
                console.log(data);
                let rowData = data.data;

                showLoador({ loador: false });
                return rowData.map((rowData) => {
                    return { ...rowData, name: rowData.yearName };
                })
            } else {

                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }

            return []
        });
        showLoador({ loador: true });
        let CompanyDetails = await apiGet({
            url: endpoints.company,
        }).then(({ data, success }) => {
            if (success) {
                console.log(data.data);
                let rowData = data.data;
                if (rowData.length == 1) {
                    setFieldData({ companyId: rowData[0].id })
                }
                showLoador({ loador: false });
                return rowData
            } else {

                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }
            return []
        });
        setDynamicMasterData({
            yearMasterDetail: yearMasterDetail,
            CompanyDetails: CompanyDetails
        });
    }
    const getList = (companyId, yearId, type) => {
        showLoador({ loador: true });
        apiGet({
            url: endpoints.MFGAndPf + "?companyIds=" + companyId + "&yearId=" + yearId + "&type=" + type
            //    "?companyId=" + companyId + "&fromDate=" + "fromDate" + "&toDate=" + toDate + "&groupIds=" + accId

        }).then(({ data, success }) => {
            if (success) {

                let leftAmount = 0;
                let rightAmount = 0;

                let RightAlign = Object.keys(data.data).length != 0 && data.data.profitAndLoss != null && data.data.profitAndLoss.dynamicData.length != 0 ? data.data.profitAndLoss.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R") : [];
                let LeftAlign = Object.keys(data.data).length != 0 && data.data.profitAndLoss != null && data.data.profitAndLoss.dynamicData.length != 0 ? data.data.profitAndLoss.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R") : [];

                let LeftAlignData = []
                LeftAlignData = LeftAlign.map((left, index) => {
                    let amount = left.creditAmt + left.debitAmt
                    leftAmount = leftAmount + amount
                    return {
                        groupId: left.groupDetail != null ? left.groupDetail.id : "",
                        groupName: left.groupDetail != null ? left.groupDetail.group_name : "",
                        amount: amount > 0 ? "( " + Math.abs(amount).toFixed(2) + " )" : Math.abs(amount).toFixed(2),
                        // amount: Math.abs(amount).toFixed(2),
                    }
                })
                let RightAlignData = []
                RightAlignData = RightAlign.map((right, index) => {
                    let amount = right.creditAmt + right.debitAmt
                    rightAmount = rightAmount + amount
                    return {
                        groupId: right.groupDetail != null ? right.groupDetail.id : "",
                        groupName: right.groupDetail != null ? right.groupDetail.group_name : "",
                        // amount: Math.abs(amount).toFixed(2),
                        amount: amount < 0 ? "( " + Math.abs(amount).toFixed(2) + " )" : Math.abs(amount).toFixed(2),
                    }
                })
                let rowListProfit = []
                let RightAlignProfitStatic = Object.keys(data.data).length != 0 && data.data.profitAndLoss != null && data.data.profitAndLoss.staticData.length != 0 ? data.data.profitAndLoss.staticData.filter((rowData) => rowData.alligment == "R") : [];
                let LeftAlignProfitStatic = Object.keys(data.data).length != 0 && data.data.profitAndLoss != null && data.data.profitAndLoss.staticData.length != 0 ? data.data.profitAndLoss.staticData.filter((rowData) => rowData.alligment != "R") : [];

                rowListProfit = RightAlignProfitStatic.map((right, index) => {
                    rightAmount = rightAmount + right.amt
                    RightAlignData.push({
                        groupName: right.name != null ? right.name : "",
                        // amount: Math.abs(right.amt).toFixed(2),
                        amount: right.amt < 0 ? "( " + Math.abs(right.amt).toFixed(2) + " )" : Math.abs(right.amt).toFixed(2),
                        groupNameLink: true,
                        groupNameRLink: true,
                    })
                })

                rowListProfit = LeftAlignProfitStatic.map((right, index) => {
                    leftAmount = leftAmount + right.amt
                    LeftAlignData.push({
                        groupName: right.name != null ? right.name : "",
                        // amount: Math.abs(right.amt).toFixed(2),
                        amount: right.amt > 0 ? "( " + Math.abs(right.amt).toFixed(2) + " )" : Math.abs(right.amt).toFixed(2),
                        groupNameLink: true,
                        groupNameRLink: true,
                    })
                })
                showLoador({ loador: false });
                setCashbookList([...LeftAlignData, {
                    groupName: "Total",
                    amount: Math.abs(leftAmount).toFixed(2),
                    groupNameRLink: false,
                    groupNameLink: false
                }])
                setCashbookList1([...RightAlignData, {
                    groupName: "Total",
                    amount: Math.abs(rightAmount).toFixed(2),
                    groupNameRLink: false,
                    groupNameLink: false
                }])
            } else {
                setCashbookList([])
                setCashbookList1([])
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }

        });
    }

    const onSave = (dataToSearch) => {

        let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)

        let yearList = dynamicMasterData["yearMasterDetail"].filter((row) =>
            row.id === dataToSearch.yearId)




        setFormData({
            ...dataToSearch,
            name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
            yearName: yearList[0].yearName,
            fromDate: yearList[0].fromDate,
            toDate: yearList[0].toDate,
        })
        getList(dataToSearch.companyId, dataToSearch.yearId, "B")
        //   getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId,dataToSearch.accountLedgerGroupsId )
    }
    const onSearch = (searchV) => {
        setSearchValue(searchV)
    };

    const generatePDFMethod = () => {

        var columns = [["Group Name", "Amount", "Group Name", "Amount"]];

        let tableList = []
        cashList.map(((rowData, index) => {

            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            tableData.push(rowData.groupNameR)

            tableData.push(rowData.amountL)
            tableList.push(tableData)
        }))

        const columnStyles = {
            0: { cellWidth: 150 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        const columnStylesPlain = {
            0: { cellWidth: 80 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        console.log(formData.accName)
        generatePDF({
            columnStylesPlain: columnStylesPlain,
            rows: tableList,
            columns,
            headerList: [
                {
                    label: "Company Name",
                    value: formData.name
                },
                {
                    label: "Year Name",
                    value: formData.yearName
                },
            ],
            pdfTitle: profitAndLossJson.screenTitle,
        });

    };

    const generateExcelMethod = () => {
        var columns = ["Group Name", "Amount", "Group Name", "Amount"];
        let alignList = [];

        let rows = []
        cashList.map((rowData, index) => {
            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            tableData.push(rowData.groupNameR)

            tableData.push(rowData.amountL)
            rows.push(tableData)
        });
        generateExcel({
            columns,
            rows,
            alignList,
            headerList: ["Company Name", formData.name, "Year Name", formData.yearName],
            title: profitAndLossJson.screenTitle,
        });

    };
    const getFilteredTableData = (tableData) => {

        let listData = tableData.filter((row) => {

            let isValid = false;

            if (row.groupName.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }


            if (row.amount.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }

            return isValid;

        });
        return listData
    };
    const rowLink = (rowData) => {
        setPopupdata(rowData)
        setOpenTable(!openTable);
    }
    return (
        <>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

                <DynamicFormWithReport
                    dynamicMasterData={dynamicMasterData}
                    showBackToList={false}
                    DataToSet={[]}
                    showSaveBtn={profitAndLossJson.showSaveBtn}
                    screenTitle={profitAndLossJson.screenTitle}
                    fieldMeta={profitAndLossJson.fieldMeta}
                    showCancel={profitAndLossJson.showCancel}
                    apiBaseURL={profitAndLossJson.apiBaseURL}
                    showSaveNextBtn={profitAndLossJson.showSaveNextBtn}
                    showSaveBtnMain={true}
                    padding={false}
                    showSearchBox={false}
                    fieldData={fieldData}
                    generatePDF={generatePDFMethod}
                    generateExcel={generateExcelMethod}
                    onSearch={onSearch}
                    saveBtnText={"Search"}
                    paddingTop={false}
                    onSave={onSave}
                    showExcelDownload={true}
                />

                <MFMPALTable
                    showPegination={false}

                    hiddenColumnNames={[]}
                    rowList={[
                        {
                            name: "Sources of Funds",
                            groupList: getFilteredTableData(cashList)
                        },
                        {
                            name: "Application of Funds",
                            groupList: getFilteredTableData(cashList1)
                        }

                    ]}
                    rowLink={rowLink}
                    isActionColActive={false}
                // rowCopy={this.rowCopy}
                />
            </Paper>
            {openTable && (
                <GroupSummarySubReportByGroup


                    open={openTable}
                    handleCloses={toggle}
                    popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                    dynamicMasterData={formData}

                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(profitAndLoss);