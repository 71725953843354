import { Paper,Grid } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { balanceSheetJson } from "../../DynamicFormsJson/Report/balanceSheet";
import RTTable from "../../components/Comman/RT/RTTable";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor } from "../../config/ColorObj";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GroupSummarySubReportByGroup from "./GroupSummarySubReportByGroup";
import MFGAndPL from "./MFGAndPL";
import MFMPALTable from "./MFM&PALTable";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import MonthlyBarChart from "../../components/MonthlyBarChart";
import BalanceSheetVerticalTable from "../AccountReport/BalanceSheetVerticalTable"
const BalanceSheet = ({ showLoador, showNotification }) => {
    const [searchValue, setSearchValue] = useState("");
    const [dynamicMasterData, setDynamicMasterData] = useState({});
    const [formData, setFormData] = React.useState({});
    const [cashList, setCashbookList] = React.useState([]);
    const [cashList1, setCashbookList1] = React.useState([]);
    const [popUpData, setPopupdata] = useState({})
    const [openTable, setOpenTable] = useState(false);
    const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
    const [fieldData, setFieldData] = React.useState({});
    const[searchFlag,setSearchFlag]=useState(false);
    const[detailList,setDetailList]=React.useState([]);
    const[detailList1,setDetailList1]=React.useState([]);
    const [MFGList, setMFGList] = React.useState({});
    useEffect(() => {
        getDropDownList()
    }, []);

    const getDropDownList = async () => {
        showLoador({ loador: true });
       
        showLoador({ loador: true });
        let CompanyDetails = await apiGet({
            url: endpoints.company,
        }).then(({ data, success }) => {
            if (success) {
                showLoador({ loador: false });
                let rowData = data.data;
                const location=localStorage.getItem("location");
                const locationList =localStorage.getItem("locationIdList");
               const locallist=JSON.parse(locationList)
               const locationlist=locallist.filter((data)=>data.id==location)
              
                   if(locationlist.length!=0 && locationlist[0]!=null && locationlist[0].company!=null){
                     
                     setFieldData({ companyId: locationlist[0].company.id })
                   }
                return rowData
            } else {
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }
            return []
        });
        setDynamicMasterData({
            
            CompanyDetails: CompanyDetails
        });
    }
    const getList = (companyId, fromDate,toDate) => {
        setSearchFlag(false);
        showLoador({ loador: true });
        apiGet({
            url: endpoints.balanceSheet + "-between-date?companyIds=" + companyId + "&fromDate=" + fromDate + "&toDate=" + toDate
        }).then(({ data, success }) => {
            if (success) {
               
                let leftAmount = 0;
                let rightAmount = 0;

                let RightAlign = Object.keys(data.data).length != 0 &&data.data.dynamicData.length != 0 ? data.data.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R") : [];
                let LeftAlign = Object.keys(data.data).length != 0 &&data.data.dynamicData.length != 0 ? data.data.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R") : [];

                let LeftAlignData = []
                LeftAlignData = LeftAlign.map((left, index) => {
                    let amount = left.creditAmt + left.debitAmt
                    leftAmount = leftAmount + amount
                    return {
                        groupId: left.groupDetail != null ? left.groupDetail.id : "",
                        groupName: left.groupDetail != null ? left.groupDetail.group_name : "",
                        amount: amount < 0 ? "( " + Math.abs(amount).toFixed(2) + " )" : Math.abs(amount).toFixed(2),
                        // amount: Math.abs(amount).toFixed(2),
                    }
                })
                let RightAlignData = []
                RightAlignData = RightAlign.map((right, index) => {
                    let amount = right.creditAmt + right.debitAmt
                    rightAmount = rightAmount + amount
                    return {
                        groupId: right.groupDetail != null ? right.groupDetail.id : "",
                        groupName: right.groupDetail != null ? right.groupDetail.group_name : "",
                        // amount: Math.abs(amount).toFixed(2),
                        amount: amount > 0 ? "( " + Math.abs(amount).toFixed(2) + " )" : Math.abs(amount).toFixed(2),
                    }
                })
                let rowListProfit = []
                let RightAlignProfitStatic = Object.keys(data.data).length != 0 && data.data.staticData.length != 0 ? data.data.staticData.filter((rowData) => rowData.alligment == "R") : [];
                let LeftAlignProfitStatic = Object.keys(data.data).length != 0 && data.data.staticData.length != 0 ? data.data.staticData.filter((rowData) => rowData.alligment != "R") : [];

                rowListProfit = RightAlignProfitStatic.map((right, index) => {
                    rightAmount = rightAmount + right.amt
                    RightAlignData.push({
                        groupName: right.name != null ? right.name : "",
                        // amount: Math.abs(right.amt).toFixed(2),
                        amount: right.amt > 0 ? "( " + Math.abs(right.amt).toFixed(2) + " )" : Math.abs(right.amt).toFixed(2),
                        groupNameLink: true,
                        groupNameRLink: true,
                    })
                })

                rowListProfit = LeftAlignProfitStatic.map((right, index) => {
                    leftAmount = leftAmount + right.amt
                    LeftAlignData.push({
                        groupName: right.name != null ? right.name : "",
                        // amount: Math.abs(right.amt).toFixed(2),
                        amount: right.amt < 0 ? "( " + Math.abs(right.amt).toFixed(2) + " )" : Math.abs(right.amt).toFixed(2),
                        groupNameLink: true,
                        groupNameRLink: true,
                    })
                })
               
                showLoador({ loador: false });
                setCashbookList([...LeftAlignData, {
                    groupName: "Total",
                    amount: Math.abs(leftAmount).toFixed(2),
                    groupNameRLink: false,
                    groupNameLink: false
                }])
                setCashbookList1([...RightAlignData, {
                    groupName: "Total",
                    amount: Math.abs(rightAmount).toFixed(2),
                    groupNameRLink: false,
                    groupNameLink: false
                }])
            } else {
                setCashbookList([])
                setCashbookList1([])
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }

        });
    }

    const onSave = (dataToSearch) => {
        let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
        

        setFormData({
            ...dataToSearch,
            name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : ""
        })
        getList(dataToSearch.companyId, dataToSearch.fromDate, dataToSearch.toDate)
       
    }
    const onSearch = (searchV) => {
        setSearchValue(searchV)
    };

    const generatePDFMethod = () => {

        var columns = [["Group Name", "Amount",]];

        let tableList = []
        tableList.push(["Sources of Funds"])
        cashList.map(((rowData, index) => {

            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            tableList.push(tableData)
        }))
        tableList.push(["Application of Funds"])
        cashList1.map(((rowData, index) => {

            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            tableList.push(tableData)
        }))
       
       
        const columnStylesPlain = {
            0: { cellWidth: 80 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        generatePDF({
            columnStylesPlain: columnStylesPlain,
           
            rows: tableList,
            columns,
            headerList: [
               
                {
                    label: "From Date",
                    value: formData.fromDate
                }, {
                    label: "To Date",
                    value: formData.toDate
                },
                {
                    label: "Company Name",
                    value: formData.name
                }
            ],
            pdfTitle: "Balance Sheet (Vertical)",
        });

    };

    const generateExcelMethod = () => {
        var columns = ["Group Name", "Amount"];
        let alignList = [];

        let rows = []
        rows.push(["Sources of Funds"])

        cashList.map((rowData, index) => {
            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            rows.push(tableData)
        });
        rows.push(["Application of Funds"])
        cashList1.map((rowData, index) => {
            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            rows.push(tableData)
        });
        
        generateExcel({
            columns,
            rows,
            alignList,
            headerList: ["From Date", formData.fromDate, "To Date", formData.toDate,"Company Name", formData.name],
            title: "Balance Sheet(Vertical)",
        });

    };
    const getFilteredTableData = (tableData) => {

        let listData = tableData.filter((row) => {

            let isValid = false;

            if (row.groupName.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }

          
            if (row.amount.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }
            if(row.result && row.result.length!=0){
                row.result.filter((data)=>{
                    let creditTotal = 0;
                    let creditAmt = 0;
                      let debitAmt = 0;
                    creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                    debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                    creditTotal = creditTotal + creditAmt + debitAmt
                    if (data.name.toString()
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                ) {
                    isValid = true;
                }
                if (creditTotal.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }
            return isValid;
                })
               
            }
            

            
            return isValid;

        });
        return listData
    };
    const rowLink = (rowData, key) => {
        if (key == "groupNameR") {
            if (rowData.groupIdR) {
                setPopupdata({ groupId: rowData.groupIdR, groupName: rowData.groupNameR })
                setOpenTable(!openTable);
            }
            else {
                toggleMFGAndProfitAndLoss()
            }


        } else {
            if (rowData.groupId) {
                setPopupdata({ groupId: rowData.groupId, groupName: rowData.groupName })
                setOpenTable(!openTable);
            }
            else {
                toggleMFGAndProfitAndLoss()
            }

        }

    }
    const toggle = () => {
        setOpenTable(!openTable);
        if(formData!=null && Object.keys(formData).length!=0){
     
            getList(formData.companyId, formData.fromDate, formData.toDate)
        }
    };
    const toggleMFGAndProfitAndLoss = () => {
        setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
        if(formData!=null && Object.keys(formData).length!=0){
     
            getList(formData.companyId, formData.fromDate, formData.toDate)
        }
    };

    const showDetail = (dataToSearch) => {
        setSearchFlag(true)
        let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
        

        setFormData({
            ...dataToSearch,
            name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
           
        })
        getDeatilList(dataToSearch.companyId, dataToSearch.fromDate, dataToSearch.toDate)
    }


    const generatePDFExcelData = () => {
        let tableList = []
        MFGList.DynamicData && MFGList.DynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R").map((rowData) => {
           let leftTotal=0;
            leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt
            let tableData = []
            tableData.push(rowData.groupDetail.group_name)
            tableData.push(Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2))
        })
        MFGList.staticData && MFGList.staticData.filter((rowData) => rowData.alligment != "R").map((rowData) => {
            let leftTotal=0;
            leftTotal = leftTotal + rowData.amt
            let tableData = []
            tableData.push(rowData.name)
            tableData.push(Math.abs(rowData.amt).toFixed(2))
        })
        MFGList.DynamicData && MFGList.DynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R").map((rowData) => {
            let leftTotal=0;
            leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt
            let tableData = []
            tableData.push(rowData.groupDetail.group_name)
            tableData.push(Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2))
        })
        MFGList.staticData && MFGList.staticData.filter((rowData) => rowData.alligment == "R").map((rowData) => {
            let leftTotal=0;
            leftTotal = leftTotal + rowData.amt
            let tableData = []
            tableData.push(rowData.name)
            tableData.push(Math.abs(rowData.amt).toFixed(2))
        })

    }
    const getDeatilList=(companyId,fromDate, toDate)=>{

        showLoador({ loador: true });
        apiGet({
            url: endpoints.cashFlowSummary+"/balance-sheet-detail?companyIds="+ companyId + "&fromDate=" + fromDate + "&toDate=" + toDate,
           
        }).then(({ data, success }) => {
            if (success) {
                let leftAmount = 0;
                let rightAmount = 0;

                let RightAlign = Object.keys(data.data).length != 0 &&data.data.dynamicData.length != 0 ? data.data.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R") : [];
                let LeftAlign = Object.keys(data.data).length != 0 &&data.data.dynamicData.length != 0 ? data.data.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R") : [];

                let LeftAlignData = []
                LeftAlignData = LeftAlign.map((left, index) => {
                    let amount = left.creditAmt + left.debitAmt
                    leftAmount = leftAmount + amount
                    return {
                        groupId: left.groupDetail != null ? left.groupDetail.id : "",
                        groupName: left.groupDetail != null ? left.groupDetail.group_name : "",
                        amount: amount < 0 ? "( " + Math.abs(amount).toFixed(2) + " )" : Math.abs(amount).toFixed(2),
                       result: left.groupDetail != null && left.groupDetail.result && left.groupDetail.result.length!=0?left.groupDetail.result:[]
                    }
                })
                let RightAlignData = []
                RightAlignData = RightAlign.map((right, index) => {
                    let amount = right.creditAmt + right.debitAmt
                    rightAmount = rightAmount + amount
                    return {
                        groupId: right.groupDetail != null ? right.groupDetail.id : "",
                        groupName: right.groupDetail != null ? right.groupDetail.group_name : "",
                        // amount: Math.abs(amount).toFixed(2),
                        amount: amount > 0 ? "( " + Math.abs(amount).toFixed(2) + " )" : Math.abs(amount).toFixed(2),
                        result:right.groupDetail != null && right.groupDetail.result && right.groupDetail.result.length!=0?right.groupDetail.result:[]
                    }
                })
                let rowListProfit = []
                let RightAlignProfitStatic = Object.keys(data.data).length != 0 && data.data.staticData.length != 0 ? data.data.staticData.filter((rowData) => rowData.alligment == "R") : [];
                let LeftAlignProfitStatic = Object.keys(data.data).length != 0 && data.data.staticData.length != 0 ? data.data.staticData.filter((rowData) => rowData.alligment != "R") : [];

                rowListProfit = RightAlignProfitStatic.map((right, index) => {
                    rightAmount = rightAmount + right.amt
                    RightAlignData.push({
                        groupName: right.name != null ? right.name : "",
                        // amount: Math.abs(right.amt).toFixed(2),
                        amount: right.amt > 0 ? "( " + Math.abs(right.amt).toFixed(2) + " )" : Math.abs(right.amt).toFixed(2),
                        manufactureAndProfit:right.manufactureAndProfit && right.manufactureAndProfit!=null ?right.manufactureAndProfit:"",
                        groupNameLink: true,
                        groupNameRLink: true,
                    })
                })

                rowListProfit = LeftAlignProfitStatic.map((right, index) => {
                    leftAmount = leftAmount + right.amt
                    LeftAlignData.push({
                        groupName: right.name != null ? right.name : "",
                        // amount: Math.abs(right.amt).toFixed(2),
                        amount: right.amt < 0 ? "( " + Math.abs(right.amt).toFixed(2) + " )" : Math.abs(right.amt).toFixed(2),
                        manufactureAndProfit:right.manufactureAndProfit && right.manufactureAndProfit!=null ?right.manufactureAndProfit:"",

                        groupNameLink: true,
                        groupNameRLink: true,
                    })
                })
              
              
                showLoador({ loador: false });
                setDetailList([...LeftAlignData, {
                    groupName: "Total",
                    amount: Math.abs(leftAmount).toFixed(2),
                    groupNameRLink: false,
                    groupNameLink: false
                }])
                setDetailList1([...RightAlignData, {
                    groupName: "Total",
                    amount: Math.abs(rightAmount).toFixed(2),
                    groupNameRLink: false,
                    groupNameLink: false
                }])
                let DynamicData = Object.keys(data.data).length != 0 && data.data.dynamicData.length != 0 ? data.data.dynamicData : [];
                let staticData = Object.keys(data.data).length != 0 && data.data.staticData.length != 0 ? data.data.staticData : [];
                setMFGList({ DynamicData: DynamicData, staticData: staticData })
              
            } else {
                setDetailList([])
                setDetailList1([])
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }
        
        });
            }

            const generateDetailExcelMethod = () => {
                var columns = ["Group Name", "Amount"];
                let alignList = [];
        
                let rows = []
                rows.push(["Sources of Funds"])
        
               detailList.map((rowData, index) => {
                    let tableData = []
        
                    tableData.push(rowData.groupName)
        
                    tableData.push(rowData.amount)
                    // tableData.push(rowData.groupNameR)
        
                    // tableData.push(rowData.amountL)
                    rows.push(tableData)
                    if(rowData.result && rowData.result.length!=0){
                        rowData.result.map((data)=>{
                        let tableData = []
                        let creditTotal = 0;
                        let creditAmt = 0;
                        let debitAmt = 0;
                        creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                        debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                        creditTotal = creditTotal + creditAmt + debitAmt
                        tableData.push(data.name)
            
                        tableData.push(Math.abs(creditTotal).toFixed(2))
                        // tableData.push(rowData.groupNameR)
            
                        // tableData.push(rowData.amountL)
                        rows.push(tableData) 
                        }) 
                    }
                });
                rows.push(["Application of Funds"])
               detailList1.map((rowData, index) => {
                    let tableData = []
        
                    tableData.push(rowData.groupName)
        
                    tableData.push(rowData.amount)
                    // tableData.push(rowData.groupNameR)
        
                    // tableData.push(rowData.amountL)
                    rows.push(tableData)
                    if(rowData.result && rowData.result.length!=0){
                        rowData.result.map((data)=>{
                        let tableData = []
                        let creditTotal = 0;
                        let creditAmt = 0;
                        let debitAmt = 0;
                        creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                        debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                        creditTotal = creditTotal + creditAmt + debitAmt
                        tableData.push(data.name)
            
                        tableData.push(Math.abs(creditTotal).toFixed(2))
                        // tableData.push(rowData.groupNameR)
            
                        // tableData.push(rowData.amountL)
                        rows.push(tableData) 
                        }) 
                    }
                })
                generateExcel({
                    columns,
                    rows,
                    alignList,
                    headerList: ["From Date", formData.fromDate, "To Date", formData.toDate,"Company Name", formData.name],
                     title: "Balance Sheet(Vertical)",
                });
        
            };


            const generateDetailPDFMethod = () => {
                var columns = [["Group Name", "Amount",]];

        let tableList = []
        tableList.push(["Sources of Funds"])
        detailList.map(((rowData, index) => {

            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            tableList.push(tableData)
            if(rowData.result && rowData.result.length!=0){
                rowData.result.map((data)=>{
                let tableData = []
                let creditTotal = 0;
                let creditAmt = 0;
                let debitAmt = 0;
                creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                creditTotal = creditTotal + creditAmt + debitAmt
                tableData.push(data.name)
    
                tableData.push(Math.abs(creditTotal).toFixed(2))
                // tableData.push(rowData.groupNameR)
    
                // tableData.push(rowData.amountL)
                tableList.push(tableData) 
                }) 
            }
        }))
        tableList.push(["Application of Funds"])
        detailList1.map(((rowData, index) => {

            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            tableList.push(tableData)
            if(rowData.result && rowData.result.length!=0){
                rowData.result.map((data)=>{

               
                let tableData = []
                let creditTotal = 0;
                let creditAmt = 0;
                let debitAmt = 0;
                creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                creditTotal = creditTotal + creditAmt + debitAmt
                tableData.push(data.name)
    
                tableData.push(Math.abs(creditTotal).toFixed(2))
                // tableData.push(rowData.groupNameR)
    
                // tableData.push(rowData.amountL)
                tableList.push(tableData) 
            }) 
            }
        }))
       
               
                   
                        
                    
             
        
                const columnStyles = {
                    0: { cellWidth: 150 },
                    1: { cellWidth: 150 },
                    2: { cellWidth: 90 },
                };
                const columnStylesPlain = {
                    0: { cellWidth: 80 },
                    1: { cellWidth: 150 },
                    2: { cellWidth: 90 },
                };
                generatePDF({
                    columnStylesPlain: columnStylesPlain,
                    rows: tableList,
                    columns,
                    headerList: [
               
                        {
                            label: "From Date",
                            value: formData.fromDate
                        }, {
                            label: "To Date",
                            value: formData.toDate
                        },
                        {
                            label: "Company Name",
                            value: formData.name
                        }
                    ],
                    pdfTitle: "Balance Sheet(Vertical)",
                });
        
            };
    return (
        <>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

                <DynamicFormWithReport
                    dynamicMasterData={dynamicMasterData}
                    showBackToList={false}
                    DataToSet={[]}
                    fieldData={fieldData}
                    showSaveBtn={balanceSheetJson.showSaveBtn}
                    screenTitle={"Balance Sheet(Vertical)"}
                    fieldMeta={balanceSheetJson.fieldMeta}
                    showCancel={balanceSheetJson.showCancel}
                    apiBaseURL={balanceSheetJson.apiBaseURL}
                    showSaveNextBtn={balanceSheetJson.showSaveNextBtn}
                    showSaveBtnMain={true}
                    padding={false}
                    generatePDF={searchFlag?generateDetailPDFMethod:generatePDFMethod}
                    generateExcel={searchFlag?generateDetailExcelMethod:generateExcelMethod}
                    onSearch={onSearch}
                    saveBtnText={"Search"}
                    paddingTop={false}
                    onSave={onSave}
                    showExcelDownload={true}
                    detailButton={false}
                    onSaveDetail={showDetail}
                />
                <br />
                {
                    formData.fromDate && formData.toDate &&
                    <DynamicDetails DataToSet={[
                        {
                            xs: 6,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                            label: "Period",
                            value:formData.fromDate?formData.fromDate+" To "+formData.toDate:"-",
                        }]} />
                }
                {searchFlag?( 
                    <>
              <BalanceSheetVerticalTable 
        showPegination={false}

        hiddenColumnNames={[]}
        rowList={[
            {
                name: detailList.length!=0?"Sources of Funds":"",
                groupList: getFilteredTableData(detailList),
            },
            {
                name: detailList1.length!=0?"Application of Funds":"",
                groupList: getFilteredTableData(detailList1)
            }
          

        ]}
        rowLink={rowLink}
        isActionColActive={false}
        formData={formData}
        getDeatilList={getDeatilList}/>

                </>
                ):( <MFMPALTable
                    showPegination={false}

                    hiddenColumnNames={[]}
                    rowList={[
                        {
                            name: cashList.length!=0?"Sources of Funds":"",
                            groupList: getFilteredTableData(cashList)
                        },
                        {
                            name: cashList1.length!=0?"Application of Funds":"",
                            groupList: getFilteredTableData(cashList1)
                        }

                    ]}
                    rowLink={rowLink}
                    isActionColActive={false}
                // rowCopy={this.rowCopy}
                />)}
              

{/* <MFMPALTable
                    showPegination={false}

                    hiddenColumnNames={[]}
                    rowList={[
                        {
                            name: cashList.length!=0?"Sources of Funds":"",
                            groupList: getFilteredTableData(cashList)
                        },
                        {
                            name: cashList1.length!=0?"Application of Funds":"",
                            groupList: getFilteredTableData(cashList1)
                        }

                    ]}
                    rowLink={rowLink}
                    isActionColActive={false}
                // rowCopy={this.rowCopy}
                /> */}

                {openTable && (
                    <GroupSummarySubReportByGroup


                        open={openTable}
                        handleCloses={toggle}
                        popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                        dynamicMasterData={formData}

                    />
                )}
                {openMFGAndProfitAndLoss && (
                    <MFGAndPL
                    rowLink={rowLink}
                        open={openMFGAndProfitAndLoss}
                        handleCloses={toggleMFGAndProfitAndLoss}
                        popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                        formData={formData}

                    />   
                )}
            </Paper>
            <Grid sx={{ paddingTop: 2 }} container spacing={1} justifyContent="center">
                    <Grid container xs={12} sm={10} md={10} lg={10} >
                        <>
                    {
                cashList.length != 0 && <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6} >
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>
                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Sources of Funds'}
                                    />
                                </div>

                                <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={200}
                      series={[
                        {
                            name:"Amount",
                            data: cashList.filter((row)=>row.groupName!="Total" && row.amount!="" ).map((rowData) => {
                                return rowData.amount
                            }),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                            //   onClickLineChart(config.dataPointIndex);
                            },
                          },
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "40%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                            categories: cashList.filter((row)=>row.groupName!="Total" && row.groupName!="" ).map((rowData,index) => {
                                index=index+1;
                               
                                return rowData.groupName
                                
                            }),
                        },
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />
                                {/* <BarChart
                                    series={[{
                                        data: cashList.map((rowData) => {
                                            return rowData.amount
                                        }),
                                    }, {
                                        data: cashList.map((rowData) => {
                                            return rowData.amountL
                                        }),
                                    }]}
                                    options={
                                        {
                                            chart: {
                                                type: 'bar',
                                                height: 350,
                                                toolbar: {
                                                    show: false,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },

                                            xaxis: {
                                                categories: cashList.map((rowData) => {
                                                    return rowData.groupNameR
                                                }),
                                            },
                                            grid: {
                                                show: false,
                                            },


                                        }
                                    }

                                /> */}
                            </Paper>
                        </Grid>}
                        </>
                        {
                cashList1.length != 0 &&  <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>

                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Application of Funds'}
                                    />
                                </div>

                               
                    {/* Sales & Manpower Table 2 starts */}
                    <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={200}
                      series={[
                        {
                            name:"Amount",
                            data: cashList1.filter((row)=>row.groupName!="Total" && row.amount!="" ).map((rowData) => {
                                return rowData.amount
                            }),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                            //   onClickLineChart(config.dataPointIndex);
                            },
                          },
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "40%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                            categories: cashList1.filter((row)=>row.groupName!="Total" && row.groupName!="" ).map((rowData,index) => {
                                index=index+1;
                             
                                return rowData.groupName
                               
                                   
                                
                                
                            }),
                        },
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />

                    {/* Sales & Manpower Table 2 end */}
                 


                                {/* <BarChart
                                    series={[{
                                        data: cashList.map((rowData) => {
                                            return rowData.amount
                                        }),
                                    }, {
                                        data: cashList.map((rowData) => {
                                            return rowData.amountL
                                        }),
                                    }]}
                                    options={
                                        {
                                            chart: {
                                                type: 'bar',
                                                height: 350,
                                                toolbar: {
                                                    show: false,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },
                                            stroke: {
                                                show: false,
                                                width: 2,
                                                colors: ['transparent']
                                            },
                                            xaxis: {
                                                categories: cashList.map((rowData) => {
                                                    return rowData.groupName
                                                }),
                                            },
                                            fill: {
                                                opacity: 1
                                            },
                                        }
                                    }
                                /> */}
                            </Paper>
                        </Grid>
                         }
                    </Grid>
                </Grid>
           
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BalanceSheet);
