import { useState, useEffect } from "react";
import RTTable from "../../components/Comman/RT/RTTable";
import { apiGet } from "../../utils/api_service";
import { serverMsg, urlMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { AccountSummaryJson } from "../../DynamicFormsJson/Report/AccountSummary";
import { Paper } from "@mui/material";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicDetails";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import {
  Grid
} from "@mui/material";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor, grayColor } from "../../config/ColorObj";
import BarChart from '../../components/Charts/BarChart';
import {
  getCashRecieptWithAccNameById,
  getCostCenter,
} from "../../Slice/cashReceipt.slice";
import CashReciptPopupForReport from '../CashReceipt/CashReciptPopupForReport'
import BankReciptPopupForReport from '../BankReciept/BankReciptPopupForReport'
import CashPaymentPopupForReport from '../CashPayment/CashPaymentPopupForReport'
import BankPaymentPopupForReport from "../BankPayment/BankPaymentPopupForReport"
import JouranlEntryPopupForReport from "../JournalEntry/JournalEntryPopupForReport"
import ContraEntryPopupForReport from "../ContraEntry/ContraEntryPopupForReport";
import DebiteNotePopupForReport from "../DebitNote/DebiteNotePopupForReport";
import CreditNotePopupForReport from "../CreditNote/CrediteNotePopupForReport";
import { getJVWithAccNameById } from "../../Slice/journalVoucher.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const GroupSummarySubreportByAcc = ({
  handleCloses,
  openPopup,
  popUpData,
  dynamicMasterData,
  accPopupata,
  showLoador,
  showNotification,
  getCashRecieptWithAccNameById,
  getJVWithAccNameById
  
}) => {
  const [accInfo, setAccInfo] = useState({});

  const [rowList, setRowList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [dataToSet, setDataToSet] = useState([
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "Company Name",
      value: dynamicMasterData.name,
    }, {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "Group",
      value: popUpData.groupName,
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "Leger Name",
      value: accPopupata.name,
    },

    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "From Date",
      value: dynamicMasterData.fromDate+" To "+ dynamicMasterData.toDate,
    }
  ]);
  const [openCashReceiptPopup, setOpenCashReceiptPopup] = useState(false);
  const [openBankReceiptPopup, setOpenBankReceiptPopup] = useState(false);
  const [openCashPaymentPopup, setOpenCashPaymentPopup] = useState(false);
  const [openBankPaymentPopup, setOpenBankPaymentPopup] = useState(false);
  const [openJouranlEntryPopup, setOpenJouranlEntryPopup] = useState(false);
  const [openContraEntryPopup, setOpenContraEntryPopup] = useState(false);
  const [openDebiteNotePopup, setOpenDebiteNotePopup] = useState(false);
  const [openCreditNotePopup, setOpenCreditNotePopup] = useState(false);
  const [editData, setEditData] = useState({});
  const [journalEditData, setJournalEditData] = useState({});

  const togglePopup = () => {
  
    setOpenCashReceiptPopup(!openCashReceiptPopup);
    getAccList();

  };
const toggleBankReceiptPopup = ()=>{

  setOpenBankReceiptPopup(!openBankReceiptPopup)
  getAccList();
}
const toggleCashPaymentPopup = () => {
  
  setOpenCashPaymentPopup(!openCashPaymentPopup);
  getAccList();

};
const toggleBankPaymentPopup = ()=>{
  setOpenBankPaymentPopup(!openBankPaymentPopup)
  getAccList();
}
const toggleJouranlEntryPopup = ()=>{
  setOpenJouranlEntryPopup(!openJouranlEntryPopup)
  getAccList();
}

const toggleContraEntryPopup = ()=>{
  setOpenContraEntryPopup(!openContraEntryPopup)
  getAccList();
}
const toggleDebiteNotePopup = ()=>{
  setOpenDebiteNotePopup(!openDebiteNotePopup)
}

const toggleCreditNotePopup = ()=>{
  setOpenCreditNotePopup(!openCreditNotePopup)
  getAccList();
}
  useEffect(() => {
    console.log(popUpData)

    getAccList();
  }, []);
  const getAccList = ()=>{
     showLoador({ loador: true });
    apiGet({
      url: endpoint.accountGroupSummaryByAcc + "?companyIds=" + dynamicMasterData.companyId + "&fromDate=" + dynamicMasterData.fromDate + "&toDate=" + dynamicMasterData.toDate + "&accId=" + accPopupata.id
      ,
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data;


        let openingCreditAmt = 0;
        let openingDdebitAmt = 0;
        let openAmt = rowData.openingCreditAmt + rowData.openingDdebitAmt
        let accList = [];
        //  let obj={index:index,documentDate:"",refAccName:"Opening Balance",documentNo:"",transactionDate:"",openingCreditAmt:rowData.openingCreditAmt,openingDdebitAmt:rowData.openingDdebitAmt,documentAmt:"",type:"",bookType:""}
        //  accList.push({index:"",documentDate:accPopupata.accCode,refAccName:accPopupata.name,documentNo:"",transactionDate:"",amount:"",documentAmt:"",type:"",bookType:""});
        accList.push({
          nameLink:false,
          index: 0,
          documentDate: "",
          documentNo: "",
          refAccName: "Opening Balance",
          billNo: "",
          transactionDate: "",
          openingDdebitAmt: Math.abs(+rowData.openingDdebitAmt).toFixed(2),
          openingCreditAmt: Math.abs(+rowData.openingCreditAmt).toFixed(2),

          documentAmt: "",
          type: "",
          bookType: "",
          flag:1,
        });
        let cerditTotal = 0.00;
        let debitTotal = 0.00;
        let docTotal = 0.00;
        let closingAmount = 0
        let openingAmount = 0
        if (rowData.accDetail != null) {
          setAccInfo(rowData.accDetail)
        }

        const rowlist =
          rowData.accLedgerList.length != 0
            ? rowData.accLedgerList.map((row, index) => {
              let creditAmt = 0
              let debitAmt = 0
              if (row.type == "D") {
                creditAmt = 0
                debitAmt = row.amount
                openingCreditAmt = 0;
                openingDdebitAmt = Math.abs(+row.amount).toFixed(2);
              } else {
                creditAmt = row.amount
                debitAmt = 0
                openingCreditAmt = Math.abs(+row.amount).toFixed(2);
                openingDdebitAmt = 0;
              }
              if (index == 0) {
                closingAmount = openAmt + debitAmt + creditAmt
                openingAmount = openAmt
              } else {
                openingAmount = closingAmount
                closingAmount = closingAmount + debitAmt + creditAmt
              }
              cerditTotal = cerditTotal + creditAmt;
              debitTotal = debitTotal + debitAmt;
              docTotal = docTotal + closingAmount
              let data = {
                
                nameLink:row.tranctionTable==1?false:true,
                documentId:row.documentId != null ? row.documentId : "",
                documentDate: moment(row.documentDate).format("DD-MM-YYYY"),
                documentNo: row.documentNo != null ? row.documentNo : "",
                refAccName: row.refAccName != null ? row.refAccName : "",

                billNo: "-",
                transactionDate: moment(row.transactionDate).format(
                  "DD-MM-YYYY"
                ),
                openingDdebitAmt: Math.abs(+openingDdebitAmt).toFixed(2),
                openingCreditAmt: Math.abs(+openingCreditAmt).toFixed(2),

                documentAmt: Math.abs(+closingAmount).toFixed(2),
                type: closingAmount >= 0 ? "Cr" : "Dr",
                bookType: row.bookType,
                flag:0,
                tranctionTable:row.tranctionTable != null ? row.tranctionTable : "",
              };

              accList.push(data);
              //   accList.push({index:index,documentDate:"",refAccName:"Opening Balance",documentNo:"",transactionDate:"",openingCreditAmt:(+rowData.openingCreditAmt).toFixed(2),openingDdebitAmt:(+rowData.openingDdebitAmt).toFixed(2),documentAmt:"",type:"",bookType:""});

              return data;
            })
            : [];

        setRowList([...accList, {  nameLink:false,documentDate: "Total", refAccName: "", documentNo: "", billNo: "", transactionDate: "", openingDdebitAmt: Math.abs(+debitTotal).toFixed(2), openingCreditAmt: Math.abs(+cerditTotal).toFixed(2), documentAmt: "", type: "", bookType: "" ,flag:1}]);

        showLoador({ loador: false });
      } else {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
        showLoador({ loador: false });
      }
    });
  }
  const handleClose = () => {
    handleCloses();
  };
  //   const onSave = (searchData) => {
  //     let date1 =
  //       searchData != null
  //         ? moment(searchData.fromDate).format("YYYY-MM-DD")
  //         : "";
  //     let date2 =
  //       searchData != null ? moment(searchData.toDate).format("YYYY-MM-DD") : "";
  //     if (searchData != null) {
  //       apiGet({
  //         url:
  //           endpoint.accountGroupSummary +
  //           "?companyIds=" +
  //           searchData.companyId.id +
  //           "&fromDate=" +
  //           "2023-11-01" +
  //           "&toDate=" +
  //           "2023-11-30",
  //       }).then(({ data, success }) => {
  //         if (success) {
  //           let rowData = data.data;
  //           let index = 0;
  //           let cerditTotal = 0;
  //           let debitTotal = 0;
  //           let opBalance = 0;

  //           // cretditTotal:cerditTotal?(cerditTotal).toFixed(2):"0.00",
  //           // debitTotal:debitTotal?(debitTotal).toFixed(2):"0.00",
  //           // opBalance:cerditTotal>debitTotal?(+cerditTotal)-(+debitTotal):(+debitTotal)-(cerditTotal),
  //           let rowList = rowData.map((row) => {
  //             index = index + 1;
  //             cerditTotal = cerditTotal + row.creditAmt;
  //             debitTotal = debitTotal + row.debitAmt;
  //             opBalance=cerditTotal-cerditTotal;
  //             return {
  //               index: index,
  //               ...row,
  //             };
  //           });
  //           rowList.push({index:"Total",groupName:"",creditAmt:cerditTotal,debitAmt:debitTotal})
  //           if(cerditTotal>= debitTotal){
  //             rowList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})
  //           }else{
  //             rowList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
  //           }

  //           setRowList(rowList);
  //         }
  //       });
  //     }
  //   };

  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };
  const generatePDFMethod = () => {
    var columns = [
      [

        "Date",
        "Naration",
        "Bill No.",
        "Bill Date",
        "Debit",
        "Credit",

        "Closing Bal",
        "Type",
        "Book",
      ],
    ];
    let tableList = [];

    rowList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.documentDate);
      tableData.push(rowData.refAccName);
      tableData.push(rowData.documentNo);
      tableData.push(rowData.transactionDate);
      tableData.push(Math.abs(rowData.openingDdebitAmt));
      tableData.push(Math.abs(rowData.openingCreditAmt));

      tableData.push(Math.abs(rowData.documentAmt));

      tableData.push(rowData.type);
      tableData.push(rowData.bookType);

      tableList.push(tableData);
    });

    // tableList.push("Total");
    // tableList.push("");
    // tableList.push({creditAmt:creditTotalAmt});
    // tableList.push({debitAmt:debitTotalAmt});

    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    // if(creditTotalAmt>= debitTotalAmt){
    //   tableList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})

    // }else{
    //   tableList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
    // }
    const columnStyles = {

      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };

    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "Date Range ",
          value: dynamicMasterData.fromDate + " To " + dynamicMasterData.toDate,
        },
        {
          label: "Company Name",
          value: dynamicMasterData.name,
        },
        { label: "Group", value: popUpData.groupName },
        { label: "From Leger", value: accPopupata.name },
        {
          label: "To Leger",
          value: accPopupata.name,
        },
      ],
      pdfTitle: "Group Summary",
    });
  };

  const getFilteredTableData = (tableData) => {
    let listData = tableData.filter((row) => {
      let isValid = false;

      if (
        row.documentDate
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.refAccName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.documentNo
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.transactionDate
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      if (
        row.openingDdebitAmt
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.openingCreditAmt
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.documentAmt
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.type.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      if (
        row.bookType
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      // if (transactionDate.toString()
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase())
      // ) {
      //   isValid = true;
      // }
      // if (debitTotalAmt.toString()
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase())
      // ) {
      //   isValid = true;
      // }
      return isValid;
    });
    return listData;
  };
  const generateExcelMethod = () => {
    var columns = [
      "Sr No.",
      "Date",
      "Naration",
      "Bill No.",
      "Bill Date",
      "Debit",
      "Credit",

      "Closing Bal",
      "Type",
      "Book",
    ];
    let alignList = [];

    let rows = [];

    rowList.map((reportData, index) => {
      let reportObj = [index + 1];
      reportObj.push(reportData.documentDate);
      reportObj.push(reportData.refAccName);
      reportObj.push(reportData.documentNo);
      reportObj.push(reportData.transactionDate);
      reportObj.push(Math.abs(reportData.openingDdebitAmt));

      reportObj.push(Math.abs(reportData.openingCreditAmt));
      reportObj.push(Math.abs(reportData.documentAmt));

      reportObj.push(reportData.type);
      reportObj.push(reportData.bookType);

      rows.push(reportObj);

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("")
      // reportObj.push(debitTotal==0?"": Math.abs(debitTotal) )
      // reportObj.push(creditTotal==0?"":Math.abs(creditTotal) )
      // rows.push(reportObj)

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("Day Closing")
      // reportObj.push("")
      // reportObj.push(Math.abs(reportData.closingAmt) )
      // rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Date Range ",
        dynamicMasterData.fromDate + " To " + dynamicMasterData.toDate,
        "Company Name",
        dynamicMasterData.name,
        "Group",
        popUpData.groupName,

        "From Leger",
        accPopupata.name,

        "To Leger",
        accPopupata.name,
      ],
      title: "Group Summary",
    });
  };

  const rowLink = async(rowData)=>{
    console.log(rowData)
    if(rowData.tranctionTable==2){
      showLoador({ loador: true });
      await getCashRecieptWithAccNameById({
          id: rowData.documentId,
      }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
  
           
             
              setEditData(response);
              if(response.voucherType!=null){
                if(response.voucherType==1){
                  setOpenCashReceiptPopup(!openCashReceiptPopup);
                }
                if(response.voucherType==2){
                  setOpenCashPaymentPopup(!openCashPaymentPopup);
                }
                if(response.voucherType==3){
                  setOpenBankReceiptPopup(!openBankReceiptPopup)
                }
                if(response.voucherType==4){
                  setOpenBankPaymentPopup(!openBankPaymentPopup)
                }
               
                if(response.voucherType==6){
                  setOpenCreditNotePopup(!openCreditNotePopup)
                }
                if(response.voucherType==7){
                  setOpenDebiteNotePopup(!openDebiteNotePopup)
                }
                if(response.voucherType==8){
                  setOpenContraEntryPopup(!openContraEntryPopup)
                }
              }
            
            
          }
         
      });
     }
      
     if(rowData.tranctionTable==3){
      showLoador({ loador: true });
      await getJVWithAccNameById({
          id: id,
      }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
  
           
             
              setJournalEditData(response);
             
                  setOpenJouranlEntryPopup(!openJouranlEntryPopup)
                
             
              
            
            
          }
         
      });
    }
  }

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {accPopupata.name
              ? accPopupata.accCode + " " + accPopupata.name
              : ""}

          </BootstrapDialogTitle>
          <DialogContent dividers>

            <Grid container spacing={1}>
              <Grid container xs={12} sm={12} md={12} lg={12}>
                <Grid sx={{ padding: 1}} item xs={12} sm={9} md={9} lg={9}>
                  <DynamicFormWithReport
                    showBackToList={false}
                    DataToSet={dataToSet}
                    showSaveBtnMain={false}
                    padding={false}
                    saveBtnText={"Search"}
                    paddingTop={false}
                    showExcelDownload={true}
                  />
                  <LandingScreenHeader
                    screenTitle=""
                    showTable={true}
                    dynamicMasterData={{}}
                    showBackToList={false}
                    showPdfDownload={true}
              showExcelDownload={true}
                    DataToSet={[]}
                    fieldMeta={[]}
                    showSaveBtnMain={true}
                    onSearch={onSearch}
                    generatePDF={generatePDFMethod}
                    generateExcel={generateExcelMethod}
                  />
                  <RTTable
                    showPegination={false}
                    columns={[

                      { name: "documentDate", title: "Date", align: "center" },
                      { name: "documentNo", title: "Vou No." },
                      { name: "refAccName", title: "Naration",linkFlag: true ,linkName:"nameLink" },
                      { name: "billNo", title: "Bill No." },
                      { name: "openingDdebitAmt", title: "Debit", align: "right" },

                      { name: "openingCreditAmt", title: "Credit", align: "right" },
                      { name: "documentAmt", title: "Closing Bal", align: "right" },
                      { name: "type", title: "Type" },
                    ]}
                    hiddenColumnNames={[]}
                    tableData={getFilteredTableData(rowList)}
                    isActionColActive={false}
                    showEditIcon={false}
                    showDeleteIcon={false}
                    rowLink={rowLink}
                    showCopyIcon={false}
                    generatePDF={generatePDFMethod}
                    generateExcel={generateExcelMethod}
                  />
                </Grid>
                <Grid sx={{ padding: 1 ,borderLeft: "2px solid #CFCFCF" }} item xs={12} sm={3} md={3} lg={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 14, color: blackColor }}
                      label={'Customer Details'}
                    />
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'Ledger Code :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 ? accInfo.account_ledger_code : ""}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'Ledger Name :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 ? accInfo.account_ledger_name : ""}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'Phone No. :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 ? accInfo.contact_no : ""}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'Adhar No :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 ? accInfo.adhar_no : ""}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'GST No. :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 ? accInfo.gstno : ""}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'E-Mail Id :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 ? accInfo.mail : ""}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'PAN No. :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 ? accInfo.pan_no : ""}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'TCS Applicable :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 &&accInfo.tcs_applicable? 'Yes' : 'No'}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: grayColor }}
                        label={'TDS Applicable :'}
                      />
                    </Grid>
                    <Grid justifyContent='flex-end' container xs={12} sm={8} md={8} lg={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 12, color: blackColor }}
                        label={Object.keys(accInfo).length != 0 &&accInfo.tds_applicable? 'Yes' : 'No'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* {
                rowList.length != 0 && <Grid sx={{ paddingTop: 2 }} container spacing={1}>
                    <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid sx={{ padding: 1 }} item xs={12} sm={12} md={12} lg={12}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>
                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Group Summary By Account'}
                                    />
                                </div>

                                <BarChart
                                    series={[{
                                      name:"debit amount",
                                        data: rowList.filter((row)=>row.flag==0 && row.openingDdebitAmt!="" ).map((rowData) => {
                                            return rowData.openingDdebitAmt
                                        }),
                                    }, {
                                      name:"credit amount",
                                        data:  rowList.filter((row)=>row.flag==0 && row.openingCreditAmt!="" ).map((rowData) => {
                                            return rowData.openingCreditAmt
                                        }),
                                    }]}
                                    options={
                                        {
                                            chart: {
                                                type: 'bar',
                                                height: 350,
                                                toolbar: {
                                                    show: false,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },

                                            xaxis: {
                                                categories: rowList.filter((row)=>row.flag==0 && row.refAccName!="" && row.refAccName!="" ).map((rowData) => {
                                                    return rowData.refAccName
                                                }),
                                            },
                                            grid: {
                                                show: false,
                                            },


                                        }
                                    }

                                />
                                
                            </Paper>
                        </Grid>
                       
                        
                    </Grid>
                </Grid>
            } */}

{openCashReceiptPopup && (
        <CashReciptPopupForReport
          editData={editData}
          open={openCashReceiptPopup}
          handleCloses={togglePopup}
        />
      )}
       {openBankReceiptPopup && (
        <BankReciptPopupForReport
          editData={editData}
          open={openBankReceiptPopup}
          handleCloses={toggleBankReceiptPopup}
        />
      )}
      {openCashPaymentPopup && (
        <CashPaymentPopupForReport
          editData={editData}
          open={openCashPaymentPopup}
          handleCloses={toggleCashPaymentPopup}
        />
      )}
      {openBankPaymentPopup && (
        <BankPaymentPopupForReport
          editData={editData}
          open={openBankPaymentPopup}
          handleCloses={toggleBankPaymentPopup}
        />
      )}

{openJouranlEntryPopup && (
        <JouranlEntryPopupForReport
          editData={journalEditData}
          open={openJouranlEntryPopup}
          handleCloses={toggleJouranlEntryPopup}
        />
      )}


{openContraEntryPopup && (
        <ContraEntryPopupForReport
          editData={editData}
          open={openContraEntryPopup}
          handleCloses={toggleContraEntryPopup}
        />
      )}


{openDebiteNotePopup && (
        <DebiteNotePopupForReport
          editData={editData}
          open={openDebiteNotePopup}
          handleCloses={toggleDebiteNotePopup}
        />
      )}
      

{openCreditNotePopup && (
        <CreditNotePopupForReport
          editData={editData}
          open={openCreditNotePopup}
          handleCloses={toggleCreditNotePopup}
        />
      )}
      
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  cashRecieptList: state.cashReciept,
  journalVoucherList: state.journalVoucher,
});

const mapDispatchToProps = { showLoador, showNotification,getCashRecieptWithAccNameById,
  getJVWithAccNameById };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupSummarySubreportByAcc);
