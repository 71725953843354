
import { Grid, Paper, Switch, Tooltip } from "@mui/material";
import React, { Component } from "react";
import { TitleAndBreadCrum } from "./Comman/TitleAndBreadCrum";
import Search from "./Search";
import { FontAwesomeIconCompo } from "./Comman/IconButton";
import RTSelectMultiple from "./Comman/RT/RTSelectMultiple";
import { ButtonCompo } from "./Comman/Button";



class HeaderTitle extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      role: "",
    };
  }
  componentDidMount() {
    let role = localStorage.getItem("role");
    if (role) {
      this.setState({
        role: role,
      });
    }
  }
  onAddButtonClick = () => {
    this.props.onAddButtonClick();
  };

  onSearch = (searchvalue) => {
    this.props.onSearch(searchvalue);
  };

  render() {
    const {
      screenTitle,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      onRTSelectMultipleChange,
      tableColumnsToFilter = [],
      searchValue,
      generatePDF,
      generateExcel,
      showTable,
      showSearchBox = true,
      showSearch = true,
      showSwitchButton = false,
      handleChange,
      checked,
      title,
      addTitle = "",
      showBackButton = false,
      fullWidthButton = false,
      onCancel,
    } = this.props;
    return (
      <>
      
          {screenTitle !== "" && (
            <Paper sx={{ borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="space-between"
              >
                 <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="right"
                  >
                     <TitleAndBreadCrum title={screenTitle} />
               
                  </Grid>
                {(showAddButton || showBackButton) && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={8}
                    lg={8}
                    container
                    justifyContent="right"
                  >
                    {showAddButton && (
                      <ButtonCompo
                        sx={{ color: "white", backgroundColor: "#0f3f6f" }}
                        size="medium"
                        type="button"
                        variant="contained"
                        name={
                          addTitle === ""
                            ? "Add " + screenTitle
                            : "Add " + addTitle
                        }
                        onClick={this.onAddButtonClick}
                        fullWidth={fullWidthButton}
                      />
                    )}
                    {showBackButton && (
                      <>
                        &nbsp; &nbsp;
                        <ButtonCompo
                          size="small"
                          type="button"
                          variant="outlined"
                          name=" Back to List"
                          onClick={onCancel}
                        />
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Paper>
          )}

        
      </>
    );
  }
}

export default HeaderTitle;
