import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PurchaseReportJson } from "../../DynamicFormsJson/Report/PurchaseReport";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PurchaseSaleSummaryTable from "./PurchaseSaleTable";

const PurchaseSaleSummary = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [cashbook, setCashbookList] = React.useState({});
  const [formData, setFormData] = React.useState({});
  const [reportData, setReportData] = React.useState({})
  useEffect(() => {
    // getList = ("01-11-2023", "30-11-2023");
  }, [])
  const getList = (fromDate, toDate) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.purchaseSummaryReport +
        "?fromDate=" + fromDate + "&toDate=" + toDate,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        console.log(data.data)
        if (Object.keys(data.data).length != 0) {
          setReportData(data.data);
          if (data.data.purchaseList != null) {

            setCashbookList(data.data != null ? data.data : {})
          } else {
            setCashbookList([]);
          }
        }

        // rowData .map((rowData) => {
        //     return {
        //         ...rowData, transactionDetail: rowData.transactionDetail.map((trans) => {
        //             return { ...trans, transactionDetailes: trans.transactionDetailes != null ? JSON.parse(trans.transactionDetailes) : [] }
        //         })
        //     }
        // })


      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  }

  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };
  const  generatePDFMethod1= () => {
    var columns = [["Sr.No", "Perticuler", "Receipt", "Payment"]];
    let tableList = []
    cashbook.map(((rowData, index) => {
      let creditTotal = 0
      let debitTotal = 0
      let tableData = []

      let Perticuler = ""
      let Receipt = ""
      let Payment = ""
      rowData.transactionDetail.map((data) => {
        if (data.type == "D") {
          debitTotal = debitTotal + data.amount
        } else {
          creditTotal = creditTotal + data.amount
        }

        let refAccName = data.type == "D" ? "To " + data.refAccName : "By " + data.refAccName
        let type = data.type == "D" ? "Amt Paid To " + data.refAccName : "Amt Received "
        Perticuler = Perticuler + refAccName + "\n" + type
        Receipt = Receipt + data.type == "D" ? Math.abs(data.amount).toFixed(2) : ""
        Payment = Payment + data.type == "D" ? "" : Math.abs(data.amount).toFixed(2)

      })

      tableData = []
      let ct = creditTotal == 0 ? "\n" : Math.abs(creditTotal).toFixed(2)
      tableData.push(index + 1)
      tableData.push(rowData.date + "\n" + Perticuler + "\n\nDay Closing")
      let dt = debitTotal == 0 ? "" : Math.abs(debitTotal).toFixed(2)
      tableData.push("\n" + Receipt + "\n\n" + dt)
      tableData.push("\n" + Payment + "\n\n" + ct + "\n" + Math.abs(rowData.closingAmt)).toFixed(2)
      tableList.push(tableData)
    }))
    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 200 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate
        },
        {
          label: "Company Name",
          value: formData.name
        },
      ],
      pdfTitle: PurchaseReportJson.screenTitle,
    });

  };
  const generatePDFMethod = () => {
    var columns = [["","","","DESCRIPTION", "Taxable Amount", "CGST", "SGST", "IGST", "CESS", "Total"]];
    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 200 },
      2: { cellWidth: 90 },
    };
    let tableData = []
    let rows = []
    let TotalTaxableamt = 0;
    let TotalCgst = 0;
    let totalIgst = 0;
    let totalSgst = 0;
    let cessTotal = 0;
    let totalPurchase = 0;
    
let title="INPUT"
    let desc = "";
    let taxA = "";
    let sgst = "";
    let cgst = "";
    let igst = "";
    let cess = "";
    let total = "";
   let descMain="Add"
   let descMain1="Purchase"
    cashbook.purchaseList && cashbook.purchaseList.map((data, index) => {
        
        TotalTaxableamt = TotalTaxableamt + data.taxableAmt
        TotalCgst = TotalCgst + data.cgstAmt
        totalIgst = totalIgst + data.igstAmt
        totalSgst = totalSgst + data.sgstAmt
        cessTotal = cessTotal + data.cessAmt
        totalPurchase = totalPurchase + data.total
        
        let description="GST" + data.gstPer.toFixed(2) + " %"
        descMain=descMain+"\n"
        descMain1=descMain1+"\n"
        desc=desc==""?desc+description:desc+"\n"+description
        taxA=taxA==""?taxA+Math.abs(data.taxableAmt).toFixed(2)
        :taxA+"\n"+Math.abs(data.taxableAmt).toFixed(2)
    
        cgst=cgst==""?cgst+Math.abs(data.cgstAmt).toFixed(2)
        :cgst+"\n"+Math.abs(data.cgstAmt).toFixed(2)
        
        sgst=sgst==""?sgst+Math.abs(data.sgstAmt).toFixed(2)
        :sgst+"\n"+Math.abs(data.sgstAmt).toFixed(2)

        igst=igst==""?igst+Math.abs(data.igstAmt).toFixed(2)
        :igst+"\n"+Math.abs(data.igstAmt).toFixed(2)


        cess=cess==""?cess+Math.abs(data.cessAmt).toFixed(2)
        :cess+"\n"+Math.abs(data.cessAmt).toFixed(2)

        total=total==""?total+Math.abs(data.total).toFixed(2)
        :total+"\n"+Math.abs(data.total).toFixed(2)

      })
      descMain=descMain+"\nLess"
      descMain1=descMain1+"\nPurchase Return "
      cashbook.purchaseReturnList && cashbook.purchaseReturnList.map((data, index) => {
         tableData = []

        TotalTaxableamt = TotalTaxableamt + data.taxableAmt
        TotalCgst = TotalCgst + data.cgstAmt
        totalIgst = totalIgst + data.igstAmt
        totalSgst = totalSgst + data.sgstAmt
        cessTotal = cessTotal + data.cessAmt
        totalPurchase = totalPurchase + data.total

      
        
        let description="GST" + data.gstPer.toFixed(2) + " %"
        
        descMain=descMain+"\n"
        descMain1=descMain1+"\n"
        desc=desc==""?desc+description:desc+"\n"+description
        taxA=taxA==""?taxA+Math.abs(data.taxableAmt).toFixed(2)
        :taxA+"\n"+Math.abs(data.taxableAmt).toFixed(2)
    
        cgst=cgst==""?cgst+Math.abs(data.cgstAmt).toFixed(2)
        :cgst+"\n"+Math.abs(data.cgstAmt).toFixed(2)
        
        sgst=sgst==""?sgst+Math.abs(data.sgstAmt).toFixed(2)
        :sgst+"\n"+Math.abs(data.sgstAmt).toFixed(2)

        igst=igst==""?igst+Math.abs(data.igstAmt).toFixed(2)
        :igst+"\n"+Math.abs(data.igstAmt).toFixed(2)

        cess=cess==""?cess+Math.abs(data.cessAmt).toFixed(2)
        :cess+"\n"+Math.abs(data.cessAmt).toFixed(2)

        total=total==""?total+Math.abs(data.total).toFixed(2)
        :total+"\n"+Math.abs(data.total).toFixed(2)

      })

      tableData = []
      tableData.push(title)
      tableData.push(descMain)
      tableData.push(descMain1)
      tableData.push(desc)
      tableData.push(taxA)
      tableData.push(cgst)
      tableData.push(sgst)
      tableData.push(igst)

      tableData.push(cess)
      tableData.push(total)
    rows.push(tableData)
      tableData = []
      tableData.push("")
      tableData.push("")
      tableData.push("")
      tableData.push("")
      tableData.push(Math.abs(TotalTaxableamt).toFixed(2))
      tableData.push(Math.abs(TotalCgst).toFixed(2))

      tableData.push(Math.abs(totalSgst).toFixed(2))
      tableData.push(Math.abs(totalIgst).toFixed(2))
      tableData.push(Math.abs(cessTotal).toFixed(2))
      tableData.push(Math.abs(totalPurchase).toFixed(2))
    rows.push(tableData)
     TotalTaxableamt = 0;
     TotalCgst = 0;
     totalIgst = 0;
     totalSgst = 0;
     cessTotal = 0;
     totalPurchase = 0;
     title="OUTPUT"
      desc = "";
      descMain=""
      descMain1=""
      taxA = "";
      sgst = "";
      cgst = "";
      igst = "";
      cess = "";
      total = "";
      descMain=  "Add"
      descMain1=  "Sales"
     cashbook.saleList && cashbook.saleList.map((data, index) => {
     
     TotalTaxableamt = TotalTaxableamt + data.taxableAmt
     TotalCgst = TotalCgst + data.cgstAmt
     totalIgst = totalIgst + data.igstAmt
     totalSgst = totalSgst + data.sgstAmt
     cessTotal = cessTotal + data.cessAmt
     totalPurchase = totalPurchase + data.total
     
     let description="GST" + data.gstPer.toFixed(2) + " %"
     descMain=descMain+"\n"
     descMain1=descMain1+"\n"
     desc=desc==""?desc+description:desc+"\n"+description
     taxA=taxA==""?taxA+Math.abs(data.taxableAmt).toFixed(2)
     :taxA+"\n"+Math.abs(data.taxableAmt).toFixed(2)
 
     cgst=cgst==""?cgst+Math.abs(data.cgstAmt).toFixed(2)
     :cgst+"\n"+Math.abs(data.cgstAmt).toFixed(2)
     
     sgst=sgst==""?sgst+Math.abs(data.sgstAmt).toFixed(2)
     :sgst+"\n"+Math.abs(data.sgstAmt).toFixed(2)

     igst=igst==""?igst+Math.abs(data.igstAmt).toFixed(2)
     :igst+"\n"+Math.abs(data.igstAmt).toFixed(2)

     cess=cess==""?cess+Math.abs(data.cessAmt).toFixed(2)
     :cess+"\n"+Math.abs(data.cessAmt).toFixed(2)

     total=total==""?total+Math.abs(data.total).toFixed(2)
     :total+"\n"+Math.abs(data.total).toFixed(2)
   })
   descMain=descMain+"\nLess"
   descMain1=descMain1+"\nSales Return"
   cashbook.saleReturnList && cashbook.saleReturnList.map((data, index) => {
    
   TotalTaxableamt = TotalTaxableamt + data.taxableAmt
   TotalCgst = TotalCgst + data.cgstAmt
   totalIgst = totalIgst + data.igstAmt
   totalSgst = totalSgst + data.sgstAmt
   cessTotal = cessTotal + data.cessAmt
   totalPurchase = totalPurchase + data.total
   
   let description=index == 0 ? "      GST" + data.gstPer.toFixed(2) + " %": "           GST" + data.gstPer.toFixed(2) + " %"
   descMain=descMain+"\n"
   descMain1=descMain1+"\n"
   desc=desc==""?desc+description:desc+"\n"+description
   taxA=taxA==""?taxA+Math.abs(data.taxableAmt).toFixed(2)
   :taxA+"\n"+Math.abs(data.taxableAmt).toFixed(2)

   cgst=cgst==""?cgst+Math.abs(data.cgstAmt).toFixed(2)
   :cgst+"\n"+Math.abs(data.cgstAmt).toFixed(2)
   
   sgst=sgst==""?sgst+Math.abs(data.sgstAmt).toFixed(2)
   :sgst+"\n"+Math.abs(data.sgstAmt).toFixed(2)

   igst=igst==""?igst+Math.abs(data.igstAmt).toFixed(2)
   :igst+"\n"+Math.abs(data.igstAmt).toFixed(2)

   cess=cess==""?cess+Math.abs(data.cessAmt).toFixed(2)
   :cess+"\n"+Math.abs(data.cessAmt).toFixed(2)

   total=total==""?total+Math.abs(data.total).toFixed(2)
   :total+"\n"+Math.abs(data.total).toFixed(2)
 })
 tableData = []
      tableData.push(title)
      tableData.push(descMain)
      tableData.push(descMain1)
      tableData.push(desc)
      tableData.push(taxA)
      tableData.push(cgst)
      tableData.push(sgst)
      tableData.push(igst)

      tableData.push(cess)
      tableData.push(total)
    rows.push(tableData)
 tableData = []
 tableData.push("")
 tableData.push("")
 tableData.push("")
 tableData.push("")
 tableData.push(Math.abs(TotalTaxableamt).toFixed(2))
 tableData.push(Math.abs(TotalCgst).toFixed(2))
 tableData.push(Math.abs(totalSgst).toFixed(2))
 tableData.push(Math.abs(totalIgst).toFixed(2))
 tableData.push(Math.abs(cessTotal).toFixed(2))
 tableData.push(Math.abs(totalPurchase).toFixed(2))
 rows.push(tableData)
 generatePDF({
  columnStylesPlain: {},
  rows: rows,
  columns,
  columnStyles: {},
  headerList: [
    {
      label: "Date Range ",
      value: formData.fromDate + " To " + formData.toDate
    },
    
  ],
  pdfTitle: PurchaseReportJson.screenTitle,
});

  };
  const getFilteredTableData = (tableData) => {

    let listData = []
    tableData.map((row) => {
      const subList = row["transactionDetail"].filter((currentRow) => {
        let isValid = false;

        if (
          currentRow["refAccName"] &&
          currentRow["refAccName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        return isValid;
      });
      if (subList.length != 0) {
        row = {
          ...row,
          ["transactionDetail"]: subList,
        };
        listData.push(row);
      }
    });
    return listData
  };
  const generateExcelMethod = () => {
    var columns = ["", "","DESCRIPTION","", "Taxable Amount", "CGST", "SGST", "IGST", "CESS", "Total"];
    let alignList = [];
    let tableData = []
    let rows = []
    let TotalTaxableamt = 0;
    let TotalCgst = 0;
    let totalIgst = 0;
    let totalSgst = 0;
    let cessTotal = 0;
    let totalPurchase = 0;
    cashbook.purchaseList && cashbook.purchaseList.map((data, index) => {
         tableData = []
        TotalTaxableamt = TotalTaxableamt + data.taxableAmt
        TotalCgst = TotalCgst + data.cgstAmt
        totalIgst = totalIgst + data.igstAmt
        totalSgst = totalSgst + data.sgstAmt
        cessTotal = cessTotal + data.cessAmt
        totalPurchase = totalPurchase + data.total
        tableData.push(index == 0 ? "INPUT" : "")
        tableData.push(index == 0 ? "Add" : "")
        tableData.push(index == 0 ? "Purchase" : "")
        tableData.push("GST" + data.gstPer.toFixed(2) + " %")

        tableData.push(Math.abs(data.taxableAmt).toFixed(2))
        tableData.push(Math.abs(data.cgstAmt).toFixed(2))

        tableData.push(Math.abs(data.sgstAmt).toFixed(2))
        tableData.push(Math.abs(data.igstAmt).toFixed(2))
        tableData.push(Math.abs(data.cessAmt).toFixed(2))
        tableData.push(Math.abs(data.total).toFixed(2))
        rows.push(tableData)
      })
      cashbook.purchaseReturnList && cashbook.purchaseReturnList.map((data, index) => {
         tableData = []

        TotalTaxableamt = TotalTaxableamt + data.taxableAmt
        TotalCgst = TotalCgst + data.cgstAmt
        totalIgst = totalIgst + data.igstAmt
        totalSgst = totalSgst + data.sgstAmt
        cessTotal = cessTotal + data.cessAmt
        totalPurchase = totalPurchase + data.total

        tableData.push("")
        tableData.push(index == 0 ? "Less" : "")
        tableData.push(index == 0 ? "Purchase Return" : "")
        tableData.push("GST" + data.gstPer.toFixed(2) + " %")

        tableData.push(Math.abs(data.taxableAmt).toFixed(2))
        tableData.push(Math.abs(data.cgstAmt).toFixed(2))

        tableData.push(Math.abs(data.sgstAmt).toFixed(2))
        tableData.push(Math.abs(data.igstAmt).toFixed(2))
        tableData.push(Math.abs(data.cessAmt).toFixed(2))
        tableData.push(Math.abs(data.total).toFixed(2))
        rows.push(tableData)
      })
      tableData = []
      tableData.push("")
      tableData.push("")
      tableData.push( "")
      tableData.push("")

      tableData.push(Math.abs(TotalTaxableamt).toFixed(2))
      tableData.push(Math.abs(TotalCgst).toFixed(2))

      tableData.push(Math.abs(totalSgst).toFixed(2))
      tableData.push(Math.abs(totalIgst).toFixed(2))
      tableData.push(Math.abs(cessTotal).toFixed(2))
      tableData.push(Math.abs(totalPurchase).toFixed(2))
    rows.push(tableData)
     TotalTaxableamt = 0;
     TotalCgst = 0;
     totalIgst = 0;
     totalSgst = 0;
     cessTotal = 0;
     totalPurchase = 0;

     cashbook.saleList && cashbook.saleList.map((data, index) => {
      tableData = []
     TotalTaxableamt = TotalTaxableamt + data.taxableAmt
     TotalCgst = TotalCgst + data.cgstAmt
     totalIgst = totalIgst + data.igstAmt
     totalSgst = totalSgst + data.sgstAmt
     cessTotal = cessTotal + data.cessAmt
     totalPurchase = totalPurchase + data.total
     tableData.push(index == 0 ? "OUTPUT" : "")
     tableData.push(index == 0 ? "Add" : "")
     tableData.push(index == 0 ? "Sales" : "")
     tableData.push("GST" + data.gstPer.toFixed(2) + " %")
     tableData.push(Math.abs(data.taxableAmt).toFixed(2))
     tableData.push(Math.abs(data.cgstAmt).toFixed(2))
     tableData.push(Math.abs(data.sgstAmt).toFixed(2))
     tableData.push(Math.abs(data.igstAmt).toFixed(2))
     tableData.push(Math.abs(data.cessAmt).toFixed(2))
     tableData.push(Math.abs(data.total).toFixed(2))
     rows.push(tableData)
   })
   cashbook.saleReturnList && cashbook.saleReturnList.map((data, index) => {
    tableData = []
   TotalTaxableamt = TotalTaxableamt + data.taxableAmt
   TotalCgst = TotalCgst + data.cgstAmt
   totalIgst = totalIgst + data.igstAmt
   totalSgst = totalSgst + data.sgstAmt
   cessTotal = cessTotal + data.cessAmt
   totalPurchase = totalPurchase + data.total
   tableData.push("")
   tableData.push(index == 0 ? "Less" : "")
   tableData.push(index == 0 ? "Sales Return" : "")
   tableData.push("GST" + data.gstPer.toFixed(2) + " %")

   tableData.push(Math.abs(data.taxableAmt).toFixed(2))
   tableData.push(Math.abs(data.cgstAmt).toFixed(2))

   tableData.push(Math.abs(data.sgstAmt).toFixed(2))
   tableData.push(Math.abs(data.igstAmt).toFixed(2))
   tableData.push(Math.abs(data.cessAmt).toFixed(2))
   tableData.push(Math.abs(data.total).toFixed(2))
   rows.push(tableData)
 })
 tableData = []
 tableData.push("")
 tableData.push("")
 tableData.push( "")
 tableData.push("")
 tableData.push(Math.abs(TotalTaxableamt).toFixed(2))
 tableData.push(Math.abs(TotalCgst).toFixed(2))
 tableData.push(Math.abs(totalSgst).toFixed(2))
 tableData.push(Math.abs(totalIgst).toFixed(2))
 tableData.push(Math.abs(cessTotal).toFixed(2))
 tableData.push(Math.abs(totalPurchase).toFixed(2))
 rows.push(tableData)
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate

      ],
      title: PurchaseReportJson.screenTitle,
    });

  };
  const onSave = (dataToSearch) => {

    setFormData({
      ...dataToSearch
    })
    getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId)
  }
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={PurchaseReportJson.showSaveBtn}
          screenTitle={PurchaseReportJson.screenTitle}
          fieldMeta={PurchaseReportJson.fieldMeta}
          showCancel={PurchaseReportJson.showCancel}
          apiBaseURL={PurchaseReportJson.apiBaseURL}
          searchName=""
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          showSaveNextBtn={PurchaseReportJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          onSearch={onSearch}
          showSearchBox={false}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <PurchaseSaleSummaryTable tableHead={[
          {
            title: " ",
            align: "left"
          },

          {
            title: "DESCRIPTION ",
            align: "left",
            colSpan: 3,
          },
          {
            title: "Taxable Amount",
            align: "right",
            width: "12%"
          },
          {
            title: "CGST",
            align: "right",
            width: "12%"
          },
          {
            title: "SGST",
            align: "right",
            width: "12%"
          },
          {
            title: "IGST",
            align: "right",
            width: "12%"
          },
          {
            title: "CESS",
            align: "right",
            width: "12%"
          },
          {
            title: "Total",
            align: "right",
            width: "12%"
          },
        ]} rowList={cashbook} />

      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSaleSummary);
