import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import { titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
// import { apiPostForDownload, apiPostForImage } from "../../utils/api_service";
import { generateExcelImport } from "../../components/GeneratePDFExcel";
import { apiPostForImage } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class ImportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "0",
      salesTeamId: "",
      stakeholderId: "",
      areaIds: "",
      beatIds: "",

      optionList: [
        {
          id: "0",
          name: "Account group master",
        },
        {
          id: "1",
          name: "Account ledger",
        },
        {
          id: "2",
          name: "Sale and Purchase",
        },
      ],

      fileName: "",
    };
  }

  onAutocompleteChange = () => (name, newValue) => {
    this.props;
    if (newValue != null) {
      this.setState({
        [name]: newValue,
        fileName: "",
      });
    }
  };

  onExportHandler = () => {
    const { option ,optionList} = this.state;
    generateExcelImport({
      columns:
        option == 1
          ? [
              "Account Ledger Code",
              "Account Ledger Name",
              "Print Name",
              "Group Name",
              "Acc Type",
              "Account Level",
              "Address",
              "City",
              "Contact No",
              "Email",
              "Pin Code",
              "Distance (km)",
              "GST No",
              "GST Type",
              "PAN No",
              "FSSAI No",
              "FSSAI",
              "Expiry Date",
              "Drug Licence No	",
              "Drug Licence Expiry Date",
              "Udyom Aadhar No",
              "IEC Code",
              "CIN No	",
              "Company Vendor Code	",
              "Third Party Link Code",
              "TDS Applicable",
              "TCS Applicable",
              "Credit Days",
              "Alert Days",
              "Credit Bills",
              "Credit Limit",
              "Is Related To Tax",
              "Is Hold",
            ]
          : option == 0
          ? [
              "Group Name",
              "Primary Account Legder Group",
              "Display Order No",
              "Remark",
            ]
          : [
              "Document No",
              "Document Date",
              "Voucher Code",
              "Account Ledger Code",
              "Basic Value",
              "Taxable Amt",
              "CGST",
              "SGST",
              "IGST",
              "Round Off",
              "Total Amt",
              "Remaining Amt",
              "Entry Type",
              "Product Code ",
              "Bill Qty",
              "Description",
              "Rate",
              "Details  Taxable Amt",
              "Details CGST %",
              "Details CGST Amt",
              "Details SGST %",
              "Details SGST Amt",
              "Details IGST %",
              "Details IGST Amt",
            ],
      rows: [],
      alignList: {},
      headerList: [],
      title: optionList.find((row)=>row.id==option).name,
    });
  };

  backToList = () => {
    this.props.navigate(-1);
  };

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  onClickViewPreview = (e, row) => {
    e.stopPropagation();
    const url = URL.createObjectURL(row);
    window.open(url);
  };

  onClearHandler = (event, value) => {
    this.setState({
      ...this.state,
      fileName: "",
    });
  };

  onSubmit = () => {
    const { fileName, salesTeamId, option } = this.state;
    const { showNotification, showLoador } = this.props;

    const formData = new FormData();
    formData.append("dataFile", fileName);
    //  if (option == "0") {
    //   formData.append("dataFile", fileName);
    // } else if (option == "1") {
    //   formData.append("salesTeamFile", fileName);
    // } else{}
    showLoador({ loador: true });
    apiPostForImage({
      url:
        option == "0"
          ? endpoint.importAccountLedger + "/import-account-ledger-group"
          : option == "1"
          ? endpoint.importAccountLedger +
            "/import-account-ledger?locationId=" +
            localStorage.getItem("location")
          : endpoint.importAccountLedger +
            "/import-purchase-sales-transaction-detail?locationId=" +
            localStorage.getItem("location"),
      postBody: formData,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        showNotification({
          msg: data.data.message,
        });
      }
    });
  };

  render() {
    const { option, optionList, fileName } = this.state;
    return (
      <>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Import Data"}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={false}
            onCancel={this.backToList}
          />
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Select"}
              />
              <AutoComplete
                keyColName={"id"}
                value={option}
                name="option"
                options={optionList}
                onChange={this.onAutocompleteChange()}
              />
            </Grid>
          </Grid>

          <Grid container xs={12} sm={12} md={12}>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Select file"}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={7}>
              <AttachFile name={"file"} fileName={this.changeH} />
              &nbsp; {fileName !== "" ? fileName.name : ""}
              &nbsp;{" "}
              {fileName !== "" ? (
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => this.onClickViewPreview(e, fileName)}
                />
              ) : (
                ""
              )}
            </Grid>
            {fileName !== "" ? (
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="outlined"
                  name="Clear"
                  onClick={(event) => this.onClearHandler(event, option)}
                  // fullWidth={true}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
            )}

            {fileName !== "" ? (
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="contained"
                  name="Import"
                  onClick={this.onSubmit}
                  // fullWidth={true}
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="contained"
                  name="Export"
                  onClick={this.onExportHandler}
                  // fullWidth={true}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ImportData);
