import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../../components/Comman/Label";
import { blackColor, grayColor, primaryColor } from "../../../config/ColorObj";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const GSTPOP = ({
  handleCloses,
  GSTData,
  handleUpdate
}) => {
console.log(GSTData)
 
  const handleClose = () => {
    handleCloses();
  };



 

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="sm"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
          GST Details

          </BootstrapDialogTitle>
          <DialogContent dividers>

            <Grid container spacing={1}>
              <Grid container xs={12} sm={12} md={12} lg={12}>
              <Grid  item xs={12} sm={2} md={2} lg={2}>
                  
                  <LabelCompo
                        className="text-black"
                        style={{ fontSize: 14, color: primaryColor  }}
                        label={'GST No. '}
                      />
                   
                  </Grid>
                   <Grid  item xs={12} sm={10} md={10} lg={10}>
                 
                 <LabelCompo
                       className="text-black"
                       style={{ fontSize: 14, color: blackColor }}
                       label={GSTData.gstin}
                     />
                  
                 </Grid> 
                 
                  <Grid  item xs={12} sm={2} md={2} lg={2}>
                 
                 <LabelCompo
                       className="text-black"
                       style={{ fontSize: 14, color: primaryColor }}
                       label={'Name : '}
                     />
                  
                 </Grid>
                 <Grid  item xs={12} sm={10} md={10} lg={10}>
                  
                  <LabelCompo
                        className="text-black"
                        style={{ fontSize: 14, color: blackColor }}
                        label={GSTData.tradeNam}
                      />
                   
                  </Grid>
                     <Grid  item xs={12} sm={2} md={2} lg={2}>
                 
                <LabelCompo
                      className="text-black"
                      style={{ fontSize: 14, color: primaryColor }}
                      label={'Address :'}
                    />
                 
                </Grid>
                <Grid  item xs={12} sm={10} md={10} lg={10}>
                 
                 <LabelCompo
                       className="text-black"
                       style={{ fontSize: 14, color: blackColor }}
                       label={GSTData.pradr.addr.bno+" , "+GSTData.pradr.addr.bnm+" , "+GSTData.pradr.addr.st+" , "+GSTData.pradr.addr.dst+" , "+GSTData.pradr.addr.stcd+" - "+GSTData.pradr.addr.pncd}
                     />
                  
                 </Grid>
                 <Grid justifyContent={"space-between"} container xs={12} sm={12} md={12} lg={12}>
                 
                 <Grid  item xs={5.5} sm={5.5} md={5.5} lg={5.5}>
                 
                 <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Update Details"
                  fullWidth={true}
                  onClick={handleUpdate}
                />
                  
                 </Grid>
                 <Grid  item xs={12} sm={2} md={2} lg={2}>
                 
                 <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="ok"
                  fullWidth={true}
                  onClick={handleClose}
                />
                  
                 </Grid>
                  
                 </Grid>
                  
              </Grid>
              
            </Grid>
            

      
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  cashRecieptList: state.cashReciept,
  journalVoucherList: state.journalVoucher,
});

const mapDispatchToProps = { showLoador, showNotification };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GSTPOP);
