export const AccessRoleColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "Remarks",
    name: "empTypeRemarks",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },

  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const RoomColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Floor Details",
    name: "floorName",
    formDataKey: "floor",
    tableDataKey: "floor",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Room Capacity",
    name: "roomCapacity",
    showInExcel: true,
    align: "center",
  },
  {
    title: "Exam Capacity",
    name: "examCapacity",
    showInExcel: true,
    align: "center",
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const DocumentColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "docName",
    formDataKey: "docName",
    showInExcel: true,
    canSearch: true,
  },

  { name: "action", title: "Action" },
];

export const UomColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const ZoneColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "zoneName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Region",
    name: "regionName",
    formDataKey: "region",
    tableDataKey: "region",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const TaxColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Tax Name",
    name: "taxName",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "SGST",
    name: "sgst",
    formDataKey: "sgst",
    showInExcel: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgst",
    formDataKey: "cgst",
    showInExcel: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "IGST",
    name: "igst",
    formDataKey: "igst",
    showInExcel: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "CESS",
    name: "cess",
    formDataKey: "cess",
    showInExcel: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "HSN Code",
    name: "hsnCode",
    formDataKey: "hsnCode",
    showInExcel: true,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action" },
];

export const CategoryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "categoryName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const SubCategoryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "subCategoryName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Category",
    name: "categoryName",
    formDataKey: "category",
    tableDataKey: "category",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const AreaColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Zone",
    name: "zoneName",
    formDataKey: "zone",
    tableDataKey: "zoneName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const SalesExecutiveColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "User Name",
    name: "userName",
    formDataKey: "userName",
    tableDataKey: "userName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Person Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Address",
    name: "address",
    formDataKey: "address",
    tableDataKey: "address",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Region",
    name: "regionName",
    formDataKey: "region",
    tableDataKey: "regionName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Zone",
    name: "zoneNames",
    formDataKey: "zone",
    tableDataKey: "zoneNames",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area",
    name: "areaNames",
    formDataKey: "area",
    tableDataKey: "areaNames",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Email Id",
    name: "emailId",
    formDataKey: "emailId",
    tableDataKey: "emailId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Contact Number",
    name: "contactNo",
    formDataKey: "contactNo",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Whatsapp Number",
    name: "whatsAppNo",
    formDataKey: "whatsAppNo",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const ProductColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Product Code",
    name: "code",
    formDataKey: "code",
    tableDataKey: "code",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "UOM Conversion Value",
    name: "uomConversionValue",
    formDataKey: "uomConversionValue",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },

  {
    title: "Product Shelf Life",
    name: "selfLife",
    formDataKey: "selfLife",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Product Weight",
    name: "weight",
    formDataKey: "weight",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];

export const NoticeMsgColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Notice Headline",
    name: "noticeHeadline",
    formDataKey: "noticeHeadline",
    tableDataKey: "noticeHeadline",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Description",
    name: "description",
    formDataKey: "description",
    tableDataKey: "description",
    canSearch: true,
    align: "left",
    showInExcel: true,
  },
  {
    title: "Type",
    name: "typeName",
    tableDataKey: "typeName",
    formDataKey: "typeName",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "From Date",
    name: "fromdate",
    tableDataKey: "fromdate",
    formDataKey: "fromdate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "To Date",
    name: "todate",
    tableDataKey: "todate",
    formDataKey: "todate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const RegionColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const SingleMrpConfigurationByItemListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "MRP",
    name: "mrp",
    showInscreen: true,
    positionCenter: false,
    alignCenter: "right",
  },
  {
    title: "Date",
    name: "publishDate",
    showInscreen: true,
    positionCenter: true,
  },
  {
    name: "action",
    title: "Action",
    align: "center",
    showInscreen: true,
    positionCenter: false,
  },
];

export const StakeHolderColumns = [
  { title: "Sr.No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "User Name",
    name: "userName",
    formDataKey: "userName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Firm Name",
    name: "firmName",
    formDataKey: "firmName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    formDataKey: "ownerName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "State",
    name: "stateName",
    formDataKey: "stateName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area",
    name: "areaNames",
    formDataKey: "areaNames",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Contact Person Name",
    name: "contactPersonName",
    formDataKey: "contactPersonName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Contact Person Number",
    name: "contactPersonNo",
    formDataKey: "contactPersonNo",
    showInExcel: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Email Id",
    name: "emailId",
    showInExcel: true,
    canSearch: true,
    align: "left",
  },
  {
    title: "StakeHolder Details",
    name: "stockStakeHolderTypeName",
    formDataKey: "stockStakeHolderType",
    tableDataKey: "stockStakeHolderType",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const ManagerColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "User Name",
    name: "userName",
    formDataKey: "userName",
    tableDataKey: "userName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Person Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Address",
    name: "address",
    formDataKey: "address",
    tableDataKey: "address",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Region",
    name: "regionName",
    formDataKey: "region",
    tableDataKey: "regionName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Zone",
    name: "zoneNames",
    formDataKey: "zone",
    tableDataKey: "zoneNames",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area",
    name: "areaNames",
    formDataKey: "area",
    tableDataKey: "areaNames",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "EmailId",
    name: "emailId",
    formDataKey: "emailId",
    tableDataKey: "emailId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Contact Number",
    name: "contactNo",
    formDataKey: "contactNo",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Whatsapp Number",
    name: "whatsAppNo",
    formDataKey: "whatsAppNo",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const OrderConfigurationColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Order To Name",
    name: "orderToName",
    formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];
export const PublishRateConfigColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "StakeHolder Type",
    name: "stockStakeHolderTypeName",
    formDataKey: "stockStakeHolderType",
    tableDataKey: "stockStakeHolderType",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Category",
    name: "category",
    formDataKey: "category",
    tableDataKey: "category",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Configuration",
    name: "rateConfiguration",
    formDataKey: "rateConfiguration",
    tableDataKey: "rateConfiguration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Publication Date",
    name: "publicationDate",
    formDataKey: "publicationDate",
    tableDataKey: "publicationDate",
    canSearch: true,
    showInExcel: true,
  },
];

export const RateConfigurationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    align: "center",
  },
  {
    title: "Structure Name",
    name: "name",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Stake Holder Type",
    name: "stockStakeHolderTypeName",
    positionCenter: false,
    canSearch: true,
    alignCenter: "right",
  },
  {
    title: "Category Name",
    name: "categoryName",
    positionCenter: false,
    canSearch: true,
  },

  {
    title: "Reference Date For Mrp",
    name: "dateForMrpRate",
    positionCenter: true,
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const OutletRateStructureColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    align: "center",
  },

  {
    title: "Structure Name",
    name: "name",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Category Name",
    name: "categoryName",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },

  { name: "action", title: "Action", align: "center" },
];

export const accessRoleColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },

  // {
  //   title: "Stakeholder Type",
  //   name: "type",
  //   formDataKey: "type",
  //   showInExcel: true,
  //   canSearch: true,
  // },
  // { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];
export const RangeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Maximum Range",
    name: "maxRange",
    formDataKey: "maxRange",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Minimum Range",
    name: "minRange",
    formDataKey: "minRange",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Type",
    name: "type",
    formDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];
export const GrievanceCategoryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "categoryName",
    formDataKey: "categoryName",
    tableDataKey: "categoryName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Type",
    name: "type",
    formDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const OutletTypeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const BeatColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "beatName",
    formDataKey: "beatName",
    tableDataKey: "beatName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area",
    name: "areaName",
    formDataKey: "area",
    tableDataKey: "area",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    tableDataKey: "sortOrderNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];

export const YearColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "yearName",
    formDataKey: "yearName",
    tableDataKey: "yearName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    tableDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    tableDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];

export const OutletColumns = [
  { title: "Sr.No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Firm Name",
    name: "firmName",
    formDataKey: "firmName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    formDataKey: "ownerName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "State",
    name: "stateName",
    formDataKey: "stateName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Address",
    name: "address",
    formDataKey: "address",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Lattitude",
    name: "latitude",
    formDataKey: "latitude",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Longitude",
    name: "longitude",
    formDataKey: "longitude",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Mobile Number",
    name: "mobileNo",
    formDataKey: "mobileNo",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Email Id",
    name: "emailId",
    showInExcel: true,
    align: "left",
  },

  {
    title: "Beat Name",
    name: "beatName",
    formDataKey: "beat",
    tableDataKey: "beat",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Outlet Type",
    name: "outLetTypeName",
    formDataKey: "outLetTypeName",
    tableDataKey: "outLetTypeName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const SubVoucherColumns = [
  { title: "Sr.No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "subVoucherName",
    formDataKey: "subVoucherName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Voucher",
    name: "voucherName",
    formDataKey: "voucherName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Account Posting",
    name: "accountPosting",
    formDataKey: "accountPosting",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Stock Update",
    name: "stockUpdate",
    formDataKey: "stockUpdate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Auto Approve",
    name: "autoApprove",
    formDataKey: "autoApprove",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const userColumns = [
  { title: "Sr.No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "userName",
    formDataKey: "userName",
    canSearch: true,
    showInExcel: true,
  },
  // {
  //   title: "Access Role",
  //   name: "roleId",
  //   formDataKey: "roleId",
  //   canSearch: true,
  //   showInExcel: true,
  //   align: "center",
  // },
  {
    title: "Email",
    name: "email",
    formDataKey: "email",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Contact No.",
    name: "contact",
    formDataKey: "contact",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const SubVoucherDetailColumns = [
  { title: "Sr.No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Sub-Voucher",
    name: "subVoucherName",
    formDataKey: "subVoucherName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Column Name",
    name: "columnName",
    formDataKey: "columnName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Ledger Account",
    name: "ledgerAccountName",
    formDataKey: "ledgerAccountName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Pojo Name",
    name: "pojoName",
    formDataKey: "pojoName",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];
export const TargetColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Jan",
    name: "jan",
    formDataKey: "jan",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Feb",
    name: "feb",
    formDataKey: "feb",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "March",
    name: "march",
    formDataKey: "march",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "April",
    name: "april",
    formDataKey: "april",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "May",
    name: "may",
    formDataKey: "may",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },

  {
    title: "Jun",
    name: "jun",
    formDataKey: "jun",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },

  {
    title: "July",
    name: "july",
    formDataKey: "july",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Aug",
    name: "aug",
    formDataKey: "aug",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Sep",
    name: "sep",
    formDataKey: "sep",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Oct",
    name: "oct",
    formDataKey: "oct",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Nov",
    name: "nov",
    formDataKey: "nov",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Dec",
    name: "dec",
    formDataKey: "dec",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Total",
    name: "totalTarget",
    formDataKey: "totalTarget",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
];

export const AuditColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Audit / Survey",
    name: "isAuditSurvey",
    formDataKey: "isAuditSurvey",
    tableDataKey: "isAuditSurvey",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Audit/Survey For",
    name: "type",
    formDataKey: "type",
    tableDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];
export const dayWiseBitAssignColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Person Name",
    name: "salesTeamName",
    formDataKey: "yearName",
    tableDataKey: "yearName",
    canSearch: true,
  },
  {
    title: "Number of Beat",
    name: "noOfBeat",
    formDataKey: "remark",
    tableDataKey: "remark",
    canSearch: true,
    align: "right",
  },
  {
    title: "Number of Outlet",
    name: "noOfOutLet",
    canSearch: true,
    align: "right",
  },

  { name: "action", title: "Action", align: "center" },
];

export const RegionAndTargetColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Region",
    name: "name",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Jan",
    name: "jan",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Feb",
    name: "feb",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "March",
    name: "march",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "April",
    name: "april",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "May",
    name: "may",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Jun",
    name: "jun",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "July",
    name: "july",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Aug",
    name: "aug",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Sep",
    name: "sep",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Oct",
    name: "oct",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Nov",
    name: "nov",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Dec",
    name: "dec",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
];
export const GrievanceColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    align: "center",
  },
  {
    title: "Category",
    name: "categoryName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Complaint Description",
    name: "remark",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Date",
    name: "insertDateTime",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const CompanyTargetColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Year",
    name: "name",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Jan",
    name: "jan",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Feb",
    name: "feb",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "March",
    name: "march",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "April",
    name: "april",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "May",
    name: "may",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Jun",
    name: "jun",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "July",
    name: "july",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Aug",
    name: "aug",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Sep",
    name: "sep",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Oct",
    name: "oct",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Nov",
    name: "nov",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Dec",
    name: "dec",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];
export const OrderHistoryColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
    canSearch: true,
  },
  {
    title: "Outlet Name",
    name: "outletName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },

  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];
export const PaymentColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    align: "center",
  },
  {
    title: "Date",
    name: "billDate",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Amount",
    name: "amt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Settle Remaining Amount",
    name: "settleRemainingAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Pay Type",
    name: "payType",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const BillingHistoryColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Firm Name",
    name: "firmName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const CreditNoteColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    align: "center",
  },
  {
    title: "Beat",
    name: "beatName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Outlet",
    name: "outletName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Is Calculated ?",
    name: "isCalculated",
    positionCenter: false,
    align: "center",
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const requestGRNColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    align: "center",
  },
  {
    title: "GRN Number",
    name: "grnGvnNo",
    positionCenter: true,
    canSearch: true,
  },
  {
    title: "GRN Date",
    name: "date",
    positionCenter: true,
    canSearch: true,
  },
  {
    title: "GRN To",
    name: "toStockStakeHolderOwnerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Value",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: true,
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const GenratedBillListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];
export const OrderListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Stake Holder",
    name: "fromOrderOwnerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const GeneratedBillListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Stake Holder",
    name: "ownerAndFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Bill Received",
    name: "isRecievedName",
    positionCenter: true,
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const PlaceOrderColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: false,
    align: "center",
    canSearch: true,
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    formDataKey: "status",
    chipCompo: true,
    chipColor: "",
    chipClick: 0,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },

  { name: "action", title: "Action", align: "center" },
];

export const ShowMyBillListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Bill Received",
    name: "isRecievedStatus",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const historyOfCreditNoteColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Credit Note Number",
    name: "creditNoteNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Stake Holder",
    name: "ownerNameAndFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];

export const creditNoteHeaderListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Credit Note No",
    name: "creditNoteNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },

  { name: "action", title: "Action", align: "center" },
];

export const grnHistoryListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "GRN Number",
    name: "grnGvnNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "GRN Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Stake Holder",
    name: "fromStockStakeHolderOwnerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },

  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];
export const PendingGrnListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "GRN Number",
    name: "grnGvnNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "GRN Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Stake Holder",
    name: "ownerAndFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];
export const PaymentListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Firm Name",
    name: "fromStockStakeHolderFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Transaction No",
    name: "transactionNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Amount",
    name: "amt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Settle Remaining Amount",
    name: "settleRemainingAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Pay Type",
    name: "payType",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const MangeStockListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Consumption Number",
    name: "consumptionNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Type",
    name: "type",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },

  { name: "action", title: "Action", align: "center" },
];

export const CashReceiptColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Receipt No.",
    name: "documentNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
    linkFlag: true
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
    width: 90
  },
  // {
  //   title: "Debited Account",
  //   name: "accountLedgerName",
  //   positionCenter: false,
  //   canSearch: true,
  //   align: "left",
  // },
  {
    title: "Credited Account",
    name: "locationName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },

  {
    title: "Voucher Amount",
    name: "voucherAmount",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Received From",
    name: "receivedFrom",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },

  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
    chipCompo: true
  },
];
export const debitColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Receipt No.",
    name: "documentNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
    linkFlag: true
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
    width: 90
  },
  {
    title: "Debited Account",
    name: "accountLedgerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Credited Account",
    name: "locationName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },

  {
    title: "Voucher Amount",
    name: "voucherAmount",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
    chipCompo: true
  },
];
export const creditColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Receipt No.",
    name: "documentNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
    linkFlag: true
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
    width: 90
  },

  {
    title: "Credited Account",
    name: "locationName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Debited Account",
    name: "accountLedgerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Voucher Amount",
    name: "voucherAmount",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },

  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
    chipCompo: true
  },

];
export const ContraEntryColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Receipt No.",
    name: "documentNo",
    canSearch: true,
    align: "center",
    linkFlag: true
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
    width: 90
  },
  {
    title: "Credited Account",
    name: "locationName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Debited Account",
    name: "accountLedgerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },


  {
    title: "Voucher Amount",
    name: "voucherAmount",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
    chipCompo: true,
  },

];
export const CashPaymentColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Receipt No.",
    name: "documentNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
    linkFlag: true
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
    width: 90
  },

  // {
  //   title: "Credited Account",
  //   name: "locationName",
  //   positionCenter: false,
  //   canSearch: true,
  //   align: "left",
  // },
  {
    title: "Debited Account",
    name: "accountLedgerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Voucher Amount",
    name: "voucherAmount",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Paid To",
    name: "paidTo",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },

  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
    chipCompo: true,
  },

];

export const JVColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "JV No.",
    name: "jvNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
    linkFlag: true
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
    width: 90
  },
  // {
  //   title: "Voucher",
  //   name: "voucherName",
  //   positionCenter: false,
  //   canSearch: true,
  //   align: "left",
  // },
  // {
  //   title: "Account Name",
  //   name: "accountLedgerName",
  //   positionCenter: false,
  //   canSearch: true,
  //   align: "center",
  // },
  // {
  //   title: "Voucher Amount",
  //   name: "voucherAmount",
  //   positionCenter: false,
  //   canSearch: true,
  //   align: "center",
  // },
  {
    title: "Location",
    name: "locationName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
    chipCompo: true,
  },

];

export const BatchwiseOutstockColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Consumption Number",
    name: "consumptionNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Type",
    name: "type",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const stockConsumptionHeaderColums = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Product Name",
    name: "productName",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "left",
  },
  {
    title: "Opening Quantity",
    name: "openingQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Purchase Quantity",
    name: "purchaseQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Distributor Sell Quantity",
    name: "distributorSellQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Outlet Sell Quantity",
    name: "outletSellQty",
    canSearch: true,
    showInExcel: true,
    align: "right",
    width: 200,
  },
  {
    title: "GRN Quantity",
    name: "grnQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "In Stock Quantity",
    name: "inStockQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Out Stock Quantity",
    name: "outStockQty",
    canSearch: true,
    width: 200,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Closing Quantity",
    name: "closingQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Total",
    name: "total",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "left",
  },
];
export const stockBetweenDateColumns = [
  {
    title: "Sr.No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    align: "center",
  },
  {
    title: "Product Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: 200,
    canSearch: true,
  },
  {
    title: "UOM",
    name: "stakeHolderUomName",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "center",
    canSearch: true,
  },
  {
    title: "Opening Quantity",
    name: "openingQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },

  {
    title: "Purchase Quantity",
    name: "purchaseQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Distributor Quantity",
    name: "distributorSellQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Outlet Sell Quantity",
    name: "outletSellQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Grn Quantity",
    name: "grnQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Instock Quantity",
    name: "inStockQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Outstock Quantity",
    name: "outStockQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Closing Quantity",
    name: "closingQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
  },
  {
    title: "Total",
    name: "total",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "left",
    canSearch: true,
  },
];

export const BatchWiseOutletBillingListColumns = [
  { title: "Sr.No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Firm Name",
    name: "firmName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const BatchwiseStakeholderListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Order from",
    name: "toBillOwnerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Bill Received",
    name: "isRecievedName",
    positionCenter: true,
    canSearch: true,
  },

  { name: "action", title: "Action", align: "center" },
];

export const BatchwiseMangeStockColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Consumption Number",
    name: "consumptionNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Type",
    name: "type",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const paymentDetailsTableColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Amount",
    name: "amt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Pay Type",
    name: "payType",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Stake Holder",
    name: "toStakeHolderNameAndFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },

  { name: "action", title: "Action", align: "center" },
];

export const InactiveOutletColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Outlet",
    name: "outLetType",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Status",
    name: "status",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
];
export const OfferColumns = [
  { title: "Sr.No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Offer Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Offer For",
    name: "offerFor",
    formDataKey: "offerFor",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const ReportInfoColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Report Type",
    name: "reportType",
    formDataKey: "reportType",
    canSearch: true,
  },
  {
    title: "Report Name",
    name: "reportName",
    formDataKey: "reportName",
    canSearch: true,
  },
  {
    title: "Is PDF",
    name: "isPdf",
    formDataKey: "isPdf",
    canSearch: true,
  },
  {
    title: "Is EXCEL",
    name: "isExcel",
    formDataKey: "isExcel",
    canSearch: true,
  },
  {
    title: "Is Graph",
    name: "isGraph",
    formDataKey: "isGraph",
    canSearch: true,
  },
  {
    title: "Is Subreport",
    name: "isSubreport",
    formDataKey: "isSubreport",
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const MasterInfoColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Master Name",
    name: "reportName",
    formDataKey: "reportName",
    canSearch: true,
  },
  {
    title: "Show Add Button",
    name: "showAddButton",
    formDataKey: "showAddButton",
    canSearch: true,
  },
  {
    title: "Show Search Box",
    name: "showSearchBox",
    formDataKey: "showSearchBox",
    canSearch: true,
  },
  {
    title: "Is PDF",
    name: "isPdf",
    formDataKey: "isPdf",
    canSearch: true,
  },
  {
    title: "Is EXCEL",
    name: "isExcel",
    formDataKey: "isExcel",
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const TypeColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Type Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const accountLedgerContactInformationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Person Name",
    name: "contactPrsnName",
    showInscreen: true,
    positionCenter: false,
    alignCenter: "left",
  },
  {
    title: "Contact No",
    name: "contactNo",
    showInscreen: true,
    positionCenter: true,
    alignCenter: "center",
  },
  {
    name: "email",
    title: "E-mail",
    align: "center",
    showInscreen: true,
    positionCenter: false,
  },
  {
    name: "action",
    title: "Action",
    align: "center",
    showInscreen: true,
    positionCenter: false,
  },
];

export const accountLedgerBankInformationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Bank Name",
    name: "bankName",
    showInscreen: true,
    positionCenter: false,
    alignCenter: "left",
  },
  {
    title: "Account No",
    name: "accountNumber",
    showInscreen: true,
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Branch Name",
    name: "branchName",
    showInscreen: true,
    positionCenter: false,
    alignCenter: "left",
  },
  {
    name: "ifscCode",
    title: "IFSC Code",
    align: "center",
    showInscreen: true,
    positionCenter: false,
  },
  {
    name: "action",
    title: "Action",
    align: "center",
    showInscreen: true,
    positionCenter: false,
  },
];

export const AccountLedgerColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Account Code",
    name: "accountLedgerCode",
    formDataKey: "accountLedgerCode",
    canSearch: true,
    align: "center",
  },
  {
    title: "Account Ledger Name",
    name: "accountLedgerName",
    formDataKey: "accountLedgerName",
    canSearch: true,
  },
  // { title: "Status", name: "isActive", showInExcel: true, align: "center" },
  { name: "action", title: "Action", align: "center" },
];

export const AssetColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Ledger Code",
    name: "ledgerCode",
    showInscreen: true,
    positionCenter: false,
    alignCenter: "center",
    canSearch: true,
    width:200
  },
  {
    title: "Date",
    name: "date",
    showInscreen: true,
    positionCenter: false,
    align: "center",
    canSearch: true,
  },
  {
    title: "Depreciation Percentage",
    name: "depreciationPercentage",
    showInscreen: true,
    align: "right",
    canSearch: true,
  },
  {
    title: "Opening Value",
    name: "openingValue",
    showInscreen: true,
    positionCenter: false,
    align: "right",
    canSearch: true,
  },
  {
    title: "Purchase In First Half",
    name: "purchaseInFirstHalf",
    showInscreen: true,
    positionCenter: false,
    align: "right",
    canSearch: true,
  },
  {
    title: "Purchase In Second Half",
    name: "purchaseInSecondHalf",
    showInscreen: true,
    positionCenter: false,
    align: "right",
    canSearch: true,
  },
  {
    title: "Sale Value",
    name: "sellValue",
    showInscreen: true,
    positionCenter: false,
    align: "right",
    canSearch: true,
  },{
    title: "Total",
    name: "total",
    showInscreen: true,
    positionCenter: false,
    align: "right",
    canSearch: true,
  },
  {
    title: "Depreciation Value",
    name: "depriValue",
    showInscreen: true,
    positionCenter: false,
    align: "right",
    canSearch: true,
  },
 
  {
    title: "Closing Value",
    name: "closingValue",
    showInscreen: true,
    positionCenter: false,
    align: "right",
    canSearch: true,
  },
  // {
  //   title: "Profit Center",
  //   name: "profit",
  //   showInscreen: true,
  //   positionCenter: false,
  //   alignCenter: "left",
  //   canSearch: true,
  //   width:200
  // },
  {
    title: "Remark",
    name: "remark",
    showInscreen: true,
    positionCenter: false,
    align: "left",
    canSearch: true,
    width:250
  },
  { name: "action", title: "Action", align: "center" },


];
export const salePurchaseHistoryColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Document No",
    name: "documentNo",
    formDataKey: "documentNo",
    canSearch: true,
    align: "center",
  },
  {
    title: "Document Date",
    name: "documentDate",
    formDataKey: "documentDate",
    canSearch: true,
    align: "center",
  },


  {
    title: "Account Ledger Name",
    name: "accountLedgerName",
    formDataKey: "accountLedgerName",
    canSearch: true,
  },
  // { title: "Status", name: "isActive", showInExcel: true, align: "center" },
  { name: "action", title: "Action", align: "center" },
];