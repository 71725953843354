import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from "@mui/material/Tooltip";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../Comman/Button";
import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import MultipleSelect from "../Comman/MultipleSelect";
import TimePickerCompo from "../Comman/TimePicker";
import { TextFieldCompo } from "../Comman/TextFieldCompo";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import { resetReducList } from "../../CommonActions/User.action";
import {
  titleColor,
  redColor,
  labelColor,
  blackColor,
} from "../../config/ColorObj";
import MonthCalender from "../MonthCalender";
import DetailsIcon from '@mui/icons-material/Details';
class DynamicForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
    };
  }

  componentDidMount() { }

  onAutocompleteChange = (dataKey, isRootLevelKey) => (name, newValue) => {
    if (newValue != null) {
      let value = "";
      if (isRootLevelKey) {
        value = newValue;
      } else {
        value = {
          id: newValue,
        };
      }
      this.props.onDataChange(dataKey, value);
    } else {
      let value = "";
      this.props.onDataChange(dataKey, value);
    }
  };
  // multiSelectHandler = (value, name) => {
  //   this.setState({
  //     ...this.state,
  //     [name]: typeof value === "string" ? value.split(",") : value,
  //   });
  // };

  onMultiSelectChange = (dataKey, masterName) => (value, name) => {
      let dataValue = typeof value === "string" ? value.split(",") : value;
    this.props.onDataChange(dataKey, dataValue);
  };
  onTextFieldDataChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onDateChange = (dataKey) => (date) => {
    // date.setHours(0, 0, 0, 0);
    this.props.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.props.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onSave = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSave();
    }
  };
  onSaveDetail = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSaveDetail();
    }
  };
  
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.onSubmitCheck();
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData, today } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
       
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];

          if (typeof dataKeyValue === "string") {
            const dateArr = dataKeyValue?.split("-");

            dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
          }
          if (typeof compareWithValue === "string") {
            const dateArr = compareWithValue?.split("-");

            compareWithValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
          }

          switch (compareCond) {
            case "l":
              if (
                (!(dataKeyValue < compareWithValue) && today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue < compareWithValue) &&
                  today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue < compareWithValue) &&
                  today)
              ) {
                isError = true;
                condErrorText = "less than";
              }

              break;
            case "le":
              if (
                (!(dataKeyValue <= compareWithValue) && today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue <= compareWithValue) &&
                  today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue <= compareWithValue) &&
                  today)
              ) {
                isError = true;
                condErrorText = "less than equal to";
              }
              break;
            case "g":
              if (
                (!(dataKeyValue > compareWithValue) && today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue > compareWithValue) &&
                  today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue > compareWithValue) &&
                  today)
              ) {
                isError = true;
                condErrorText = "greater than";
              }

              break;
            case "ge":
              if (
                (!(dataKeyValue >= compareWithValue) && today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue >= compareWithValue) &&
                  today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue >= compareWithValue) &&
                  today)
              ) {
                isError = true;
                condErrorText = "greater than equal to";
              }

              break;
            case "eq":
              if (
                (!(dataKeyValue === compareWithValue) && today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue === compareWithValue) &&
                  today == false) ||
                (!(dataKeyValue !== "" && dataKeyValue === compareWithValue) &&
                  today)
              ) {
                isError = true;
                condErrorText = "equal to";
              }

              break;
            default:
              break;
          }

          if (isError) {
            const test = fieldMeta.filter(
              (item) => item.dataKey === compareWith
            )[0];

            const compareWithFieldLabel = fieldMeta.filter(
              (item) => item.dataKey === compareWith
            )[0].label;
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
              },
            };
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData, today } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            !today &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  monthChangeHandler = (dataKey) => (time) => {
  
    this.props.onDataChange(dataKey, time);
  };
  onSaveAndNext = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSaveAndNext();
    }
  };
  render() {
    const {
      fieldMeta,
      dynamicMasterData = {},
      fieldData,
      buttonCenter,
      searchIconTrue = true,
      GridSave = 0,
      marginTop,
      searchButton = true,
      detailButton=false,
      PDFButton = false,
      EXCELButton = false,
      today=true,
    } = this.props;

    const { formErrors } = this.state;
    return (
      <>
        <Grid container sx={{ marginTop: 1 }} spacing={1}>
          {fieldMeta.map((currentField) => (
            <Grid
              container
              xs={currentField.xs}
              sm={currentField.sm}
              md={currentField.md}
              sx={{
                marginTop: currentField.marginTop ? currentField.marginTop : 1,
              }}
            //  rowSpacing={12}
            >
              {currentField.toolTip && currentField.toolTip !== "" && (
                <>
                  <Tooltip placement="top" title={currentField.toolTip}>
                    <InfoIcon color="disabled" />
                  </Tooltip>
                </>
              )}
              <Grid
                item xs={12} sm={currentField.labelSM ? currentField.labelSM : 5}
                md={currentField.labelMD ? currentField.labelMD : 5}
                lg={currentField.labelLG ? currentField.labelLG : 5}>
                <>
                  {
                    (!currentField.hideLabel || currentField.hideLabel == undefined) ?
                      <>

                        {currentField.controlType !== "hideDatepicker" &&
                          currentField.controlType !== "hideTextfield" &&
                          currentField.controlType !== "hideAutocomplete" && (
                            <>
                              
                              <LabelCompo
                              isMandatory={currentField.isMandatory}
                                className="text-black"
                                style={{
                                  fontSize: 12,
                                  color: currentField.isMandatory
                                    ? titleColor
                                    : blackColor,
                                }}
                                label={currentField.label + ":"}
                              />
                            </>
                          )}
                        {currentField.controlType === "hideDatepicker" &&
                          fieldData[currentField.tableDataKey] ===
                          currentField.open && (
                            <>
                             
                              <LabelCompo
                              isMandatory={currentField.isMandatory}
                                className="text-black"
                                style={{
                                  fontSize: 12,
                                  color: currentField.isMandatory
                                    ? titleColor
                                    : blackColor,
                                }}
                                label={currentField.label + ":"}
                              />
                            </>
                          )}
                        {currentField.controlType === "hideAutocomplete" &&
                          fieldData[currentField.tableDataKey] ===
                          currentField.open && (
                            <>
                             
                              <LabelCompo
                              isMandatory={currentField.isMandatory}
                                className="text-black"
                                style={{
                                  fontSize: 12,
                                  color: currentField.isMandatory
                                    ? titleColor
                                    : blackColor,
                                }}
                                label={currentField.label + ":"}
                              />
                            </>
                          )}
                        {currentField.controlType === "hideTextfield" &&
                          fieldData[currentField.tableDataKey] ===
                          currentField.open && (
                            <>
                              {currentField.showLabel ? (
                                <>
                                  
                                  <LabelCompo
                                  isMandatory={currentField.isMandatory}
                                    className="text-black"
                                    style={{
                                      fontSize: 12,
                                      color: currentField.isMandatory
                                        ? titleColor
                                        : blackColor,
                                    }}
                                    label={currentField.label + ":"}
                                  />
                                </>
                              ) : (
                                <>
                                  &nbsp;&nbsp;
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 12 }}
                                    label={"  "}
                                  />
                                </>
                              )}
                            </>
                          )}</> : null
                  }
                </>

              </Grid>

              <Grid item
                xs={12}
                sm={currentField.valueSM ? currentField.valueSM : 7}
                md={currentField.valueMD ? currentField.valueMD : 7}
                lg={currentField.valueLG ? currentField.valueLG : 7}
                sx={{ paddingRight: 2 }}>
                {currentField.controlType === "textfield" && (
                  <>
                    <TextFieldCompo
                      placeHolder={currentField.placeHolder}
                      size="small"
                      color="primary"
                      name={currentField.label}
                      id={currentField.label}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      disabled={
                        fieldData["id"] && currentField.disable
                          ? true
                          : currentField.disableTrue
                            ? true
                            : false
                      }
                      inputProps={{
                        maxLength:
                          currentField.maxLength && currentField.maxLength,
                        min: currentField.minNum && currentField.minNum,
                      }}
                      type={
                        currentField.inputType ? currentField.inputType : "text"
                      }
                      onChange={this.onTextFieldDataChange(
                        currentField.dataKey
                      )}
                      fullWidth
                      errorText={
                        formErrors[currentField.dataKey]?.isError
                          ? formErrors[currentField.dataKey].errorText
                          : ""
                      }
                    />
                    {currentField.note &&
                      currentField.space &&
                      currentField.note.split("@").map((note) => {
                        return (
                          <>
                            <LabelCompo
                              className="text-black"
                              style={{
                                fontSize: 12,
                                color: labelColor,
                                marginLeft: 2,
                              }}
                              label={note}
                            />
                            {currentField.space === "br" ? (
                              <br></br>
                            ) : currentField.space === "&nbsp;" ? (
                              " "
                            ) : (
                              ""
                            )}
                            {/* <br></br> */}
                          </>
                        );
                      })}
                  </>
                )}
                {currentField.controlType === "textarea" && (
                  <TextFieldCompo
                    placeHolder={currentField.placeHolder}
                    size="small"
                    color="primary"
                    name={currentField.label}
                    id={currentField.label}
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    disabled={
                      fieldData["id"] && currentField.disable ? true : false
                    }
                    inputProps={{
                      maxLength:
                        currentField.maxLength && currentField.maxLength,
                    }}
                    type={
                      currentField.inputType ? currentField.inputType : "text"
                    }
                    multiline
                    onChange={this.onTextFieldDataChange(currentField.dataKey)}
                    fullWidth
                    errorText={
                      formErrors[currentField.dataKey]?.isError
                        ? formErrors[currentField.dataKey].errorText
                        : ""
                    }
                  />
                )}
                 {currentField.controlType === "monthpicker" && (
                                    <MonthCalender
                                      title=""
                                      name="saleMonth"
                                      value={"saleMonth"}
                                      onChange={this.monthChangeHandler(currentField.dataKey)}
                                      sx={{
                                        paddingBottom: 0,
                                      }}
                                    />
                                  )}
                {currentField.controlType === "hideTextfield" &&
                  fieldData[currentField.tableDataKey] ===
                  currentField.open && (
                    <TextFieldCompo
                      placeHolder={currentField.placeHolder}
                      size="small"
                      color="primary"
                      name={currentField.label}
                      disabled={
                        fieldData["id"] && currentField.disable
                          ? true
                          : currentField.disableTrue
                            ? true
                            : false
                      }
                      id={currentField.label}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      inputProps={{
                        maxLength:
                          currentField.maxLength && currentField.maxLength,
                      }}
                      type={
                        currentField.inputType ? currentField.inputType : "text"
                      }
                      onChange={this.onTextFieldDataChange(
                        currentField.dataKey
                      )}
                      fullWidth
                      errorText={
                        formErrors[currentField.dataKey]?.isError
                          ? formErrors[currentField.dataKey].errorText
                          : ""
                      }
                    />
                  )}
                {currentField.controlType === "datepicker" && (
                  <BasicDatePicker
                    color="primary"
                    name="dob"
                    size="small"
                    value={
                      fieldData[currentField.dataKey] && today == false
                        ? fieldData[currentField.dataKey]
                        : fieldData[currentField.dataKey] && today == true
                          ? fieldData[currentField.dataKey]
                          : today == true
                            ? dayjs()
                            : null
                    }
                    {...(currentField.isMAxDate && {
                      maxDate: dayjs(),
                    })}
                    {...(currentField.isMinDate && {
                      minDate: dayjs(),
                    })}
                    disabled={
                      fieldData["id"] && currentField.disable
                        ? true
                        : currentField.disableTrue
                          ? true
                          : false
                    }
                    fullWidth
                    onChange={this.onDateChange(currentField.dataKey)}
                    isError={formErrors[currentField.dataKey]?.isError}
                    errorText={formErrors[currentField.dataKey]?.errorText}
                  />
                )}
                {currentField.controlType === "hideDatepicker" &&
                  fieldData[currentField.tableDataKey] ===
                  currentField.open && (
                    <BasicDatePicker
                      color="primary"
                      name="dob"
                      size="small"
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : null
                      }
                      {...(currentField.isMAxDate && {
                        maxDate: dayjs(),
                      })}
                      {...(currentField.isMinDate && {
                        minDate: dayjs(),
                      })}
                      disabled={
                        fieldData["id"] && currentField.disable ? true : false
                      }
                      fullWidth
                      onChange={this.onDateChange(currentField.dataKey)}
                      isError={formErrors[currentField.dataKey]?.isError}
                      errorText={formErrors[currentField.dataKey]?.errorText}
                    />
                  )}
                {currentField.controlType === "hideAutocomplete" &&
                  fieldData[currentField.tableDataKey] ===
                  currentField.open && (
                    <AutoComplete
                      label={currentField.label}
                      placeHolder={currentField.placeHolder}
                      keyColName={"id"}
                      value={
                        currentField.isRootLevelKey &&
                          fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : !currentField.isRootLevelKey &&
                            fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey].id
                            : ""
                      }
                      options={
                        dynamicMasterData[currentField["masterName"]]
                          ? dynamicMasterData[currentField["masterName"]]
                          : []
                      }
                      disabled={
                        fieldData["id"] && currentField.disable ? true : false
                      }
                      onChange={this.onAutocompleteChange(
                        currentField.dataKey,
                        currentField.isRootLevelKey
                      )}
                      isError={formErrors[currentField.dataKey]?.isError}
                      errorText={formErrors[currentField.dataKey]?.errorText}
                    />
                  )}
                {currentField.controlType === "autocomplete" && (
                  <>
                    <AutoComplete
                      label={currentField.label}
                      placeHolder={currentField.placeHolder}
                      keyColName={"id"}
                      value={
                        currentField.isRootLevelKey &&
                          fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : !currentField.isRootLevelKey &&
                            fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey].id
                            : ""
                      }
                      options={
                        dynamicMasterData[currentField["masterName"]]
                          ? dynamicMasterData[currentField["masterName"]]
                          : []
                      }
                      disabled={
                        fieldData["id"] && currentField.disable ? true : false
                      }
                      onChange={this.onAutocompleteChange(
                        currentField.dataKey,
                        currentField.isRootLevelKey
                      )}
                      isError={formErrors[currentField.dataKey]?.isError}
                      errorText={formErrors[currentField.dataKey]?.errorText}
                    />

                    {currentField.note &&
                      currentField.space &&
                      currentField.note.split("@").map((note) => {
                        return (
                          <>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 12, color: labelColor }}
                              label={note}
                            />
                            {currentField.space === "br" ? (
                              <br></br>
                            ) : currentField.space === "&nbsp;" ? (
                              " "
                            ) : (
                              ""
                            )}
                            {/* <br></br> */}
                          </>
                        );
                      })}
                  </>
                )}
                {currentField.controlType === "autocomplete/textfield" && (
                  <>
                    {fieldData["id"] ? (
                      <TextFieldCompo
                        placeHolder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKeyName] &&
                            fieldData[currentField.dataKeyName]
                            ? fieldData[currentField.dataKeyName]
                            : ""
                        }
                        disabled={true}
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                          min: currentField.minNum && currentField.minNum,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey
                        )}
                        fullWidth
                        errorText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : ""
                        }
                      />
                    ) : (
                      <>
                        <AutoComplete
                          label={currentField.label}
                          placeHolder={currentField.placeHolder}
                          keyColName={"id"}
                          value={
                            currentField.isRootLevelKey &&
                              fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : !currentField.isRootLevelKey &&
                                fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey].id
                                : ""
                          }
                          options={
                            dynamicMasterData[currentField["masterName"]]
                              ? dynamicMasterData[currentField["masterName"]]
                              : []
                          }
                          disabled={
                            fieldData["id"] && currentField.disable
                              ? true
                              : false
                          }
                          onChange={this.onAutocompleteChange(
                            currentField.dataKey,
                            currentField.isRootLevelKey
                          )}
                          isError={formErrors[currentField.dataKey]?.isError}
                          errorText={
                            formErrors[currentField.dataKey]?.errorText
                          }
                        />
                        {currentField.note &&
                          currentField.space &&
                          currentField.note.split("@").map((note) => {
                            return (
                              <>
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 12, color: labelColor }}
                                  label={note}
                                />
                                {currentField.space === "br" ? (
                                  <br></br>
                                ) : currentField.space === "&nbsp;" ? (
                                  " "
                                ) : (
                                  ""
                                )}
                                {/* <br></br> */}
                              </>
                            );
                          })}
                      </>
                    )}
                  </>
                )}
                {currentField.controlType === "multiSelect" && (
                  <MultipleSelect
                    label={currentField.placeHolder}
                    er={formErrors[currentField.dataKey]?.errorText}
                    name1={currentField.dataKey}
                    value1={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : []
                    }
                    names={
                      dynamicMasterData[currentField["masterName"]] &&
                        dynamicMasterData[currentField["masterName"]].length != 0
                        ? [
                          { id: 0, name: "All" },
                          ...dynamicMasterData[currentField["masterName"]],
                        ]
                        : []
                    }
                    onChange={this.onMultiSelectChange(
                      currentField.dataKey,
                      currentField["masterName"]
                    )}
                  />
                )}
                {currentField.controlType === "timePicker" && (
                  <TimePickerCompo
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    onChange={this.timeChangeHandler(currentField.dataKey)}
                  />
                )}
                {currentField.controlType === "radiogroup" && (
                  <>
                    <FormControl>
                      <RadioGroup
                        row
                        // aria-label={currentField.label}
                        // name={currentField.label}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        id={currentField.label}
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : currentField.defaultValue
                              ? currentField.defaultValue
                              : ""
                        }
                        onChange={this.onRadioChange(currentField.dataKey)}
                        size="small"
                      >
                        {currentField.radioGroupItems.map((radioItem) => (
                          <FormControlLabel
                          sx={{
                            "& svg": {
                              width: "0.8em",
                              height: "0.8em"
                            }
                          }}
                            value={radioItem.value}
                            control={<Radio />}
                            label={radioItem.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    {currentField.note &&
                      currentField.note.split("@").map((note) => {
                        return (
                          <>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 12, color: labelColor }}
                              label={note}
                            />
                            <br></br>
                          </>
                        );
                      })}
                  </>
                )}
              </Grid>
            </Grid>
          ))}

          {!buttonCenter && (PDFButton || EXCELButton) && (
            <Grid
              item
              sm={12}
              xs={12}
              md={1.2}
              lg={1.2}
              columnSpacing={1}
              sx={{ marginTop: marginTop }}
              container
              justifyContent="space-evenly"
            >
              {PDFButton && !EXCELButton && (
                <>
                  <Grid item sm={12} xs={12} md={4} lg={4}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Export to PDF"
                      fullWidth={true}
                      onClick={this.onSave}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12} md={7} lg={7} />
                </>
              )}
              {EXCELButton && !PDFButton && (
                <>
                  <Grid item sm={12} xs={12} md={4} lg={4}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Export to EXCEL"
                      fullWidth={true}
                      onClick={this.onSaveAndNext}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12} md={7} lg={7} />
                </>
              )}
              {EXCELButton && PDFButton && (
                <>
                  <Grid item sm={12} xs={12} md={4} lg={4}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Export to EXCEL"
                      fullWidth={true}
                      onClick={this.onSaveAndNext}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Export to PDF"
                      fullWidth={true}
                      onClick={this.onSave}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4} />
                </>
              )}
            </Grid>
          )}
          {!buttonCenter && searchButton && (
            <Grid
              item
              xs={12}
              sm={GridSave != 0 ? GridSave : 4}
              md={GridSave != 0 ? GridSave : 4}
              lg={GridSave != 0 ? GridSave : 4}
              container
              justifyContent="space-evenly"
            >
              <Grid item sm={12} xs={12} md={4} lg={4}>

                <Button onClick={this.onSave}variant="contained" color={"primary"}>
                 
                 {searchIconTrue?<SearchIcon />:
                 'Export'}
                </Button>
              </Grid>

              <Grid item sm={12} xs={12} md={10} lg={10} />
            </Grid>
          )}
           {detailButton && (
            <Grid
              item
              xs={12}
              sm={GridSave != 0 ? GridSave : 4}
              md={GridSave != 0 ? GridSave : 4}
              lg={GridSave != 0 ? GridSave : 4}
              container
              justifyContent="space-evenly"
            >
              <Grid item sm={12} xs={12} md={4} lg={4}>

                <Button onClick={this.onSaveDetail}variant="contained" color={"primary"}>
                  <DetailsIcon />
                </Button>
              </Grid>

              <Grid item sm={12} xs={12} md={10} lg={10} />
            </Grid>
          )}
        </Grid>{" "}
        {buttonCenter && (PDFButton || EXCELButton) && (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="center"
          >
            {EXCELButton && (
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Export to EXCEL"
                  fullWidth={true}
                  onClick={this.onSaveAndNext}
                />
              </Grid>
            )}
            {PDFButton && (
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Export to PDF"
                  fullWidth={true}
                  onClick={this.onSave}
                />
              </Grid>
            )}
          </Grid>
        )}
        {buttonCenter && searchButton && (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="center"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Search"
                fullWidth={true}
                onClick={this.onSave}
              />
            </Grid>
          </Grid>
        )}
        {/* {detailButton &&( <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="center"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Detail"
                fullWidth={true}
                onClick={this.onSave}
              />
            </Grid>
          </Grid>)} */}
        
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicForm);
