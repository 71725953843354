import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ratioAnalysisJson } from "../../DynamicFormsJson/Report/RatioAnalysis";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GroupSummarySubReportByGroup from "./GroupSummarySubReportByGroup";
import MFGAndPL from "./MFGAndPL";

import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import RatioAnalysisTable from "../AccountReport/RatioAnalysisTable";
const RatioAnalysis = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [formData, setFormData] = React.useState({});
  const [cashList, setCashbookList] = React.useState([]);
  const [RatioAnalysis, setRatioAnalysis] = React.useState({});
  const [popUpData, setPopupdata] = useState({});
  const [openTable, setOpenTable] = useState(false);
  const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
  const [fieldData, setFieldData] = React.useState({});
  useEffect(() => {
    getDropDownList();
  }, []);

  const getDropDownList = async () => {
    showLoador({ loador: true });
    const yearMasterDetail = await apiGet({
      url: endpoints.year,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        let rowData = data.data;

        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.yearName };
        });
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    showLoador({ loador: true });
    let CompanyDetails = await apiGet({
      url: endpoints.company,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        let rowData = data.data;
        if (rowData.length == 1) {
          setFieldData({ companyId: rowData[0].id });
        }
        return rowData
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    setDynamicMasterData({
      yearMasterDetail: yearMasterDetail,
      CompanyDetails: CompanyDetails,
    });
  };
  const getList = (companyId, yearId) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoints.cashFlowSummary +
        "/ratio-analysis?companyIds=" +
        companyId +
        "&yearId=" +
        yearId,
    }).then(({ data, success }) => {
      if (success) {
        let rowList = [];
        let leftAmount = 0;
        let rightAmount = 0;

        let rowData = data.data;
        let dynamicData = rowData.show_data;
        let dont_show = rowData.dont_show;
        dynamicData=[
            ...dynamicData,
            ...dont_show
        ]
        let staticData = rowData.static_data;
        let currentAsset = dont_show.find(
          (row) =>
            row.groupDetail &&
            row.groupDetail != null &&
            row.groupDetail.group_name == "Current Assets"
        );
        let CurrentLiabilities = dont_show.find(
          (row) =>
            row.groupDetail &&
            row.groupDetail != null &&
            row.groupDetail.group_name == "Current Liabilities"
        );
        let currentAssetAmt = currentAsset.creditAmt + currentAsset.debitAmt;
        let CurrentLiabilitiesAmt =
          CurrentLiabilities.creditAmt + CurrentLiabilities.debitAmt;
  
        let ratioData = {};
        dynamicData.map((row) => {
          let group_name =
            row.groupDetail &&
            row.groupDetail != null &&
            row.groupDetail.group_name
              ? row.groupDetail.group_name
              : "";
          ratioData = {
            ...ratioData,
            [group_name]: row.creditAmt + row.debitAmt,
          };
        });
        staticData.map((row) => {
            let group_name =row.name;
            ratioData = {
              ...ratioData,
              [group_name]: row.amt,
            };
          });
console.log(ratioData)
        setRatioAnalysis({
          workingCapital: currentAssetAmt - CurrentLiabilitiesAmt,
          ...ratioData
        });
        setCashbookList(dynamicData);
        showLoador({ loador: false });
      } else {
        setCashbookList([]);
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  };

  const onSave = (dataToSearch) => {
    let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter(
      (row) => row.id === dataToSearch.companyId
    );
    let filterYearMasterDetail = dynamicMasterData["yearMasterDetail"].filter(
      (row) => row.id === dataToSearch.yearId
    );

    setFormData({
      ...dataToSearch,
      name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
      yearName:
        filterYearMasterDetail.length != 0
          ? filterYearMasterDetail[0].name
          : "",
      fromDate:
        filterYearMasterDetail.length != 0
          ? filterYearMasterDetail[0].fromDate
          : "",
      toDate:
        filterYearMasterDetail.length != 0
          ? filterYearMasterDetail[0].toDate
          : "",
    });
    getList(dataToSearch.companyId, dataToSearch.yearId);
    //   getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId,dataToSearch.accountLedgerGroupsId )
  };
  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };

  const generatePDFMethod = () => {
   
    var columns = [["Principal Group", "Amount", "Principal Ratios", "Amount"]];

    let tableList = [];
    let tableData = [];

    let capitalTurn=  RatioAnalysis['Sales Account']&&RatioAnalysis['workingCapital']?(RatioAnalysis['Sales Account']/RatioAnalysis['workingCapital']):0
    let inventry=RatioAnalysis['Sales Account']&&RatioAnalysis['Stock-In-Hand']?(RatioAnalysis['Sales Account']/RatioAnalysis['Stock-In-Hand']):0
    let CurrentRatio=RatioAnalysis['Current Assets']&&RatioAnalysis['Current Liabilities']?(RatioAnalysis['Current Assets']/RatioAnalysis['Current Liabilities']):0
        
    let QuickRatio=RatioAnalysis['Current Assets']&&RatioAnalysis['Current Liabilities']&&RatioAnalysis['Stock-In-Hand']?((RatioAnalysis['Current Assets']-RatioAnalysis['Stock-In-Hand'] )/RatioAnalysis['Current Liabilities']):0
    
    let WkgCapital=RatioAnalysis['Profit And Loss A/c']&&RatioAnalysis['workingCapital']?(RatioAnalysis['Profit And Loss A/c'] /RatioAnalysis['workingCapital'])*100:0
    
    let sumData=RatioAnalysis['Profit And Loss A/c']+RatioAnalysis['Capital Accounts']
    
    let returnInv=RatioAnalysis['Profit And Loss A/c']&&sumData?(RatioAnalysis['Profit And Loss A/c'] /sumData)*100:0
    
    let grossProfit=RatioAnalysis['Sales Account']&&RatioAnalysis['Purchase Accounts']?(RatioAnalysis['Sales Account'] /RatioAnalysis['Purchase Accounts'])*100:0
    let netProfit=RatioAnalysis['Sales Account']&&RatioAnalysis['Profit And Loss A/c']?(RatioAnalysis['Sales Account'] /RatioAnalysis['Profit And Loss A/c'])*100:0
    let loan=RatioAnalysis['Loans (Liability)']&&sumData?(RatioAnalysis['Loans (Liability)'] /sumData):0
    let opration=RatioAnalysis['Indirect Expenses']&&RatioAnalysis['Sales Account']?(RatioAnalysis['Indirect Expenses'] /RatioAnalysis['Sales Account'])*100:0
 
    tableData = []
    tableData.push('Working Capital \n ( Current Assets-Current Liabilities )');
    tableData.push(RatioAnalysis.workingCapital&&RatioAnalysis.workingCapital > 0?RatioAnalysis.workingCapital.toFixed(2)+ " Cr":RatioAnalysis.workingCapital? Math.abs(RatioAnalysis.workingCapital).toFixed(2)+ " Dr":0);
    tableData.push('Current Ratio \n( Current Assets : Current Liabilities )');
    tableData.push(CurrentRatio?Math.abs(CurrentRatio).toFixed(2) +" : 1" :+"0 : 1");
    tableList.push(tableData);
      
    tableData = []
    tableData.push('Cash in hand ');
    tableData.push(RatioAnalysis['Cash in hand']&&RatioAnalysis['Cash in hand'] > 0?RatioAnalysis['Cash in hand'].toFixed(2)+ " Cr":RatioAnalysis['Cash in hand']? Math.abs(RatioAnalysis['Cash in hand']).toFixed(2)+ " Dr":0);
    tableData.push('Quick Ratio \n ( Current Assets - Stock-in-Hand : Current Liabilities )');
    tableData.push(QuickRatio?Math.abs(QuickRatio).toFixed(2) +" : 1" :+"0 : 1");
    tableList.push(tableData);
  
    tableData = []
    tableData.push('Bank Accounts');
    tableData.push(RatioAnalysis['Bank Accounts']&&RatioAnalysis['Bank Accounts'] > 0?RatioAnalysis['Bank Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Bank Accounts']? Math.abs(RatioAnalysis['Bank Accounts']).toFixed(2)+ " Dr":0);
    tableData.push('Debt/Equity Ratio \n ( Loan ( Liability ) : Capital Account + Net Profit )');
    tableData.push(loan?Math.abs(loan).toFixed(2)+" %":0);
    tableList.push(tableData);  
   
    tableData = []
    tableData.push('Bank OD Accounts');
    tableData.push(RatioAnalysis['Bank OD Accounts']&&RatioAnalysis['Bank OD Accounts'] > 0?RatioAnalysis['Bank OD Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Bank OD Accounts']? Math.abs(RatioAnalysis['Bank OD Accounts']).toFixed(2)+ " Dr":0);
    tableData.push('Gross Profit % ');
    tableData.push(grossProfit?Math.abs(grossProfit).toFixed(2)+" %":0);
    tableList.push(tableData); 
        
    tableData = []
    tableData.push('Sundry Debtors');
    tableData.push(RatioAnalysis['Sundry Debtors']&&RatioAnalysis['Sundry Debtors'] > 0?RatioAnalysis['Sundry Debtors'].toFixed(2)+ " Cr":RatioAnalysis['Sundry Debtors']? Math.abs(RatioAnalysis['Sundry Debtors']).toFixed(2)+ " Dr":0);
    tableData.push('Net Profit %');
    tableData.push(netProfit?Math.abs(netProfit).toFixed(2)+" %":0);
    tableList.push(tableData);   
      
    tableData = []
    tableData.push('Sundry Creditors');
    tableData.push(RatioAnalysis['Sundry Creditors']&&RatioAnalysis['Sundry Creditors'] > 0?RatioAnalysis['Sundry Creditors'].toFixed(2)+ " Cr":RatioAnalysis['Sundry Creditors']? Math.abs(RatioAnalysis['Sundry Creditors']).toFixed(2)+ " Dr":0);
    tableData.push('Operating Cost % \n ( As percentage of Sales Accounts )');
    tableData.push(opration?Math.abs(opration).toFixed(2)+" %":0);
    tableList.push(tableData); 
   
    tableData = []
    tableData.push('Sales Account');
    tableData.push(RatioAnalysis['Sales Account']&&RatioAnalysis['Sales Account'] > 0?RatioAnalysis['Sales Account'].toFixed(2)+ " Cr":RatioAnalysis['Sales Account']? Math.abs(RatioAnalysis['Sales Account']).toFixed(2)+ " Dr":0);
    tableData.push('Recv. Turnover in days \n ( Payment performance of Debtors )');
    tableData.push('0 Days');
    tableList.push(tableData);    
          
    tableData = []
    tableData.push('Purchase Accounts');
    tableData.push(RatioAnalysis['Purchase Accounts']&&RatioAnalysis['Purchase Accounts'] > 0?RatioAnalysis['Purchase Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Purchase Accounts']? Math.abs(RatioAnalysis['Purchase Accounts']).toFixed(2)+ " Dr":0);
    tableData.push('Return on Inventment % \n (Net profit / Captial Account + Net profit)');
    tableData.push(returnInv?Math.abs(returnInv).toFixed(2)+" %":0);
    tableList.push(tableData); 
         
    tableData = []
    tableData.push('Stock-In-Hand');
    tableData.push(RatioAnalysis['Stock-In-Hand']&&RatioAnalysis['Stock-In-Hand'] > 0?RatioAnalysis['Stock-In-Hand'].toFixed(2)+ " Cr":RatioAnalysis['Stock-In-Hand']? Math.abs(RatioAnalysis['Stock-In-Hand']).toFixed(2)+ " Dr":0);
    tableData.push('Return on Wkg. Capital % \n (Net profit / Working Captial )');
    tableData.push(WkgCapital?Math.abs(WkgCapital).toFixed(2)+" %":0);
    tableList.push(tableData); 
    
    tableData = []
    tableData.push('Net Profit');
    tableData.push(RatioAnalysis['Profit And Loss A/c']&&RatioAnalysis['Profit And Loss A/c'] > 0?RatioAnalysis['Profit And Loss A/c'].toFixed(2)+ " Cr":RatioAnalysis['Profit And Loss A/c']? Math.abs(RatioAnalysis['Profit And Loss A/c']).toFixed(2)+ " Dr":0);
    tableData.push('');
    tableData.push('');
    tableList.push(tableData); 

    tableData = []
    tableData.push('Wkg. Capital Turnover \n ( Sales Account / Working Capital )');
    tableData.push(capitalTurn&&capitalTurn > 0?capitalTurn.toFixed(2):capitalTurn? Math.abs(capitalTurn).toFixed(2):0);
    tableData.push('');
    tableData.push('');
    tableList.push(tableData);      
    
    tableData = []
    tableData.push('Inventry Turnover \n( Sales Account / Closing Stock )');
    tableData.push(inventry&&inventry > 0?inventry.toFixed(2):inventry? Math.abs(inventry).toFixed(2):0);
    tableData.push('');
    tableData.push('');
    tableList.push(tableData);       
 
    const columnStylesPlain = {
      0: { cellWidth: 100 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    const columnStyles = {
      0: { cellWidth:170 },
      1: { cellWidth: 70 },
      3: { cellWidth: 70 },
    };
    generatePDF({
      columnStylesPlain: columnStylesPlain,
      rows: tableList,
      columns,
      columnStyles,
      headerList: [
        {
          label: "Company Name",
          value: formData.name,
        },
        {
          label: "Year Name",
          value: formData.yearName,
        },
      ],
      pdfTitle: ratioAnalysisJson.screenTitle,
    });
  };


  const generateExcelMethod = () => {
    var columns = ["Principal Group", "Amount", "Principal Ratios", "Amount"];
    let alignList = [];

  
    let tableList = [];
    let tableData = [];

    let capitalTurn=  RatioAnalysis['Sales Account']&&RatioAnalysis['workingCapital']?(RatioAnalysis['Sales Account']/RatioAnalysis['workingCapital']):0
    let inventry=RatioAnalysis['Sales Account']&&RatioAnalysis['Stock-In-Hand']?(RatioAnalysis['Sales Account']/RatioAnalysis['Stock-In-Hand']):0
    let CurrentRatio=RatioAnalysis['Current Assets']&&RatioAnalysis['Current Liabilities']?(RatioAnalysis['Current Assets']/RatioAnalysis['Current Liabilities']):0
        
    let QuickRatio=RatioAnalysis['Current Assets']&&RatioAnalysis['Current Liabilities']&&RatioAnalysis['Stock-In-Hand']?((RatioAnalysis['Current Assets']-RatioAnalysis['Stock-In-Hand'] )/RatioAnalysis['Current Liabilities']):0
    
    let WkgCapital=RatioAnalysis['Profit And Loss A/c']&&RatioAnalysis['workingCapital']?(RatioAnalysis['Profit And Loss A/c'] /RatioAnalysis['workingCapital'])*100:0
    
    let sumData=RatioAnalysis['Profit And Loss A/c']+RatioAnalysis['Capital Accounts']
    
    let returnInv=RatioAnalysis['Profit And Loss A/c']&&sumData?(RatioAnalysis['Profit And Loss A/c'] /sumData)*100:0
    
    let grossProfit=RatioAnalysis['Sales Account']&&RatioAnalysis['Purchase Accounts']?(RatioAnalysis['Sales Account'] /RatioAnalysis['Purchase Accounts'])*100:0
    let netProfit=RatioAnalysis['Sales Account']&&RatioAnalysis['Profit And Loss A/c']?(RatioAnalysis['Sales Account'] /RatioAnalysis['Profit And Loss A/c'])*100:0
    let loan=RatioAnalysis['Loans (Liability)']&&sumData?(RatioAnalysis['Loans (Liability)'] /sumData):0
    let opration=RatioAnalysis['Indirect Expenses']&&RatioAnalysis['Sales Account']?(RatioAnalysis['Indirect Expenses'] /RatioAnalysis['Sales Account'])*100:0
 
    tableData = []
    tableData.push('Working Capital \n ( Current Assets-Current Liabilities )');
    tableData.push(RatioAnalysis.workingCapital&&RatioAnalysis.workingCapital > 0?RatioAnalysis.workingCapital.toFixed(2)+ " Cr":RatioAnalysis.workingCapital? Math.abs(RatioAnalysis.workingCapital).toFixed(2)+ " Dr":0);
    tableData.push('Current Ratio \n( Current Assets : Current Liabilities )');
    tableData.push(CurrentRatio?Math.abs(CurrentRatio).toFixed(2) +" : 1" :+"0 : 1");
    tableList.push(tableData);
      
    tableData = []
    tableData.push('Cash in hand ');
    tableData.push(RatioAnalysis['Cash in hand']&&RatioAnalysis['Cash in hand'] > 0?RatioAnalysis['Cash in hand'].toFixed(2)+ " Cr":RatioAnalysis['Cash in hand']? Math.abs(RatioAnalysis['Cash in hand']).toFixed(2)+ " Dr":0);
    tableData.push('Quick Ratio \n ( Current Assets - Stock-in-Hand : Current Liabilities )');
    tableData.push(QuickRatio?Math.abs(QuickRatio).toFixed(2) +" : 1" :+"0 : 1");
    tableList.push(tableData);
  
    tableData = []
    tableData.push('Bank Accounts');
    tableData.push(RatioAnalysis['Bank Accounts']&&RatioAnalysis['Bank Accounts'] > 0?RatioAnalysis['Bank Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Bank Accounts']? Math.abs(RatioAnalysis['Bank Accounts']).toFixed(2)+ " Dr":0);
    tableData.push('Debt/Equity Ratio \n ( Loan ( Liability ) : Capital Account + Net Profit )');
    tableData.push(loan?Math.abs(loan).toFixed(2)+" %":0);
    tableList.push(tableData);  
   
    tableData = []
    tableData.push('Bank OD Accounts');
    tableData.push(RatioAnalysis['Bank OD Accounts']&&RatioAnalysis['Bank OD Accounts'] > 0?RatioAnalysis['Bank OD Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Bank OD Accounts']? Math.abs(RatioAnalysis['Bank OD Accounts']).toFixed(2)+ " Dr":0);
    tableData.push('Gross Profit % ');
    tableData.push(grossProfit?Math.abs(grossProfit).toFixed(2)+" %":0);
    tableList.push(tableData); 
        
    tableData = []
    tableData.push('Sundry Debtors');
    tableData.push(RatioAnalysis['Sundry Debtors']&&RatioAnalysis['Sundry Debtors'] > 0?RatioAnalysis['Sundry Debtors'].toFixed(2)+ " Cr":RatioAnalysis['Sundry Debtors']? Math.abs(RatioAnalysis['Sundry Debtors']).toFixed(2)+ " Dr":0);
    tableData.push('Net Profit %');
    tableData.push(netProfit?Math.abs(netProfit).toFixed(2)+" %":0);
    tableList.push(tableData);   
      
    tableData = []
    tableData.push('Sundry Creditors');
    tableData.push(RatioAnalysis['Sundry Creditors']&&RatioAnalysis['Sundry Creditors'] > 0?RatioAnalysis['Sundry Creditors'].toFixed(2)+ " Cr":RatioAnalysis['Sundry Creditors']? Math.abs(RatioAnalysis['Sundry Creditors']).toFixed(2)+ " Dr":0);
    tableData.push('Operating Cost % \n ( As percentage of Sales Accounts )');
    tableData.push(opration?Math.abs(opration).toFixed(2)+" %":0);
    tableList.push(tableData); 
   
    tableData = []
    tableData.push('Sales Account');
    tableData.push(RatioAnalysis['Sales Account']&&RatioAnalysis['Sales Account'] > 0?RatioAnalysis['Sales Account'].toFixed(2)+ " Cr":RatioAnalysis['Sales Account']? Math.abs(RatioAnalysis['Sales Account']).toFixed(2)+ " Dr":0);
    tableData.push('Recv. Turnover in days \n ( Payment performance of Debtors )');
    tableData.push('0 Days');
    tableList.push(tableData);    
          
    tableData = []
    tableData.push('Purchase Accounts');
    tableData.push(RatioAnalysis['Purchase Accounts']&&RatioAnalysis['Purchase Accounts'] > 0?RatioAnalysis['Purchase Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Purchase Accounts']? Math.abs(RatioAnalysis['Purchase Accounts']).toFixed(2)+ " Dr":0);
    tableData.push('Return on Inventment % \n (Net profit / Captial Account + Net profit)');
    tableData.push(returnInv?Math.abs(returnInv).toFixed(2)+" %":0);
    tableList.push(tableData); 
         
    tableData = []
    tableData.push('Stock-In-Hand');
    tableData.push(RatioAnalysis['Stock-In-Hand']&&RatioAnalysis['Stock-In-Hand'] > 0?RatioAnalysis['Stock-In-Hand'].toFixed(2)+ " Cr":RatioAnalysis['Stock-In-Hand']? Math.abs(RatioAnalysis['Stock-In-Hand']).toFixed(2)+ " Dr":0);
    tableData.push('Return on Wkg. Capital % \n (Net profit / Working Captial )');
    tableData.push(WkgCapital?Math.abs(WkgCapital).toFixed(2)+" %":0);
    tableList.push(tableData); 
    
    tableData = []
    tableData.push('Net Profit');
    tableData.push(RatioAnalysis['Profit And Loss A/c']&&RatioAnalysis['Profit And Loss A/c'] > 0?RatioAnalysis['Profit And Loss A/c'].toFixed(2)+ " Cr":RatioAnalysis['Profit And Loss A/c']? Math.abs(RatioAnalysis['Profit And Loss A/c']).toFixed(2)+ " Dr":0);
    tableData.push('');
    tableData.push('');
    tableList.push(tableData); 

    tableData = []
    tableData.push('Wkg. Capital Turnover \n ( Sales Account / Working Capital )');
    tableData.push(capitalTurn&&capitalTurn > 0?capitalTurn.toFixed(2):capitalTurn? Math.abs(capitalTurn).toFixed(2):0);
    tableData.push('');
    tableData.push('');
    tableList.push(tableData);      
    
    tableData = []
    tableData.push('Inventry Turnover \n( Sales Account / Closing Stock )');
    tableData.push(inventry&&inventry > 0?inventry.toFixed(2):inventry? Math.abs(inventry).toFixed(2):0);
    tableData.push('');
    tableData.push('');
    tableList.push(tableData);  
    generateExcel({
      columns,
      rows:tableList,
      alignList,
      headerList: [
        "Company Name",
        formData.name,
        "Year Name",
        formData.yearName,
      ],
      title: ratioAnalysisJson.screenTitle,
    });
  };
  const getFilteredTableData = (tableData) => {
    let listData = tableData.filter((row) => {
      let isValid = false;

      if (
        row.groupName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      if (
        row.groupNameR
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.amount.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      if (
        row.amountL.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      return isValid;
    });
    return listData;
  };
  const rowLink = (rowData, key) => {
    if (key == "groupNameR") {
      if (rowData.groupIdR) {
        setPopupdata({
          groupId: rowData.groupIdR,
          groupName: rowData.groupNameR,
        });
        setOpenTable(!openTable);
      } else {
        toggleMFGAndProfitAndLoss();
      }
    } else {
      if (rowData.groupId) {
        setPopupdata({
          groupId: rowData.groupId,
          groupName: rowData.groupName,
        });
        setOpenTable(!openTable);
      } else {
        toggleMFGAndProfitAndLoss();
      }
    }
  };
  const toggle = () => {
    setOpenTable(!openTable);
    if (formData != null && Object.keys(formData).length != 0) {
      getList(formData.companyId, formData.yearId);
    }
  };
  const toggleMFGAndProfitAndLoss = () => {
    setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
    if (formData != null && Object.keys(formData).length != 0) {
      getList(formData.companyId, formData.yearId);
    }
  };
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          // PeriodData={formData.fromDate ? formData.fromDate + " To " + formData.toDate : ""}
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          fieldData={fieldData}
          showSaveBtn={ratioAnalysisJson.showSaveBtn}
          screenTitle={ratioAnalysisJson.screenTitle}
          fieldMeta={ratioAnalysisJson.fieldMeta}
          showCancel={ratioAnalysisJson.showCancel}
          apiBaseURL={ratioAnalysisJson.apiBaseURL}
          showSaveNextBtn={ratioAnalysisJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          showSearchBox={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br />

        {/* <CustomTable/> */}
        {
            Object.keys(RatioAnalysis).length!=0?<RatioAnalysisTable
            showPegination={false}
            // columns={[
            //     { name: "groupName", title: "Principle Groups", linkFlag: true, linkName: "groupNameLink" },
            //     { name: "amount", title: "Amount", fontWeight: "bold", align: "right" },
            //     { name: "groupNameR", title: "Principle Ratios", linkFlag: true, linkName: "groupNameRLink" },
            //     { name: "amountL", title: "Amount", fontWeight: "bold", align: "right" }
            // ]}
            hiddenColumnNames={[]}
            rowList={cashList}
            RatioAnalysis={RatioAnalysis}
            rowLink={rowLink}
            isActionColActive={false}
            // rowCopy={this.rowCopy}
          />
          :null
        }
        
        {openTable && (
          <GroupSummarySubReportByGroup
            open={openTable}
            handleCloses={toggle}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            dynamicMasterData={formData}
          />
        )}
        {openMFGAndProfitAndLoss && (
          <MFGAndPL
            rowLink={rowLink}
            open={openMFGAndProfitAndLoss}
            handleCloses={toggleMFGAndProfitAndLoss}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            formData={formData}
          />
        )}
      </Paper>
      
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RatioAnalysis);
