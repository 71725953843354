import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTableWithoutBorder from "../../components/Preview/DynamicPreview";
import { withRouter } from "../../components/withRouter";
import TrialBalanceTable from "./TrialBalanceTable";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { serverMsg } from "../../config/messageconstant";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import { TrialBalanceJson } from "../../DynamicFormsJson/Report/TrialBalance";

const ClosingBalance = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [formData, setFormData] = React.useState({});
  const [cashList, setCashbookList] = React.useState([]);
  const [fieldData, setFieldData] = React.useState({});

  useEffect(() => {
    getDropDownList();
  }, []);

  const getDropDownList = async () => {
    const accountLedgerGroupMasterDetail = await apiGet({
      url: endpoints.accountLedgerGroupMaster,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        let rowData = data.data;

        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.groupName };
        });
      }
      return [];
    });
    let CompanyDetails = await apiGet({
      url: endpoints.company,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data.data);
        let rowData = data.data;
        const location = localStorage.getItem("location");
        const locationList = localStorage.getItem("locationIdList");
        const locallist = JSON.parse(locationList);
        const locationlist = locallist.filter((data) => data.id == location);
        console.log("location");
        console.log(locationlist);

        if (
          locationlist.length != 0 &&
          locationlist[0] != null &&
          locationlist[0].company!=null
        ) {
          setFieldData({ companyId: locationlist[0].company.id });
        }
        return rowData
      }
      return [];
    });
    setDynamicMasterData({
      accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetail,
      CompanyDetails: CompanyDetails,
    });
  };
  const getList = (fromDate, toDate, companyId, accId) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoints.trailBalance +
        "?companyId=" +
        companyId +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&groupIds=" +
        accId+"&flag=3",
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        setCashbookList(data.data);
      } else {
        setCashbookList([]);
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  };

  const onSave = (dataToSearch) => {
    let account = dataToSearch.accountLedgerGroupsId.split(",");
    let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter(
      (row) => row.id === dataToSearch.companyId
    );
    let filteraccountLedgerGroupMaster = [];
    dynamicMasterData["accountLedgerGroupMasterDetail"].map((row) => {
      account.map((accData) => {
        if (row.id === accData) {
          filteraccountLedgerGroupMaster.push(row.name);
        }
      });
    });
    console.log(filteraccountLedgerGroupMaster.join());
    setFormData({
      ...dataToSearch,
      name: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
      accName: filteraccountLedgerGroupMaster.join(),
    });

    getList(
      dataToSearch.fromDate,
      dataToSearch.toDate,
      dataToSearch.companyId,
      dataToSearch.accountLedgerGroupsId
    );
  };
  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };

  const generatePDFMethod = () => {
    var columns = [
      [
        "Accd",
        "Account Head",
        "Op. Debit",
        "Op. Credit",
        "Year Debit",
        "Year Credit",
        "CL. Debit",
        "CL. Credit",
      ],
    ];

    let tableList = [];
    cashList.map((rowData, index) => {
      if(rowData.showGroup==1){
      let opCreditTotal = 0;
      let opDebitTotal = 0;
      let yearCreditTotal = 0;
      let yearDebitTotal = 0;
      let creditTotal = 0;
      let debitTotal = 0;
      let tableData = [];

      let accountLedgerCode = "";
      let accountLedgerName = "";
      let debit = "";
      let Credit = "";
      let opDebit = "";
      let opCredit = "";
      let yearDebit = "";
      let yearCredit = "";
      rowData.getTrialBalanceReport.map((data) => {
        let debitAmt = data.openingDebitAmount - data.yearDebitAmount;
        let creditAmt = data.openingCreditAmount - data.yearCreditAmount;
        debitTotal = debitTotal + debitAmt;

        creditTotal = creditTotal + creditAmt;
        yearDebitTotal = yearDebitTotal + data.yearDebitAmount;

        yearCreditTotal = yearCreditTotal + data.yearCreditAmount;
        opDebitTotal = opDebitTotal + data.openingDebitAmount;

        opCreditTotal = opCreditTotal + data.openingCreditAmount;
        accountLedgerCode =
          data.accountLedgerCode != null
            ? accountLedgerCode + data.accountLedgerCode+ "\n"
            : accountLedgerCode + "\n";
        accountLedgerName =
          data.accountLedgerName != null
            ? accountLedgerName + data.accountLedgerName+ "\n"
            : accountLedgerName + "\n";
        debit = debit + Math.abs(debitTotal) + "\n";
        Credit = Credit + Math.abs(creditAmt) + "\n";
        opDebit = opDebit + Math.abs(data.openingDebitAmount) + "\n";
        opCredit = opCredit + Math.abs(data.openingCreditAmount) + "\n";
        yearDebit = yearDebit + Math.abs(data.yearDebitAmount) + "\n";
        yearCredit = yearCredit + Math.abs(data.yearCreditAmount) + "\n";
      });

      tableData = [];
      if (
        opDebit != 0 ||
        opCredit != 0 ||
        yearDebit != 0 ||
        yearCredit != 0 ||
        debit != 0 ||
        Credit != 0
      ) {
        tableData.push(rowData.groupName + "\n" + accountLedgerCode);

        tableData.push("\n" + accountLedgerName);
        tableData.push("\n" + opDebit + "\n" + Math.abs(opDebitTotal));
        tableData.push("\n" + opCredit + "\n" + Math.abs(opCreditTotal));
        tableData.push("\n" + yearDebit + "\n" + Math.abs(yearDebitTotal));
        tableData.push("\n" + yearCredit + "\n" + Math.abs(yearCreditTotal));
        tableData.push("\n" + debit + "\n" + Math.abs(debitTotal));
        tableData.push("\n" + Credit + "\n" + Math.abs(creditTotal));
        tableList.push(tableData);
      }
    }
    });

    const columnStyles = {
      0: { cellWidth: 150 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    const columnStylesPlain = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    console.log(formData.accName);
    generatePDF({
      columnStylesPlain: columnStylesPlain,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate,
        },
        {
          label: "Company Name",
          value: formData.name,
        },
        {
          label: "Group Name",
          value: formData.accName,
        },
      ],
      pdfTitle: TrialBalanceJson.screenTitle,
    });
  };

  const generateExcelMethod = () => {
    var columns = [
      "Accd",
      "Account Head",
      "Op. Debit",
      "Op. Credit",
      "Year Debit",
      "Year Credit",
      "CL. Debit",
      "CL. Credit",
    ];
    let alignList = [];

    let rows = [];
    cashList.map((reportData, index) => {
      if(reportData.showGroup==1){
      let reportObj = [];
      reportObj.push(reportData.groupName);
      reportObj.push("");
      reportObj.push("");
      reportObj.push("");
      rows.push(reportObj);
      let opCreditTotal = 0;
      let opDebitTotal = 0;
      let yearCreditTotal = 0;
      let yearDebitTotal = 0;
      let creditTotal = 0;
      let debitTotal = 0;

      reportData.getTrialBalanceReport.map((data) => {
        let debitAmt = data.openingDebitAmount - data.yearDebitAmount;
        let creditAmt = data.openingCreditAmount - data.yearCreditAmount;
        debitTotal = debitTotal + debitAmt;

        creditTotal = creditTotal + creditAmt;
        yearDebitTotal = yearDebitTotal + data.yearDebitAmount;

        yearCreditTotal = yearCreditTotal + data.yearCreditAmount;
        opDebitTotal = opDebitTotal + data.openingDebitAmount;

        opCreditTotal = opCreditTotal + data.openingCreditAmount;
        reportObj = [];

        reportObj.push(data.accountLedgerCode);
        reportObj.push(data.accountLedgerName);
        reportObj.push(Math.abs(data.openingDebitAmount));
        reportObj.push(Math.abs(data.openingCreditAmount));
        reportObj.push(Math.abs(data.yearDebitAmount));
        reportObj.push(Math.abs(data.yearCreditAmount));
        reportObj.push(Math.abs(debitAmt));
        reportObj.push(Math.abs(creditAmt));
        rows.push(reportObj);
      });
      reportObj = [];
      reportObj.push("");
      reportObj.push("");
      reportObj.push(Math.abs(opDebitTotal));
      reportObj.push(Math.abs(opCreditTotal));
      reportObj.push(Math.abs(yearDebitTotal));
      reportObj.push(Math.abs(yearCreditTotal));
      reportObj.push(Math.abs(debitTotal));
      reportObj.push(Math.abs(creditTotal));
      rows.push(reportObj);
    }
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Date Range ",
        formData.fromDate + " To " + formData.toDate,
        "Company Name",
        formData.name,
        "Group Name",
        formData.accName,
      ],
      title: TrialBalanceJson.screenTitle,
    });
  };
  const getFilteredTableData = (tableData) => {
    let listData = tableData.filter((row) => {
      let isValid = false;

      if (
        row.groupName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      return isValid;
    });
    return listData;
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={TrialBalanceJson.showSaveBtn}
          screenTitle={TrialBalanceJson.screenTitle}
          fieldMeta={TrialBalanceJson.fieldMeta}
          showCancel={TrialBalanceJson.showCancel}
          fieldData={fieldData}
          apiBaseURL={TrialBalanceJson.apiBaseURL}
          showSaveNextBtn={TrialBalanceJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          searchName="Group Name"
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br />

        <TrialBalanceTable
          tableHead={[
            {
              title: "Group Name",

              align: "left",
            },   {
              title: "Accd",

              align: "left",
            },
            {
              title: "Account Head",
              align: "left",
            },
            {
              title: "Op. Debit",
              align: "left",
            },
            {
              title: "Op. Credit",
              align: "left",
            },
            {
              title: "Year Debit",
              align: "left",
            },
            {
              title: "Year Credit",
              align: "left",
            },
            {
              title: "CL Debit",
              align: "left",
            },
            {
              title: "Cl Credit",
              align: "left",
            },
          ]}
          rowList={getFilteredTableData(cashList)}
          formData={formData}
          getList={getList}
          dynamicMasterData={formData}
        />
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(ClosingBalance);
