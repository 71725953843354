import {
  Grid,
  Paper
} from "@mui/material";
import React, { Component } from "react";
import { showLoador, showNotification } from "../../Pages/Landing/Landing.slice";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import Search from "../../components/Search";

import DynamicFormWithTable from "./DynamicFormWithTable";


import dayjs from "dayjs";
import { connect } from "react-redux";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import { FontAwesomeIconCompo } from "../Comman/IconButton";
class DynamicFormWithReport extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      fieldData: {}, formErrors: {}
    };
  }
  async componentDidMount() {
    const { showLoador, yearEndApi = true } = this.props
    const dataToReset = {};
    this.props.fieldMeta && this.props.fieldMeta.forEach((currentField) => {
      if (currentField.defaultValue) {
        dataToReset[currentField.dataKey] = currentField.defaultValue;
      }
    });
    if (yearEndApi) {
      showLoador({ loador: true });
      await apiGet({
        url: endpoint.yearEnd + "/get-year-fromDate-toDate",
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          console.log(data.data);
          let rowData = data.data
          dataToReset["fromDate"] = dayjs(rowData.fromDate, "DD-MM-YYYY");
          dataToReset["toDate"] = dayjs(rowData.toDate, "DD-MM-YYYY");
        } else {
          showLoador({ loador: false });
        }
      });
    }
    this.setState({
      fieldData: dataToReset,
    });
    const { onSaveData, searchDataOnLoad = false } = this.props
      if (searchDataOnLoad) {
        onSaveData(dataToReset)
      }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.fieldData !== nextProps.fieldData) {
      let DataToSet = {
        ...this.state.fieldData,
        ...nextProps.fieldData
      }
      this.setState({
        fieldData: DataToSet
      });
      const { onSaveData, searchDataOnLoad = false } = this.props
      if (searchDataOnLoad) {
        onSaveData(DataToSet)
      }
    }
  }
 

 
  onDataChange = (fieldName, newValue) => {
    const { fieldMeta = [], getDataForDisabled, getDataForCalculate } = this.props;
    const { fieldData } = this.state;
    const dataToSearch = {};
    let dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.dataKey === fieldName && currentField.calculateMinus) {
        const dataStatic = getDataForCalculate(newValue, dataToReset);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.props.getListById(dataToSearch);
      }

      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        console.log(currentField.getListFrom);
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        !currentField.disableTrue &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
 
 
  
  onCancel = () => {
    this.props.navigate(-1);
  };

 

  onFormSave = () => () => {
   
      const { fieldData } = this.state;
      const { fieldMeta = [], type = "", resetAfterSave = false } = this.props;

      const dataToSave = {
        isActive: 1,
        // currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      };
      if (type !== "") {
        dataToSave["formType"] = type;
      }
      if (fieldData["id"]) {
        dataToSave["id"] = fieldData["id"];
      }

      fieldMeta.forEach((currentField) => {
        if (currentField.controlType === "multiSelect") {
          let multiselectValue = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].join()
            : "";

          dataToSave[currentField.dataKey] = multiselectValue;
        } else if (currentField.controlType === "hideMultiSelect") {
          if (fieldData[currentField.tableDataKey] == currentField.open) {
            let multiselectValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].join()
              : "";

            dataToSave[currentField.dataKey] = multiselectValue;
          }
        } else if (currentField.controlType === "timePicker") {
          let timeValue =
            fieldData[currentField.dataKey] === ""
              ? ""
              : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                "HH:mm"
              );
          dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            dataToSave[currentField.dataKey] = day + "-" + month + "-" + year;
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else if (
          currentField.controlType === "hideTextfield" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            dataToSave[currentField.tableDataKey] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "hideDatepicker") {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            if (fieldData[currentField.dataKey]) {
              let date = new Date(fieldData[currentField.dataKey]);

              let day = date.getDate();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();

              fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
            }

            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });

      this.props.onSave(dataToSave);
      if (resetAfterSave) {
        const dataToReset = {};
        fieldMeta.forEach((currentField) => {
          if (currentField.defaultValue) {
            dataToReset[currentField.dataKey] = currentField.defaultValue;
          }
        });
        this.setState({
          fieldData: dataToReset,
        });
      }
    
  };
  onFormSaveDetail = () => () => {
   
      const { fieldData } = this.state;
      const { fieldMeta = [], type = "", resetAfterSave = false } = this.props;

      const dataToSave = {
        isActive: 1,
        // currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      };
      if (type !== "") {
        dataToSave["formType"] = type;
      }
      if (fieldData["id"]) {
        dataToSave["id"] = fieldData["id"];
      }

      fieldMeta.forEach((currentField) => {
        if (currentField.controlType === "multiSelect") {
          let multiselectValue = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].join()
            : "";

          dataToSave[currentField.dataKey] = multiselectValue;
        } else if (currentField.controlType === "hideMultiSelect") {
          if (fieldData[currentField.tableDataKey] == currentField.open) {
            let multiselectValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].join()
              : "";

            dataToSave[currentField.dataKey] = multiselectValue;
          }
        } else if (currentField.controlType === "timePicker") {
          let timeValue =
            fieldData[currentField.dataKey] === ""
              ? ""
              : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                "HH:mm"
              );
          dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            dataToSave[currentField.dataKey] = day + "-" + month + "-" + year;
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else if (
          currentField.controlType === "hideTextfield" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            dataToSave[currentField.tableDataKey] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "hideDatepicker") {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            if (fieldData[currentField.dataKey]) {
              let date = new Date(fieldData[currentField.dataKey]);

              let day = date.getDate();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();

              fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
            }

            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });

      this.props.onSaveDetail(dataToSave);
      if (resetAfterSave) {
        const dataToReset = {};
        fieldMeta.forEach((currentField) => {
          if (currentField.defaultValue) {
            dataToReset[currentField.dataKey] = currentField.defaultValue;
          }
        });
        this.setState({
          fieldData: dataToReset,
        });
      }
    
  };

  ValidationCheck = () => {
    let formValid = true;
    const { fieldMeta = [] } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        // if (
        //   currentField.validName === "validPointName" &&
        //   fieldData[currentField.dataKey] >= 11 || 
        //   fieldData[currentField.dataKey] <= 0
        // ) {
        //   console.log(fieldData[currentField.dataKey] < 0)
        //   console.log(fieldData[currentField.dataKey] > 11)
        //   formValid = false;
        //   formErrors = {
        //     ...formErrors,
        //     [currentField.dataKey]: {
        //       isError: true,
        //       errorText: `${currentField.label} must be between 1-10`,

        //     },
        //   };
        // }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] == 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (currentField.validName === "greater-less") {
          if (currentField.zeroValid && fieldData[currentField.dataKey] == 0) {
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} is Invalid`,
              },
            };
          } else {
            const validArr = currentField.validCondition.split("-");
            const errorData =
              validArr[0] == "less"
                ? +fieldData[currentField.dataKey] > +fieldData[validArr[1]] &&
                +fieldData[currentField.dataKey] != +fieldData[validArr[1]]
                : +fieldData[currentField.dataKey] < +fieldData[validArr[1]] &&
                +fieldData[currentField.dataKey] != +fieldData[validArr[1]];
            console.log(errorData);
            if (errorData) {
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText:
                    validArr[0] == "less"
                      ? currentField.label +
                      ` shound be less than ` +
                      currentField.validText
                      : currentField.label +
                      ` shound be greater than ` +
                      currentField.validText,
                },
              };
            }
          }
        }
        if (
          currentField.validName === "greater-thanHundered" &&
          fieldData[currentField.dataKey] > 100
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} should not be gretaer than 100 per.`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      if (this.ValidationCheck()) {
        return this.onSubmitCheck();
      }
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldMeta = [] } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];
          if (dataKeyValue && compareWithValue) {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }

                break;
              case "le":
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }

                break;
              case "g":
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }

                break;
              case "ge":
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }

                break;
              case "eq":
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }

                break;
              default:
                break;
            }

            if (isError) {
              const compareWithFieldLabel = fieldMeta.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldMeta = [] } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined || fieldData[currentField.dataKey] == "" ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||  fieldData[currentField.dataKey] == "" ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&  fieldData[currentField.dataKey] != ""
            &&  fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  onSearch = (searchvalue) => {
    this.props.onSearch(searchvalue);
  };

  render() {
    const {
      screenTitle,
      showPDFExcel = true,
      showExcelDownload = true,
      showPDFDownload = true,
      showAddButton = false,
      onRTSelectMultipleChange,
      tableColumnsToFilter = [],
      searchValue,
      generatePDF,
      generateExcel,
      searchIconTrue = true,
      PeriodData = "",
      showTable = true,
      showSearchBox = true,
      showSearch = true,
      searchName = "Search",
      handleChange,
      dynamicMasterData = {},
      fieldMeta = [],
      addTitle = "",
      detailButton=false,
      showBackButton = false,
      fullWidthButton = false,
      onCancel,
    } = this.props;
    const { fieldData, formErrors } = this.state
    return (
      <>
        <div>
          {screenTitle !== "" && (
            <Paper sx={{ borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="space-between"
              >
                <TitleAndBreadCrum title={screenTitle} PeriodData={PeriodData} />

              </Grid>
            </Paper>
          )}
          <Grid container >
            <Grid

              sx={{ paddingLeft: 2, }}
              item
              xs={12}
              md={12}
              lg={12}
            >
              <DynamicFormWithTable
              searchIconTrue={searchIconTrue}
                GridSave={1}
                fieldMeta={fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                onSave={this.onFormSave(true)}
                onSaveDetail={this.onFormSaveDetail(true)}
                searchButton={true}
                detailButton={detailButton}
              // buttonCenter={this.props.buttonCenter}
              />
            </Grid>

          </Grid>

          {showTable && (
            <>
              {showSearch && (
                <>

                  <Paper sx={{ borderRadius: 2 }} elevation={0}>
                    <Grid container spacing={3}>
                      <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        lg={4}
                      >
                        {showSearchBox && (
                          <Search
                            sx={{ width: "100%" }}
                            searchValue={searchValue}
                            filteredRows={this.onSearch}
                          />
                        )}
                      </Grid>
                      <Grid
                        container
                        justifyContent="flex-end"
                        item
                        xs={12}
                        md={8}
                        lg={8}
                      >
                        <Grid sx={{ alignItems: "center" }} container justifyContent="flex-end" item md xs>

                          {showPDFDownload && (
                            <>
                              <FontAwesomeIconCompo
                                color="error"
                                fontSize="small"
                                title="PDF"
                                baseClassName="fas"
                                className="fa-file-pdf"
                                onClick={generatePDF}
                              />
                              &emsp;
                            </>

                          )}

                          {showExcelDownload && (
                            <>

                              <FontAwesomeIconCompo
                                color="primary"
                                fontSize="small"
                                title="Excel"
                                baseClassName="fas"
                                className="fa-file-excel"
                                onClick={generateExcel}
                              />
                              &nbsp;
                            </>
                          )}

                        </Grid>

                      </Grid>

                      {/* {
                        showPDFExcel &&
                        <Grid
                          container
                          sm={0.7}
                          md={0.7}
                          lg={0.7}
                          justifyContent="flex-end"
                          sx={{ marginTop: 1 }}
                          xs={12}
                        //  rowSpacing={14}
                        >
                          <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: 2 }}>

                            <MenuCompo showExcelDownload={showExcelDownload}
                              showPDFDownload={showPDFDownload} generatePDF={generatePDF} generateExcel={generateExcel} />


                          </Grid>
                        </Grid>
                      } */}


                    </Grid>
                  </Paper>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(DynamicFormWithReport);

