import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RegisterJson } from "../../DynamicFormsJson/Report/RegistorReport";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import MatrixReport from "./RegistorMatrixTable";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const SellRegisterReport = ({type, showLoador, showNotification }) => {
  const params = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [headList, setHeadList] = useState([]);
  const [detailList, setDetailList] = React.useState([]);
  const [formData, setFormData] = React.useState({});


  useEffect(() => {
    setDetailList([])
    setHeadList([])
  }, [])

  const getList = (fromDate, toDate) => {
    let location = localStorage.getItem("location");
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.accountReport + "/get-purchase-sale-report?locationId="+location+"&fromDate="+fromDate+"&toDate="+toDate+"&type=" + type
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data
        showLoador({ loador: false });
        setDetailList(rowData)
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

    });
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.accountReport + "/get-purchase-sale-report-header?locationId="+location+"&fromDate="+fromDate+"&toDate="+toDate+"&type="+ type
    }).then(({ data, success }) => {
      if (success) {
        let rowData = []
        data.data.map((row) => {
          rowData.push({ label: "Taxable " + row.tax_per + " %", name: row.tax_per })
          rowData.push({ label: "CGST " + row.cgst_per + " %", name: row.cgst_per })
          rowData.push({ label: "SGST " + row.sgst_per + " %", name: row.sgst_per })
          rowData.push({ label: "IGST " + row.igst_per + " %", name: row.igst_per })
        })
        showLoador({ loador: false });
        setHeadList(data.data)
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

    });
  }
  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };

  const generateExcelMethod = () => {


    let columns = ["Date","Bill No.","Party Name","City","GSTIN","Address"]
    headList.map((rowData) => {
      columns.push("Taxable " + rowData.tax_per + " %")
      columns.push("CGST " + rowData.cgst_per + " %")
      columns.push("SGST " + rowData.sgst_per + " %")
      columns.push("IGST " + rowData.igst_per + " %")
    })
    columns.push("Cess")  
    columns.push("TCS")
    columns.push("R.off")
    columns.push("Total")
    columns.push("Vou No")
    columns.push("Accd")
    columns.push("Tot.Taxable")
    columns.push("Tot.CGST")
    columns.push("Tot.SGST")
    columns.push("Tot.IGST")
    let rows = []
      detailList.map((matrixDataRow, indexRow) => {
        let tableData = []

            tableData.push(matrixDataRow.date)
            tableData.push(matrixDataRow.billNo)
            tableData.push(matrixDataRow.accountLedgerName)
            tableData.push(matrixDataRow.city)
            tableData.push(matrixDataRow.gstNo)
            tableData.push(matrixDataRow.address)
            {headList.map((rowData) => {
              let filterData = matrixDataRow.dtoGetPurchaseSalesReportDetail.filter((rowInfo) => rowInfo.taxPercentage == rowData.tax_per)
              if (filterData.length != 0) {
                tableData.push(+(filterData[0].taxableAmt.toFixed(2)))
                tableData.push(+(filterData[0].cgstAmt.toFixed(2)))
                tableData.push(+(filterData[0].sgstAmt.toFixed(2)))
                tableData.push(+(filterData[0].igstAmt.toFixed(2)))
                  
              } else {
                tableData.push(0.00)
                tableData.push(0.00)
                tableData.push(0.00)

                tableData.push(0.00)
              }

            })
            }
            tableData.push(+(matrixDataRow.cessAmt.toFixed(2)))
            tableData.push(matrixDataRow.tcs)
            tableData.push(Math.abs(matrixDataRow.roundOff))
            tableData.push(+(matrixDataRow.totalAmt.toFixed(2)))
            tableData.push(matrixDataRow.voucherCode)
            tableData.push(matrixDataRow.accountLedgerCode)
            tableData.push(+(matrixDataRow.taxableAmt.toFixed(2)))
            tableData.push(+(matrixDataRow.cgstAmt.toFixed(2)))
            tableData.push(+(matrixDataRow.sgstAmt.toFixed(2)))
          tableData.push(+(matrixDataRow.igstAmt.toFixed(2)))
          rows.push(tableData)
      })
    
   
    generateExcel({
      columns,
      rows,
      alignList:[],
      headerList: ["Date Range ", formData.fromDate , " To " , formData.toDate ],
      title: type == 1 ? RegisterJson.screenTitle : "Sell Register Report",
    });
  };



 
  const onSave = (dataToSearch) => {

    setFormData({
      ...dataToSearch,
    })

    getList(dataToSearch.fromDate, dataToSearch.toDate)
  }

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>



        <DynamicFormWithReport
          showPDFDownload={false}
          dynamicMasterData={{}}
          showBackToList={false}
          DataToSet={[]}
          searchName="Reference Account Name"
          showSaveBtn={RegisterJson.showSaveBtn}
          screenTitle={type != 1 ? RegisterJson.screenTitle : "Sell Register Report"}
          fieldMeta={RegisterJson.fieldMeta}
          showCancel={RegisterJson.showCancel}
          apiBaseURL={RegisterJson.apiBaseURL}
          showSaveNextBtn={RegisterJson.showSaveNextBtn}
          showSaveBtnMain={true}
          generateExcel={generateExcelMethod}
          padding={false}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
          showSearchBox={false}
        />
        <MatrixReport headList={headList} detailList={detailList} />

      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(SellRegisterReport);

