import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GroupSummarySubreportByGroup from "./GroupSummarySubReportByGroup";
import MFGAndPL from "./MFGAndPL";
const BalanceSheetTable = ({ rowList = [],getDeatilList, tableHead = [], formData={},classes }) => {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [popUpData, setPopupdata] = useState({})
  const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
  const toggle = () => {
    
    setOpenTable(!openTable);
    if(Object.keys(formData).length!=0){
      console.log("formData")
      getDeatilList(formData.companyId, formData.fromDate, formData.toDate)
    }
    

  };
  
  const toggleMFGAndProfitAndLoss = () => {
    setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
    if(formData!=null && Object.keys(formData).length!=0){
      getDeatilList(formData.companyId,formData.yearId)
    }
};

  console.log("rowList")
  console.log(rowList)
  const rowLink = (rowData,flag) => {
    console.log(rowData)
    if(flag==0){
      setPopupdata({...rowData,groupId:rowData.id,groupName:rowData.name})
      setOpenTable(!openTable);
    }else {
      console.log("in else")
            toggleMFGAndProfitAndLoss()
        }


    

}
  return (
    <>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr className={classes.MuiTableHeadRoot}>
            {tableHead.map((head) => {
              return (
                <td
                  style={{ textAlign: "center" }}
                  className={classes.MuiTableHeadCell}
                >
                  {head.title}
                </td>
              );
            })}{" "}
          </tr>
          {rowList.map((rowData) => {
             console.log("rowData")
           console.log(rowData)
            return (
              <>
                <tr>
                  <td

                    className={classes.MuiTableBodyCell}
                    style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontWeight: fontWeight }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        fontWeight: fontWeight,
                        // paddingBottom: "3px"
                      }}
                    >
                      
                        <div 
                        style={{
                          justifyContent: "flex-start",
                          width: "50%",
                          marginRight: "10px",

                          cursor: "pointer"
                        }}
                        
                        onClick={() => {
                          if(  rowData.
manufactureAndProfit==0 || rowData.
manufactureAndProfit==1){
                            console.log(rowData)
                            rowLink(
                              rowData,1
                             
                            )
                          }
                       
                        }}
                      >
                 
                        {rowData.groupName != null
                          ? rowData.groupName :
                          " - "
                        }
                      </div>
                      
                      <div
                        style={{
                          justifyContent: "flex-end",
                          // width: "40%",
                          // paddingBottom: "8px",
                        }}
                      >
                        {rowData.amount != null
                          ? rowData.amount :
                          " - "
                        }
                      </div>
                    </div>

                  </td>
                  <td

                    className={classes.MuiTableBodyCell}
                    style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontWeight: fontWeight }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        fontWeight: fontWeight,
                        // paddingBottom: "3px"
                      }}
                    >


<div
                          style={{
                            justifyContent: "flex-start",
                            width: "50%",
                            marginRight: "10px",

                          cursor: "pointer",
                        }}
                        
                        onClick={() => {
                          if(  rowData.
manufactureAndProfit==0 || rowData.
manufactureAndProfit==1){
                            console.log(rowData)
                            rowLink(
                              rowData,1
                             
                            )
                          }
                       
                        }}
                      >
                 
                 {rowData.groupNameR != null
                          ? rowData.groupNameR :
                          " - "
                        }
                      </div>
                      {/* <div
                        style={{
                          justifyContent: "flex-start",
                          width: "50%",
                          marginRight: "10px",

                        }}
                      >

                        {rowData.groupNameR != null
                          ? rowData.groupNameR :
                          " - "
                        }
                      </div> */}
                      <div
                        style={{
                          justifyContent: "flex-end",
                          // width: "40%",
                          // paddingBottom: "8px",
                        }}
                      >
                        {rowData.amountL != null
                          ? rowData.amountL :
                          " - "
                        }
                      </div>
                    </div>

                  </td>

                </tr>








                <tr>
                  <td

                    className={classes.MuiTableBodyCell}
                    style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", }}
                  >
                    {rowData.resultL && rowData.resultL.length != 0 && rowData.resultL.map((data, index) => {
                      let creditTotal = 0;
                      let creditAmt = 0;
                      let debitAmt = 0;
                      creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                      debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                      creditTotal = creditTotal + creditAmt + debitAmt
                      return (

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            // fontWeight: fontWeight,
                            // paddingBottom: "3px"
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                              marginLeft: "10px",

                              cursor: "pointer",
                            }}
                            
                            onClick={() => {
                              rowLink(
                                data,0
                               
                              );
                            }}
                          >

                            {data.name}
                          </div>
                          <div
                            style={{
                              justifyContent: "flex-start",
                              textAlign:"right",
                              width: "40%",
                            //  marginRight:"10px"
                            }}
                          >
                            {creditTotal == null ? "" : (Math.abs(creditTotal)).toFixed(2)}
                          </div>
                        </div>
                      )
                    })}
                  </td>
                  <td

                    className={classes.MuiTableBodyCell}
                    style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", }}
                  >
                    {rowData.resultR && rowData.resultR.length != 0 && rowData.resultR.map((data, index) => {
                      let creditTotal = 0;
                      let creditAmt = 0;
                      let debitAmt = 0;
                      creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                      debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                      creditTotal = creditTotal + creditAmt + debitAmt
                      return (

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",

                            // paddingBottom: "3px"
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                            
                            onClick={() => {
                              rowLink(
                                data,0
                               
                              );
                            }}
                          >

                            {data.name}
                          </div>
                          <div
                            style={{
                              justifyContent: "flex-start",
                              textAlign:"right",
                              width: "40%",
                              // marginRight:"10px"
                            }}
                          >
                            {creditTotal == null ? "" : (Math.abs(creditTotal)).toFixed(2)}
                          </div>
                        </div>


                      )
                    })}
                  </td>



                </tr>



              </>
            );
          })}
        </table>
      </div>

      {openTable && (
        <GroupSummarySubreportByGroup


          open={openTable}
          handleCloses={toggle}
          popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
          dynamicMasterData={formData}

        />
      )}
{openMFGAndProfitAndLoss && (
                    <MFGAndPL
                        rowLink={rowLink}
                        open={openMFGAndProfitAndLoss}
                        handleCloses={toggleMFGAndProfitAndLoss}
                        popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                        formData={formData}

                    />
                )}

    </>
  );
};

const mapStateToProps = (state) => ({
 
});

const mapDispatchToProps = {
 
  showLoador, showNotification
};


export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(BalanceSheetTable);
