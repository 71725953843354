import endpoint from "../../config/endpoints";

export const BankReceiptJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.recieptPaymentHeader,
  screenTitle: "Bank Receipt",
  formPath: "/bank-receipt-form",
  formPathView: "/bank-receipt-view",
  showBackToList: false,
  showTitle: false,
  searchList: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md:6,
      lg:6,
      sm:6,
      xs: 6,
      masterName: "subVoucherDetail",
      dataKey: "subVoucher",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
  fieldMeta: [
    // {
    //   label: "Voucher No.",
    //   controlType: "textfield",
    //   placeHolder: "Enter Voucher No.",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "documentNo",
    //   inputType: "number",
    //   isMandatory: true,
    //   disable:true
    // },
    {
      label: "Sub-voucher",
      controlType: "textfield",
      placeHolder: "Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "subVoucherName",
      isRootLevelKey: false,
      isMandatory: true,
      disable:true
    },
   
    {
      label: "Bank A/C",
      controlType: "autocomplete",
      placeHolder: "Select Cash Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      getListId: "cashBankId",
      isRootLevelKey: true,
      isMandatory: true,
    }, 
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
      freezCurrentDate:true,
      dateApiValid:true
    },
     {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Profit Center",  
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Cheque No.",
      controlType: "textfield",
      placeHolder: "Enter Cheque No.",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "chequeNo",
      inputType: "number",
      isMandatory: false,
    },
    {
      label: "Cheque Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "chequeDate",
      isMandatory: false,
      onSubmit: "compare",
      onSubmitParameter: "passDate-ge",
    },
    {
      label: "Cheque Pass Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "passDate",
      isMandatory: false,
      onSubmit: "compare",
      onSubmitParameter: "chequeDate-le",
    },
    {
      label: "Clearance Days",
      controlType: "textfield",
      placeHolder: "Enter Clearance days",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "clearanceDays",
      inputType: "number",
      isMandatory: false,
    },
    {
      label: "Drawn On",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "drawnOn",
      isMandatory: false,
    },
    {
      label: "Advance Payment",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isAdvance",
      // tableDataKey: "isAdvance",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value:"1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Payment Mode",
      controlType: "autocomplete",
      placeHolder: "Select Payment Mode ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "paymentModeDetails",
      dataKey: "paymentMode",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMetaAdd: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucherMaster",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
      freezCurrentDate:true,
      dateApiValid:true
    },
   
    {
      label: "Bank A/C",
      controlType: "autocomplete",
      placeHolder: "Select Cash Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      getListId: "cashBankId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Profit Center",  
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 6,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Cheque No.",
      controlType: "textfield",
      placeHolder: "Enter Cheque No.",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "chequeNo",
      inputType: "number",
      isMandatory: false,
    },
    {
      label: "Cheque Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "chequeDate",
      isMandatory: false,
      onSubmit: "compare",
      onSubmitParameter: "passDate-ge",
    },
    {
      label: "Cheque Pass Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "passDate",
      isMandatory: false,
      onSubmit: "compare",
      onSubmitParameter: "chequeDate-le",
    },
    {
      label: "Clearance Days",
      controlType: "textfield",
      placeHolder: "Enter Clearance days",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "clearanceDays",
      inputType: "number",
      isMandatory: false,
    },
    {
      label: "Drawn On",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "drawnOn",
      isMandatory: false,
    },
   
    {
      label: "Advance Payment",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isAdvance",
      // tableDataKey: "isAdvance",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value:"1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Payment Mode",
      controlType: "autocomplete",
      placeHolder: "Select Payment Mode ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "paymentModeDetails",
      dataKey: "paymentMode",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Credit Account",
      controlType: "autocomplete",
      placeHolder: "Select Credit Account ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "creditAccountDetails",
      dataKey: "mainAccountId",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "mainAccountId",
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "amt",
      inputType: "number",
      isMandatory: true,
    },
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeDetails",
      dataKey: "type",
      isRootLevelKey: true,
      isMandatory: true,
      defaultValue: "C",
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
  fieldMetaForOption: [
    {
      label: "Cost Center",
      controlType: "autocomplete",
      placeHolder: "Select Cost Center ",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "costCenterDetail",
      dataKey: "costCenterMaster",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      inputType: "number",
      isMandatory: true,
    },
  ],
  fieldMetaForOption1: [
    {
      label: "Bill",
      controlType: "autocomplete",
      placeHolder: "Select Bill ",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "billDetail",
      dataKey: "billId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      inputType: "number",
      isMandatory: true,
    },
  ],
  fieldMeta1: [
    {
      label: "Received From",
      controlType: "textfield",
      placeHolder: "Enter Received From",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2.5,
      labelMD: 2.5,
      labelLG: 2.5,
      valueSM: 9.5,
      valueMD: 9.5,
      valueLG: 9.5,
      dataKey: "receivedFrom",
      isMandatory: true,
      disableTrue: false,
    },
   
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2.5,
      labelMD: 2.5,
      labelLG: 2.5,
      valueSM: 9.5,
      valueMD: 9.5,
      valueLG: 9.5,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
};
