import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RegisterJson } from "../../DynamicFormsJson/Report/RegistorReport";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import MatrixReport from "./MatrixReportMontwise";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import RTTable from "../../components/Comman/RT/RTTable";

const HSNReport = ({type, showLoador, showNotification }) => {
  const params = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [HSN, setHSN] = useState([]);
  const [formData, setFormData] = React.useState({});


  useEffect(() => {
    setHSN([])
  }, [])

  const getList = (fromDate, toDate) => {
    let location = localStorage.getItem("location");
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.accountReport + "/get-purchase-sale-report-summary-hsnwise?locationId="+location+"&fromDate="+fromDate+"&toDate="+toDate+"&type=" +type
      //  +  "&fromDate=" + fromDate + "&toDate=" + toDate,
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data.map((rowData)=>{
          return {hsn_code:rowData.hsn_code,
            bill_no:rowData.bill_no,
            uom_name:rowData.uom_name,
            recived_qty:rowData.recived_qty,
            total_amt:rowData.total_amt,
            taxable_amt:rowData.taxable_amt,
            tax_per:rowData.tax_per,
            igst_amt:rowData.igst_amt,
            cgst_amt:rowData.cgst_amt,
            sgst_amt:rowData.sgst_amt,
            cess_amt:rowData.cess_amt,
          }
        })
        showLoador({ loador: false });
        setHSN(rowData)
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

    });
   
  }
  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };
 
  const generateExcelMethod = () => {


    let columns = ["HSNCD","Descrption","UOM","Total Quantity","Total","Taxable","Gst %","IGST","CGST","SGST","CESS"]
    let rows = []
    HSN.map((matrixDataRow, indexRow) => {
        let tableData = []

            tableData.push(matrixDataRow.hsn_code)
            tableData.push(matrixDataRow.bill_no)
            tableData.push(matrixDataRow.uom_name)
            tableData.push(matrixDataRow.recived_qty)
            tableData.push(+(matrixDataRow.total_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.taxable_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.tax_per.toFixed(2)))
            tableData.push(+(matrixDataRow.igst_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.cgst_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.sgst_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.cess_amt.toFixed(2)))
          tableData.push(matrixDataRow.igstAmt)
          rows.push(tableData)
      })
      
    generateExcel({
      columns,
      rows,
      alignList:[],
      headerList: ["Date Range ", formData.fromDate , " To " , formData.toDate ],
      title:type == 1 ? "Purchase HSN Report": "Sell HSN Report"
    });
  };

  const generatePDFMethod = () => {
    let columns = [["HSNCD","Descrption","UOM","Total Quantity","Total","Taxable","Gst %","IGST","CGST","SGST","CESS"]]

    
      
      let tableList = []
      HSN.map((matrixDataRow, indexRow) => {
        let tableData = []

            tableData.push(matrixDataRow.hsn_code)
            tableData.push(matrixDataRow.bill_no)
            tableData.push(matrixDataRow.uom_name)
            tableData.push(matrixDataRow.recived_qty)
            tableData.push(+(matrixDataRow.total_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.taxable_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.tax_per.toFixed(2)))
            tableData.push(+(matrixDataRow.igst_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.cgst_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.sgst_amt.toFixed(2)))
            tableData.push(+(matrixDataRow.cess_amt.toFixed(2)))
          tableData.push(matrixDataRow.igstAmt)
          tableList.push(tableData)
      })
     
      
     
      generatePDF({
       
        rows: tableList,
        columns,
        headerList: [
          {
            label: "Date Range ",
            value: formData.fromDate,
          },
          {
            label: "Date Range ",
            value: formData.toDate,
          },
         
        ],
        pdfTitle:type == 1 ? "Purchase HSN Report": "Sell HSN Report",
        
      });
    
    };

 
  const onSave = (dataToSearch) => {

    setFormData({
      ...dataToSearch,
    })

    getList(dataToSearch.fromDate, dataToSearch.toDate)
  }

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithReport
          showPDFDownload={true}
          dynamicMasterData={{}}
          showBackToList={false}
          DataToSet={[]}
          searchName="Reference Account Name"
          showSaveBtn={RegisterJson.showSaveBtn}
          screenTitle={type != 1 ? "Purchase HSN Report": "Sell HSN Report"}
          fieldMeta={RegisterJson.fieldMeta}
          showCancel={RegisterJson.showCancel}
          apiBaseURL={RegisterJson.apiBaseURL}
          showSaveNextBtn={RegisterJson.showSaveNextBtn}
          showSaveBtnMain={true}
          generateExcel={generateExcelMethod}
          generatePDF={generatePDFMethod}
          padding={false}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br />
        <RTTable
                    showPegination={false}
                    columns={[
                        { name: "hsn_code", title: "HSNCD",align: "center"},
                        { name: "bill_no", title: "Descrption" },
                        { name: "uom_name", title: "UOM" },
                        { name: "recived_qty", title: "Total Quantity", align: "right"},
                        { name: "total_amt", title: "Total", align: "right" },
                        { name: "taxable_amt", title: "Taxable", align: "right" },
                        { name: "tax_per", title: "Gst %", align: "right" },
                        { name: "igst_amt", title: "IGST", align: "right" },
                        { name: "cgst_amt", title: "CGST", align: "right" },
                        { name: "sgst_amt", title: "SGST", align: "right" },
                        { name: "cess_amt", title: "CESS", align: "right" }
                    ]}
                    hiddenColumnNames={[]}
                    // tableData={getFilteredTableData(cashList)}
                    tableData={HSN}
                    isActionColActive={false}
                // rowCopy={this.rowCopy}
                />
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(HSNReport);

