import React, { useState } from "react";
import { styles } from "../../../components/reportStyle";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
let colors=[
  '#0b0b0b',
  '#202020',
  '#353535',
  '#4a4a4a',
  '#606060',
  '#757575',
  '#8a8a8a',
  '#9f9f9f'
]
colors=[
  '#577ab3',
  // '#ff0000',
  // '#FFFF00',
  
]
const TreeRow = ({ onClick, headerList, item, level = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
 let allignment=item.groupDetail.allignment
  const toggleExpand = () => setIsExpanded(!isExpanded);
  
  if (
    item.yearWiseAmt.filter((row) => row.creditAmt + row.dabitAmt != 0)
      .length != 0
  ) {
    return (
      <>
        <tr>
          <td
            onClick={toggleExpand}
            style={{
              cursor:
                item.drilList && item.drilList.length != 0
                  ? "pointer"
                  : "default",
              paddingLeft: `${level * 20}px`,
              width: "10%",
            }}
          >
            <div style={{ display: "flex" }}>
              {item.drilList && item.drilList.length != 0 && (
                <>{isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}</>
              )}

              <Typography
                sx={{
                  paddingLeft:
                    item.drilList && item.drilList.length != 0 ? 0 : 1,
                }}
                variant="body2"
                color={colors[level]}
              >
                {item.groupDetail.group_name}
              </Typography>
            </div>
          </td>
          {headerList.map((row) => {
            return (
              <>
                {item.yearWiseAmt.map((yearWiseAmt) => {
                  if (yearWiseAmt.monthYear == row.monthYearDate) {
                    let amt=yearWiseAmt.creditAmt + yearWiseAmt.dabitAmt
                    return (
                      <>
                        <td style={{ width: "15%", textAlign: "right" }}>
                        {((allignment=='R'&&amt>0)||(allignment=='L'&&amt<0))?"("+Math.abs(amt).toFixed(2)+")": Math.abs(amt).toFixed(2)} 
                        </td>
                      </>
                    );
                  }
                })}
              </>
            );
          })}
        </tr>
        {isExpanded &&
          item.drilList &&
          item.drilList.map((child) => (
            <TreeRowDetails
            onClick={onClick}
            isStatic={false}
            allignment={allignment}
              headerList={headerList}
              key={child.id}
              item={child}
              level={level + 1}
            />
          ))}
      </>
    );
  }
};
const TreeRowDetails = ({onClick, isStatic,headerList,allignment, item, level = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
 let colorIndex=colors[level]?colors[level]:colors[(level/colors.length)-1]
  
  const toggleExpand = () => setIsExpanded(!isExpanded);
  if (
    item.yearWiseAmt.filter((row) => row.creditAmt + row.dabitAmt != 0)
      .length != 0
  ) {
    let cursorFlag=item.group && item.group != null && item.group.length != 0?1:(isStatic&& item.manufactureAndProfit==1)|| item.gruopOrAcc == 1 ?2:3
    return (
      <>
        <tr>
          <td
          onClick={()=>{
           if(cursorFlag==1){
            toggleExpand()
           }else if(cursorFlag==2){
           let flagToShow= item.gruopOrAcc == 1?true:false
            onClick(item,flagToShow)
           } 
          }}
            style={{
              cursor:
              cursorFlag!=3
                  ? "pointer"
                  : "default",
              paddingLeft: `${level * 20}px`,
              width: "10%",
            }}
          >
            <div style={{ display: "flex" }}>
              {item.group && item.group != null && item.group.length != 0 && (
                <>{isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}</>
              )}

              <Typography
                sx={{
                  
                  paddingLeft:
                    item.group && item.group != null && item.group.length != 0
                      ? 0
                      : 1,
                }}
                
                variant="body2"
                color={colorIndex}
              >
                {item.name}
              </Typography>
            </div>
          </td>
          {headerList.map((row) => {
            return (
              <>
                {item.yearWiseAmt.map((yearWiseAmt) => {
                  if (yearWiseAmt.monthYear == row.monthYearDate) {
                    let amt=yearWiseAmt.creditAmt + yearWiseAmt.dabitAmt
                    return (
                      <>
                        <td style={{ width: "15%", textAlign: "right" }}>
                         
                          {((allignment=='R'&&amt>0)||(allignment=='L'&&amt<0))?"("+Math.abs(amt).toFixed(2)+")": Math.abs(amt).toFixed(2)} 
                        </td>
                      </>
                    );
                  }
                })}
              </>
            );
          })}
        </tr>
        {isExpanded &&
          item.group &&
          item.group != null &&
          item.group.map((child) => (
            <TreeRowDetails
            onClick={onClick}
            isStatic={false}
            allignment={allignment}
              headerList={headerList}
              key={child.id}
              item={child}
              level={level + 1}
            />
          ))}
      </>
    );
  }
};

const TreeView = ({ onClick,classes,allignment='',staticList=[], cashList = [], headerList = [] }) => {
  let totalObj={}
  console.log(staticList)
  return (
    <table style={{ width: "100%" }}>
      {cashList.length != 0 ? (
        <tbody>
          <tr>
            <td style={{ width: "40%" }}></td>
            {headerList.map((row) => {
              return (
                <td style={{ width: "15%", textAlign: "right" }}>
                  {row.showName}
                </td>
              );
            })}
          </tr>
          {cashList.map((item) => {
            headerList.map((row) => {
              item.yearWiseAmt.map((yearWiseAmt) => {
                if (yearWiseAmt.monthYear == row.monthYearDate) {
                  totalObj={
                    ...totalObj,
                    [row.monthYearDate]:totalObj[row.monthYearDate]?totalObj[row.monthYearDate]+yearWiseAmt.creditAmt + yearWiseAmt.dabitAmt:yearWiseAmt.creditAmt + yearWiseAmt.dabitAmt
                  }
                  return (
                    <>
                      <td style={{ width: "15%", textAlign: "right" }}>
                        {yearWiseAmt.creditAmt + yearWiseAmt.dabitAmt}
                      </td>
                    </>
                  );
                }
              })
            })
            return(
            <TreeRow
            onClick={onClick}
              headerList={headerList}
              classes={classes}
              key={item.id}
              item={item}
            />
          )})}
            {staticList.map((item) => {
            headerList.map((row) => {
              item.yearWiseAmt.map((yearWiseAmt) => {
                if (yearWiseAmt.monthYear == row.monthYearDate) {
                  totalObj={
                    ...totalObj,
                    [row.monthYearDate]:totalObj[row.monthYearDate]?totalObj[row.monthYearDate]+yearWiseAmt.creditAmt + yearWiseAmt.dabitAmt:yearWiseAmt.creditAmt + yearWiseAmt.dabitAmt
                  }
                  return (
                    <>
                      <td style={{ width: "15%", textAlign: "right" }}>
                        {yearWiseAmt.creditAmt + yearWiseAmt.dabitAmt}
                      </td>
                    </>
                  );
                }
              })
            })
            return(
            <TreeRowDetails
            onClick={onClick}
            isStatic={true}
              headerList={headerList}
              classes={classes}
              key={item.id}
              item={item}
            />
          )})}
          <tr>
            <td
              style={{
                width: "10%",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography variant="body1" color="#000">
                  Total
                </Typography>
              </div>
            </td>
            {headerList.map((row) => {
              return (
                <>
                 <td style={{ width: "15%", textAlign: "right" }}>
                 <Typography variant="body1" color="#000">
                 {((allignment=='R'&&totalObj[row.monthYearDate]>0)||(allignment=='L'&&totalObj[row.monthYearDate]<0))?"("+Math.abs(totalObj[row.monthYearDate]).toFixed(2)+")": Math.abs(totalObj[row.monthYearDate]).toFixed(2)} 
                
                </Typography>    
                          </td>
                </>
              );
            })}
          </tr>
        </tbody>
      ) : null}
    </table>
  );
};

export default withStyles(styles)(TreeView);
