import { Divider, Grid, Paper, TableCell, TableRow } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  deleteWarningMsg,
} from "../../config/messageconstant";
import { CashReceiptJSON } from "../../DynamicFormsJson/Transaction/cashReceipt";
import { getAccountLedgerListByType1 } from "../../Slice/subVoucherDetail.slice";
import { getSubVoucherByType } from "../../Slice/subVoucher.slice";
import { getCostCenter } from "../../Slice/cashReceipt.slice";
import { getProfitCenter } from "../../Slice/profitCenter.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicTransactionForm from "../../components/dynamicscreens/DynamicTransactionForm";
import CostCenterMappingDialouge from "./CostCenterMappingDialouge";
import { getSalesPurchase } from "../../Slice/salesPurchase.slice";
import { getLedgerDataByAccId } from "../../Slice/ledger.slice";

import BillMappingDialouge from "./BillMappingDialouge";
import { stringify } from "json5";
import { async } from "q";
import dayjs from "dayjs";
class CashReceiptForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      headerData: {},
      flag: 1,
      formData: {},
      setFieldDataFromApi: {},
      fieldData: {
        type: "C",
        isAdvance: "1",
        mainAccountId:
          this.props.subVoucherDetailList?.accountLedgerByType1.length != 0
            ? this.props.subVoucherDetailList?.accountLedgerByType1[0].id1
            : [],
        date: dayjs()
      },
      voucherAmountShow: 0,
      recieptPaymentDetailCcInfoList: [],
      recieptPaymentDetailBillInfoList: [],
      dynamicMasterData: {
        profitCenterDetails: this.props.profitCenter?.profitCenter,
        creditAccountDetails:
          this.props.subVoucherDetailList?.accountLedgerByType1,
        cashBankDetails: this.props.subVoucherDetailList?.accountLedgerByType,
        costCenterDetail: this.props.cashRecieptList?.costCenter,
        billDetail: this.props.salesPurchase?.salesPurchaseList,
        typeDetails: [
          { id: "C", name: "Credit" },
          { id: "D", name: "Debit" },
        ],

        subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
        openCostCenter: false,
        openBillMapping: false,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.subVoucherDetailList.accountLedgerByType1 !==
      nextProps.subVoucherDetailList.accountLedgerByType1
    ) {
      const list = nextProps.subVoucherDetailList.accountLedgerByType1.filter(
        (row) => row.accType == 1
      );

      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          creditAccountDetails:
            nextProps.subVoucherDetailList.accountLedgerByType1.filter(
              (row) => row.accType == 0
            ),
          cashBankDetails: list,
        },
      });

      if (this.state.flag == 1 && list.length != 0) {
        let formData = {
          cashBankId: list[0].id,
        };

        this.setState({
          fieldData: {
            ...this.state.fieldData,
            cashBankId: formData.cashBankId,
          },
        });
        this.getListById(formData);
        // mainAccountId: nextProps.subVoucherDetailList?.accountLedgerByType1.length != 0 ? [nextProps.subVoucherDetailList?.accountLedgerByType1[0].id] : [],
      }
    }
    if (
      this.props.cashRecieptList?.costCenter !==
      nextProps.cashRecieptList?.costCenter
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          costCenterDetail: nextProps.cashRecieptList?.costCenter,
        },
      });
    }
    if (
      this.props.profitCenter.profitCenter !==
      nextProps.profitCenter.profitCenter
    ) {
      if (nextProps.profitCenter.profitCenter.length == 1) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            profitCenter: { id: nextProps.profitCenter.profitCenter[0].id }
          }
        })
      }
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          profitCenterDetails: nextProps.profitCenter.profitCenter,
        },
      });
    }
    if (
      this.props.salesPurchase.salesPurchaseList !==
      nextProps.salesPurchase.salesPurchaseList
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          billDetail: nextProps.salesPurchase.salesPurchaseList,
        },
      });
    }
    if (
      this.props.subVoucherList?.subVoucherByType !==
      nextProps.subVoucherList?.subVoucherByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          subVoucherDetail: nextProps.subVoucherList?.subVoucherByType,
        },
      });
    }
  }

  setDataToFieldData = (rowData) => {
    const { fieldMeta, dynamicMasterData } = this.state;
    let fieldData = {};

    CashReceiptJSON.fieldMeta.forEach((currentField) => {
      let dataToSearch = {};
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] =
          rowData[tableDataKey] && rowData[tableDataKey] != null
            ? rowData[tableDataKey].split(",")
            : [];
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey] &&
              rowData[currentField.dataKey] != null
              ? rowData[currentField.dataKey].split(",")
              : [];
        } else {
          fieldData[currentField.dataKey] = [];
        }
      } else if (currentField.controlType === "autocomplete/textfield") {
        if (!currentField.isRootLevelKey) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: {
              id: rowData[tableDataKey].id,
            },
          };
        } else {
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
        fieldData = {
          ...fieldData,
          [currentField.dataKeyName]: rowData[tableDataKey].name,
        };
      } else if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: {
            id: rowData[tableDataKey].id,
          },
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.getListById(dataToSearch);
        }
      } else if (
        currentField.controlType === "autocomplete" &&
        currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: rowData[tableDataKey],
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "timePicker") {
        const timeDate = moment(rowData[tableDataKey], ["h:mm A"]).format(
          "HH:mm"
        );
        const timeArr = timeDate?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          fieldData[currentField.dataKey] = dayjs(
            rowData[tableDataKey],
            "DD-MM-YYYY"
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] === currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] === currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    console.log(fieldData);
    this.setState({
      editData: {
        ...rowData,
        ...fieldData,
      },
      fieldData: fieldData,
    });
  };


  async componentDidMount() {
    this.setState({
      rowList: [],
    });

    const {
      showLoador,
      showNotification,
      getAccountLedgerListByType1,
      getSubVoucherByType,
      getCostCenter,
      getProfitCenter
    } = this.props;
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getProfitCenter().then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        }
      );
      showLoador({ loador: true });
      await getAccountLedgerListByType1({ accType: "1,2,0" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        }
      );
      // showLoador({ loador: true });
      // await getSubVoucherByType({ type: 1 }).then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     showNotification({ msg: serverMsg, severity: "error" });
      //   } else {
      //   }
      // });
      showLoador({ loador: true });
      await getCostCenter().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  onSearch = (data) => {
    const {
      rowList,
      dynamicMasterData,
      recieptPaymentDetailCcInfoList,
      recieptPaymentDetailBillInfoList,
    } = this.state;
    console.log(data.amt)
    let catList = dynamicMasterData["creditAccountDetails"]
      ? dynamicMasterData["creditAccountDetails"].filter(
        (row) => row.id == data.mainAccountId
      )
      : [];
    let amt = 0;
    let debitotal = 0;
    let creditTotal = 0;

    rowList.map((row) => {
      amt = row.type == "C" ? amt + +row.amt : amt - +row.amt;
      creditTotal = creditTotal + +row.amt;

    });
    console.log(creditTotal)
    amt =
      (data.type ? data.type : "C") == "C" ? amt + +data.amt : amt - +data.amt;
    console.log(amt > 0 ? amt : amt * -1);

    if (+data.amt > 0) {
      this.setState({
        fieldData: {
          ...this.state.fieldData,
          voucherAmount: amt > 0 ? (+amt).toFixed(2) : (amt * -1).toFixed(2), // Assuming 2 decimal places
        },
        voucherAmountShow: amt,

        rowList: [
          ...rowList,
          {
            index: rowList.length + 1,
            mainAccountCode:
              catList.length != 0 ? catList[0].accountLedgerCode : "-",
            mainAccountName:
              catList.length != 0 ? catList[0].accountLedgerName : "-",

            recieptPaymentDetailCcInfoList: recieptPaymentDetailCcInfoList,
            recieptPaymentDetailBillInfoList: recieptPaymentDetailBillInfoList,
            ...data,
            amt: (+data.amt).toFixed(2),
            creditAmount: data.type == "C" ? (+data.amt).toFixed(2) : "",
            debitAmount: data.type == "C" ? "" : (+data.amt).toFixed(2),
          },
        ],
      });
    }
  };

  onSubmitData = () => {
    const { showNotification } = this.props;
    const location = localStorage.getItem("location");
    if (this.checkValidationOnSubmit()) {
      const {
        rowList,
        fieldData,
        recieptPaymentDetailCcInfoList,
        recieptPaymentDetailBillInfoList,
        dynamicMasterData,
      } = this.state;
      let totalAmt = 0;
      if (rowList.length != 0) {
        let creditAmount = 0;
        let debitAmount = 0;
        const recieptPaymentDetailList = rowList.map((rowData) => {
          if (rowData.type == "C") {
            creditAmount = creditAmount + +rowData.amt;
          }
          if (rowData.type == "D") {
            debitAmount = debitAmount + -Math.abs(+rowData.amt);
          }
          totalAmt = creditAmount + debitAmount;
          console.log(typeof totalAmt);
          const rowObj = {
            sortNo: 1,
            mainAccId: rowData.mainAccountCode,
            amt: rowData.amt,
            type: rowData.type,
            remark: rowData.remark,
            recieptPaymentDetailCcInfoList:
              rowData.recieptPaymentDetailCcInfoList,
            recieptPaymentDetailBillInfoList:
              rowData.recieptPaymentDetailBillInfoList,
            percentage: 0.0,
            isEffectonGstr: 0,
            HsnCode: "",
            taxableAmount: "0"
          };

          return rowObj;
        });
        console.log(totalAmt * -1);
        let catList = dynamicMasterData["subVoucherDetail"]
          ? dynamicMasterData["subVoucherDetail"].filter(
            (row) => row.id == fieldData.subVoucherMaster.id
          )
          : [];
        console.log(catList);
        const dataToSave = {
          profitCenter: fieldData.profitCenter,
          isAdvance: fieldData.isAdvance,
          subVoucherMaster: fieldData.subVoucherMaster,
          mainAccountId: rowList[0].mainAccountId,
          location: {
            id: location,
          },
          remark: fieldData.remark,
          voucherAmount: totalAmt > 0 ? totalAmt : totalAmt * -1,
          cashBankId: fieldData.cashBankId,
          receivedFrom: fieldData.receivedFrom,
          recieptPaymentDetailList: recieptPaymentDetailList,
          date: fieldData.date.format("DD-MM-YYYY"),
          status: catList[0].autoApproveStatus == 0 ? 0 : 1,
          bookType: 1,
          contraEntryType: 0,
        };

        console.log(dataToSave);
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            // this.onSave(dataToSave);
            if (catList[0].vouNature == 1 && totalAmt > 0) {
              showNotification({
                msg: "Voucher Amount must be negative",
                severity: "error",
              });
            } else if (catList[0].vouNature == 1 && totalAmt < 0) {
              this.onSave(dataToSave);
            } else if (catList[0].vouNature == 2 && totalAmt <= 0) {
              showNotification({
                msg: "Voucher Amount must be positive",
                severity: "error",
              });
            } else if (catList[0].vouNature == 2 && totalAmt > 0) {
              this.onSave(dataToSave);
            }
          }
        });
      } else {
        showNotification({
          msg: "Please add atleast one product",
          severity: "error",
        });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.recieptPaymentHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.setState({
            fieldData: {},
            rowList: []
          })
          this.props.callApi();
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: data.response.data.errorMessage, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    CashReceiptJSON.fieldMetaAdd.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (
        currentField.controlType === "hideDatepicker" &&
        (currentField.isMandatory || currentField.isMandatory == undefined)
      ) {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck1 = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    CashReceiptJSON.fieldMeta1.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    CashReceiptJSON.fieldMetaAdd.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck() && this.mandatoryCheck1()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    CashReceiptJSON.fieldMetaAdd.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = this.getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  rowDelete = (rowData) => {
    console.log(rowData);
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = this.state.rowList.filter(
          (row) => row.index != rowData.index
        );
        console.log(rowListData);
        const row = rowListData.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        console.log(row);
        let finalAmt =
          rowData.type == "C"
            ? this.state.voucherAmountShow - +rowData.amt
            : this.state.voucherAmountShow + +rowData.amt;
        console.log(finalAmt);
        this.setState({
          rowList: row,
          fieldData: {
            ...this.state.fieldData,
            voucherAmount:
              rowData.amt > 0
                ? Math.abs((+finalAmt)).toFixed(2)
                : Math.abs((+finalAmt * -1)).toFixed(2), // Assuming 2 decimal places
          },
          voucherAmountShow: finalAmt,
        });
      }
    });
  };

  rowCopy = (rowData) => {
    const { openCostCenter, rowList } = this.state;
    const rowListData = rowList.filter((row) => {
      if (row.index == rowData && rowData.index) {

      }
    });
    this.setState({
      openCostCenter: !openCostCenter,
      headerData: rowData && rowData,
    });
    console.log(rowData);
  };

  OnSaveCostCenter = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      recieptPaymentDetailCcInfoList: [],
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };

  handleCloseCC = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      recieptPaymentDetailCcInfoList: [],
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };

  handleCloseBill = (dataToSave, headerId) => {
    console.log(dataToSave)
    console.log(headerId)

    const { openBillMapping, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailBillInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      recieptPaymentDetailBillInfoList: [],
      openBillMapping: !openBillMapping,
      rowList: rowListData,
    });
  };

  rowAdd = async (rowData) => {
    const { openBillMapping } = this.state;
    const { getSalesPurchase, showLoador, showNotification } = this.props;
    console.log(rowData);
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getSalesPurchase({
        entryType: 1,
        accountLedgerCode: rowData.mainAccountCode,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            openBillMapping: !openBillMapping,
            headerData: rowData,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
    console.log(rowData);
  };

  onSaveBillMapping = (dataToSave, headerId) => {
    const { openBillMapping, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailBillInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    this.setState({
      recieptPaymentDetailBillInfoList: [],
      openBillMapping: !openBillMapping,
      rowList: rowListData,
    });
  };
  getListById = async (data) => {
    const { showNotification } = this.props;
    if (navigator.onLine) {

      if (data.mainAccountId != null && data.mainAccountId != "") {
        this.getList(data.mainAccountId, 1);
      }
      if (data.cashBankId != null && data.cashBankId != "") {
        //    this.setState({
        //   dynamicMasterData: {
        //     ...this.state.dynamicMasterData,
        //     creditAccountDetails:this.state.dynamicMasterData.creditAccountDetails.filter((row)=>row.id!=data.cashBankId),
        //   },
        // });
        this.getList(data.cashBankId, 0);
      }


    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }
  getList = async (accId, type) => {
    const {
      showLoador,
      showNotification,
      getLedgerDataByAccId,
      getCostCenter,
    } = this.props;
    const locationIdList = localStorage.getItem("locationIdList");
    let valuesArray = [];
    if (locationIdList != null) {
      valuesArray = JSON.parse(locationIdList);
      valuesArray = valuesArray.filter(
        (row) => row.id === localStorage.getItem("location")
      );
    }

    showLoador({ loador: true });
    await getLedgerDataByAccId({
      fromDate: dayjs().format("DD-MM-YYYY"),
      toDate: dayjs().format("DD-MM-YYYY"),
      companyIds: valuesArray.length != 0 ? valuesArray[0].company.id : 0,
      accId: accId,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        if (response != null) {

          if (type == 1) {
            this.setState({
              setFieldDataFromApi: {
                balance: response.closingAmt != null && response.closingAmt >= 0 ? Math.abs(response.closingAmt).toFixed(2) + " " + "Cr" : Math.abs(response.closingAmt).toFixed(2) + " " + "Dr"
              },
            });
          } else {
            this.setState({
              fieldData: {
                ...this.state.fieldData,
                balance: response.closingAmt != null && response.closingAmt >= 0 ? Math.abs(response.closingAmt).toFixed(2) + " " + "Cr" : Math.abs(response.closingAmt).toFixed(2) + " " + "Dr"
              },
            });
          }
        }
      }
    });
  };
  getCreditData = (list) => {
    let CreditList = []
    let rowList = this.state.rowList
    console.log(list)
    console.log(rowList)
    list.map((rowInfo) => {
      let filterData = rowList.filter((row) => row.mainAccountCode == rowInfo.id)
      if (filterData.length == 0) {
        CreditList.push(rowInfo)
      }
    })
    return CreditList
  }
  getListData = () => {
    const {
      rowList
    } = this.state;
    let rowListData = ["Total", ""]

    let creditAmount = 0
    let debitAmount = 0
    rowList.map((rowListInfo) => {
      creditAmount = creditAmount + +rowListInfo.creditAmount
      debitAmount = debitAmount + +rowListInfo.debitAmount
      return rowListInfo
    })
    rowListData = [
      ...rowListData,
      creditAmount.toFixed(2),
      debitAmount.toFixed(2),
      ""
    ]
    if (rowList.length != 0)
      return rowListData
    return []
  }
  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      openCostCenter,
      openBillMapping,
      headerData,
      formData,
      setFieldDataFromApi,
    } = this.state;
    const { rowReset } = this.props
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            paddingTop: 1,
            height: "100%"
          }}
          elevation={0}
        >
          <DynamicTransactionForm
            amount={fieldData.voucherAmount ? fieldData.voucherAmount : "0"}
            getListById={this.getListById}
            formData={formData}
            showReset={true}
            rowReset={rowReset}
            dynamicMasterData={dynamicMasterData}
            screenTitle={CashReceiptJSON.screenTitle}
            fieldMeta={CashReceiptJSON.fieldMetaAdd}
            apiBaseURL={CashReceiptJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={CashReceiptJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
          />
          <br />
          <Divider sx={{ borderWidth: 1, color: "#000000" }} />
          <br />
          <DynamicFormWithoutSave

            dynamicMasterData={{
              ...dynamicMasterData,
              creditAccountDetails: dynamicMasterData.creditAccountDetails.filter(
                (row) => row.id != fieldData.cashBankId
              )
            }}
            getListById={this.getListById}
            searchList={CashReceiptJSON.searchList}
            showTitle={CashReceiptJSON.showTitle}
            screenTitle={CashReceiptJSON.screenTitle}
            fieldMeta={CashReceiptJSON.fieldMetaDetails}
            showCancel={CashReceiptJSON.showCancel}
            showBackToList={CashReceiptJSON.showBackToList}
            apiBaseURL={CashReceiptJSON.apiBaseURL}
            showSaveNextBtn={CashReceiptJSON.showSaveNextBtn}
            saveBtnText={"ADD"}
            onSave={this.onSearch}
            resetAfterSave={true}
            getDataForDisabled={this.getDataForDisabled}
            setFieldDataFromApi={setFieldDataFromApi}
          />
          <br />
          <RTTable
            totalList={this.getListData()}
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "mainAccountCode", title: "ACCD" },
              { name: "mainAccountName", title: "Account Head" },
              {
                name: "creditAmount",
                title: "Credit Amount",
                align: "right",
              },
              {
                name: "debitAmount",
                title: "Debit Amount",
                align: "right",
              },

              { name: "action", title: "Action" },
            ]}
            hiddenColumnNames={[]}
            tableData={rowList}
            isActionColActive={true}
            showEditIcon={false}
            showDeleteIcon={true}
            rowDelete={this.rowDelete}
            showCopyIcon={true}
            rowCopy={this.rowCopy}
            showAddButtonInTable={true}
            rowAdd={this.rowAdd}
            iconTitle="Map Sell Bill"
            iconTitleForCopy="Cost Center"
          />

          <br />
          <DynamicTransactionForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={CashReceiptJSON.screenTitle}
            fieldMeta={CashReceiptJSON.fieldMeta1}
            apiBaseURL={CashReceiptJSON.apiBaseURL}
            formErrors={formErrors}

            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showTitle={false}
            getDataForDisabled={this.getDataForDisabled}

          />
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
        {openCostCenter && (
          <CostCenterMappingDialouge
            open={openCostCenter}
            handleCloses={this.handleCloseCC}
            dynamicMasterData={dynamicMasterData}
            onSave={this.OnSaveCostCenter}
            dataToSave={headerData}
          />
        )}
        {openBillMapping && (
          <BillMappingDialouge
            open={openBillMapping}
            handleCloses={this.handleCloseBill}
            dynamicMasterData={dynamicMasterData}
            onSave={this.onSaveBillMapping}
            dataToSave={headerData}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
  subVoucherList: state.subVoucher,
  cashRecieptList: state.cashReciept,
  salesPurchase: state.salesPurchase,
  profitCenter: state.profitCenter
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getAccountLedgerListByType1,
  getSubVoucherByType,
  getCostCenter,
  getSalesPurchase,
  getLedgerDataByAccId,
  getProfitCenter
  //   getProductByCategoryForStakeHolder,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CashReceiptForm);
