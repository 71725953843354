import endpoint from "../../config/endpoints";

export const ContraEntryJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.recieptPaymentHeader,
  screenTitle: "Contra Entry",
  formPath: "/contra-entry-form",
  formPathView: "/contra-entry-view",
  showBackToList: false,
  showTitle: false,
  searchList: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucher",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
  fieldMeta: [
 
    {
      label: "Contra Entry Type",
      controlType: "autocomplete",
      placeHolder: "Select Contra Entry Type ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "contraEntryTypeDetails",
      dataKey: "contraEntryType",
      getListId: "contraEntryType",
      isRootLevelKey: true,
      isMandatory: true,
      disable:true
    },
    {
      label: "Profit Center",
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Sub-voucher",
      controlType: "textfield",
      placeHolder: "Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "subVoucherName",
      isRootLevelKey: false,
      isMandatory: true,
      disable:true
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
      freezCurrentDate:true,
      dateApiValid:true
    },
    {
      label: "Bank A/C",
      controlType: "autocomplete",
      placeHolder: "Select Cash Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      getListId: "cashBankId",
      isRootLevelKey: true,
      getListFrom: "contraEntryType",
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "textfield",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Debit Account",
      controlType: "autocomplete",
      placeHolder: "Select Debit Account ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "creditAccountDetails",
      dataKey: "mainAccountId",
      getListId: "mainAccountId",
      getListFrom: "contraEntryType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "textfield",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "debitBalance",
      disableTrue:true
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "amt",
      inputType: "number",
      isMandatory: true,
    },
   
    {
      label: "Paid To",
      controlType: "textfield",
      placeHolder: "Enter Paid To",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "paidTo",
      isMandatory: true,
      disableTrue: false,
    },
    {
      label: "Payment Mode",
      controlType: "autocomplete",
      placeHolder: "Select Payment Mode ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "paymentModeDetails",
      dataKey: "paymentMode",
      isRootLevelKey: true,
      isMandatory:true ,
    },
    {
      label: "Cheque No.",
      controlType: "hideTextfield",
      placeHolder: "Enter Cheque No.",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "chequeNo",
      tableDataKey: "contraEntryType",
      inputType: "number",
      openDataKey: "contraEntryType",
      open: "1",
      hideGrid:true,
      error: "contraEntryType",
      isMandatory: false,
    },
    {
      label: "Cheque Date",
      controlType: "hideDatepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      hideGrid:true,
      tableDataKey: "contraEntryType",
      dataKey: "chequeDate",
      openDataKey: "contraEntryType",
      open: "1",
      // error: "contraEntryType",
      isMandatory: false,
      onSubmit: "compare",
      onSubmitParameter: "passDate-ge",
    },
    {
      label: "Cheque Pass Date",
      controlType: "hideDatepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      hideGrid:true,
      tableDataKey: "contraEntryType",
      dataKey: "passDate",
      openDataKey: "contraEntryType",
      open: "1",
      // error: "contraEntryType",
      isMandatory: false,
      onSubmit: "compare",
      onSubmitParameter: "chequeDate-le",
    },
    {
      label: "Clearance Days",
      controlType: "hideTextfield",
      placeHolder: "Enter Clearance days",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      hideGrid:true,
      tableDataKey: "contraEntryType",
      dataKey: "clearanceDays",
      openDataKey: "contraEntryType",
      open: "1",
      inputType: "number",
      isMandatory: false,
    },
    {
      label: "Drawn On",
      controlType: "hideDatepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      hideGrid:true,
      tableDataKey: "contraEntryType",
      dataKey: "drawnOn",
      openDataKey: "contraEntryType",
      open: "1",
      error: "contraEntryType",
      isMandatory: false,
    },
    {
      label: "",
      controlType: "hideDatepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      hideGrid:true,
      openDataKey: "contraEntryType",
      open: "4",
    },
   
    
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
  fieldMetaAdd: [
    {
      label: "Contra Entry Type",
      controlType: "autocomplete",
      placeHolder: "Select Contra Entry Type ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "contraEntryTypeDetails",
      dataKey: "contraEntryType",
      getListId: "contraEntryType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Profit Center",
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucherMaster",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      freezCurrentDate:true,
      isMandatory: true,
      dateApiValid:true
    },
    {
      label: "Bank A/C",
      controlType: "autocomplete",
      placeHolder: "Select Cash Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      isRootLevelKey: true,
      getListFrom: "contraEntryType",
   
      getListId: "cashBankId",
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Cash A/C",
      controlType: "autocomplete",
      placeHolder: "Select Debit Account ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "creditAccountDetails",
      dataKey: "mainAccountId",
      getListFrom: "contraEntryType",
   
      getListId: "mainAccountId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "debitBalance",
      disableTrue:true
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "amt",
      inputType: "number",
      isMandatory: true,
    },
    {
      label: "Paid To",
      controlType: "textfield",
      placeHolder: "Enter Paid To",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "paidTo",
      isMandatory: true,
      disableTrue: false,
    },
    {
      label: "Payment Mode",
      controlType: "autocomplete",
      placeHolder: "Select Payment Mode ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "paymentModeDetails",
      dataKey: "paymentMode",
      isRootLevelKey: true,
      isMandatory:true ,
    },
    {
      label: "Cheque No.",
      controlType: "hideTextfield",
      placeHolder: "Enter Cheque No.",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "chequeNo",
      tableDataKey: "contraEntryType",
      inputType: "number",
      openDataKey: "contraEntryType",
      open: "1",
      hideGrid:true,
      error: "contraEntryType",
      isMandatory: false,
    },
    {
      label: "Cheque Date",
      controlType: "hideDatepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      tableDataKey: "contraEntryType",
      dataKey: "chequeDate",
      openDataKey: "contraEntryType",
      open: "1",
      hideGrid:true,
      isMandatory: false,
      onSubmit: "compare",
      onSubmitParameter: "passDate-ge",
    },
    {
      label: "Cheque Pass Date",
      controlType: "hideDatepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      tableDataKey: "contraEntryType",
      hideGrid:true,
      dataKey: "passDate",
      openDataKey: "contraEntryType",
      open: "1",
      // error: "contraEntryType",
      isMandatory: false,
      onSubmit: "compare",
      onSubmitParameter: "chequeDate-le",
    },
    {
      label: "Clearance Days",
      controlType: "hideTextfield",
      placeHolder: "Enter Clearance days",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      tableDataKey: "contraEntryType",
      dataKey: "clearanceDays",
      openDataKey: "contraEntryType",
      open: "1",
      hideGrid:true,
      inputType: "number",
      isMandatory: false,
    },
    {
      label: "Drawn On",
      controlType: "hideDatepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      tableDataKey: "contraEntryType",
      dataKey: "drawnOn",
      openDataKey: "contraEntryType",
      hideGrid:true,
      open: "1",
      error: "contraEntryType",
      isMandatory: false,
    },
    {
      label: "",
      controlType: "hideDatepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      openDataKey: "contraEntryType",
      hideGrid:true,
      open: "4",
    },
   
   
   
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
  fieldMetaDetails: [],
  fieldMetaForOption: [
    {
      label: "Cost Center",
      controlType: "autocomplete",
      placeHolder: "Select Cost Center ",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      masterName: "costCenterDetail",
      dataKey: "costCenterMaster",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "amount",
      inputType: "number",
      isMandatory: true,
    },
  ],
  fieldMeta1: [],
};
