import dayjs from "dayjs";
import { disabledTime } from "rsuite/esm/utils/dateUtils";

export const balanceSheetJson = {
    screenTitle: "Balance Sheet (Horizontal)",
    showPdfDownload: true,
    showExcelDownload: false,
    fieldMeta: [
       
       
        {
            label: "Date Range",
            controlType: "datepicker",
            placeHolder: "",
            md:3.5,
            lg:3.5,
            sm:3.5,
            xs: 12,
            labelSM: 6,
            labelMD: 6,
            labelLG: 6,
            valueSM: 6,
            valueMD: 6,
            valueLG: 6,
            showLabel:true,
            error:"From Date",
            dataKey: "fromDate",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-le",
          },
          {
            label: "toDate",
            controlType: "datepicker",
            placeHolder: "",
            md: 2,
            lg: 2,
            sm: 2,
            xs: 12,
            hideLabel:true,
            labelSM: 1,
            labelMD: 1,
            labelLG: 1,
            valueSM: 11,
            valueMD: 11,
            valueLG: 11,
            dataKey: "toDate",
            onSubmit: "compare",
            error:"To Date",
            onSubmitParameter: "fromDate-ge",
            isMandatory: true,
            showLabel:true,
            onSubmit: "compare",
            defaultValue: dayjs(new Date()),
          },
          {
            label: "",
            controlType: "autocomplete",
            placeHolder: "Select Company",
            md: 3,
            lg: 3,
            sm: 3,
            xs: 12,
            labelSM: 1,
            labelMD: 1,
            labelLG: 1,
            valueSM: 11,
            valueMD: 11,
            valueLG: 11,
            error: "Company",
            masterName: "CompanyDetails",
            hideLabel: true,
            dataKey: "companyId",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
    fieldMetaComparisionType: [ 
      {
        label: "Search By",
        controlType: "autocomplete",
        placeHolder: "Select Search By",
        md: 3.5,
        lg: 3.5,
        sm: 3.5,
        xs: 12,
      
        error: "searchBy",
        masterName: "searchByList",
        hideLabel: true,
        dataKey: "searchBy",
        isRootLevelKey: true,
        isMandatory: true,
    }, {
      label: "Company",
      controlType: "autocomplete",
      placeHolder: "Select Company",
      md: 3.5,
      lg: 3.5,
      sm: 3.5,
      xs: 12,
    
      error: "Company",
      masterName: "CompanyDetails",
      hideLabel: true,
      dataKey: "companyId",
      isRootLevelKey: true,
      isMandatory: true,
  },
],
fieldMetaComparisionTypeDisabled: [ 
  {
    label: "Search By",
    controlType: "autocomplete",
    placeHolder: "Select Search By",
    md: 3.5,
    lg: 3.5,
    sm: 3.5,
    xs: 12,
    disableTrue:true,
    error: "searchBy",
    masterName: "searchByList",
    hideLabel: true,
    dataKey: "searchBy",
    isRootLevelKey: true,
    isMandatory: true,
}, {
  label: "Company",
  controlType: "autocomplete",
  placeHolder: "Select Company",
  md: 3.5,
  lg: 3.5,
  sm: 3.5,
  xs: 12,
  disableTrue:true,
  error: "Company",
  masterName: "CompanyDetails",
  hideLabel: true,
  dataKey: "companyId",
  isRootLevelKey: true,
  isMandatory: true,
},
],
fieldMetaYearwise: [
    {
  label: "year",
  controlType: "autocomplete",
  placeHolder: "Select  year ",
  md: 3.5,
  lg: 3.5,
  sm: 3.5,
  xs: 12,
 
  error: "year",
  masterName: "yearMasterDetail",
  hideLabel: true,
  dataKey: "monthYearDate",
  isRootLevelKey: true,
  isMandatory: true,
},
],
fieldMetaDateWise: [
       
       
  {
      label: "Date Range",
      controlType: "datepicker",
      placeHolder: "",
      md:3.5,
      lg:3.5,
      sm:3.5,
      xs: 12,
     
      error:"From Date",
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "",
      controlType: "datepicker",
      placeHolder: "",
      md: 2,
      lg: 2,
      sm: 2,
      xs: 12,
      // hideLabel:true,
      labelSM: 0.5,
      labelMD: 0.5,
      labelLG: 0.5,
      valueSM: 11.5,
      valueMD: 11.5,
      valueLG: 11.5,
      dataKey: "toDate",
      onSubmit: "compare",
      error:"To Date",
      onSubmitParameter: "fromDate-ge",
      isMandatory: false,
      showLabel:true,
      onSubmit: "compare",
      defaultValue: dayjs(new Date()),
    },
],
fieldMetaMonthWise: [
       
       
  {
      label: "Month",
      controlType: "monthpicker",
      placeHolder: "",
      md: 3.5,
  lg: 3.5,
  sm: 3.5,
  xs: 12,
 
      showLabel:true,
      error:"Month",
      dataKey: "monthYearDate",
      isMandatory: true,
    }
],
};