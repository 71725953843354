import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../components/withRouter";
import {
    noInternetMsg,
   
  } from "../../config/messageconstant";
import { AssetJSON } from "../../DynamicFormsJson/Transaction/Asset";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";

import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
class AssetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
     
     
    };
  }
  async componentDidMount() {
    const {  showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.props.popUpData && this.props.popUpData.id) {
        showLoador({ loador: true });
        showLoador({ loador: true });
        await apiGet({
           url: endpoint.asset + "/" + this.props.popUpData.id ,
       }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            let dValue=((( data.data.openingValue) + ( data.data.purchaseInFirstHalf)) *
            ((data.data.depreciationPercentage)) /
          100
        ) +
        +(((data.data.purchaseInSecondHalf) * ((data.data.depreciationPercentage) / 2)) / 100) 
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "ledger Code",
                  value: data.data.ledgerCode?data.data.ledgerCode
                   
                    : "",
                },

                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Depreciation Percentage",
                  value:data.data.depreciationPercentage !== null ? data.data.depreciationPercentage : "",
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Opening Value",
                  value:
                  data.data.openingValue!=null? data.data.openingValue:""
                },
              
                {
                  xs: 12,
                  sm:4,
                  md:4,
                  lg:4,
                  label: "Purchase In First Half",
                  value:
                   data.data.purchaseInFirstHalf!=null? data.data.purchaseInFirstHalf: "",
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Purchase In Second Half",
                  value:  data.data.purchaseInSecondHalf!=null? data.data.purchaseInSecondHalf: "",
                },
                {
                  xs: 12,
                  sm:4,
                  md:4,
                  lg:4,
                  label: "Sell Value",
                  value:
                  data.data.sellValue!=null? data.data.sellValue: "",
                  
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Total",
                  value:  data.data.total!=null? data.data.total: "",
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Closing Value",
                  value: data.data.closingValue!=null? data.data.closingValue: "",
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Remark",
                  value:
                  data.data.remark !== null
                      ? data.data.remark != ""
                        ? data.data.remark
                        : " - "
                      : "",
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "date",
                  value:
                  data.data.date !== null
                      ?  data.data.date
                      : "",
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label:"Depreciation Value",
                  value:dValue,
         
                     
                },
                
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
   handleClose = () => {
   this.props.handleCloses();
  };
  

  
  
  render() {
    const { DataToSet, openCostCenterView, headerData, openBillView } =
      this.state;
    const { cashRecieptList } = this.props;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
           <BootstrapDialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={this.handleClose}
          >

Asset View
          </BootstrapDialogTitle>
           
          <DialogContent dividers>
          <DynamicFormWithoutSave
             showBackToList={false}
            DataToSet={DataToSet}
            showSaveBtn={AssetJSON.showSaveBtn}
            showTitle={false}
            screenTitle={""}
            fieldMeta={[]}
            showCancel={AssetJSON.showCancel}
            apiBaseURL={AssetJSON.apiBaseURL}
            showSaveNextBtn={AssetJSON.showSaveNextBtn}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
           </DialogContent>
        </BootstrapDialog>
         
        </Paper>
       
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  manageStockList: state.manageStockByDate,
  cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssetView);
