import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { compose } from "@reduxjs/toolkit";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import DrawerNav from "../../components/Drawer";
import Header from "../../components/Header";
import config from "../../config/config";

import PrivateRoute from "../../routes/index";
import BankReceiptTable from "../BankReciept/BankRecieptIndex";
import CashReceiptTable from "../CashReceipt/index";
import { closeNotification } from "./Landing.slice";
// import ExportToExcel from "../../components/ExportToExcel";
import B2B from "../../Pages/AccountReport/B2B";
import DynamicMasterForm from "../../components/dynamicscreens/DynamicMasterForm";
import DynamicMasterIndex from "../../components/dynamicscreens/DynamicMasterIndex";
import DynamicMasterMain from "../../components/dynamicscreens/DynamicMasterMain";
import GroupSummary from "../AccountReport/ GroupSummary";
import BalanceSheet from "../AccountReport/BalanceSheet";
import BalanceSheetVertical from "../AccountReport/BalanceSheetVertical";
import BankReco from "../AccountReport/BankReco";
import BankRecoReport from "../AccountReport/BankRecoReport";
import Bankbook from "../AccountReport/Bankbook";
import CashBalance from "../AccountReport/CashBalance";
import Cashbook from "../AccountReport/Cashbook";
import ClosingBalance from "../AccountReport/ClosingBalance";
import CostCenter from "../AccountReport/CostCenter";
import GroupSummaryByPlant from "../AccountReport/GroupSummaryByPlant";
import HSNReport from "../AccountReport/HSNReport";
import JournalBook from "../AccountReport/JournalBook";
import Leadger from "../AccountReport/Ledger";
import MFG from "../AccountReport/MFG";
import MFGAndPL from "../AccountReport/MFGAndPL";
import MFGPlantWise from "../AccountReport/MFGPlantWise";
import OpeningBalance from "../AccountReport/OpeningBalance";
import Outstanding from "../AccountReport/OutstandingReport";
import ProfitAndLoss from "../AccountReport/ProfitAndLoss";
import ProfitAndLossPlantWise from "../AccountReport/ProfitAndLossPlantWise";
import ProfitAndLossVertical from "../AccountReport/ProfitAndLossVertical";
import PurchaseSaleSummary from "../AccountReport/PurchaseSaleReport";
import Sample from "../AccountReport/Sample";
import SellPurchaseDatewiseReport from "../AccountReport/SellPurchaseDatewiseReport";
import SellPurchaseHSNHeadersReport from "../AccountReport/SellPurchaseHSNHeadersReport";
import SellPurchasePartyWise from "../AccountReport/SellPurchasePartyWise";
import SellRegisterReport from "../AccountReport/SellRegisterReport";
import TrialBalance from "../AccountReport/TrialBalance";
import AssetForm from "../AssetCalculationOld/AssetCalculationForm";
import AssetTable from "../AssetCalculationOld/AssetTable";
import AssetView from "../AssetCalculation/AssetView";
import BankPaymentApprovalTable from "../BankPayment/BankPaymentApprovalTable";
import BankPaymentForm from "../BankPayment/BankPaymentForm";
import BankPaymentFormEdit from "../BankPayment/BankPaymentFormEdit";
import BankPaymentTable from "../BankPayment/BankPaymentIndex";
import BankPaymentView from "../BankPayment/BankPaymentView";
import BankRecieptApprovalTable from "../BankReciept/BankRecieptApprovalTable";
import BankRecieptForm from "../BankReciept/BankRecieptForm";
import BankRecieptFormEdit from "../BankReciept/BankRecieptFormEdit";
import BankRecieptView from "../BankReciept/BankRecieptView";
import CashPaymentApprovalTable from "../CashPayment/CashPaymentApprovalTable";
import CashPaymentForm from "../CashPayment/CashPaymentForm";
import CashPaymentFormEdit from "../CashPayment/CashPaymentFormEdit";
import CashPaymentTable from "../CashPayment/CashPaymentIndex";
import CashPaymentView from "../CashPayment/CashPaymentView";
import CashReceiptApprovalTable from "../CashReceipt/CashReceiptApprovalTable";
import CashReceiptForm from "../CashReceipt/CashReceiptForm";
import CashReceiptFormEdit from "../CashReceipt/CashReceiptFormEdit";
import CashReceiptPreview from "../CashReceipt/CashReceiptPreview";
import CashRecieptView from "../CashReceipt/CashRecieptView";
import ContraEntryApprovalTable from "../ContraEntry/ContraEntryApprovalTable";
import ContraEntryForm from "../ContraEntry/ContraEntryForm";
import ContraEntryFormEdit from "../ContraEntry/ContraEntryFormEdit";
import ContraEntryTable from "../ContraEntry/ContraEntryIndex";
import ContraEntryView from "../ContraEntry/ContraEntryView";
import CreditNoteApprovalTable from "../CreditNote/CreditNoteApprovalTable";
import CreditNoteForm from "../CreditNote/CreditNoteForm";
import CreditNoteFormEdit from "../CreditNote/CreditNoteFormEdit";
import CreditNoteTable from "../CreditNote/CreditNoteIndex";
import CreditNoteView from "../CreditNote/CreditNoteView";
import DebitNoteApprovalTable from "../DebitNote/DebitNoteApprovalTable";
import DebitNoteForm from "../DebitNote/DebitNoteForm";
import DebitNoteFormEdit from "../DebitNote/DebitNoteFormEdit";
import DebitNoteTable from "../DebitNote/DebitNoteIndex";
import DebitNoteView from "../DebitNote/DebitNoteView";
import JournalEntryApprovalTable from "../JournalEntry/JournalEntryApprovalTable";
import JournalEntryForm from "../JournalEntry/JournalEntryForm";
import JournalEntryFormEdit from "../JournalEntry/JournalEntryFormEdit";
import JournalEntryTable from "../JournalEntry/JournalEntryIndex";
import JournalEntryView from "../JournalEntry/JournalEntryView";
import AccountBankInformation from "../Master/AccountLedger/AccountBankInformation";
import AccountContactInformation from "../Master/AccountLedger/AccountContactInformation";
import AccountLedgerTable from "../Master/AccountLedger/AccountLedgerTable";
import AccountLedgerIndex from "../Master/AccountLedger/MainIndex";
import BillBook from "../BillBook";
import BalanceSheetByPlant from "../../Pages/AccountReport/BalanceSheetByPlant";
import SalePurchaseView from "../../Pages/AccountReport/SalePurchaseView";
import ColumnSpacingForm from "../../Pages/Master/SubVoucherDetail/ColumnSapcing";
import PurchaseSaleHistory from "../AccountReport/PurchaseSaleHistory";
import AccountLedger from "../Master/AccountLedgerForReports/index";
import SubVoucherForm from "../Master/SubVoucher/SubVoucherForm";
import SubVoucherTable from "../Master/SubVoucher/SubVoucherTable";
import SubVoucherDetailForm from "../Master/SubVoucherDetail/SubVoucherDetailForm";
import SubVoucherDetailTable from "../Master/SubVoucherDetail/SubVoucherDetailTable";
import UserForm from "../Master/User/UserForm";
import UserFormEdit from "../Master/User/UserFormEdit";
import UserTableIndex from "../Master/User/UserTableIndex";
import MasterGenerator from "../MasterGenerator";
import MasterTable from "../MasterGenerator/MasterTable";
import ReportTableMatrix from "../MatricReportGenerator/ReportTable";
import MatricReportGenerator from "../MatricReportGenerator/index";
import OPSettlementForm from "../OpeningSettlement/OpeningSettlement";
import ReportTable from "../ReportGenerator/ReportTable";
import ReportGenerator from "../ReportGenerator/index";
import ReportTableWithQuery from "../ReportGeneratorWithQuery/ReportTable";
import ReportGeneratorWithQuery from "../ReportGeneratorWithQuery/index";
import BalanceSheetComparision from "../Comparision/BalanceSheet";

import CashFlowSummary from "../../Pages/AccountReport/CashFlowSummary";
import CashFlowSummaryVertical from "../../Pages/AccountReport/CashFlowSummaryVertical";
import RatioAnalysis from "../../Pages/AccountReport/RatioAnalysis";
import { withRouter } from "../../components/withRouter";
import B2C from "../AccountReport/B2C";
import B2CL from "../AccountReport/B2CL";
import BalanceSheetPlantWiseVertical from "../AccountReport/PlantWiseBalanceSheetVertical";
import AssetPreview from "../AssetCalculation/AssetPreview";
import BankPaymentPreview from "../BankPayment/BankPaymentPreview";
import BankReceiptPreview from "../BankReciept/BankReceiptPreview";
import CashPaymentPreview from "../CashPayment/CashPaymentPreview";
import ContraEntryPreview from "../ContraEntry/ContraEntryPreview";
import CrediteNotePreview from "../CreditNote/CrediteNotePreview";
import Dashbourd from "../Dashbourd/index";
import DebiteNotePreview from "../DebitNote/DebiteNotePreview";
import JournalEntryPreView from "../JournalEntry/JournalEntryPreView";
import ImportAccountLedger from "../Master/AccountLedger/ImportAccountLedger";
import SalePurchaseApproval from "../SalePurchaseApproval";
import SalePurchaseSettle from "../SalePurchaseSettle/SalePurchaseSettle";
import StockEvolutionFormNew from "../StockEvalutionOld/StockEvolutionFormNew";
import StockEvolutionForm from "../StockEvaluation/StockEvolutionFormNew";

import UserAccessForm from "../UserAccess/UserAccessForm";
import UserAccessTable from "../UserAccess/UserAccessTable";
import CreditNoteERP from "../CreditNoteERP";
import Import from "../Import";
import ExportReport from "../Import/Report";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(7),
    backgroundColor: "#F5F5F7",
    minHeight: "calc(100vh - 64px)",
    padding: theme.spacing(1),
    paddingTop:"12px"
    // borderRadius: `${ theme.spacing( 1.5 ) } ${ theme.spacing( 1.5 ) } 0px 0px`,
  },
  typo: {
    color: theme.palette.primary.contrastText,
  },
});

class Landing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSnackBar: false,
    };
  }

  closeSnak = () => {
    const { closeNotification } = this.props;
    closeNotification();
  };

  render() {
    const { classes, common, accessRole,location } = this.props;
    const { moduleList } = this.state;
    return (
      <>
    
    <Header/>
    {
     accessRole.moduleName!='Dashboard'&& <DrawerNav  />
    }
   
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: common.toggleNav&&  accessRole.moduleName!='Dashboard'
              ? `calc(100% - ${config.drawerWidth}px)`
              : "100%",
            ml: common.toggleNav&&  accessRole.moduleName!='Dashboard' ? `${config.drawerWidth}px` : "0px",
          }}
          className={classes.root}
        >
          <Routes>
           
          <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashbourd />
            </PrivateRoute>
          }
        />
              <Route
              path="/bill-book"
              element={
                <PrivateRoute>
                  <BillBook/>
                </PrivateRoute>
              }
            />
               <Route
            path="/credit-note-new"
            element={
              <PrivateRoute>
                <CreditNoteERP/>
              </PrivateRoute>
            }
          />
          
          <Route
            path="/import"
            element={
              <PrivateRoute>
                <Import/>
              </PrivateRoute>
            }
          />
          
             <Route
              path="/import-account-ledger"
              element={
                <PrivateRoute>
                  <ImportAccountLedger />
                </PrivateRoute>
              }
            />
            
         
            <Route
              path="/user"
              element={
                <PrivateRoute>
                  <UserTableIndex />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-form"
              element={
                <PrivateRoute>
                  <UserForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-form/:id"
              element={
                <PrivateRoute>
                  <UserFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/sale-purchase-approval"
              element={
                <PrivateRoute>
                  <SalePurchaseApproval />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/stock-evaluation-form"
              element={
                <PrivateRoute>
                  <StockEvalutionForm />
                </PrivateRoute>
              }
            /> */}

            <Route
              path="/stock-evaluation-form"
              element={
                <PrivateRoute>
                  <StockEvolutionFormNew />
                </PrivateRoute>
              }
            />
  <Route
              path="/month-wise-freeze"
              element={
                <PrivateRoute>
                  <StockEvolutionForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sale-purchase-settle"
              element={
                <PrivateRoute>
                  <SalePurchaseSettle />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-receipt"
              element={
                <PrivateRoute>
                  <CashReceiptTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-receipt-form"
              element={
                <PrivateRoute>
                  <CashReceiptForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-receipt-form/:id/:approvalFlag"
              element={
                <PrivateRoute>
                  <CashReceiptFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-receipt-view/:id"
              element={
                <PrivateRoute>
                  <CashRecieptView />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-receipt-preview/:id"
              element={
                <PrivateRoute>
                  <CashReceiptPreview />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-receipt-approval"
              element={
                <PrivateRoute>
                  <CashReceiptApprovalTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-receipt"
              element={
                <PrivateRoute>
                  <BankReceiptTable/>
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-receipt-form"
              element={
                <PrivateRoute>
                  <BankRecieptForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-receipt-form/:id/:approvalFlag"
              element={
                <PrivateRoute>
                  <BankRecieptFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-receipt-view/:id"
              element={
                <PrivateRoute>
                  <BankRecieptView />
                </PrivateRoute>
              }
            />
             <Route
              path="/bank-receipt-preview/:id"
              element={
                <PrivateRoute>
                  <BankReceiptPreview/>
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-receipt-approval"
              element={
                <PrivateRoute>
                  <BankRecieptApprovalTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-payment"
              element={
                <PrivateRoute>
                  <BankPaymentTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-payment-form"
              element={
                <PrivateRoute>
                  <BankPaymentForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-payment-form/:id/:approvalFlag"
              element={
                <PrivateRoute>
                  <BankPaymentFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-payment-view/:id"
              element={
                <PrivateRoute>
                  <BankPaymentView />
                </PrivateRoute>
              }
            />
             <Route
              path="/bank-payment-preview/:id"
              element={
                <PrivateRoute>
                  <BankPaymentPreview />
                </PrivateRoute>
              }
            />
            
            <Route
              path="/bank-payment-approval"
              element={
                <PrivateRoute>
                  <BankPaymentApprovalTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/contra-entry"
              element={
                <PrivateRoute>
                  <ContraEntryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/contra-entry-form"
              element={
                <PrivateRoute>
                  <ContraEntryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/contra-entry-form/:id/:approvalFlag"
              element={
                <PrivateRoute>
                  <ContraEntryFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/contra-entry-view/:id"
              element={
                <PrivateRoute>
                  <ContraEntryView />
                </PrivateRoute>
              }
            />
              <Route
              path="/contra-entry-preview/:id"
              element={
                <PrivateRoute>
                  <ContraEntryPreview />
                </PrivateRoute>
              }
            />
           
            <Route
              path="/contra-entry-approval"
              element={
                <PrivateRoute>
                  <ContraEntryApprovalTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-payment"
              element={
                <PrivateRoute>
                  <CashPaymentTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-payment-form"
              element={
                <PrivateRoute>
                  <CashPaymentForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-payment-form/:id/:approvalFlag"
              element={
                <PrivateRoute>
                  <CashPaymentFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-payment-view/:id"
              element={
                <PrivateRoute>
                  <CashPaymentView />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-payment-preview/:id"
              element={
                <PrivateRoute>
                  <CashPaymentPreview/>
                </PrivateRoute>
              }
            />
           
            <Route
              path="/cash-payment-approval"
              element={
                <PrivateRoute>
                  <CashPaymentApprovalTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry"
              element={
                <PrivateRoute>
                  <JournalEntryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry-form"
              element={
                <PrivateRoute>
                  <JournalEntryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry-form/:id/:approvalFlag"
              element={
                <PrivateRoute>
                  <JournalEntryFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-entry-view/:id"
              element={
                <PrivateRoute>
                  <JournalEntryView />
                </PrivateRoute>
              }
            />

<Route
              path="/journal-entry-preview/:id"
              element={
                <PrivateRoute>
                  <JournalEntryPreView />
                </PrivateRoute>
              }
            />

            <Route
              path="/journal-entry-approval"
              element={
                <PrivateRoute>
                  <JournalEntryApprovalTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note"
              element={
                <PrivateRoute>
                  <DebitNoteTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note-form"
              element={
                <PrivateRoute>
                  <DebitNoteForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note-form/:id/:approvalFlag"
              element={
                <PrivateRoute>
                  <DebitNoteFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/debit-note-view/:id"
              element={
                <PrivateRoute>
                  <DebitNoteView />
                </PrivateRoute>
              }
            />
             <Route
              path="/debit-note-preview/:id"
              element={
                <PrivateRoute>
                  <DebiteNotePreview />
                </PrivateRoute>
              }
            />
            
            <Route
              path="/debit-note-approval"
              element={
                <PrivateRoute>
                  <DebitNoteApprovalTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note"
              element={
                <PrivateRoute>
                  <CreditNoteTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-form"
              element={
                <PrivateRoute>
                  <CreditNoteForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-form/:id/:approvalFlag"
              element={
                <PrivateRoute>
                  <CreditNoteFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-view/:id"
              element={
                <PrivateRoute>
                  <CreditNoteView />
                </PrivateRoute>
              }
            />
             <Route
              path="/credit-note-preview/:id"
              element={
                <PrivateRoute>
                  <CrediteNotePreview />
                </PrivateRoute>
              }
            />
            
            <Route
              path="/credit-note-approval"
              element={
                <PrivateRoute>
                  <CreditNoteApprovalTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-voucher"
              element={
                <PrivateRoute>
                  <SubVoucherTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-voucher-form"
              element={
                <PrivateRoute>
                  <SubVoucherForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-voucher-form/:id"
              element={
                <PrivateRoute>
                  <SubVoucherForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-voucher-detail/:paramId"
              element={
                <PrivateRoute>
                  <SubVoucherDetailTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-voucher-detail-form/:paramId"
              element={
                <PrivateRoute>
                  <SubVoucherDetailForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-voucher-detail-form/:id/:paramId"
              element={
                <PrivateRoute>
                  <SubVoucherDetailForm />
                </PrivateRoute>
              }
            />
           
          
            <Route
              path="/report-generate"
              element={
                <PrivateRoute>
                  <ReportGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/master-generate"
              element={
                <PrivateRoute>
                  <MasterGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/master-generate/:id"
              element={
                <PrivateRoute>
                  <MasterGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/report"
              element={
                <PrivateRoute>
                  <ReportTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/master"
              element={
                <PrivateRoute>
                  <MasterTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-generate/:id"
              element={
                <PrivateRoute>
                  <ReportGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/dynamic-master-old/:id"
              element={
                <PrivateRoute>
                  <DynamicMasterMain />
                </PrivateRoute>
              }
            />
             <Route
              path="/dynamic-master/:id"
              element={
                <PrivateRoute>
                  <DynamicMasterIndex />
                </PrivateRoute>
              }
            />
            <Route
              path="/dynamic-master-form/:id"
              element={
                <PrivateRoute>
                  <DynamicMasterForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/dynamic-master-form/:id/:rowDataId"
              element={
                <PrivateRoute>
                  <DynamicMasterForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-access-form"
              element={
                <PrivateRoute>
                  <UserAccessForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-access-form/:id"
              element={
                <PrivateRoute>
                  <UserAccessForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-access-table"
              element={
                <PrivateRoute>
                  <UserAccessTable />
                </PrivateRoute>
              }
            />
          
            <Route
              path="/report-generate-query"
              element={
                <PrivateRoute>
                  <ReportGeneratorWithQuery />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-with-query"
              element={
                <PrivateRoute>
                  <ReportTableWithQuery />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-generate-query/:id"
              element={
                <PrivateRoute>
                  <ReportGeneratorWithQuery />
                </PrivateRoute>
              }
            />

        
            <Route
              path="/matrix-report-generate-query"
              element={
                <PrivateRoute>
                  <MatricReportGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/matrix-report-with-query"
              element={
                <PrivateRoute>
                  <ReportTableMatrix />
                </PrivateRoute>
              }
            />
            <Route
              path="/matrix-report-generate-query/:id"
              element={
                <PrivateRoute>
                  <MatricReportGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/account-ledger-table"
              element={
                <PrivateRoute>
                  <AccountLedgerIndex />
                </PrivateRoute>
              }
            />
             <Route
              path="/account-ledger-table-old"
              element={
                <PrivateRoute>
                  <AccountLedgerTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/account-ledger"
              element={
                <PrivateRoute>
                  <AccountLedger />
                </PrivateRoute>
              }
            />
            <Route
              path="/account-ledger/:id"
              element={
                <PrivateRoute>
                  <AccountLedger />
                </PrivateRoute>
              }
            />
            <Route
              path="/account-contact-information/:id"
              element={
                <PrivateRoute>
                  <AccountContactInformation />
                </PrivateRoute>
              }
            />

            <Route
              path="/account-bank-information/:id"
              element={
                <PrivateRoute>
                  <AccountBankInformation />
                </PrivateRoute>
              }
            />

            <Route
              path="/cash-payment"
              element={
                <PrivateRoute>
                  <CashPaymentTable />
                </PrivateRoute>
              }
            />

            <Route
              path="/cash-payment-form"
              element={
                <PrivateRoute>
                  <CashPaymentForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sample"
              element={
                <PrivateRoute>
                  <Sample />
                </PrivateRoute>
              }
            />
            <Route
              path="/cashbook"
              element={
                <PrivateRoute>
                  <Cashbook />
                </PrivateRoute>
              }
            />
            <Route
              path="/bankbook"
              element={
                <PrivateRoute>
                  <Bankbook />
                </PrivateRoute>
              }
            />
            <Route
              path="/opening-balance"
              element={
                <PrivateRoute>
                  <OpeningBalance />
                </PrivateRoute>
              }
            />
            <Route
              path="/leadger"
              element={
                <PrivateRoute>
                  <Leadger />
                </PrivateRoute>
              }
            />
            <Route
              path="/closing-balance"
              element={
                <PrivateRoute>
                  <ClosingBalance />
                </PrivateRoute>
              }
            />
            <Route
              path="/trial-balance"
              element={
                <PrivateRoute>
                  <TrialBalance />
                </PrivateRoute>
              }
            />
            <Route
              path="/acc-group-summary"
              element={
                <PrivateRoute>
                  <GroupSummary />
                </PrivateRoute>
              }
            />
            <Route
              path="/journal-book"
              element={
                <PrivateRoute>
                  <JournalBook />
                </PrivateRoute>
              }
            />
            <Route
              path="/cash-balance"
              element={
                <PrivateRoute>
                  <CashBalance />
                </PrivateRoute>
              }
            />
            <Route
              path="/MFG"
              element={
                <PrivateRoute>
                  <MFG />
                </PrivateRoute>
              }
            />
            <Route
              path="/profit-and-loss"
              element={
                <PrivateRoute>
                  <ProfitAndLoss />
                </PrivateRoute>
              }
            />
             <Route
              path="/profit-and-loss-vertical"
              element={
                <PrivateRoute>
                  <ProfitAndLossVertical />
                </PrivateRoute>
              }
            />
            <Route
              path="/balance-sheet"
              element={
                <PrivateRoute>
                  <BalanceSheet />
                </PrivateRoute>
              }
            />
             <Route
              path="/balance-sheet-vertical"
              element={
                <PrivateRoute>
                  <BalanceSheetVertical />
                </PrivateRoute>
              }
            />
            <Route
              path="/mfg-and-pl"
              element={
                <PrivateRoute>
                  <MFGAndPL />
                </PrivateRoute>
              }
            />
            <Route
              path="/cost-center"
              element={
                <PrivateRoute>
                  <CostCenter />
                </PrivateRoute>
              }
            />
            <Route
              path="/outstanding-report"
              element={
                <PrivateRoute>
                  <Outstanding />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-sale-summary"
              element={
                <PrivateRoute>
                  <PurchaseSaleSummary />
                </PrivateRoute>
              }
            />
            <Route
              path="/sell-register-report"
              element={
                <PrivateRoute>
                  <SellRegisterReport type={1} />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-register-report"
              element={
                <PrivateRoute>
                  <SellRegisterReport type={2} />
                </PrivateRoute>
              }
            />

            <Route
              path="/sell-datewise-report"
              element={
                <PrivateRoute>
                  <SellPurchaseDatewiseReport type={1} />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-datewise-report"
              element={
                <PrivateRoute>
                  <SellPurchaseDatewiseReport type={2} />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-hsnwise-report"
              element={
                <PrivateRoute>
                  <SellPurchaseHSNHeadersReport type={2} />
                </PrivateRoute>
              }
            />
            <Route
              path="/sell-hsnwise-report"
              element={
                <PrivateRoute>
                  <SellPurchaseHSNHeadersReport type={1} />
                </PrivateRoute>
              }
            />
            <Route
              path="/sell-hsn-summary-report"
              element={
                <PrivateRoute>
                  <HSNReport type={1} />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-hsn-summary-report"
              element={
                <PrivateRoute>
                  <HSNReport type={2} />
                </PrivateRoute>
              }
            />
            <Route
              path="/sell-partywise-report"
              element={
                <PrivateRoute>
                  <SellPurchasePartyWise type={1} />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-partywise-report"
              element={
                <PrivateRoute>
                  <SellPurchasePartyWise type={2} />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-reco"
              element={
                <PrivateRoute>
                  <BankReco />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-reco-report"
              element={
                <PrivateRoute>
                  <BankRecoReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/asset-form"
              element={
                <PrivateRoute>
                  <AssetForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/asset-form/:id"
              element={
                <PrivateRoute>
                  <AssetForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/asset-list"
              element={
                <PrivateRoute>
                  <AssetTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/asset-form-view/:id"
              element={
                <PrivateRoute>
                  <AssetView />
                </PrivateRoute>
              }
            />
             <Route
              path=" /asset-calculation-preview/:id"
              element={
                <PrivateRoute>
                  <AssetPreview />
                </PrivateRoute>
              }
            />
           
            <Route
              path="/op-settlement-form"
              element={
                <PrivateRoute>
                  <OPSettlementForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/column-form"
              element={
                <PrivateRoute>
                  <ColumnSpacingForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/column-form/:id"
              element={
                <PrivateRoute>
                  <ColumnSpacingForm />
                </PrivateRoute>
              }
            />
            <Route
              path=" /sub-voucher-Details/:id"
              element={
                <PrivateRoute>
                  <ColumnSpacingForm />
                </PrivateRoute>
              }
            />
              <Route
              path="/purchase-sale-history"
              element={
                <PrivateRoute>
                  <PurchaseSaleHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/sale-purchase-view/:id"
              element={
                <PrivateRoute>
                  <SalePurchaseView />
                </PrivateRoute>
              }
            />
             <Route
              path="/balance-sheet-plant"
              element={
                <PrivateRoute>
                  <BalanceSheetByPlant />
                </PrivateRoute>
              }
            />
             <Route
              path="/MFG-plant-wise"
              element={
                <PrivateRoute>
                  <MFGPlantWise />
                </PrivateRoute>
              }
            />
             <Route
              path="/profit-and-loss-plant-wise"
              element={
                <PrivateRoute>
                  <ProfitAndLossPlantWise />
                </PrivateRoute>
              }
            />
             <Route
              path="/group-summary-plant-wise"
              element={
                <PrivateRoute>
                  <GroupSummaryByPlant />
                </PrivateRoute>
              }
            />
              <Route
              path="/b2b"
              element={
                <PrivateRoute>
                  <B2B />
                </PrivateRoute>
              }
            />
             <Route
              path="/b2c"
              element={
                <PrivateRoute>
                  <B2C />
                </PrivateRoute>
              }
            />
             <Route
              path="/b2cl"
              element={
                <PrivateRoute>
                  <B2CL />
                </PrivateRoute>
              }
            />
             <Route
              path="/cash-flow-summary"
              element={
                <PrivateRoute>
                  <CashFlowSummary/>
                </PrivateRoute>
              }
            />
             <Route
              path="/cash-flow-summary-vertical"
              element={
                <PrivateRoute>
                  <CashFlowSummaryVertical/>
                </PrivateRoute>
              }
            />
             <Route
              path="/ratio-analysis"
              element={
                <PrivateRoute>
                  <RatioAnalysis/>
                </PrivateRoute>
              }
            />
            <Route
              path="/balance-sheet-plant-vertical"
              element={
                <PrivateRoute>
                  <BalanceSheetPlantWiseVertical/>
                </PrivateRoute>
              }
            />
           <Route
              path="/export-tax-report"
              element={
                <PrivateRoute>
                  <ExportReport/>
                </PrivateRoute>
              }
            />
            <Route
              path="/balancesheet-comparision"
              element={
                <PrivateRoute>
                  <BalanceSheetComparision/>
                </PrivateRoute>
              }
            />
            
          </Routes>
        </Box>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  common: state.common,
  accessRole: state.accessRole,
});

const mapDispatchToProps = { closeNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(Landing));
