import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { B2BJson } from "../../DynamicFormsJson/Report/B2B";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithReport from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import B2BTable from "./B2BTable";

const B2B = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [cashbook, setCashbookList] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [reportData, setReportData] = React.useState({})
  useEffect(() => {
    // getList = ("01-11-2023", "30-11-2023");
  }, [])
  const getList = (fromDate, toDate) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.b2b +
        "?fromDate=" + fromDate + "&toDate=" + toDate+"&locationId="+localStorage.getItem("location")+'&type=1',
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        console.log(data.data)
        setCashbookList(data.data )

      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  }

  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };
  const  generatePDFMethod1= () => {
    var columns = [["Sr.No", "Perticuler", "Receipt", "Payment"]];
    let tableList = []
    cashbook.map(((rowData, index) => {
      let creditTotal = 0
      let debitTotal = 0
      let tableData = []

      let Perticuler = ""
      let Receipt = ""
      let Payment = ""
      rowData.transactionDetail.map((data) => {
        if (data.type == "D") {
          debitTotal = debitTotal + data.amount
        } else {
          creditTotal = creditTotal + data.amount
        }

        let refAccName = data.type == "D" ? "To " + data.refAccName : "By " + data.refAccName
        let type = data.type == "D" ? "Amt Paid To " + data.refAccName : "Amt Received "
        Perticuler = Perticuler + refAccName + "\n" + type
        Receipt = Receipt + data.type == "D" ? Math.abs(data.amount).toFixed(2) : ""
        Payment = Payment + data.type == "D" ? "" : Math.abs(data.amount).toFixed(2)

      })

      tableData = []
      let ct = creditTotal == 0 ? "\n" : Math.abs(creditTotal).toFixed(2)
      tableData.push(index + 1)
      tableData.push(rowData.date + "\n" + Perticuler + "\n\nDay Closing")
      let dt = debitTotal == 0 ? "" : Math.abs(debitTotal).toFixed(2)
      tableData.push("\n" + Receipt + "\n\n" + dt)
      tableData.push("\n" + Payment + "\n\n" + ct + "\n" + Math.abs(rowData.closingAmt)).toFixed(2)
      tableList.push(tableData)
    }))
    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 200 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate
        },
        {
          label: "Company Name",
          value: formData.name
        },
      ],
      pdfTitle: B2BJson.screenTitle,
    });

  };
  const getFilteredTableData = (tableData) => {

    let listData = []
    tableData.map((row) => {
      const subList = row["transactionDetail"].filter((currentRow) => {
        let isValid = false;

        if (
          currentRow["refAccName"] &&
          currentRow["refAccName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        return isValid;
      });
      if (subList.length != 0) {
        row = {
          ...row,
          ["transactionDetail"]: subList,
        };
        listData.push(row);
      }
    });
    return listData
  };
  const generateExcelMethod = () => {
    
    var columns = ['GSTIN/UIN of Recipient',"Invoice Number", "Invoice date","Invoice Value","Place Of Supply",'Invoice Type', "Rate", "Taxable Value", "Cess Amount"];
    let alignList = [];
    let tableData = []
    let rows = []
    let invoiceAmt=0
    let taxPercentage = 0;
    let taxableAmount = 0;
    let cessAmount = 0;
    cashbook.map((data, index) => {
         tableData = []
         invoiceAmt = invoiceAmt + data.invoiceAmt
         taxPercentage = taxPercentage + data.taxPercentage
         taxableAmount = taxableAmount + data.taxableAmount
         cessAmount = cessAmount + data.cessAmount
         tableData.push(data.gstNo)
        tableData.push(data.documentNo)
        tableData.push(data.documentDate)
        tableData.push(Math.abs(data.invoiceAmt).toFixed(2) )
        tableData.push(data.placeofSupply)
        tableData.push(data.gstType)
        tableData.push(Math.abs(data.taxPercentage).toFixed(2))
        tableData.push(Math.abs(data.taxableAmount).toFixed(2))
        tableData.push(Math.abs(data.cessAmount).toFixed(2))
        rows.push(tableData)
      })
      tableData = []
      
     tableData.push('Total')
     tableData.push('')
     tableData.push('')
     tableData.push(Math.abs(invoiceAmt).toFixed(2))
     tableData.push('')
     tableData.push('')
     tableData.push(Math.abs(taxPercentage).toFixed(2))
     tableData.push(Math.abs(taxableAmount).toFixed(2))
     tableData.push(Math.abs(cessAmount).toFixed(2))
     rows.push(tableData)

    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate

      ],
      title: B2BJson.screenTitle,
    });

  };
  const onSave = (dataToSearch) => {

    setFormData({
      ...dataToSearch
    })
    getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId)
  }
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

        <DynamicFormWithReport
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={B2BJson.showSaveBtn}
          screenTitle={B2BJson.screenTitle}
          fieldMeta={B2BJson.fieldMeta}
          showCancel={B2BJson.showCancel}
          apiBaseURL={B2BJson.apiBaseURL}
          searchName=""
          generateExcel={generateExcelMethod}
          showSaveNextBtn={B2BJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          onSearch={onSearch}
          showPDFDownload={false}
          showSearchBox={false}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br/>
        <B2BTable tableHead={[
          {
            title: "GSTIN/UIN of Recipient",
            align: "left"
          },

          {
            title: "Invoice Number ",
            align: "left",
            
          },
          {
            title: "Invoice date",
            align: "right",
            width: "12%"
          },
          {
            title: "Invoice Value",
            align: "right",
            width: "12%"
          },
          {
            title: "Place Of Supply",
            align: "right",
            width: "12%"
          },
          {
            title: "Invoice Type",
            align: "right",
            width: "12%"
          },
          {
            title: "Rate",
            align: "right",
            width: "12%"
          },
          {
            title: "Taxable Value",
            align: "right",
            width: "12%"
          },
          {
            title: "Cess Amount",
            align: "right",
            width: "12%"
          },
        ]} rowList={cashbook} />

      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(B2B);
