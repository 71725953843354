import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { CashReceiptJSON } from "../../DynamicFormsJson/Transaction/cashReceipt";
import { ButtonCompo } from "../../components/Comman/Button";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { deleteFailedMsg } from "../../config/messageconstant";
import { apiDelete } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";

import swal from "sweetalert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BillMappingDialouge = ({
  open,
  getAuditQestionList,
  handleCloses,
  onSave,
  dataToSave,
  showLoador,
  showNotification,
  cashRecieptList,
  dynamicMasterData,
}) => {
  const [fieldData, setFieldData] = React.useState({});
  const [costMappingList, setCostMappingList] = React.useState([]);
  const [dynamicMasterDataMain, setDynamicMasterDataMain] = React.useState({});

  useEffect(() => {
   
   
    if (
      dataToSave &&
      dataToSave.recieptPaymentDetailBillInfoList.length !== 0 &&
      costMappingList.length == 0
    ) {
      console.log(dataToSave.recieptPaymentDetailBillInfoList);
      let rowList = dataToSave.recieptPaymentDetailBillInfoList.map(
        (rowData, index) => {
          let catList = dynamicMasterDataMain["billDetail"]
            ? dynamicMasterDataMain["billDetail"].filter(
                (row) => row.id == rowData.billId
              )
            : [];
          console.log(catList);
          return {
            index: index + 1,
           mainId:rowData.mainId? rowData.mainId: rowData.id,
            billId: rowData.mainId? rowData.mainId: rowData.id,
            billIdMain: rowData.billIdMain?rowData.billIdMain:rowData.billId,
            billName: rowData.billNo,
            billNo: rowData.billNo,
            amount: +rowData.amount,
            type: rowData.type,
          };
        }
      );
      setCostMappingList(rowList);
      setDynamicMasterDataMain({...dynamicMasterData,billDetail:dynamicMasterData.billDetail.filter((billDetail)=>!rowList.some((main)=>billDetail.id==main.billIdMain))})
    }
     if (
      dataToSave &&
      dataToSave.recieptPaymentDetailBillInfoList.length == 0 
    ) {
      
      setDynamicMasterDataMain(dynamicMasterData)
    }
  }, []);
  const handleClose = () => {
    if (dataToSave && dataToSave.recieptPaymentDetailBillInfoList.length == 0) {
      setCostMappingList([]);
    }
    handleCloses(
      costMappingList,
      dataToSave && dataToSave.index
    );
  };

  const submitHandler = (dataToSet) => {
    console.log(dataToSet);
    console.log(dataToSave);
    let catList = dynamicMasterDataMain["billDetail"]
      ? dynamicMasterDataMain["billDetail"].filter(
          (row) => row.id == dataToSet.billId
        )
      : [];
    let saveFlag = +dataToSet.amount <= catList[0].remainingAmt;
    if (saveFlag) {
      let amount = 0;
      costMappingList.map((cost) => {
        amount = amount + cost.amount;
      })
      if (amount + (+dataToSet.amount) > (dataToSave && +dataToSave.amt)) {

        showNotification({
          msg: "Total amount should be less than detail amount",
          severity: "error",
        })
        return
      } 
      let billDetailFilter = dynamicMasterDataMain["billDetail"]
      ? dynamicMasterDataMain["billDetail"].filter(
          (row) => row.id !== dataToSet.billId
        )
      : []; 
      setDynamicMasterDataMain({...dynamicMasterDataMain,
        billDetail:billDetailFilter
      })
      const costMappingdata = {
        index: costMappingList.length + 1,
        billId: dataToSet.billId,
        billName: catList[0].documentNo,
        billNo: catList[0].documentNo,
        amount: +dataToSet.amount,
        type: 1,
      };
      console.log(costMappingList);
      let costMapping = [...costMappingList, costMappingdata];
      console.log(costMapping);
      setCostMappingList(costMapping.sort((a, b) => a.orderNo - b.orderNo));
    }else{
      showNotification({
        msg: "Amount should be less than or equal to remaining amount.",
        severity: "error",
      });
    }
  };

  let columns = [
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "billName",
      title: "Bill Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "amount",
      title: "Amount",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "action",
      title: "Actions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  const rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (rowData.id) {
          onDelete(rowData);
        }
        const filteredList = costMappingList.filter(
          (row) => row.index !== rowData.index
        );
        let billDetailFilter = dynamicMasterData["billDetail"]
        ? dynamicMasterData["billDetail"].find(
            (row) => row.id == rowData.billId
          )
        : []; 
        setDynamicMasterDataMain({...dynamicMasterDataMain,
          billDetail:[...dynamicMasterDataMain.billDetail,billDetailFilter]
        })
        setCostMappingList(
          filteredList.map((costMappingData, index) => {
            return { ...costMappingData, index: index + 1 };
          })
        );
      }
    });
  };

  const onDelete = (row) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.auditOption + "/" + row.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          getAuditQestionList();
        } else {
          showNotification({ msg: deleteFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xl"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Bill Mapping
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DynamicFormWithoutSave
            paddingTop={false}
            showTitle={false}
            showBackToList={false}
            // DataToSet={dataToSet}
            dynamicMasterData={dynamicMasterDataMain}
            getByIdApi={false}
            callApi={false}
            screenTitle={CashReceiptJSON.screenTitle}
            fieldMeta={CashReceiptJSON.fieldMetaForOption1}
            showCancel={CashReceiptJSON.showCancel}
            apiBaseURL2={CashReceiptJSON.apiBaseURL2}
            showSaveNextBtn={CashReceiptJSON.showSaveNextBtn}
            saveBtnText={"Add"}
            resetAfterSave={true}
            setFieldData={fieldData}
            onSave={submitHandler}
          />
          <br />
          <Grid item xs={12} sm={12} md={12}>
            <DynamicTable
              data={costMappingList}
              tableHead={columns}
              showHeadDelete={true}
              showHeadEdit={false}
              //   rowEdit={rowEdit}
              showPegination={false}
              rowDelete={rowDelete}
            />
          </Grid>
          
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  feedBack: state.feedBack,
  cashRecieptList: state.cashReciept,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  // getCostCenter
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillMappingDialouge);
