import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { ButtonCompo } from "../../components/Comman/Button";
import { settlementJSON } from "../../DynamicFormsJson/Transaction/OpSettlement";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { apiGet, apiPost } from "../../utils/api_service";
import { apiPostForImage } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import OpeningSettlementTable from "./OpeningSettlementTable";
import { LabelCompo } from "../../components/Comman/Label";
import AttachFile from "../../components/Comman/AttachFile";
import { redColor } from "../../config/ColorObj";

class OPSettlementForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {},
      formErrors: {},
      fieldData: {},
      headerData: {},
      DataToSet: [],
      historyData: {},
      rowList: [],
      companyId: "",
      yearId: "",
      headerData: {},
      fileName: "",
      error: false,
    };
  }

  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.getDropDownList();
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getDropDownList = async () => {
    const { showLoador, showNotification } = this.props;
    showLoador({ loador: true });
    const yearMasterDetail = await apiGet({
      url: endpoint.year,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        let rowData = data.data;

        showLoador({ loador: false });
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.yearName };
        });
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    showLoador({ loador: true });
    const CompanyDetails = await apiGet({
      url: endpoint.company,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data.data);
        let rowData = data.data;
        showLoador({ loador: false });
        return rowData
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    showLoador({ loador: true });
    const accountLedgerGroupMasterDetail = await apiGet({
      url: endpoint.accountLedgerGroupMaster,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        console.log(data);
        let rowData = data.data;

        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.groupName };
        });
      } else {
        showLoador({ loador: false });
      }
      return [];
    });
    this.setState({
      dynamicMasterData: {
        accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetail,
        yearMasterDetail: yearMasterDetail,
        CompanyDetails: CompanyDetails,
      },
    });
  };
  checkValidationOnSubmit = () => {
   return this.mandatoryCheck();
  };
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    settlementJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  onSubmitData = () => {
   
    if (this.checkValidationOnSubmit()) {
      const {
       
        fieldData,
       
      } = this.state;
    
        console.log(fieldData);
       
              this.onSearchList(fieldData);
         
     
      
    }
  };
  onSearchList = (data) => {
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      if (data != null) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.yearEnd +
            "/by-comoany-and-year-id?yearId=" +
            data.yearId +
            "&companyId=" +
            data.companyId +
            "&groupIds=" +
            data.accountLedgerGroupsId,
        }).then(({ data, success }) => {
          console.log("data");
          console.log(data.data);
          showLoador({ loador: true });
          if (success) {
            let obj = Object.keys(data.data).length != 0 ? data.data : "";
            this.setState({ headerData: obj });

            const rowList =
              Object.keys(data.data).length != 0 &&
              data.data.yearEndDetailList != null
                ? data.data.yearEndDetailList.map((row, index) => {
                    let dtoYearEndDetail = row.dtoYearEndDetail.map(
                      (rowInfo, index) => {
                        index = index + 1;
                        return {
                          index: index,
                          id: rowInfo.yearEndDetailId,
                          accId: rowInfo.accId,
                          yearEndId: rowInfo.yearEndId,
                          accountName: rowInfo.accountName,
                          openingAmtCredit:
                            rowInfo.openingAmt >= 0
                              ? Math.abs(rowInfo.openingAmt)
                              : 0,
                          openingAmtDebit:
                            rowInfo.openingAmt >= 0
                              ? 0
                              : Math.abs(rowInfo.openingAmt),
                          accountLedgerCode: rowInfo.accountLedgerCode,
                          closingAmt: rowInfo.closingAmt,
                        };
                      }
                    );
                    return {
                      ...row,
                      dtoYearEndDetail: dtoYearEndDetail,
                    };
                  })
                : [];

            this.setState({
              rowList: rowList,
            });

            showLoador({ loador: false });
          } else {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
            showLoador({ loador: false });
          }
        });
      }

      this.setState({ companyId: data.companyId, yearId: data.yearId });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSubmit = () => {
    const { companyId, rowList, yearId, headerData } = this.state;
    let error = false;
    let yearEndDetailList = [];
    rowList.map((rowData) => {
      rowData.dtoYearEndDetail.map((YearEndDetail) => {
        yearEndDetailList.push({
          ...YearEndDetail,
          companyId: companyId,
          openingAmt:
            YearEndDetail.openingAmtCredit == 0
              ? "-" + YearEndDetail.openingAmtDebit
              : YearEndDetail.openingAmtCredit,
        });
      });
    });
    let dataTosave = {
      id: headerData.id,
      company: {
        id: companyId,
      },
      year: {
        id: yearId,
      },
      status: headerData.status,
      yearEndDetailList: yearEndDetailList,
    };
    console.log(dataTosave);
    if (!error) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataTosave);
        }
      });
      console.log("dataTosave");
      console.log(dataTosave);
    }
  };
  onSave = (beatNewObjectToSave) => {
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.yearEnd,
        postBody: beatNewObjectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.props.navigate("/op-settlement-form");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  setListDetails = ({ row, name, rowObj, rowDetailObj }) => {
    let rowList = row.map((rowD, indexData) => {
      let dtoYearEndDetail = rowD.dtoYearEndDetail;
      if (rowObj.accountLedgerGroupId == rowD.accountLedgerGroupId) {
        dtoYearEndDetail = rowD.dtoYearEndDetail.map((rowData, indexData) => {
          if (rowData.id === rowDetailObj.id) {
            rowData = {
              ...rowData,
              openingAmtDebit:
                name == "openingAmtCredit" ? 0 : rowData.openingAmtDebit,
              openingAmtCredit:
                name == "openingAmtDebit" ? 0 : rowData.openingAmtCredit,
            };
          }
          return rowData;
        });
      }
      return {
        ...rowD,
        dtoYearEndDetail: dtoYearEndDetail,
      };
    });

    this.setState({ rowList: rowList });
  };

  onCancel = () => {
    this.props.navigate(-1);
  };
  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };
  onFormUpdate = () => {
    if (this.checkValidationOnSubmit()) {
    const { companyId, rowList, yearId, headerData,fileName } = this.state;
    let location = localStorage.getItem("location");
    console.log("location");
    console.log(location)
    if (fileName != "") {
      const formData = new FormData();
      formData.append("dataFile", fileName);
      this.props.showLoador({ loador: true });
      apiPostForImage({
        url:
          endpoint.importData +
          "/opening-settlement?locationId="+location+"&yearEndId="+yearId+"&companyId="+companyId,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          if (data.data.error) {
            this.props.showNotification({
              msg: data.data.message,
              severity: "error",
            });
          } else {
            this.props.showNotification({
              msg: "Data imported successfully",
            });
          }
          this.props.showLoador({ loador: false });
        } else {
          this.props.showNotification({ msg: serverMsg, severity: "error" });
          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.setState({
        error: true,
      });
    }
    console.log(fileName);
  }
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    settlementJSON.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = this.getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  render() {
    const { dynamicMasterData, DataToSet, fieldData, formErrors, rowList, fileName, error } =
      this.state;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutValidation
            fieldData={fieldData}
            formErrors={formErrors}
            showBackToList={false}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            getByIdApi={true}
            callApi={true}
            onDataChange={this.onDataChange}
            showTitle={settlementJSON.showTitle}
            screenTitle={"Opening Settlement"}
            paddingTopGrid={true}
            fieldMeta={settlementJSON.fieldMeta}
            showCancel={settlementJSON.showCancel}
            apiBaseURL={settlementJSON.apiBaseURL}
            showSaveNextBtn={settlementJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            onFormSave={this.onSubmitData}
          />
        
              <Grid container sx={{ marginTop: 2 }} spacing={1}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} sm={1} md={1} lg={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AttachFile
                      name={"file"}
                      fileName={this.changeH}
                      allow={false}
                    />
                    </Grid>
                      {error && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 8, color: redColor }}
                          label={"*Required "}
                        />
                      </Grid>
                    )}
                  
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
               
                 &nbsp; {fileName !== "" ? fileName.name : ""}
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={8}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Import Data"
                      // fullWidth={true}
                      onClick={this.onFormUpdate}
                    />
                  </Grid>
                </Grid>
              </Grid>
          <br />
          <OpeningSettlementTable
            getName={true}
            tableHead={[
              {
                title: "Sr.No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Account Name",
                name: "accountName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Op credit",
                name: "openingAmtCredit",
                placeHolder: "Enter Op credit",
                textFieldError: "creditError",
                error: "Op credit",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                inputType: "number",
                alignCenter: "left",
              },
              {
                title: "Op Debit",
                name: "openingAmtDebit",
                placeHolder: "Enter Op Debit",
                textFieldError: "OpDebitError",
                error: "Op Debit",
                textField: true,
                inputType: "number",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
            ]}
            data={rowList}
            setList={this.setListDetails}
            isActionColActive={false}
            showHeadDelete={false}
          />

          <br />

          <br />
          {rowList.length != 0 && (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              {/* <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Cancel"
                onClick={() => this.props.navigate(-1)}
              /> */}
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                onClick={this.onSubmit}
              />
            </Grid>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OPSettlementForm);
