import React from "react";
import { connect } from "react-redux";
import { AssetJSON } from "../../DynamicFormsJson/Transaction/Asset";
import HeaderTitle from "../../components/HeaderTitle";
import { compose } from "@reduxjs/toolkit";

import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg} from "../../config/messageconstant";
import { AssetColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";


import { apiGet } from "../../utils/api_service";
import AssetView from "./AssetView";
import { Paper } from "@mui/material";
import { withRouter } from "../../components/withRouter";
class AssetTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        
      },
    rowList:[],
    openTable:false,
    formData:{},
    };
  }
  componentDidMount(){
    const { showLoador, showNotification } = this.props;
  
      showLoador({ loador: true });
       apiGet({
        url: endpoint.yearEnd + "/get-year-fromDate-toDate",
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          console.log(data.data);
          let rowData = data.data
          this.setState({
            formData:rowData
          })
          this.onSearchData(rowData)
          // dataToReset["fromDate"] = dayjs(rowData.fromDate, "DD-MM-YYYY");
          // dataToReset["toDate"] = dayjs(rowData.toDate, "DD-MM-YYYY");
        } else {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
          showLoador({ loador: false });
        }
      });
    
  }
  onSearch=()=>{
    this.onSearchData(this.state.formData)
  }
 onSearchData = async (data) => {
    const { showLoador, showNotification } = this.props;
  
    console.log(data);
    const locationId = localStorage.getItem("location");
    if (navigator.onLine) {
       
      showLoador({ loador: true });
      if (data != null) {
        showLoador({ loador: true });
        apiGet({
          
         
          url:
            endpoint.asset+"/by-date?fromDate="+ data.fromDate+"&toDate="+data.toDate+"&locationId="+locationId,
        }).then(({ data, success }) => {
         
          showLoador({ loador: true });
          if (success) {
          let codeIds=[]
           const rowList = data.data.length!=0?data.data.map((row,index) => {
             codeIds.push(row.ledgerCode)
             index=index+1
              return {
                
                index:index,
               id:row.id,
                ledgerCode:row.ledgerCode,
                date:row.date,
                  depreciationPercentage:row.depreciationPercentage,
                openingValue:row.openingValue,
               
                purchaseInFirstHalf:row.purchaseInFirstHalf,
                purchaseInSecondHalf:row.purchaseInSecondHalf,
            
                sellValue:row.sellValue,
                total:row.openingValue+row.purchaseInFirstHalf+row.purchaseInSecondHalf-row.sellValue,
                depriValue:row.depriValue,
                closingValue:row.closingValue,
                // profit:row.remark,
                remark:row.remark,
               locationName:row.location!=null && row.location.name?row.location.name:"",
              
              };
            }):[];
            console.log("rowList")
            console.log(rowList)
           
           sessionStorage.setItem('accountcode',codeIds.join(","))
            this.setState({
              rowList: rowList,
              });
            showLoador({ loador: false })
          }else{
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
            showLoador({ loador: false })
          }
        });
       
         
        };
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  
 
  
  
 

  rowView = (rowData) => {
    this.setState({
      openTable:true,
      formData:rowData,
       
      
    })
    // window.location.replace("/asset-form-view/"+ rowData.id);
  };
  onAddButtonClick=()=>{
    this.props.navigate(AssetJSON.formPath );
  }
  toggle = () =>  {
   
    this.setState({
      openTable:!this.state.openTable,
      
       
      
    })
    };
  render() {
    const { dynamicMasterData,rowList,openTable,formData } = this.state;
    console.log("rowList")
  console.log(rowList)
    // console.log(cashRecieptList?.cashReciept);
    return (
      <>
      <Paper sx={{ borderRadius: 2,padding:2 }} elevation={0}>
      <HeaderTitle
          tableColumnsToFilter={[]}
         
          screenTitle={AssetJSON.screenTitle}
          showSearchBox={false}
          showPdfDownload={false}
          importExcel={false}
         
          showExcelDownload={false}
          showAddButton={true}
         
          onAddButtonClick={this.onAddButtonClick}
         
        /> 
      <DynamicMainScreen
          showDeleteIcon={true}
          dynamicMasterData={dynamicMasterData}
          searchList={[]}
          onSearchData={this.onSearch}
          formPathView={AssetJSON.formPathView}
          formPath={AssetJSON.formPath}
          screenTitle={"Asset Calculation "}
          fieldMeta={[]}
          rowView={this.rowView}
          showPdfDownload={false}
          showExcelDownload={false}
          staticView={true}
          isActiveURL={AssetJSON.isActiveURL}
          showAddButton={AssetJSON.showAddButton}
          tableColumnsToFilter={[]}
          tableColumns={AssetColumns}
          tableData={rowList}
          apiBaseURL={AssetJSON.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={true}
          showPdf={false}
          // rowDelete={this.rowDelete}
         
          showPegination={false}
          isNote={false}
          buttonCenter={false}
         
          showEditIcon={true}
          showViewButtonInTable={true}
        
        />
         {openTable && (
        <AssetView
          
          
          open={openTable}
          handleCloses={this.toggle}
          popUpData={Object.keys(formData).length!=0?formData:{}}
         
          
        />
      )}
      </Paper>
       
      </>
    );
  }
}

const mapStateToProps = (state) => ({
 
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  
  
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssetTable);