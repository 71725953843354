// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import GroupSummarySubReportByGroup from '../AccountReport/GroupSummarySubReportByGroup'
import { useState } from 'react'

// ** Icon Imports

// ** Custom Components Imports
// import CustomAvatar from 'src/@core/components/mui/avatar'


const SimpleT = ({ formData={},toggle, rowLink,isTotal,rowList}) => {
    
  
    const onClickLink = (params, key) => {
     
        rowLink(params, key);
      };
  let total=0
  return (
  <>
        <table style={{width:'100%'}}>
        {rowList.map((item, index) => {
          if(isTotal){
           let amt= item.amount?+item.amount:0
            total=total+ amt
          }
         
          return (
          
                <tr style={{height:30}}>
                    {
                        item.id?<td style={{cursor: "pointer", }} onClick={(e) => onClickLink( item, 'name')}>
                        <Typography sx={{ fontWeight: 400, color:"#666665",fontSize: '0.875rem' }}>{item.name}</Typography>
                        
                        </td>:
                        <td >
                        <Typography sx={{ fontWeight: 400, color:"#666665",fontSize: '0.875rem' }}>{item.name}</Typography>
                        
                        </td>
                    }

<td align='right'>
<Typography sx={{ fontWeight: 500 }}>{item.value}</Typography>
</td>
                </tr>
         
          )
        })}
        {isTotal&&
          <tr style={{height:30}}>
<td>
<Typography sx={{ fontWeight: 400, color:"#666665",fontSize: '0.875rem' }}>Total</Typography>
</td>
<td align='right'>
<Typography sx={{ fontWeight: 500 }}>{Math.abs(total).toFixed(2)}</Typography>
</td>
                </tr>
}
           </table>
           
       
     
  
  </>
      
  )
}

export default SimpleT


{/* <Box
key={index}
sx={{
  display: 'flex',
  alignItems: 'center',
  mb: 1.6 
}}
>
<Box
  sx={{
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  }}
>
  <Box sx={{ mr: 2, display: 'flex', flexDirection: 'column' }}>
    <Typography sx={{ fontWeight: 400, color:"#666665",fontSize: '0.875rem' }}>{item.name}</Typography>
  </Box>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      '& svg': { color: item.trendDir === 'down' ? 'error.main' : 'success.main' }
    }}
  >
    <Typography sx={{ mr:4, fontWeight: 500 }}>{item.value}</Typography>
  </Box>
</Box>
</Box> */}